import React, { PureComponent } from 'react';
import toastIconWarning from './img/icon_warning.svg';

export default class IconWarningToast extends PureComponent {
  render() {
    return (
      <div className="contentAvatarToastr">
        <img src={toastIconWarning} alt="avatar" className="icon_warning" />
      </div>
    );
  }
}
