import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FormInput from "../../../../components/Inputs/FormInput";
import FormSelectInput from "../../../../components/Inputs/FormSelectInput";
import InputViewEdit from "../../../../components/Inputs/InputViewEdit";
import SelectInput from "../../../../components/Inputs/SelectInput";
import { conselhoService } from "../../../../services/conselho.service";
import { cooperadoService } from "../../../../services/cooperado.service";
import { userService } from "../../../../services/user.service";
import { hospitalService } from "../../../../services/hospital.service";
import SelectableInput from "../../../../sascComponents/inputs/SelectableInput";
import _ from "lodash";
import CheckBoxInput from "../../../../components/Inputs/CheckBoxInput";

class DadosContratadoGuiaConsultaForm extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
  }
  findConselho = () => {
    conselhoService
      .conselhoSolicitante(this.props.values.numeroCarteirinha)
      .then(response => { });
  };

  verificaRetorno = () => {  
    this.props.verificaRetorno(`atendimentoExecucaos[0].tipoConsulta`)
  };

  render() {
    const {
      permissions,
      setFieldValue,
      values,
      errors,
      handleChange,
      viewMode,
      user
    } = this.props;
    const extension = userService.getExtensionUser(this.props.user);
    
    return (
      <div className="row section-form">
        <h6 className="w-100">Dados do Contratado</h6>
        <SelectableInput viewMode={viewMode} atendimento={values} className="fixo mobile-100"
          name={"codigoOperadoraContratada"}
          label={"Código na operadora"}
          value={values.codigoOperadoraContratada}
        >
          <InputViewEdit
            label={"Código na operadora"}
            component={FormInput}
            required={true}
            onChange={(name, date) => {
              setFieldValue(name, date);
            }}
            name={"codigoOperadoraContratada"}
            id={"codigoOperadoraContratada"}
            erroMensagem={errors.codigoOperadoraContratada}
            placeholder=""
            type={"text"}
            viewMode={extension || viewMode}
            value={values.codigoOperadoraContratada}
            disabled={true}

          />
        </SelectableInput>

        {values.cooperativa && values.cooperativa.utilizaHospitalLocal&&(
        <SelectableInput viewMode={viewMode} atendimento={values} className="fixo"
                               name={"hospitalLocal"}
                               label={"Hospital / Local"}
                               value={values.hospitalLocal}>
              <InputViewEdit
                  label="Hospital / Local"
                  disabled={!values.convenio}
                  required={true}
                  component={FormSelectInput}
                  parent={{type:values.type,convenio:values.convenio}}
                  service={hospitalService.findHospitalConvenioPorTipoGuia}
                  sortKey={"nome"}
                  className={"destaque"}
                  guia={values.type}
                  id={"hospitalLocal"}
                  name={"hospitalLocal"}
                  returnFullObject={true}
                  erroMensagem={_.get(errors, "hospitalLocal.id",_.get(errors, "hospitalLocal"))}
                  searchable={true}
                  labelKey={"nome"}
                  valueKey={"id"}
                  defaultValue={values.hospitalLocal ? values.hospitalLocal.nome : "--"}
                  value={values.hospitalLocal}
                  viewMode={viewMode}
                  onChange={(name, value) => {
                      setFieldValue(name, value);
                  }}
                  alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}

              />
              </SelectableInput>
        )}
        <SelectableInput viewMode={viewMode} atendimento={values} className=""
          name={"nomeContratado"}
          label={"Nome do contratado"}
          value={values.nomeContratado}>
          <InputViewEdit
            label={"Nome do contratado"}
            component={FormInput}
            required={false}
            disabled={false}
            onChange={(name, date) => {
              setFieldValue(name, date);
            }}
            name={"nomeContratado"}
            erroMensagem={errors.nomeContratado}
            placeholder=""
            type={"text"}
            viewMode={extension || viewMode}
            value={values.nomeContratado}
          />
        </SelectableInput>
        <SelectableInput viewMode={viewMode} atendimento={values} className="fixo"
          name={"cnesContratado"}
          label={"Código CNES"}
          value={values.cnesContratado}>
          <InputViewEdit
            label={"Código CNES"}
            component={FormInput}
            required={false}
            disabled={false}
            onChange={(name, date) => {
              setFieldValue(name, date);
            }}
            name={"cnes"}
            erroMensagem={errors.cnesContratado}
            placeholder=""
            type={"text"}
            viewMode={extension || viewMode}
            value={values.cnesContratado}
          />
        </SelectableInput>
        <div className="quebra"></div>
        
        {/*<SelectableInput viewMode={viewMode} atendimento={values} className="fixo"
          name={"cooperado.conselho"}
          label={"Conselho profissional"}
          value={_.get(values, 'cooperado.conselho.sigla')}>
          <InputViewEdit
            label={"Conselho profissional"}
            component={FormSelectInput}
            loadOnInit
            id={"cooperadoconselho"}
            required={false}
            service={conselhoService.findAll}
            className={"destaque"}
            name={"cooperado.conselho"}

            erroMensagem={
              errors &&
              errors.cooperado &&
              errors.cooperado.conselho &&
              errors.cooperado.conselho.id
            }
            returnFullObject={true}
            searchable={true}
            defaultValue={
              _.get(values, 'cooperado.conselho.sigla')
            }
            labelKey={"sigla"}
            valueKey={"id"}
            viewMode={viewMode}
            value={values.cooperado ? values.cooperado.conselho : ""}
            onChange={(name, value) => {
              setFieldValue(name, value);
            }}
            defaultSelected={true}
            

          />
        </SelectableInput>
        <SelectableInput viewMode={viewMode} atendimento={values} className="central fixo"
          name={"cooperado.ufConselho"}
          label={"UF"}
          value={values.cooperado && values.cooperado.ufConselho}>
          <InputViewEdit
            label={"UF"}
            id={"cooperadoufConselho"}
            required={false}
            component={FormSelectInput}
            onChange={(name, date) => {
              setFieldValue(name, date);
            }}
            name={"cooperado.ufConselho"}
            erroMensagem={
              errors &&
              errors.cooperado &&
              errors.cooperado.ufConselho
            }
            placeholder=""
            viewMode={viewMode}
            parent={values.cooperado && values.cooperado.ufConselho && values.cooperado.conselho.id}
            service={conselhoService.findUfsConselho}
            value={values.cooperado ? values.cooperado.ufConselho : ""}
          />
        </SelectableInput>*/}

                    <SelectableInput viewMode={viewMode} atendimento={values} className="central"
                      name={`cooperado.conselho`}
                      label={"Conselho profissional"}
                      value={_.get(values, 'cooperado.conselho.sigla')}
                    >

                      <InputViewEdit
                        label="Conselho profissional"
                        component={SelectInput}
                        onChange={(name, date) => {
                          setFieldValue(name, date);
                        }}
                        className={""}
                        id={`cooperadoconselho`}
                        name={`cooperado.conselho`}
                        placeholder=""
                        viewMode={viewMode}
                        required={false}
                        erroMensagem={
                          _.get(errors, `cooperado.conselho.sigla`)
                        }
                        valueKey={"id"}
                        labelKey={"sigla"}
                        returnFullObject={true}
                        defaultSelected={true}
                        value={_.get(values, 'cooperado.conselho')}
                        defaultValue={
                          _.get(values, 'cooperado.conselho.sigla')
                        }
                        options={this.props.listConselho}
                      />
                    </SelectableInput>

                    <SelectableInput viewMode={viewMode} atendimento={values} className="central"
                      name={`cooperado.ufConselho`}
                      label={"UF"}
                      value={values.cooperado && values.cooperado.ufConselho}
                    >
                      <InputViewEdit
                        label="UF"
                        component={SelectInput}
                        onChange={(name, date) => {
                          setFieldValue(name, date);
                        }}
                        className={""}
                        id={`cooperadoufConselho`}
                        name={`cooperado.ufConselho`}
                        placeholder=""
                        viewMode={viewMode}
                        required={false}
                        erroMensagem={_.get(errors, `cooperado.ufConselho`)}
                        valueKey={"id"}
                        labelKey={"nome"}
                        returnFullObject={false}
                        defaultSelected={true}
                        value={values.cooperado ? values.cooperado.ufConselho : ""}
                        defaultValue={values.cooperado ? values.cooperado.ufConselho : ""}
                        options={this.props.listUfsConselho}
                      />
                    </SelectableInput>

        <SelectableInput viewMode={viewMode} atendimento={values} className="fixo"
          name={"cooperado.conselho.numero"}
          label={"Número do conselho"}
          value={values.cooperado && values.cooperado.numeroConselho
            //&& values.cooperado.conselho
            //&& values.cooperado.conselho.numero
          }>
          <InputViewEdit
            label={"Número do conselho"}
            required={true}
            component={SelectInput}
            onChange={(name, date) => {
              setFieldValue("cooperado", date);
              setFieldValue("identificacaoAtendimentoExecucao[0].cooperado", date);
            }}
            name={"cooperado.conselho.numero"}
            id={"cooperadoconselhonumero"}
            erroMensagem={
              errors &&
              errors.cooperado &&
              errors.cooperado.numeroConselho
            }
            placeholder="Nº do Conselho"
            viewMode={viewMode}

            valueKey={"numeroConselho"}
            labelKey={"numeroConselho"}
            returnFullObject={true}
            value={values.cooperado && !_.isEmpty(values.cooperado) && values.cooperado}
            defaultValue={values.cooperado && values.cooperado.conselho && values.cooperado.numeroConselho}
            parent={values.estabelecimento.id}
            onFetchData={cooperadoService.findByNomeOuConselhoEstabelecimento}
            //onFetchData={cooperadoService.findByNomeOuConselho}
            onBlur={this.verificaRetorno}
            alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
          />
        </SelectableInput>
        <SelectableInput viewMode={viewMode} atendimento={values} className=""
          name={"cooperado"}
          label={"Nome do profissional"}
          value={values.cooperado && values.cooperado.nome}>
          <InputViewEdit
            label={"Nome do profissional"}
            required={true}
            component={SelectInput}
            parent={values.estabelecimento.id}
            onFetchData={cooperadoService.findByNomeEstabelecimento}
            //onFetchData={cooperadoService.findByNome}
            className={"destaque"}
            name={"cooperado"}
            id={"cooperadonome"}
            erroMensagem={
              _.get(errors, `cooperado.nome`)
            }
            returnFullObject={true}
            searchable={true}
            defaultValue={values.cooperado &&values.cooperado.nome ? values.cooperado.nome : "--"}
            labelKey={"nome"}
            valueKey={"id"}
            value={values.cooperado}
            viewMode={viewMode}
            onChange={(name, value) => {
              setFieldValue(name, value);
              setFieldValue("identificacaoAtendimentoExecucao[0].cooperado", value);
            }}
            onBlur={this.verificaRetorno}
            alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
          />
        </SelectableInput>
        <SelectableInput viewMode={viewMode} atendimento={values} className="fixo mobile-100"
          name={"codigoCboSolicitante"}
          label={"Código CBO"}
          value={values.codigoCboSolicitante}>
          <InputViewEdit
            label={"Código CBO"}
            required={true}
            component={FormInput}
            onChange={(name, date) => {
              setFieldValue(name, date);
            }}
            name={"codigoCboSolicitante"}
            id={"codigoCboSolicitante"}
            erroMensagem={errors.codigoCboSolicitante}
            placeholder=""
            type={"text"}
            viewMode={viewMode}
            disabled={true}
            value={values.cooperado && values.cooperado.codigoCbo}
          />
        </SelectableInput>
        {!this.props.match.params.id && values.estabelecimento.enviarClinica && <CheckBoxInput
          className="mb-0 pb-1"
          label="Enviar Cooperado Recebedor PJ"
          name="checkItem"
          disabled={viewMode}
          checked={_.get(values, "enviarPj")}
          onChange={(name, checked) => {
            _.set(values, 'enviarPj', checked);
          }}
        />}
      </div>
    );
  }
}

const mapDispatch = ({ menu: { showMenu, hideMenu } }) => ({
  showMenu: () => showMenu(),
  hideMenu: () => hideMenu()
});

function mapStateToProps(state) {
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    alert,
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(DadosContratadoGuiaConsultaForm));
