import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CheckBoxInput from "../../../components/Inputs/CheckBoxInput";
import { modeloVisualizacaoService } from "../../../services/modeloVisualizacao.service";
import _ from "lodash";
import { ConfirmAcationService } from "../../../services/ConfirmAcationService";
import { objectsConstants } from "../../../constants/objects.constants";

class PassoAPasso extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
  }

  confirmCancelBack = () => {
    localStorage.removeItem('rascunho');
    this.props.history.goBack();
  };

  smoothScroll = h => {
    const { etapaAtual } = this.props;
    let element = `passo_${_.get(etapaAtual, "etapa", "0")}`;
    const scrolTo = document.getElementById(element);
    const guia = document.getElementById('guia');
    const topo = document.getElementById('passoPasso').getBoundingClientRect().bottom;
    const novaAltura = guia.getBoundingClientRect().height + 400;
    guia.style.height = novaAltura + 'px';
    scrolTo.scrollIntoView(true);
    window.scrollBy({
      top: -topo,
      left: 0,
      behavior: "smooth"
    });

  };

  handelProximoPasso = () => {
    this.props.handelProximoPasso().then(valid => {
      if (valid) {
        this.smoothScroll();
      }
    });
  };

  render() {
    const { etapaAtual, etapas } = this.props;
    let disabled = etapaAtual != null ? false : true;
    return (
      <div id="passoPasso" className="col-12 p-3 d-flex align-items-center flex-column flex-md-row topo-guia bg-branco passo-passo">
        <div className="px-2 py-2 py-md-0">
          <p className="text-center mb-0">PASSO</p>
          <div className="big-badge">{_.get(etapaAtual, "etapa", "0")}</div>
        </div>
        <div className="pl-0 pl-md-5 py-2 py-md-0 w-40 mobile-100">
          <h6 className="mb-1">
            <b>
              {_.get(
                etapaAtual,
                "titulo",
                "Criação de atendimento passo a passo"
              )}
            </b>
          </h6>
          <p className="mb-1">
            {_.get(
              etapaAtual,
              "subTitutlo",
              "Agora você precisa informar qual tipo de atendimento, se é Consulta, Honorário  ou SADT."
            )}
            {" "}
          </p>
          <p className="mb-1">
            <b>
              {_.get(
                etapaAtual,
                "ajudaParaAvancar",
                "Selecione o Tipo de guia de atendimento para avançar"
              )}
            </b>
          </p>
        </div>
        <div className="px-0 px-md-5 py-2 py-md-0 ml-md-auto ">
          <CheckBoxInput
            label={"Criar a guia com o passo a passo"}
            name={"criarPassoApasso"}
            id={"criarPassoApasso"}
            checked={this.props.user.criarGuiaPassoPasso}
            className="my-auto"
            onChange={(name, checked) => {
              this.props.handleChangeCriarGuiaPassoPasso(checked);
            }}
          />
        </div>
        <div className="px-0 px-md-2">
          <button
            type="button"
            className="btn btn-secondary mr-2"
            disabled={disabled}
            onClick={e => {
              e.stopPropagation();
              ConfirmAcationService.confirmMsg(
                this.confirmCancelBack,
                `Cancelar criação `,
                "Você confirma que deseja " +
                `cancelar a criação do atendimento `,
                null,
                "Não",
                "Sim",
                objectsConstants.TYPE_CANCEL
              );
            }}
            disabled={false}
          >
            {"Cancelar"}
          </button>

          <button
            className="btn-primary"
            onClick={this.handelProximoPasso}
            disabled={disabled}
          >
            {" "}
            {_.get(etapaAtual, "labelBtnAvancar", "Avançar")}{" "}
          </button>
        </div>
      </div>
    );
  }
}

const mapDispatch = ({ menu: { showMenu, hideMenu } }) => ({
  showMenu: () => showMenu(),
  hideMenu: () => hideMenu()
});

function mapStateToProps(state) {
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    alert,
    user,
    permissions
  };
}

export default connect(mapStateToProps, mapDispatch)(withRouter(PassoAPasso));
