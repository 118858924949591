//@flow
type State = {
  load: boolean
};

export default {
  state: { showNotification: false, notificacoes: [] },
  reducers: {
    toogleNotification: (state: State) => {
      let showNotification = !state.showNotification;
      if (showNotification) {
        document.body.classList.add("bodyNoScroll", "notificationOpened");
        document.getElementById("bodyNotification").scrollIntoView();
      } else {
        document.body.classList.remove("bodyNoScroll", "notificationOpened");
      }
      return Object.assign({}, state, {
        showNotification: !state.showNotification
      });
    },
    addAllNotificacoes: (state, payload) => {
      return Object.assign({}, state, {
        notificacoes: payload.notificacoes,
        notificacoesSize:payload.notificacoesSize,
        updateNotificacao:false
      });
    },
    updateNotificacao: (state, payload) => {
      return Object.assign({}, state, {
        updateNotificacao: payload.updateNotificacao,

      });
    }

  }
};
