//@flow
import _ from 'lodash';

type State = {
  pendenciaOpen: boolean,
  idAtendimentoAtual: Number,
  campoSelecionado: Object
};

export default {
  state: { resolverPendenciaOpen: false, pendenciaOpen: false, idAtendimentoAtual: 0, cooperados: [], campoSelecionado: {} },
  reducers: {
    closePendecia: (state: State) => {
      return Object.assign({}, state, {
        pendenciaOpen: false,
        campoSelecionado: {},
        resolverPendenciaOpen: false
      });
    },
    togglePendencia: (state: State) => {
      return Object.assign({}, state, {
        pendenciaOpen: !state.pendenciaOpen,
        resolverPendenciaOpen: false,
        campoSelecionado: {}
      });
    },
    toggleResolverPendencia: (state: State) => {
      return Object.assign({}, state, {
        pendenciaOpen: false,
        resolverPendenciaOpen: !state.resolverPendenciaOpen
      });
    },

  }
};
