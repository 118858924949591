//@flow
import * as React from "react";
import { Label } from "reactstrap";
import { createFilter } from "react-select";
import CreatableSelect from "react-select/creatable";
import { ToolTipFeedBack } from "../Utils/ToolTipFeedBack";
import { translate } from "../../helpers/message.helper";
import { connect } from "react-redux";
import { ToolTipHelp } from "../Utils/ToolTipHelp";
import moment from "moment";

type Props = {
  id?: string,
  label: string,
  name: string,
  value?: any,
  size?: number,
  onChange: any,
  parent?: any,
  onFetchData?: any,
  valueKey: string,
  labelKey: string,
  options?: any,
  multi?: boolean,
  required: boolean,
  messageValidate?: string,
  returnFullObject: boolean,
  clearable: boolean,
  creatable: boolean,
  onNewOptionClick: any,
  newOptionCreator: any,
  cache: boolean,
  placeholder: string,
  style: any,
  searchable?: boolean,
  disabled?: boolean
};

type State = {};
let timoutVar;
const Svg = p => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
);
const ChevronDown = () => (
  <Svg style={{ marginRight: -6 }}>
    <path
      d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Svg>
);

const formatCreateLabel = (inputValue) => `Criar... ${inputValue}`;

class CreatableMulti extends React.Component<Props, State> {
  static defaultProps={
    isMulti :true
  }
  handleChange = (newValue, actionMeta) => {
    this.props.onChange(this.props.name,newValue);
  };

  handleIsValidNewOption = (inputValue, selectValue, selectOptions) => {
    // Check for the same value --> ASD === ASD ?
    const exactValueExists = selectOptions.find(el => el.value === inputValue);
    // Check if the value has a valid length.
    // Without this, it will show create option for empty values.
    const valueIsNotEmpty = inputValue.trim().length;
    // If true show create option.
    return !exactValueExists && valueIsNotEmpty;
  };

  render() {
    const { messages, name } = this.props;

    let messageValidate;
    if (messages != null && messages.length > 0) {
      messages.forEach(function(message, index) {
        if (message.fieldName === name) {
          messageValidate = translate(message.message.code);
        }
      });
    }
    if (this.props.erroMensagem) {
      messageValidate = this.props.erroMensagem;
    }
    let props = this.props;
    const menuPortalTarget = document.getElementById("root");

    return (
      <div
        className={`${this.props.required ? "input-required" : ""} ${
          this.props.style
        }`}
      >
        {this.props.label && (
          <React.Fragment>
            <Label
              for={this.props.name}
              className="label-nowrap"
              title={this.props.label}
            >
              {this.props.label}
              {this.props.required && "*"}
            </Label>{" "}
            {this.props.helpText && (
              <React.Fragment>
                <i className="icon-duvida" id={"help_" + this.props.id} />
                <ToolTipHelp
                  target={"help_" + this.props.id}
                  message={this.props.helpText}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        <CreatableSelect
          isMulti={this.props.isMulti}
          onChange={this.handleChange}
          options={this.props.options}
          filterOption={createFilter({ ignoreCase: false })}
          isValidNewOption={this.handleIsValidNewOption}
          className={`Select width-full defaultSelect sasc-select ${this.props.className} ${
            messageValidate ? "is-invalid" : ""
          }`}
          noOptionsMessage={() =>
            !this.props.noResultsText
              ? "Nenhum resultado encontrado."
              : this.props.noResultsText
          }
          placeholder={this.props.placeholder ? this.props.placeholder : ""}
          optionClassName={"selectMenuItem"}
          classNamePrefix="react-select"
          menuPlacement="auto"
          formatCreateLabel={formatCreateLabel}
          menuPortalTarget={this.props.apendRoot && menuPortalTarget}
          getOptionLabel={data => {
            // "data" contains the current option object, so you just use your "labelKey" to return the value of that property.
            return data[this.props.labelKey];
          }}
          getOptionValue={data => {
            // "data" contains the current option object, so you just use your "valueKey" to return the value of that property.
            return data[this.props.valueKey];
          }}
          getNewOptionData={(inputValue, optionLabel)=>{
            let option ={}
            if(this.props.formatValue){
              optionLabel = this.props.formatValue(optionLabel);
            }
            option[this.props.labelKey]=optionLabel;            
            option[this.props.valueKey]=inputValue;
            return option;
          }}
          value={this.props.value}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { messages } = state.fieldMessage;
  const { alert } = state;

  return {
    messages,
    alert
  };
}

export default connect(mapStateToProps)(CreatableMulti);
