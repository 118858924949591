//@flow
import { Formik } from "formik";
import _ from 'lodash';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import * as Yup from 'yup';
import { objectsConstants } from '../../constants/objects.constants';
import { translate } from '../../helpers/message.helper';
import { defaultService } from '../../services/defaultService';
import { estabelecimentoService } from '../../services/estabelecimento.service';
import { userService } from '../../services/user.service';
import TopoTitleComponente from '../home/TopoTitleComponente';
import UsuarioForm from '../usuarios/UsuarioForm';
import UsuarioTopoCrud from '../usuarios/UsuarioTopoCrud';

const NewUsuario = Yup.object().shape({
  // custum validation de um campo
  password: Yup.string().test('valid-senha', 'Senha obrigatório', function () {
    const { parent, createError } = this;
    if (!parent.id && _.isNil(parent.password)) {
      return createError();
    } else {
      return true;
    }
  }),
  nome: Yup.string()
    .min(10, 'Nome muito curto!')
    .max(250, 'Nome muito grande!')
    .required('Obrigatório'),
  email: Yup.string()
    .email('email inválido')
    .required('Obrigatório'),
  cpf: Yup.string().required('Obrigatório')
});

const NewUsuarioConsultorio = Yup.object().shape({
  nome: Yup.string()
    .min(10, 'Nome muito curto!')
    .max(250, 'Nome muito grande!')
    .required('Obrigatório'),
  email: Yup.string()
    .email('email inválido')
    .required('Obrigatório'),
  cnpj: Yup.string().required('Obrigatório'),
  especialidades: Yup.array().required('Obrigatório'),
  convenios: Yup.array().required('Obrigatório'),
  // custum validation de um campo
  // pessoaDadoBancario: Yup.object().shape({
  //   banco: Yup.object().shape({
  //     id: Yup.number().required("Obrigatório")
  //   }),
  //   agencia: Yup.number()
  //     .required("Obrigatório")
  //     .positive("Inválido")
  //     .integer("Inválido"),
  //   contaCorrente: Yup.string().required("Obrigatório")
  // }),
  endereco: Yup.object().shape({
    cep: Yup.string().required('Obrigatório'),
    logradouro: Yup.string().required('Obrigatório'),
    numero: Yup.string().required('Obrigatório'),
    bairro: Yup.string().required('Obrigatório'),
    cidade: Yup.object().shape({
      nome: Yup.string().required('Obrigatório')
    }),
    telefone: Yup.string().required('Obrigatório')
  }),
  secretaria: Yup.object().shape({
    email: Yup.string()
      .email('Email inválido')
      .required('Obrigatório'),
    nome: Yup.string().required('Obrigatório'),
    cpf: Yup.string().required('Obrigatório'),
    password: Yup.string()
      .when('id', (id, schema) => {
        return id == 0 || id === undefined;
      })
      .required('Obrigatório'),
    confirmPassword: Yup.string()
      .when('id', (id, schema) => {
        return id == 0 || id === undefined;
      })
      .required('Obrigatório')
      .oneOf([Yup.ref('password'), null], 'As senhas não conferem')
  })
});
class ConveniosEdit extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: {
        status: { name: 'ATIVO' }
      },
      validateModel: NewUsuario
    };
  }  

  goToViewUser = values => {
    let extensao = _.get(values.usuarioPerfis[0], 'extensao');
    this.props.history.push({
      pathname: '/perfil/view/' + (extensao ? extensao : values.id),
      state: { entity: values }
    });
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.loading(true);
      let service = this.getServiceDoSave();
      service.doGet(this.props.match.params.id).then(
        response => {
          let user = response.data.data;
          let validateModel = this.ObjectValidateProfile(user.usuarioPerfis);
          this.setState({ entity: user, validateModel }, () => {
            this.props.loading(false);
          });
        },
        error => {
          console.error(error);
          this.props.loading(false);
        }
      );
    }
  }

  ObjectValidateProfile = usuarioPerfis => {
    if (usuarioPerfis[0].perfil.tipoPerfil === objectsConstants.CONSULTORIO) {
      return NewUsuarioConsultorio;
    }
    return NewUsuario;
  };

  handleChangeProfile = (name, value) => {
    let validateModel = this.ObjectValidateProfile(value);
    const entity = _.cloneDeep(this.state.entity);
    _.set(entity, name, value);
    this.setState({ entity, validateModel });
  };

  handleChange = (name, value) => {
    const entity = _.cloneDeep(this.formRef.state.values);
    if (userService.isConsultorio(entity)) {
      _.set(entity.secretaria, name, value);
    } else {
      _.set(entity, name, value);
    }

    this.setState({ entity });
  };

  handleSubmit = e => {
    this.formRef.handleSubmit();
  };

  getServiceDoSave = () => {
    let entity;
    if (this.formRef) {
      entity = _.cloneDeep(this.formRef.state.values);
    } else {
      entity = this.props.location && this.props.location.state.entity;

    }

    if (userService.isConsultorio(entity)) {
      return estabelecimentoService;
    }
    return userService;
  };

  getUserFromProfile = entity => {
    if (userService.isConsultorio(entity) && entity.secretaria) {
      return entity.secretaria;
    }
    return entity;
  };

  render() {
    let _this = this;
    const { entity } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid container-com-topo">
          <TopoTitleComponente
            mainTitle={`${entity.id ? 'Editar ' : 'Novo '}  usuário ${
              entity.usuarioPerfis
                ? ' - ' + userService.getPerfilAtual(entity)
                : ''
              }`}
            canBack={true}
            backUrl={!entity.id ? null : '/usuario'}
          />
          <UsuarioTopoCrud
            values={this.getUserFromProfile(entity)}
            onChangeProfile={this.handleChangeProfile}
            onChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            handleCancel={this.handleCancel}
          ></UsuarioTopoCrud>

          {entity.usuarioPerfis && (
            <Formik
              validationSchema={this.state.validateModel}
              validateOnBlur={false}
              validateOnChange={false}
              enableReinitialize={true}
              initialValues={_this.state.entity}
              onSubmit={(values, actions) => {
                this.props.loading(true);
                let serviceSave = this.getServiceDoSave(values);
                serviceSave.doSave(values).then(
                  response => {
                    this.props.success({
                      message: `${_this.getProfileName(values.usuarioPerfis)} ${
                        values.nome
                        }, foi ${!values.id ? 'criado' : 'alterado'} com sucesso!`
                    });
                    let id = values.id
                      ? values.id
                      : defaultService.getIdFromUrl(response.headers.location);
                    if (userService.isConsultorio(values)) {
                      _.set(values.usuarioPerfis[0], 'extensao', id);
                    } else {
                      values.id = id;
                    }
                    _this.goToViewUser(values);
                  },
                  erros => {
                    console.error(erros.response);
                    this.props.error({
                      message: 'Não foi possível criar perfil.'
                    });
                    try {
                      let response = erros.response.data;
                      if (response && response.messages) {
                        for (var i = 0; i < response.messages.length; i++) {
                          let erroItem = response.messages[i];
                          actions.setFieldError(
                            erroItem.fieldName,
                            translate(erroItem.message.code)
                          );
                        }
                      }
                    } catch (error) {
                      console.error(error);
                    }

                    this.props.loading(false);
                  }
                );
                this.props.loading(false);
                actions.setSubmitting(false);
              }}
              ref={form => {
                this.formRef = form;
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                validationSchema
              }) => (
                  <form onSubmit={handleSubmit}>
                    <React.Fragment>
                      {values.usuarioPerfis && (
                        <FormByProfile
                          values={values}
                          setFieldValue={setFieldValue}
                          errors={errors}
                          handleChange={handleChange}
                          touched={touched}
                          getProfileName={_this.getProfileName}
                        ></FormByProfile>
                      )}
                    </React.Fragment>
                  </form>
                )}
            </Formik>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const FormByProfile = React.memo(({ values, ...others }) => {
  
  return <UsuarioForm values={values} {...others} />;
});

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading }
}) => ({
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  clear: () => clear()
});

export default connect(
  null,
  mapDispatch,
  null,
  { withRef: true }
)(ConveniosEdit);
