import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
//import {Input} from "reactstrap";
import {objectsConstants} from "../../constants/objects.constants";
import {ConfirmAcationService} from "../../services/ConfirmAcationService";
import {withRouter} from "react-router-dom";
import { estabelecimentoService } from '../../services/estabelecimento.service';
import {userService} from '../../services/user.service'

class LinhaAcaoAtivarInativarConsultorio extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    success = () =>{
        this.props.success({message: "Alterado com sucesso."});
        this.props.loading(false);
        if (this.props.initData) {
            this.props.initData();
        }
    }
    errorAtivar = () =>{
        this.props.success("Não foi possível ativar os consultórios.");
        this.props.loading(false);
    }

    errorInativar = () =>{
        this.props.success("Não foi possível inativar os consultórios.");
        this.props.loading(false);
    }


    ativarUsuario = () =>{
        this.props.loading(true);
        if(this.props.checkedAllPages){
            const filter = this.props.getFilter();
            estabelecimentoService.ativarUsuarioByFilter(filter).then(() => {
                this.success();
            }).catch(() => {
                this.errorAtivar();
            });
        }else {
            estabelecimentoService.ativarUsuario(this.props.rowsCheckedValue.map((rowValue) => {
                return rowValue.id;
            })).then(() => {
                this.success();
            }).catch(() => {
               this.errorAtivar();
            });
        }
    }

    inativarUsuario = () =>{
        this.props.loading(true);
        if(this.props.checkedAllPages){
            const filter = this.props.getFilter();
            estabelecimentoService.inativarUsuarioByFilter(filter).then(() => {
                this.success();
            }).catch(() => {
                this.errorInativar();
            });
        }else {
            estabelecimentoService.inativarUsuario(this.props.rowsCheckedValue.map((rowValue) => {
                return rowValue.id;
            })).then(() => {
                this.success();
            }).catch(() => {
                this.errorInativar();
            });
        }
    }

    render() {
        let statusUsuarioAtivo = false;
        let statusUsuarioInativo = false;
        const user = userService.getCurrentUser();
        const tipoPerfil = user.perfilAtual.perfil.tipoPerfil;
        this.props.rowsCheckedValue.map((rowValue)=>{
            if(rowValue.status && rowValue.status.description === objectsConstants.ATIVO){
                statusUsuarioAtivo =true;
            }else{
                statusUsuarioInativo = true;
            }
        })
        return <React.Fragment>
                        {!statusUsuarioAtivo && statusUsuarioInativo
                         && tipoPerfil === objectsConstants.ADMINISTRADOR && (
                            <button type="button" className="btn btn-primary" onClick={() => {
                                ConfirmAcationService.confirmMsg(
                                    this.ativarUsuario,
                                    "Ativar Consultórios",
                                    "Você confirma que deseja ativar os consultórios selecionados",
                                    null,
                                    "Cancelar",
                                    "Confirmar",
                                    objectsConstants.TYPE_WARNING
                                );
                            }}>
                                <span className="icon-btn-check"></span>
                                Ativar Consultório
                            </button>
                        )}
                        {statusUsuarioAtivo && !statusUsuarioInativo 
                        && tipoPerfil === objectsConstants.ADMINISTRADOR && (
                            <button type="button" className="btn btn-secondary"onClick={() => {
                                ConfirmAcationService.confirmMsg(
                                    this.inativarUsuario,
                                     "Inativar Consultórios",
                                    "Você confirma que deseja inativar os consultórios selecionados",
                                    null,
                                    "Cancelar",
                                    "Confirmar",
                                    objectsConstants.TYPE_WARNING
                                );
                            }}>
                                <span className="icon-btn-fechar"></span>
                                Inativar Consultório
                            </button>
                        )}
                        {statusUsuarioAtivo && statusUsuarioInativo && tipoPerfil === objectsConstants.ADMINISTRADOR && (<span className={'labelInfoAction'}>Existem dois status diferentes selecionados</span>)}
                
                </React.Fragment>;
        }
}

const mapDispatch = ({
                         alert: { success, error, clear },
                         load: { loading }
                     }) => ({
    success: msg => success(msg),
    loading: (load: boolean) => loading({ load }),
    error: msg => error(msg),
    clear: () => clear()
});

export default connect(
    null,
    mapDispatch,
    null,
    { withRef: true }
)(withRouter(LinhaAcaoAtivarInativarConsultorio));
