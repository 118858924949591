import _ from "lodash";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DatePickerInput from "../../../../components/Inputs/DatePickerInput";
import FormInput from "../../../../components/Inputs/FormInput";
import FormSelectInput from "../../../../components/Inputs/FormSelectInput";
import InputViewEdit from "../../../../components/Inputs/InputViewEdit";
import { objectsConstants } from "../../../../constants/objects.constants";
import { dateHelper } from "../../../../helpers/date.helper";
import SelectableInput from "../../../../sascComponents/inputs/SelectableInput";
import { atendimentoService } from "../../../../services/atendimento.service";
import { convenioService } from "../../../../services/convenio.service";

const CONVENIO = "convenio";
const LABEL_CONVENIO = "Convênio";
const REGISTRO_ANS_LABEL = "Registro ANS";

class DadosAutorizacaoAtendimentoGuiaForm extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      numeroGuiaGlobal: 0,
      numeroGuiaAnoGlobal: 0
    };
  }

  render() {
    const {
      setFieldValue,
      values,
      errors,
      viewMode
    } = this.props;

    return (
      <div className="row section-form">
          {values&&values.justificativaCancelamento&&viewMode&&(

              <div className="form-group w-100" name="justificativaCancelamento"><label htmlFor="acomodacao"
                                                                                        className="">Justificativa para guia recusada:</label>
                  <p className="form-view ">{values.justificativaCancelamento}</p></div>


          )}
          {this.props.user.cooperativa.permiteAlterarNumeroAtendimento&&(
              <SelectableInput viewMode={viewMode} atendimento={values} className="fixo"
                              name={"numeroAtendimento"}
                              label={"Nº do atendimento"}
                              value={values.numeroAtendimento}
              >
                  <InputViewEdit
                      label={"Nº do atendimento"}
                      component={FormInput}
                      onChange={(name, date) => {
                          setFieldValue(name, date);
                      }}
                      name={"numeroAtendimento"}
                      placeholder=""
                      type={"text"}
                      viewMode={viewMode}
                      value={values.numeroAtendimento?values.numeroAtendimento.replace(/\D/g, ''):values.numeroAtendimento}
                      erroMensagem={errors.numeroAtendimento}
                      required={_.get(this.props.user, 'cooperativa.permiteAlterarNumeroAtendimento', true)}
                  />
              </SelectableInput>               )}
        <SelectableInput viewMode={viewMode}
          atendimento={values}
          className=""
          name={CONVENIO}
          label={LABEL_CONVENIO}
          value={values.convenio && values.convenio.nome}
        >
          <InputViewEdit
            label={"Convênio"}
            required={true}
            component={FormSelectInput}
            service={convenioService.findAll}
            sortKey={"nome"}
            className={"destaque"}
            id={CONVENIO}
            name={CONVENIO}
            returnFullObject={true}
            erroMensagem={_.get(errors, "convenio.id")}
            searchable={true}
            labelKey={"nome"}
            valueKey={"id"}
            defaultValue={values.convenio ? values.convenio.nome : "--"}
            value={values.convenio}
            parent={{estabelecimento:values.estabelecimento,tipoGuia:this.props.atendimento.type}}
            viewMode={viewMode}
            onChange={(name, value) => {

              if (!value.numeroGuiaObrigatoria) {
                if(value.reiniciarnumeroGuiaAno){
                    if(this.state.numeroGuiaAnoGlobal > 0){
                        setFieldValue('numeroGuia', this.state.numeroGuiaAnoGlobal);
                        setFieldValue('numeroGuiaAutomatica', this.state.numeroGuiaAnoGlobal);
                        setFieldValue('numeroGuiaAno', true);
                    }else{
                      atendimentoService.getNumeroGuiaConvenio(value.id).then(result => {
                        this.setState({ numeroGuiaAnoGlobal: result.data });
                        setFieldValue('numeroGuia', result.data);
                        setFieldValue('numeroGuiaAutomatica', result.data);
                        setFieldValue('numeroGuiaAno', true);
                      })
                    }

                }else{
                    if(this.state.numeroGuiaGlobal > 0){
                      setFieldValue('numeroGuia', this.state.numeroGuiaGlobal);
                      setFieldValue('numeroGuiaAutomatica', this.state.numeroGuiaGlobal);
                      setFieldValue('numeroGuiaAno', false);
                  }else{
                    atendimentoService.getNumeroGuia().then(result => {
                      this.setState({ numeroGuiaGlobal: result.data });
                      setFieldValue('numeroGuia', result.data);
                      setFieldValue('numeroGuiaAutomatica', result.data);
                      setFieldValue('numeroGuiaAno', false);
                    })
                  }

                }

            }
            
                /*if (!value.numeroGuiaObrigatoria) {
                    if (values.numeroGuiaAutomatica) {
                        setFieldValue('numeroGuia', values.numeroGuiaAutomatica);
                    } else {
                        atendimentoService.getNumeroGuia().then(result => {
                            setFieldValue('numeroGuia', result.data);
                            setFieldValue('numeroGuiaAutomatica', result.data);
                        })

                    }
                }*/

                if(!values.id){
                setFieldValue('numeroCarteira', "");
                setFieldValue('nomeBeneficiario', "");
                setFieldValue('dataValidadeCarteira', "");
                setFieldValue('cpf', "");
                setFieldValue('dataNascimento', "");
                }

                setFieldValue(name, value);
            }}
          />
        </SelectableInput>
          {values.cooperativa && values.cooperativa.utilizaComplementoConvenio&&(
          <SelectableInput viewMode={viewMode}
                           atendimento={values}
                           className=""
                           name={"complementoConvenio"}
                           label={"Complemento"}
                           value={values.complementoConvenio }
          >
              <InputViewEdit
                  label={"Complemento"}
                  required={values.convenio? convenioService.complementoObrigatorio(values.convenio.tiposGuiaProduto,values.type): false}
                  disabled={!values.convenio}
                  component={FormSelectInput}
                  parent={{type:values.type,convenio:values.convenio}}
                  service={convenioService.findComplementoConvenioPorTipoGuia}
                  sortKey={"nome"}
                  className={"destaque"}
                  id={"complementoConvenio"}
                  name={"complementoConvenio"}
                  returnFullObject={false}
                  erroMensagem={_.get(errors, "complementoConvenio")}
                  searchable={true}
                  labelKey={"descricao"}
                  valueKey={"descricao"}
                  defaultValue={values.complementoConvenio ? values.complementoConvenio : "--"}
                  value={values.complementoConvenio}
                  viewMode={viewMode}
                  onChange={(name, value) => {
                      setFieldValue(name, value);
                  }}
                  alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
              />
          </SelectableInput>
          )}
        <SelectableInput viewMode={viewMode}
          atendimento={values}
          className="fixo"
          name={"registroAns"}
          label={REGISTRO_ANS_LABEL}
          value={_.get(values, "convenio.registroAns")}
        >
          <InputViewEdit
            label={REGISTRO_ANS_LABEL}
            required={false}
            disabled={true}
            component={FormInput}
            onChange={(name, date) => {
              setFieldValue(name, date);
            }}
            erroMensagem={errors.registroAns}
            name={"registroAns"}
            placeholder="Registro ANS"
            type={"text"}
            viewMode={viewMode}
            value={_.get(values, "convenio.registroAns")}
          />
        </SelectableInput>
        <SelectableInput viewMode={viewMode}
          atendimento={values}
          className="fixo"
          name={"dataAutorizacao"}
          label={"Data da autorização"}
          value={values.dataAutorizacao}
        >
          <InputViewEdit
            label="Data da autorização"
            component={DatePickerInput}
            onChange={(name, date) => {
              setFieldValue(name, date);
            }}
            defaultValue={
              values.dataAutorizacao
                ? dateHelper.format(values.dataAutorizacao, {
                  mode: "DATE"
                })
                : "--"
            }
            mask={"99/99/9999"}
            name={"dataAutorizacao"}
            id={"dataAutorizacao"}
            placeholder="Data da autorização"
            viewMode={viewMode}
            type={"text"}
            value={values.dataAutorizacao}
            erroMensagem={errors.dataAutorizacao}
            required={false}
            alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
          />
        </SelectableInput>
        <SelectableInput viewMode={viewMode}
          atendimento={values}
          className="fixo"
          name={"senha"}
          label={"Senha"}
          value={values.senha}
        >
          <InputViewEdit
            label={"Senha"}
            component={FormInput}
            erroMensagem={errors.senha}
            className={"destaque"}
            onChange={(name, date) => {
                let value="";
                if(date){
                    value = date.replace(/\s/g,'').toUpperCase();
                }
              setFieldValue(name, value);
              if (_.get(values, "convenio.copiarSenha")) {
                setFieldValue('numeroGuia', value);
              }
            }}
            name={"senha"}
            placeholder="Senha"
            helpText={"Esta senha deve ser gerada no site do convênio, ou conforme orientações no site da cooperativa."}
            type={"text"}
            viewMode={viewMode}
            value={values.senha}
            //maxLength={_.get(values,'convenio.quantDigitosSenha',200) }
            maxLength={
              values.convenio && 
              values.convenio.listaRegrasSenhaConvenio && 
              //values.convenio.listaRegrasSenhaConvenio.find(lista => _.get(lista, 'tipoGuia.id') == tipoGuiaAtendimtno)
              values.convenio.listaRegrasSenhaConvenio.find(lista => _.get(lista, 'tipoGuia.id') == objectsConstants.TIPOS_GUIA.find(type=>_.get(type,'label')=== values.type).id)
              ?
              values.convenio.listaRegrasSenhaConvenio.find(lista => _.get(lista, 'tipoGuia.id') == objectsConstants.TIPOS_GUIA.find(type=>_.get(type,'label')=== values.type).id).quantDigitosSenha
              :
              _.get(values,'convenio.quantDigitosSenha',200) 
              }
            required={convenioService.isSenhaObrigatoria(
              _.get(values, 'convenio.tiposGuiaSenhaObrigatoria', false),
              values.type,
              _.get(values, 'convenio.listaRegrasSenhaConvenio', false),
              values.caraterAtendimento,
              values.atendimentoExecucaos)}
            alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
          />
        </SelectableInput>

        <SelectableInput viewMode={viewMode}
          atendimento={values}
          className="fixo"
          name={"dataValidadeSenha"}
          label={"Data validade senha"}
          value={values.dataValidadeSenha}
        >
          <InputViewEdit
            label="Data validade senha"
            component={DatePickerInput}
            onChange={(name, date) => {
              setFieldValue(name, date);
            }}
            defaultValue={
              values.dataValidadeSenha
                ? dateHelper.format(values.dataValidadeSenha, {
                  mode: "DATE"
                })
                : "--"
            }
            mask={"99/99/9999"}
            name={"dataValidadeSenha"}
            id={"dataValidadeSenha"}
            placeholder="Data validade senha"
            viewMode={viewMode}
            type={"text"}
            value={values.dataValidadeSenha}
            erroMensagem={errors.dataValidadeSenha}
            required={false}
            alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
          />
        </SelectableInput>
        <SelectableInput viewMode={viewMode}
          atendimento={values}
          className="fixo"
          name={"numeroGuia"}
          label={"Nº da guia"}
          value={values.numeroGuia}
        >
          <InputViewEdit
            label={"Nº da guia"}
            component={FormInput}
            disabled={values.convenio&&!values.convenio.numeroGuiaObrigatoria}
            onChange={(name, date) => {
                if(date){
                    date = date.replace(/\s/g, '');
                }
              setFieldValue(name, date);
              if (_.get(values, "convenio.copiarSenha")) {
                setFieldValue('senha', date);
              }
            }}
            name={"numeroGuia"}
            required={_.get(values,'convenio.numeroGuiaObrigatoria',true)}
            erroMensagem={errors.numeroGuia}
            placeholder="Nº da guia"
            type={"text"}
            viewMode={viewMode}
            value={values.numeroGuia}
          />
        </SelectableInput>
      </div>
    );
  }
}

const mapDispatch = ({ menu: { showMenu, hideMenu } }) => ({
  showMenu: () => showMenu(),
  hideMenu: () => hideMenu()
});

function mapStateToProps(state) {
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;
  const { pendenciaOpen } = state.pendencia;

  return {
    loggedIn,
    alert,
    user,
    permissions,
    pendenciaOpen
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(DadosAutorizacaoAtendimentoGuiaForm));
