import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormGroup } from '../../../../components/FormGroup';
import FormInput from '../../../../components/Inputs/FormInput';
import InputViewEdit from '../../../../components/Inputs/InputViewEdit';
import _ from "lodash";
import {hospitalService} from "../../../../services/hospital.service";
import SelectableInput from "../../../../sascComponents/inputs/SelectableInput";
import FormSelectInput from '../../../../components/Inputs/FormSelectInput';
class DadosContratadoGuiaForm extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
  }

  sendToStorange = (values) => {
    try {
      values.rascunho && localStorage.setItem('rascunho', JSON.stringify(values))
    } catch (error) {
      if (error instanceof DOMException && (
        error.code === 22 ||
        error.code === 1014 ||
        error.name === 'QuotaExceededError' ||
        error.name === 'NS_ERROR_DOM_QUOTA_REACHED'
      )) {
        console.error('Limite de armazenamento local atingido');
      } else {
        console.error('Erro ao salvar no armazenamento local:', error.message);
      }
    }
  }

  render() {
    const {
      setFieldValue,
      values,
      viewMode,
        errors
    } = this.props;
    return (
      <div className="row section-form">
        <h6 className="w-100">Dados do Contratado do Executante</h6>
        <FormGroup className="fixo mobile-100">
          <InputViewEdit
            label="Código na operadora"
            component={FormInput}
            onChange={(name, date) => {
              setFieldValue(name, date);
            }}
            onBlur={() => this.sendToStorange(values)}
            viewMode={viewMode}
            disabled={true}
            name={'codigoOperadoraContratada'}
            placeholder=""
            type={'text'}
            value={values.codigoOperadoraContratada}
          />
        </FormGroup>
          <FormGroup className="fixo mobile-100">
              <SelectableInput viewMode={viewMode} atendimento={values} className="fixo"
                               name={"hospitalLocal"}
                               label={"Hospital / Local"}
                               value={values.hospitalLocal}>
              <InputViewEdit
                  label="Hospital / Local"
                  disabled={!values.convenio}
                  required={true}
                  component={FormSelectInput}
                  parent={{type:values.type,convenio:values.convenio}}
                  service={hospitalService.findHospitalConvenioPorTipoGuia}
                  sortKey={"nome"}
                  className={"destaque"}
                  guia={values.type}
                  id={"hospitalLocal"}
                  name={"hospitalLocal"}
                  returnFullObject={true}
                  erroMensagem={_.get(errors, "hospitalLocal.id",_.get(errors, "hospitalLocal"))}
                  searchable={true}
                  labelKey={"nome"}
                  valueKey={"id"}
                  defaultValue={values.hospitalLocal ? values.hospitalLocal.nome : "--"}
                  value={values.hospitalLocal}
                  viewMode={viewMode}
                  onChange={(name, value) => {
                      setFieldValue(name, value);
                  }}
                  onBlur={() => this.sendToStorange(values)}
                  alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}

              />
              </SelectableInput>
          </FormGroup>
        <FormGroup className="">
          <InputViewEdit
            label="Nome do contratado"
            component={FormInput}
            onChange={(name, date) => {
              setFieldValue(name, date);
            }}
            onBlur={() => this.sendToStorange(values)}
            viewMode={viewMode}
            disabled={true}
            name={'nomeContratado'}
            placeholder=""
            type={'text'}
            value={values.nomeContratado}
          />
        </FormGroup>
        <FormGroup className="fixo mobile-100">
          <InputViewEdit
            label="Código CNES"
            component={FormInput}
            onChange={(name, date) => {
              setFieldValue(name, date);
            }}
            onBlur={() => this.sendToStorange(values)}
            viewMode={viewMode}
            disabled={true}
            name={'props.user.cooperativa.cnes'}
            placeholder=""
            type={'text'}
            value={values.cnesContratado}
          />
        </FormGroup>
      </div>
    );
  }
}

const mapDispatch = ({ menu: { showMenu, hideMenu } }) => ({
  showMenu: () => showMenu(),
  hideMenu: () => hideMenu()
});

function mapStateToProps(state) {
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    alert,
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(DadosContratadoGuiaForm));
