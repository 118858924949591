//@flow
import _ from "lodash";
import * as React from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import connect from "react-redux/es/connect/connect";
import CooperadoSudoTopoView from "./CooperadoSudoTopoView";
import { cooperadoSudoService } from "../../../services/cooperadoSudo.service";
import CooperadoSudoForm from "./CooperadoSudoForm";

class CooperadoSudoView extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: {
        status: { name: "ATIVO" }
      }
    };
  }

  componentDidMount() {
    this.loadUser();
  }

  loadUser = () => {
    if (this.props.match.params.id) {
      this.props.loading(true);
      cooperadoSudoService.doGet(this.props.match.params.id).then(
        response => {
          let user = response.data.data;
          this.setState({ entity: user }, () => {
            this.props.loading(false);
          });
        },
        error => {
          this.props.loading(false);
        }
      );
    }
  };
  

  isAtivo = values => {
    return values.status && values.status.name === "ATIVO" ? true : false;
  };
  
  disableEnabledUser = () => {
    let values =this.state.entity;
    this.props.loading(true);
    let functionStatus = this.isAtivo(values)
      ? cooperadoSudoService.inativarCooperadoSudo
      : cooperadoSudoService.ativarCooperadoSudo;
    functionStatus([values.id])
      .then(response => {
        this.loadUser();
        this.props.success({ message: "Alterado com sucesso." });
        this.props.loading(false);
      })
      .catch(() => {
        this.props.success("Não foi possível inativar o usuário.");
        this.props.loading(false);
      });
  };

  render() {
    let _this = this;
    const { entity } = this.state;
    let canEdit= entity.id && this.props.user.id === entity.id;
    return (
      <React.Fragment>
        <div className="container-fluid container-com-topo">
          <React.Fragment>
            {entity.id && (
              <CooperadoSudoTopoView
                values={entity}
                viewMode={true}
                disableEnabledUser={this.disableEnabledUser}
                getProfileName={_this.getProfileName}
              ></CooperadoSudoTopoView>
            )}
            {entity.id && (
              <CooperadoSudoForm
                getProfileName={_this.getProfileName}
                values={entity}
                viewMode={true}
                createMode={false}
                canEdit={canEdit}
                errors={[]}
              ></CooperadoSudoForm>
            )}
          </React.Fragment>
          <div>
            <div className="bg-Sudo"></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading }
}) => ({
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  clear: () => clear()
});

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {    
    user
  };
}

export default connect(
  mapStateToProps,
  mapDispatch,
  null,
  { withRef: true }
)(CooperadoSudoView);

