//@flow
import { Formik } from "formik";
import _ from "lodash";
import * as React from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import connect from "react-redux/es/connect/connect";
import * as Yup from "yup";
import { objectsConstants } from "../../constants/objects.constants";
import { translate } from "../../helpers/message.helper";
import { defaultService } from "../../services/defaultService";
import { estabelecimentoService } from "../../services/estabelecimento.service";
import { userService } from "../../services/user.service";
import TopoTitleComponente from "../home/TopoTitleComponente";
import ConsultorioForm from "./ConsultorioForm";
import ConsultorioTopoCrud from "./ConsultorioTopoCrud";

const NewUsuarioConsultorio = Yup.object().shape({
  nome: Yup.string()
    .min(10, "Nome muito curto!")
    .max(250, "Nome muito grande!")
    .required("Obrigatório"),
  email: Yup.string()
    .email("email inválido")
    .required("Obrigatório"),
  cnpj: Yup.string().required("Obrigatório"),
  convenios: Yup.array().min(1, "Obrigatório"),
  endereco: Yup.object().shape({
    cep: Yup.string().required("Obrigatório"),
    logradouro: Yup.string().required("Obrigatório"),
    numero: Yup.string().required("Obrigatório"),
    bairro: Yup.string().required("Obrigatório"),
    cidade: Yup.object().shape({
      nome: Yup.string().required("Obrigatório")
    }),
    telefone: Yup.string().required("Obrigatório")
  }),
  especialidades: Yup.array()
    .min(1, "É necessario informar pelo menos uma especialidade")
    .of(
      Yup.object().shape({
        id: Yup.string().required("Obrigatório")
      })
    ),
  usuarios: Yup.array()
    .min(1, "É necessario informar pelo menos um usuário para consultório")
    .of(
      Yup.object().shape({
        nome: Yup.string().required("Obrigatório"),
        cpf: Yup.string().required("Obrigatório"),
        password: Yup.string().when("id", (id, schema) => {
          if (id === null || id === undefined) {
            return schema.required("Obrigatório");
          }
          return schema;
        })
      })
    )
});
class ConsultorioEdit extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: {
        status: { name: "ATIVO" },
        usuarios: [{ idKey: new Date().getTime() }]
      }
    };
  }
  
  goToViewUser = values => {
    this.props.history.push({
      pathname: "/consultorios/view/" + values.id,
      state: { entity: values }
    });
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.loading(true);
      estabelecimentoService.doGet(this.props.match.params.id).then(
        response => {
          let user = response.data.data;
          this.setState({ entity: user }, () => {
            this.props.loading(false);
          });
        },
        error => {
          console.error(error);
          this.props.loading(false);
        }
      );
    }
  }

  handleChange = (name, value) => {
    const entity = _.cloneDeep(this.formRef.state.values);
    if (userService.isConsultorio(entity)) {
      _.set(entity.secretaria, name, value);
    } else {
      _.set(entity, name, value);
    }

    this.setState({ entity });
  };

  handleSubmit = e => {
    this.formRef.validateForm(this.formRef.values).then(erros => {
      console.error(erros);
      if (_.isEmpty(erros)) {
        this.formRef.handleSubmit();
      }
    });
  };

  getServiceDoSave = () => {
    let entity;
    if (this.formRef) {
      entity = _.cloneDeep(this.formRef.state.values);
    } else {
      entity = this.props.location && this.props.location.state.entity;
    }

    if (userService.isConsultorio(entity)) {
      return estabelecimentoService;
    }
    return userService;
  };

  render() {
    let _this = this;
    const { entity } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid container-com-topo">
          <div
            className={`action-line ${this.state.activeClass} fixed-container-topo`}
          >
            <TopoTitleComponente
              mainTitle={`${entity.id ? "Editar " : "Novo "}  consultório ${
                entity.usuarioPerfis
                  ? " - " + userService.getPerfilAtual(entity)
                  : ""
              }`}
              canBack={true}
              backUrl={!entity.id ? null : "/consultorios"}
            />
            <ConsultorioTopoCrud
              values={entity}
              onChange={this.handleChange}
              handleSubmit={this.handleSubmit}
              handleCancel={this.handleCancel}
            ></ConsultorioTopoCrud>
          </div>
          <div className={`contentComTopoFix pt-4`}>
            <Formik
              validationSchema={NewUsuarioConsultorio}
              validateOnBlur={false}
              validateOnChange={false}
              enableReinitialize={true}
              initialValues={_this.state.entity}
              onSubmit={(values, actions) => {
                this.props.loading(true);
                estabelecimentoService.doSave(values).then(
                  response => {
                    this.props.success({
                      message: `Consultório ${values.nome}, foi ${
                        !values.id ? "criado" : "alterado"
                      } com sucesso!`
                    });
                    if(userService.isCooperado(this.props.user)) {
                        this.props.doRefresh();
                    }
                    let id = values.id
                      ? values.id
                      : defaultService.getIdFromUrl(response.headers.location);
                    for (let i = 0; i < values.usuarios; i++) {
                      _.set(values, `usuarioPerfis[${i}].extensao`, id);
                    }
                    values.id = id;
                    _this.goToViewUser(values);
                    this.props.loading(false);
                  },
                  erros => {
                    console.error(erros.response);
                    this.props.error({
                      message:
                        "Não foi possível criar consultórios, existem erros no formulário!"
                    });
                    try {
                      let response = erros.response.data;
                      if (response && response.messages) {
                        for (var i = 0; i < response.messages.length; i++) {

                          let erroItem = response.messages[i];
                          if(erroItem.target === objectsConstants.GLOBAL){
                            this.props.error({
                              message:
                                  translate(erroItem.message.code)
                            });
                          }else {
                            actions.setFieldError(
                                erroItem.fieldName,
                                translate(erroItem.message.code)
                            );
                          }
                          actions.setFieldError(
                            erroItem.fieldName,
                            translate(erroItem.message.code)
                          );
                          if(erroItem.target === "GLOBAL"){
                            this.props.error({
                              message:
                              translate(erroItem.message.code)
                            });
                          }

                        }

                      }
                    } catch (error) {
                      console.error(error);
                    }

                    this.props.loading(false);
                actions.setSubmitting(false);
                  }
                );
               
              }}
              ref={form => {
                this.formRef = form;
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                validationSchema,
                validateForm,
                setValues
              }) => (
                <form onSubmit={handleSubmit}>
                  <React.Fragment>
                    <ConsultorioForm
                      values={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      handleChange={handleChange}
                      touched={touched}
                      validateForm
                      setValues
                    ></ConsultorioForm>
                  </React.Fragment>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading } ,
  authentication: { doRefresh }


}) => ({
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  clear: () => clear(),
  doRefresh:() => doRefresh()
});

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user
  };
}
export default connect(mapStateToProps, mapDispatch, null, { withRef: true })(
  ConsultorioEdit
);
