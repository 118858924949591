/* eslint-disable */
import React from "react";
import { connect } from "react-redux";

const PassoaPassoItem = ({
  atendimento,
  criarGuiaPassoPasso,
  etapa,
  children,
  etapaAtual
}) => {
  let visiable = true;
  if (
    criarGuiaPassoPasso &&
    !atendimento.id &&
    (etapaAtual != null ? !etapaAtual.etapa || etapaAtual.etapa < etapa : false)
  ) {
    visiable = false;
  }
  return (
    <React.Fragment>
      {visiable && (
        <div id={`passo_${etapa}`} className='form-inside passo_item'>
          <React.Fragment>{children}</React.Fragment>
        </div>
      )}
    </React.Fragment>
  );
};

export default connect(null, null)(PassoaPassoItem);
