//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import PacoteExameEdit from './PacoteExameEdit';
import PacoteExameList from './PacoteExameList';
import PacoteExameView from './PacoteExameView';
export default class PacoteExame extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/pacoteexame" component={PacoteExameList} exact />
          <Route path="/pacoteexame/novo" component={PacoteExameEdit} />
          <Route path="/pacoteexame/edit/:id" component={PacoteExameEdit} />
          <Route path="/pacoteexame/view/:id" component={PacoteExameView} />
        </Switch>
      </React.Fragment>
    );
  }
}
