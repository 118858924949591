import { defaultService } from "./defaultService";
import { urlsConstants } from "../constants/url.constant";

export const exameService = {
    findAll,
    findTableColumns,
    findSearchableColumns,
    findByFilter,
    doSave,
    checkPrazo,
    exportToExcel
};

function findAll() {
    return defaultService.doGet(`${urlsConstants.EXAME}list`);
}

function findTableColumns() {
    return defaultService.doGet(`${urlsConstants.EXAME}list/fields`);
}

function findSearchableColumns() {
    return defaultService.doGet(`${urlsConstants.EXAME}search/fields`);
}

function doSave(values) {
    return defaultService.doSave(`${urlsConstants.EXAME}save`, values);
}

function checkPrazo(values) {
    return defaultService.doPost(`${urlsConstants.EXAME}checkPrazo`, values);
}

function findByFilter(options: Options, filter: any): Promise<Array<>> {
    return defaultService.findByFiltro(
        `${urlsConstants.EXAME}list`,
        options,
        filter
    );
}

function exportToExcel(filter, fields, labels) {
    return defaultService.exportToExcel('exame', filter, fields, labels);
}