import { PureComponent } from "react";
import { atendimentoService } from "../../../services/atendimento.service";
import { connect } from "react-redux";
import {  withRouter } from "react-router-dom";

class VisualizarPendencia extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.props.loading(true);
    atendimentoService
      .findByPendencia(this.props.match.params.id)
      .then(response => {
        let entitys = [response.data];
        this.props.history.push({
          pathname: "/guias",
          state: { entitys, idPendencia: this.props.match.params.id }
        });
        this.props.loading(true);
      },(erros)=>{
        this.props.loading(false);
        console.error(erros)
      });
  }

  render() {
    return null;
  }
}

const mapDispatch = ({ load: { loading } }) => ({
  loading: (load: boolean) => loading({ load })
});
export default connect(null, mapDispatch)(withRouter(VisualizarPendencia));
