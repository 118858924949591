import { defaultService } from "./defaultService";
import { urlsConstants } from "../constants/url.constant";

export const descricaoPendenciaService = {
    findAll,
    findTableColumns,
    findSearchableColumns,
    findByFilter,
    doSave,
    doDelete,
    doUpdate,
    findByTexto,
    doGet,
    exportToExcel
};

function findAll() {
    return defaultService.doGet(`${urlsConstants.DESCRICAO_PENDENCIA}list`);
}

function doGet(id) {
    return defaultService.doGet(`${urlsConstants.DESCRICAO_PENDENCIA}${id}`);
}

function doSave(values) {
    return defaultService.doSave(`${urlsConstants.DESCRICAO_PENDENCIA}save`, values);
}

function doDelete(ids) {
    return defaultService.doPost(`${urlsConstants.DESCRICAO_PENDENCIA}delete`, ids);
}

function doUpdate(descricaoPendencia, texto) {
    return defaultService.doPut(`${urlsConstants.DESCRICAO_PENDENCIA}update/${descricaoPendencia}`, texto);
}

function findByTexto(input) {
    return defaultService.doGet(`${urlsConstants.DESCRICAO_PENDENCIA}findByTexto/${input}`);
}

function findTableColumns() {
    return defaultService.doGet(`${urlsConstants.DESCRICAO_PENDENCIA}list/fields`);
}

function findSearchableColumns() {
    return defaultService.doGet(`${urlsConstants.DESCRICAO_PENDENCIA}search/fields`);
}

function findByFilter(options: Options, filter: any): Promise<Array<>> {
    return defaultService.findByFiltro(
        `${urlsConstants.DESCRICAO_PENDENCIA}list`,
        options,
        filter
    );
}

function exportToExcel(filter, fields, labels) {
    return defaultService.exportToExcel('descricaoPendencia', filter, fields, labels);
}