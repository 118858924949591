import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import BtnLink from "../../../components/Buttons/BtnLink";
import ItemHistorico from "../../../sascComponents/historico/ItemHistorico";

class MenuHistorico extends PureComponent<Props, State> {
  static defaultProps = {
    listaHistoricos: []
  };
  constructor(props) {
    super(props);
  }

  render() {    
    const { listaHistoricos } = this.props;
    return (
      <div
        className={`menu-historico ${
          this.props.openMenuHistorico ? "aberto" : ""
          }`}
      >
        <div
          id="abreHistorico"
          className="topo-historico text-center py-3 d-flex align-items-center justify-content-between"
        >
          <BtnLink
            type={"button"}
            className={""}
            icone={""}
            title={"Fechar"}
            onSubmit={this.props.toggleHistorico}
            disabled={false}
          />
          <h1 className="font-bold">
            <span className="icon-btn-historico"></span> Histórico
          </h1>
          <span className="float-left font-12px acerto-p-m invisible">
            Fechar
          </span>
        </div>
        <div
          className={
            "d-flex flex-column justify-content-center align-items-center py-4 bg-color4"
          }
        >
          <div className="text-center roboto-light">
            <h2>{this.props.titulo}</h2>
            {this.props.subTitulo}
          </div>
        </div>
        <div className="historico">
          {listaHistoricos.reverse().map((item, index) => {
            return <ItemHistorico item={item} key={uuidv4()} index={listaHistoricos.length - index} />;
          })}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { idAtendimentoAtual } = state.pendencia;
  const { permissions } = state.authentication;

  return {
    idAtendimentoAtual,
    permissions
  };
}

export default connect(mapStateToProps)(withRouter(MenuHistorico));
