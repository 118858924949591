import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Avatar from "../../components/Avatar/Avatar";
import AvatarEstabelecimento from "../../components/Avatar/AvatarEstabelecimento";

class EstabelecimentoCardTopo extends PureComponent<> {
    render() {
        return (
            <div className="bloco-dados-user mt-4 mt-sm-0 mr-sm-5 d-flex flex-column flex-sm-row align-items-center pointer" >
                <div className="bloco-image-user m-sm-2 mr-sm-4">
                    <AvatarEstabelecimento estabelecimento={this.props.estabelecimento}></AvatarEstabelecimento>
                </div>
                <div className="info-user">
                    <p className="txtCooperativa verde-destaque">
                        Visualizando perfil
                        <span className="pl-1">
                            <button
                                className="btn btn-icon-only"
                                onClick={() => { this.props.removeFilter('estabelecimento'); this.props.changeFilterProps('estabelecimento'); localStorage.removeItem('rascunho');}}
                            >
                                <span className="icon-btn-fechar-filtro-red"></span>
                            </button>
                        </span>
                    </p>
                    <h2 className="font-weight-bold">{this.props.estabelecimento.nome}</h2>

                    <p className="font-italic txtPerfil">
                        Consultório
                        </p>

                </div>
            </div>
        );
    }
}

const mapDispatch = ({ filtro: { toogleFiltro, removeFilter } }) => ({
    toogleFiltro: () => toogleFiltro(),
    removeFilter: (filterRemove) => removeFilter({ filterRemove })
});
function mapStateToProps(state) {
    const { user, permissions } = state.authentication;

    return {
        user,
        permissions
    };
}

export default connect(
    mapStateToProps,
    mapDispatch
)(withRouter(EstabelecimentoCardTopo));

