import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DadosExamesSolicitadosGuiaForm from "./componentes/DadosExamesSolicitadosGuiaForm";
import DadosAtendimentoGuiaConsultaForm from "./componentes/guiaConsulta/DadosAtendimentoGuiaConsultaForm";
import DadosBeneficiarioGuiaConsultaForm from "./componentes/guiaConsulta/DadosBeneficiarioGuiaConsultaForm";
import DadosContratadoGuiaConsultaForm from "./componentes/guiaConsulta/DadosContratadoGuiaConsultaForm";
import DadosConvenioConsultaForm from "./componentes/guiaConsulta/DadosConvenioConsultaForm";
import DadosObservacaoAnexosForm from "./componentes/guiaConsulta/DadosObservacaoAnexosForm";
import PassoaPassoItem from "./PassoaPassoItem";

class GuiaConsultaForm extends PureComponent<Props, State> {
  constructor(props) {
    super(props);


  }

  render() {
    const {
      criarGuiaPassoPasso,
      errors,
      setFieldValue,
      values,
      handleChange,
      setValues,
      viewMode,
      etapaAtual,
      ...otherProps
    } = this.props;
    return (
      <React.Fragment>
        <PassoaPassoItem
          etapa={1}
          atendimento={values}
          etapaAtual={etapaAtual}
          criarGuiaPassoPasso={criarGuiaPassoPasso}
        >
          <DadosConvenioConsultaForm
            errors={errors}
            setFieldValue={setFieldValue}
            setValues = {setValues}
            user={this.props.user}
            values={values}
            handleChange={handleChange}
            viewMode={viewMode}
            atendimento={this.props.atendimento}
          />
          <DadosBeneficiarioGuiaConsultaForm
            errors={errors}
            setValues={setValues}
            className={"section-form"}
            setFieldValue={setFieldValue}
            values={values}
            handleChange={handleChange}
            viewMode={viewMode}
            {...otherProps}
          />
        </PassoaPassoItem>
        <PassoaPassoItem
          etapa={2}
          atendimento={values}
          etapaAtual={etapaAtual}
          criarGuiaPassoPasso={criarGuiaPassoPasso}
        >
          <DadosContratadoGuiaConsultaForm
            errors={errors}
            className={"section-form"}
            setFieldValue={setFieldValue}
            values={values}
            handleChange={handleChange}
            viewMode={viewMode}
            user={this.props.user}
            {...otherProps}
          />
        </PassoaPassoItem>
        <PassoaPassoItem
          etapa={3}
          atendimento={values}
          etapaAtual={etapaAtual}
          criarGuiaPassoPasso={criarGuiaPassoPasso}
        >
          <DadosAtendimentoGuiaConsultaForm
            setValues={setValues}
            className={"section-form"}
            errors={errors}
            setFieldValue={setFieldValue}
            values={values}
            handleChange={handleChange}
            viewMode={viewMode}
            atendimento={this.props.atendimento}
            {...otherProps}
          />
        </PassoaPassoItem>
        <PassoaPassoItem
          etapa={4}
          atendimento={values}
          etapaAtual={etapaAtual}
          criarGuiaPassoPasso={criarGuiaPassoPasso}
        >
          <DadosObservacaoAnexosForm
            setValues={setValues}
            className={"section-form"}
            errors={errors}
            setFieldValue={setFieldValue}
            values={values}
            handleChange={handleChange}
            viewMode={viewMode}
          />
          <DadosExamesSolicitadosGuiaForm
            className={"section-form"}
            setFieldValue={setFieldValue}
            values={values}
            errors={errors}
            handleChange={handleChange}
            viewMode={viewMode}
            {...otherProps}
          />
        </PassoaPassoItem>

      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  pendencia: { togglePendencia, atendimentoAtual }
}) => ({
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  togglePendencia: () => togglePendencia(),
  atendimentoAtual: atendimento => atendimentoAtual(atendimento)
});

function mapStateToProps(state) {
  const { pendenciaOpen, idAtendimentoAtual } = state.pendencia;
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;
  const { filtroAtendimento } = state.filtro;

  return {
    pendenciaOpen,
    idAtendimentoAtual,
    loggedIn,
    alert,
    user,
    permissions,
    filtroAtendimento
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(GuiaConsultaForm));
