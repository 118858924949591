//@flow
import { urlsConstants } from '../constants/url.constant';

export const midiaService = {
    url
};


function url(id) {
    return `${urlsConstants.MIDIA}${id}`;
}
