//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import IntegracaoAtendimentosList from './IntegracaoAtendimentosList';
export default class IntegracaoAtendimentos extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/integracaoAtendimento" component={IntegracaoAtendimentosList} exact />
        </Switch>
      </React.Fragment>
    );
  }
}
