//@flow
import _ from 'lodash';

type State = {
    edicaoEmMassaOpen: boolean,
    idAtendimentoParaEditar: Number,
    campoSelecionado: Object
};

export default {
    state: { edicaoEmMassaOpen: false, idAtendimentoParaEditar: 0, cooperados: [], campoSelecionado: {} },
    reducers: {

        closeEdicao: (state: State) => {
            return Object.assign({}, state, {
                edicaoEmMassaOpen: false,
            });
        },
        toggleEdicaoEmMassa: (state: State) => {
            return Object.assign({}, state, {
                edicaoEmMassaOpen: !state.edicaoEmMassaOpen,
                campoSelecionado: {}
            });
        },

        atendimentoParaEditar: (state: State, payload: Object) => {
            let cooperados = [];
            for (let identificacaoAtendimentoExecucao in payload.identificacaoAtendimentoExecucao) {
                if (payload.identificacaoAtendimentoExecucao[identificacaoAtendimentoExecucao].cooperado) {
                    cooperados.push(payload.identificacaoAtendimentoExecucao[identificacaoAtendimentoExecucao].cooperado);
                }
            }
            cooperados = _.uniqBy(cooperados, 'id');

            return Object.assign({}, state, {
                idAtendimentoParaEditar: payload.id,
                cooperados,
                atendimentoPendencias: payload.atendimentoPendencias,
                numeroAtendimento: payload.numeroAtendimento
            });
        },
        campoAtual: (state: State, payload: Object) => {
            return Object.assign({}, state, {
                campoSelecionado: payload.campoSelecionado
            });
        }
    }
};