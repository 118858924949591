import { Formik } from "formik";
import _ from "lodash";
import * as React from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import connect from "react-redux/es/connect/connect";
import * as Yup from "yup";
import { mensagemService } from "../../services/mensagem.service";
import { userService } from "../../services/user.service";
import TopoTitleComponente from "../home/TopoTitleComponente";
import MensagemForm from "./MensagemForm";

const defaultEntity = {
    nome: '',
    tipo: '',
    cooperados: []
};

const NewMensagem = Yup.object().shape({
    nome: Yup.string()
        .min(3, 'Nome muito curto!')
        .max(120, 'Nome muito grande!')
        .required('Campo obrigatório'),
    cooperados: Yup.array().nullable().test('cooperados', 'Campo Obrigatório', function () {
        const { parent, createError } = this;
        if (parent.tipo && parent.tipo === "cooperado" && (!parent.cooperados || parent.cooperados === null)) {
            return createError({
                path: "cooperados",
                message: 'Campo Obrigatório'
            });
        } else return true;
    }),
    especialidades: Yup.array().nullable().test('especialidades', 'Campo Obrigatório', function () {
        const { parent, createError } = this;
        if (parent.tipo && parent.tipo === "especialidade" && (!parent.especialidades || parent.especialidades === null)) {
            return createError({
                path: "especialidades",
                message: 'Campo Obrigatório'
            });
        } else return true;
    }),
    categoria: Yup.string().required("Obrigatório"),
    textoCorpo: Yup.string().required('Campo obrigatório'),
});


class MensagemEdit extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
        this.state = {
            entity: defaultEntity,
        };

        if (userService.isCooperado(this.props.user)) {
            defaultEntity.tipo = 'cooperado';
            defaultEntity.cooperados.push(this.props.user)
        } else {
            defaultEntity.tipo = '';
            defaultEntity.cooperados = [];
        }
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.props.loading(true);
            mensagemService.doGet(this.props.match.params.id).then(
                response => {
                    let user = response.data;
                    this.setState({ entity: user }, () => {
                        this.props.loading(false);
                    });
                },
                error => {
                    console.error(error);
                    this.props.loading(false);
                }
            );
        }
    }

    componentDidUpdate() {
        if (this.props.match.params.id && !this.state.entity.id && this.state.entity.id !== this.props.match.params.id) {
            this.props.loading(true);
            mensagemService.doGet(this.props.match.params.id).then(
                response => {
                    let user = response.data;
                    this.setState({ entity: user }, () => {
                        this.props.loading(false);
                    });
                },
                error => {
                    console.error(error);
                    this.props.loading(false);
                }
            );
            
        }
    }
    
    handleCancel = () => {
        this.props.history.goBack();
    };

 

    handleSubmit = e => {
        this.formRef.validateForm(this.formRef.values).then(erros => {
            console.error(erros);
            if (_.isEmpty(erros)) {
                this.formRef.handleSubmit();
            }
        });
    };

    getServiceDoSave = () => {
        let entity;
        if (this.formRef) {
            entity = _.cloneDeep(this.formRef.state.values);
        } else {
            entity = this.props.location && this.props.location.state.entity;
        }
        return mensagemService;
    };

    goToView = values => {
        this.props.history.push({
            pathname: "/mensagem/view/" + (values.id),
            state: { entity: values }
        });
    };

    handleChange = (name, value) => {
        const entity = _.cloneDeep(this.formRef.state.values);
        if (userService.isConsultorio(entity)) {
            _.set(entity.secretaria, name, value);
        } else {
            _.set(entity, name, value);
        }

        this.setState({ entity });
    };

    render() {
        const { entity } = this.state;
        let _this = this;

        return (<React.Fragment>
            <div className="container-fluid container-com-topo">
                <div className={`action-line ${this.state.activeClass} fixed-container-topo`}>
                    <TopoTitleComponente
                        mainTitle={`${entity.id ? 'Editar ' : 'Novo '}  modelo`}
                        canBack={true}
                        backUrl={!entity.id ? null : "/mensagem"}
                    />
                </div>
                <div className={`pt-4`}>
                    <hr className="text-center w-100"></hr>

                    <div className="background-lines">
                        <div className="bg-riscos"></div>
                    </div>
                    <Formik
                        validationSchema={NewMensagem}
                        validateOnBlur={false}
                        validateOnChange={false}
                        enableReinitialize={true}
                        initialValues={this.state.entity}
                        onSubmit={(values, actions) => {
                            this.props.loading(true);
                            let serviceSave = this.getServiceDoSave(values);
                            serviceSave.doSave(values).then(
                                response => {
                                    this.props.success({
                                        message: `Mensagem ${values.nome}, foi ${!values.id ? "criada" : "alterada"
                                            } com sucesso!`
                                    });
                                    this.props.loading(false);
                                    let id = values.id
                                        ? values.id
                                        : response.data.id;
                                    values.id = id;
                                    _this.goToView(response.data)
                                },
                                erros => { }
                            )
                        }}
                        ref={form => {
                            this.formRef = form;
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                            validationSchema,
                            validateForm,
                            setValues
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <React.Fragment>
                                    <MensagemForm
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        errors={errors}
                                        handleChange={handleChange}
                                        handleCancel={this.handleCancel}
                                        handleSubmit={handleSubmit}
                                        touched={touched}
                                        validateForm
                                        viewMode={false}
                                        setValues={setValues}
                                    ></MensagemForm>
                                </React.Fragment>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </React.Fragment>)
    }
}

function mapStateToProps(state) {
    const { user } = state.authentication;
    return {
        user
    };
}

const mapDispatch = ({
    alert: { success, error, clear },
    load: { loading },
    authentication: { refreshUser }
}) => ({
    success: msg => success(msg),
    loading: (load: Boolean) => loading({ load }),
    error: msg => error(msg),
    clear: () => clear(),
    refreshUser: payload => refreshUser(payload)
});

export default connect(mapStateToProps, mapDispatch, null, { withRef: true })(MensagemEdit);