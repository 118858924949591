import React, { PureComponent } from "react";
import ReactLoading from "react-loading";
import { connect } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import { PrivateRoute } from "./components/templates/PrivateRoute";
import Toast from "./components/Toasts/Toast";
import { urlsConstants } from "./constants/url.constant";
import Home from "./views/home/Home";
import IndexLogin from "./views/login/IndexLogin";

class App extends PureComponent<Props, State> {

  render() {
    const { loggedIn, loading } = this.props;

    return (
        <div className="notranslate">
        <Modal
          isOpen={loading}
          toggle={this.toggle}
          backdrop="static"
          modalClassName="modalCenter"
          className="modalLoading"
          centered={true}>
          <ModalBody>
            {" "}
            <ReactLoading
              className="loading"
              type={"bars"}
              color="#fff"
              height={"70px"}
              width={"70px"}
            />
          </ModalBody>
        </Modal>
        <Toast></Toast>

        <BrowserRouter basename={urlsConstants.CONTEXT_PATH}>
          <Switch>
            <Route path="/login" component={IndexLogin} />                  
            <PrivateRoute
              path="/"
              component={Home}
              loggedIn={this.props.loggedIn}
            />
          </Switch>
        </BrowserRouter>
        </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggedIn } = state.authentication;
  const { load } = state.load;
  const { showMenu } = state.menu;

  return {
    loggedIn,    
    loading: load,
    showMenu
  };
}
const mapDispatch = ({
  fieldMessage: { addAllMessage, clearMessage },
  authentication: { success },
  alert: { error, clear, info }
}) => ({
  addAllMessage: messages => addAllMessage(messages),
  success: user => success(user),
  clearMessage: () => clearMessage(),
  error: message => error(message),
  info: message => info({ message }),
  clear: clear()
});
export default connect(
  mapStateToProps,
  mapDispatch
)(App);
