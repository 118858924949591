//@flow
import React from 'react';

type State = {
  +mainTitle: string,
  +subTitle: string
};

type Payload = {
  mainTitle: string,
  subTitle: string,
  canBack: boolean,
  backUrl:string,
  backConfirmObject: Object
};

export default {
  state: { mainTitle: '', subTitle: '', canBack: false,backConfirmObject:null },
  reducers: {
    changePageTitle: (state: State, payload: Payload) => {
      return Object.assign({}, state, {
        mainTitle: payload.mainTitle,
        subTitle: payload.subTitle,
        canBack: payload.canBack,
        backUrl:payload.backUrl,
        backConfirmObject: payload.backConfirmObject
      });
    }
  }
};
