//@flow
import { Formik } from "formik";
import _ from "lodash";
import * as React from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import connect from "react-redux/es/connect/connect";
import * as Yup from "yup";
import { translate } from "../../helpers/message.helper";
import { defaultService } from "../../services/defaultService";
import { userService } from "../../services/user.service";
import TopoTitleComponente from "../home/TopoTitleComponente";
import AvisosTopoCrud from "./AvisosTopoCrud";
import InputViewEdit from "../../components/Inputs/InputViewEdit";
import FormInput from "../../components/Inputs/FormInput";
import {FormGroup} from "../../components/FormGroup";
import {avisosService} from "../../services/avisos.service";
import SelectableInput from "../../sascComponents/inputs/SelectableInput";
import DatePickerInput from "../../components/Inputs/DatePickerInput";
import { dateHelper } from "../../helpers/date.helper";
import { Card, CardBody, CardHeader } from "reactstrap";
import moment from 'moment';
import FormTextCKEditor from "../../components/Inputs/FormTextCKEditorAviso";
const NewAvisoValidator = Yup.object().shape({
  titulo: Yup.string()
    .min(10, "Nome muito curto!")
    .max(50, "Nome muito grande!")
    .required("Obrigatório"),
  descricao: Yup.string()
     .required("Obrigatório")
     //.max(300, "Quantidade de caracteres excedeu o limite permitido (300)")
     .test('charCount', 'Quantidade de caracteres excedeu o limite permitido (300)', function (value) {
       const textOnly = value ? value.replace(/<[^>]*>/g, '').replace(/&[^;]+;/g, '') : '';
       return textOnly.length <= 300;
     }), 
     dataHoraFim: Yup.string().nullable().test('dataHoraFim', 'Data fim anterior a data inicio do Aviso.', function () {
    const { parent, createError } = this;
    let dataHoraFim = parent.dataHoraFim;
    let dataHoraInicio = parent.dataHoraInicio;

    if (dataHoraFim&&dataHoraInicio && moment(dataHoraInicio).isAfter(dataHoraFim)) {
      return createError();
    } else {
      return true;
    }
  }),
});
class AvisosEdit extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: {
        status: { name: "ATIVO" },
        usuarios: [{ idKey: new Date().getTime() }],
      },
      filter : {}
    };
  }
  
  goToViewUser = values => {
    this.props.history.push({
      pathname: "/avisos/view/" + values.id,
      state: { entity: values }
    });
  };

  handleCancel = () => {
    this.props.history.goBack();
  };


  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.loading(true);
      avisosService.doGet(this.props.match.params.id).then(
        response => {
          let user = response.data.data;
          this.setState({ entity: user }, () => {
            this.props.loading(false);
          });
        },
        error => {
          console.log(error);
          this.props.loading(false);
        }
      );
    }
  }

  handleChange = (name, value) => {
    const entity = _.cloneDeep(this.formRef.state.values);
    if (userService.isAviso(entity)) {
      _.set(entity.secretaria, name, value);
    } else {
      _.set(entity, name, value);
    }

    this.setState({ entity });
  };

  handleSubmit = e => {
    this.formRef.validateForm(this.formRef.values).then(erros => {
      console.log(erros);
      if (_.isEmpty(erros)) {
        this.formRef.handleSubmit();
      }
    });
  };

  getServiceDoSave = () => {
    let entity;
    if (this.formRef) {
      entity = _.cloneDeep(this.formRef.state.values);
    } else {
      entity = this.props.location && this.props.location.state.entity;
    }

    if (userService.isAviso(entity)) {
      return avisosService;
    }
    return userService;
  };

  render() {
    let _this = this;
    const { entity, viewMode } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid container-com-topo">
          <div
            className={`action-line ${this.state.activeClass} fixed-container-topo`}
          >
            <TopoTitleComponente
              mainTitle={`${entity.id ? "Editar " : "Novo "}  Aviso ${
                entity.usuarioPerfis
                  ? " - " + userService.getPerfilAtual(entity)
                  : ""
              }`}
              canBack={true}
              backUrl={!entity.id ? null : "/avisos"}
            />
            <AvisosTopoCrud
              values={entity}
              onChange={this.handleChange}
              handleSubmit={this.handleSubmit}
              handleCancel={this.handleCancel}
            ></AvisosTopoCrud>
          </div>
          <div className={`contentComTopoFix pt-4`}>
            <Formik
              validationSchema={NewAvisoValidator}
              validateOnBlur={false}
              validateOnChange={false}
              enableReinitialize={true}
              initialValues={_this.state.entity}
              onSubmit={(values, actions) => {
                this.props.loading(true);
                avisosService.doSave(values).then(
                  response => {
                    this.props.success({
                      message: `Aviso ${values.titulo}, foi ${
                        !values.id ? "criado" : "alterado"
                      } com sucesso!`
                    });
                    let id = values.id
                      ? values.id
                      : defaultService.getIdFromUrl(response.headers.location);

                    values.id = id;
                    _this.goToViewUser(values);
                    this.props.loading(false);
                  },
                  erros => {
                    console.log(erros.response);
                    this.props.error({
                      message:
                        "Não foi possível criar o aviso, existem erros no formulário!"
                    });
                    try {
                      let response = erros.response.data;
                      if (response && response.messages) {
                        for (var i = 0; i < response.messages.length; i++) {

                          let erroItem = response.messages[i];
                          actions.setFieldError(
                            erroItem.fieldName,
                            translate(erroItem.message.code)
                          );
                        }
                      }
                    } catch (error) {
                      console.log(error);
                    }

                    this.props.loading(false);
                actions.setSubmitting(false);
                  }
                );
               
              }}
              ref={form => {
                this.formRef = form;
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                validationSchema,
                validateForm,
                setValues
              }) => (
                <form onSubmit={handleSubmit}>
                  <React.Fragment>
                  <div className="item">
                    <Card className="cardCrud content-form">
                      <CardHeader>
                        <div className="d-flex justify-content-between align-items-center">

                          <div className="px-sm-3">
                            {viewMode && (
                              <button
                                type="button"
                                className="btn btn-light"
                                onClick={this.goToEdit}
                              >
                                <span className="icon-btn-editar"></span>Editar
                              </button>
                            )}
                          </div>
                        </div>
                      </CardHeader>

                      <CardBody className={"form-inside viewMode"}>
                        <div className="row section-form">
                          <h6 className="w-100">Título e Descrição</h6>
                        </div>
                        <div className="row section-form">
                          <FormGroup className="">
                            <InputViewEdit
                                component={FormInput}
                                label={"Título"}
                                type="text"
                                id="titulo"
                                name="titulo"
                                maxLength={50}
                                placeholder="Digite o titulo do aviso"
                                value={values.titulo}
                                onChange={(name, value) => setFieldValue(name, value)}
                                noSize={true}
                                erroMensagem={errors.titulo}
                                viewMode={false}
                                required={true}
                            />
                          </FormGroup>
                          <div className="quebra"></div>
                          <FormGroup className="">  
                            <InputViewEdit
                              label={"Descrição"}
                              component={FormTextCKEditor}
                              maxLength={300}
                              onChange={(name, value) => {
                                  setFieldValue(name, value);
                              }}
                              viewMode={this.state.viewMode}
                              required={false}
                              name={`descricao`}
                              placeholder="Digite a descrição do aviso"
                              type={"text"}
                              value={values.descricao
                              }
                              clearable={true}
                              defaultValue={<div
                                  dangerouslySetInnerHTML={{__html: values.descricao ? values.descricao : ''}}></div>}
                              id={`descricao`}
                              erroMensagem={
                                  _.get(errors, `descricao`)
                              }
                          />

                                                    
                          </FormGroup>
                          </div>
                          </CardBody>

                        {(values.tipoAviso !== null && <CardBody className={"form-inside viewMode"}>
                          <div className="row section-form">
                            <h6 className="w-100">Datas e Filtro</h6>
                          </div>
                          <div className="row section-form">

                          <FormGroup className="">  
                            <SelectableInput viewMode={viewMode} atendimento={values} className="fixo"
                              name={"dataHoraInicio"}
                              label={"Data Início Aviso"}
                              value={values.dataHoraInicio}
                            >
                              <InputViewEdit
                                label="Data Início Aviso"
                                component={DatePickerInput}
                                onChange={(name, date) => {
                                  setFieldValue(name, date);
                                }}
                                defaultValue={
                                  values.dataHoraInicio
                                    ? dateHelper.format(values.dataHoraInicio, {
                                      mode: "DATE"
                                    })
                                    : "--"
                                }
                                mask={"99/99/9999"}
                                name={"dataHoraInicio"}
                                id={"dataHoraInicio"}
                                placeholder="Data Início Aviso"
                                viewMode={viewMode}
                                type={"text"}
                                value={values.dataHoraInicio}
                                erroMensagem={errors.dataHoraInicio}
                                required={false}
                              />
                            </SelectableInput>
                            </FormGroup>
                            <FormGroup className="">  
                            <SelectableInput viewMode={viewMode} atendimento={values} className="fixo"
                              name={"dataHoraFim"}
                              label={"Data Fim Aviso"}
                              value={values.dataHoraFim}
                            >
                              <InputViewEdit
                                label="Data Fim Aviso"
                                component={DatePickerInput}
                                onChange={(name, date) => {
                                  setFieldValue(name, date);
                                }}
                                defaultValue={
                                  values.dataHoraFim
                                    ? dateHelper.format(values.dataHoraFim, {
                                      mode: "DATE"
                                    })
                                    : "--"
                                }
                                mask={"99/99/9999"}
                                name={"dataHoraFim"}
                                id={"dataHoraFim"}
                                placeholder="Data Fim Aviso"
                                viewMode={viewMode}
                                type={"text"}
                                value={values.dataHoraFim}
                                erroMensagem={errors.dataHoraFim}
                                required={false}
                              />
                            </SelectableInput>
                          </FormGroup>
                                      
                          </div>
                        </CardBody>)}
                      </Card>
                    </div>
                    
                  </React.Fragment>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading } ,
  authentication: { doRefresh }


}) => ({
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  clear: () => clear(),
  doRefresh:() => doRefresh()
});

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user
  };
}
export default connect(mapStateToProps, mapDispatch, null, { withRef: true })(
  AvisosEdit
);