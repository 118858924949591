//@flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

type Props = {
  title: string,
  className: string,
  type: string,
  onSubmit: any,
  disabled: boolean
};

class BtnSecondary extends PureComponent<Props> {
  render() {
    const { title, className, onSubmit, type, disabled } = this.props;

    let defaultClass = 'btn btn-secondary';
    if (this.props.className) {
      defaultClass += ' ' + className;
    }

    return (
      <div className="container-btn-secondary">
        <button
          onClick={onSubmit}
          className={defaultClass}
          type={type}
          disabled={disabled}
        >
          {title}
        </button>
      </div>
    );
  }
}

export default connect()(BtnSecondary);
