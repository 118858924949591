//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import CooperadoList from './CooperadoList';
import CooperadoEdit from './CooperadoEdit';
import CooperadoView from './CooperadoView';
export default class Cooperado extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/cooperados" component={CooperadoList} exact/>
          <Route path="/cooperados/novo" component={CooperadoEdit}  />
          <Route path="/cooperados/edit/:id" component={CooperadoEdit}  />
          <Route path="/cooperados/view/:id" component={CooperadoView}  />
        </Switch>
      </React.Fragment>
    );
  }
}
