//@flow
import * as React from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import connect from "react-redux/es/connect/connect";
import { withRouter } from "react-router-dom";
import UsuarioTopoCrud from "./UsuarioTopoCrud";
import { Formik } from "formik";
import * as Yup from "yup";
import TopoTitleComponente from "../home/TopoTitleComponente";
import UsuarioForm from "./UsuarioForm";
import { userService } from "../../services/user.service";
import { translate } from "../../helpers/message.helper";
import { defaultService } from "../../services/defaultService";
import _ from "lodash";
import { objectsConstants } from "../../constants/objects.constants";
import { estabelecimentoService } from "../../services/estabelecimento.service";

const NewUsuario = Yup.object().shape({
  // custum validation de um campo
  password: Yup.string().test("valid-senha", "Senha obrigatório", function() {
    const { parent, createError } = this;
    if (!parent.id && _.isNil(parent.password)) {
      return createError();
    } else {
      return true;
    }
  }),
  nome: Yup.string()
    .min(10, "Nome muito curto!")
    .max(250, "Nome muito grande!")
    .required("Obrigatório"),
/*  email: Yup.string()
    .email("email inválido")
    .required("Obrigatório"),*/
  cpf: Yup.string().required("Obrigatório"),
  password: Yup.string().when("id", (id, schema) => {
    if (id === null || id === undefined) {
      return schema.required("Obrigatório");
    }
  })
});

const NewUsuarioConsultorio = Yup.object().shape({
  nome: Yup.string()
    .min(10, "Nome muito curto!")
    .max(250, "Nome muito grande!")
    .required("Obrigatório"),
 /* email: Yup.string()
    .email("email inválido")
    .required("Obrigatório"),*/
  cnpj: Yup.string().required("Obrigatório"),
  especialidades: Yup.array().min(1, "Obrigatório"),
  convenios: Yup.array().min(1, "Obrigatório"),
  endereco: Yup.object().shape({
    cep: Yup.string().required("Obrigatório"),
    logradouro: Yup.string().required("Obrigatório"),
    numero: Yup.string().required("Obrigatório"),
    bairro: Yup.string().required("Obrigatório"),
    cidade: Yup.object().shape({
      nome: Yup.string().required("Obrigatório")
    }),
    telefone: Yup.string().required("Obrigatório")
  }),
  secretaria: Yup.object().shape({
    email: Yup.string()
      .email("Email inválido")
      .required("Obrigatório"),
    nome: Yup.string().required("Obrigatório"),
    cpf: Yup.string().required("Obrigatório"),
    password: Yup.string().when("id", (id, schema) => {
      if (id === null || id === undefined) {
        return schema.required("Obrigatório");
      }
      return schema;
    }),
    confirmPassword: Yup.string()
      .when("id", (id, schema) => {
        if (id === null || id === undefined) {
          return schema.required("Obrigatório");
        }
        return schema;
      })
      .oneOf([Yup.ref("password"), null], "As senhas não conferem")
  })
});
class UsuarioEdit extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: {
        status: { name: "ATIVO" }
      },
      validateModel: NewUsuario
    };
  }

  goToViewUser = values => {
    this.props.history.push({
      pathname: "/usuario/view/" + values.id,
      state: { entity: values }
    });
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.loading(true);
      let service = this.getServiceDoSave();
      service.doGet(this.props.match.params.id).then(
        response => {
          let user = response.data.data;
          let validateModel = this.ObjectValidateProfile(user.usuarioPerfis);
          this.setState({ entity: user, validateModel }, () => {
            this.props.loading(false);
          });
        },
        error => {
          console.error(error);
          this.props.loading(false);
        }
      );
    }
  }

  ObjectValidateProfile = usuarioPerfis => {
    if (usuarioPerfis&&usuarioPerfis.length>0&&usuarioPerfis[0].perfil.tipoPerfil === objectsConstants.CONSULTORIO) {
      return NewUsuarioConsultorio;
    }
    return NewUsuario;
  };

  handleChangeProfile = (name, value) => {
    let validateModel = this.ObjectValidateProfile(value);
    const entity = _.cloneDeep(this.state.entity);
    _.set(entity, name, value);
    this.setState({ entity, validateModel });
  };

  handleChange = (name, value) => {
    const entity = _.cloneDeep(this.formRef.state.values);
    _.set(entity, name, value);
    this.setState({ entity });
  };

  handleSubmit = e => {
    this.formRef.validateForm(this.formRef.values).then(erros => {
      console.error(erros);
      if (_.isEmpty(erros)) {
        this.formRef.handleSubmit();
      }
    });
  };

  getServiceDoSave = () => {
    let entity;
    if (this.formRef) {
      entity = _.cloneDeep(this.formRef.state.values);
    } else {
      entity = this.props.location && this.props.location.state.entity;
    }
    return userService;
  };

  getUserFromProfile = entity => {
    return entity;
  };

  render() {
    let _this = this;
    const { entity } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid container-com-topo">
          <TopoTitleComponente
            mainTitle={`${entity.id ? "Editar " : "Novo "}  usuário ${
              entity.usuarioPerfis
                ? " - " + userService.getPerfilAtual(entity)
                : ""
            }`}
            canBack={true}
            backUrl={!entity.id ? null : "/usuario"}
          />
          <UsuarioTopoCrud
            values={this.getUserFromProfile(entity)}
            onChangeProfile={this.handleChangeProfile}
            onChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            handleCancel={this.handleCancel}
            edit={true}
          ></UsuarioTopoCrud>

          {entity.usuarioPerfis && (
            <Formik
              validationSchema={this.state.validateModel}
              validateOnBlur={false}
              validateOnChange={false}
              enableReinitialize={true}
              initialValues={_this.state.entity}
              onSubmit={(values, actions) => {
                this.props.loading(true);
                let serviceSave = this.getServiceDoSave(values);
                serviceSave.doSave(values).then(
                  response => {
                    this.props.success({
                      message: `${userService.getPerfilAtual(values)}
 ${values.nome}, foi ${!values.id ? "criado" : "alterado"} com sucesso!`
                    });
                    let id = values.id
                      ? values.id
                      : defaultService.getIdFromUrl(response.headers.location);
                    values.id = id;
                    //se for usuário atual, atualiza usuário no redux
                    if(_this.props.user.id===values.id){
                      values.usuarioPerfis=_this.props.user.usuarioPerfis;
                      _this.props.refreshUser({user:values})
                    }
                    _this.goToViewUser(values);
                  },
                  erros => {
                    console.error(erros.response);
                    this.props.error({
                      message:
                        "Não foi possível criar usuário, existem erros no formulário!"
                    });
                    try {
                      let response = erros.response.data;
                      if (response && response.messages) {
                        for (var i = 0; i < response.messages.length; i++) {
                          let erroItem = response.messages[i];
                          actions.setFieldError(
                            erroItem.fieldName,
                            translate(erroItem.message.code)
                          );
                        }
                      }
                    } catch (error) {
                      console.error(error);
                    }

                    this.props.loading(false);
                  }
                );
                this.props.loading(false);
                actions.setSubmitting(false);
              }}
              ref={form => {
                this.formRef = form;
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                validationSchema,
                validateForm
              }) => (
                <form onSubmit={handleSubmit}>
                  <React.Fragment>
                    {values.usuarioPerfis && (
                      <FormByProfile
                        values={values}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                      ></FormByProfile>
                    )}
                  </React.Fragment>
                </form>
              )}
            </Formik>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const FormByProfile = React.memo(({ values, ...others }) => {
  return <UsuarioForm values={values} {...others} />;
});

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  authentication: { refreshUser }
}) => ({
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  clear: () => clear(),
  refreshUser: payload => refreshUser(payload)
});

export default connect(mapStateToProps, mapDispatch, null, { withRef: true })(UsuarioEdit);
