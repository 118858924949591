//@flow
import React, { PureComponent } from 'react';
import ReduxToastr from 'react-redux-toastr';
import { connect } from 'react-redux';

class Toast extends PureComponent<> {
  render() {
    return (
      <ReduxToastr
        timeOut={10000}
        newestOnTop={false}
        preventDuplicates
        position="top-center"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        removeOnHover={true}
        progressBar={true}
      />
    );
  }
}

export default connect()(Toast);
