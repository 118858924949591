//@flow
import { Formik } from "formik";
import _ from "lodash";
import * as React from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import connect from "react-redux/es/connect/connect";
import * as Yup from "yup";
import { translate } from "../../helpers/message.helper";
import { cooperativaService } from "../../services/cooperativa.service";
import { defaultService } from "../../services/defaultService";
// import { estabelecimentoService } from "../../services/estabelecimento.service";
import { userService } from "../../services/user.service";
import TopoTitleComponente from "../home/TopoTitleComponente";
import CooperativaForm from "./CooperativaForm";
import CooperativaTopoCrud from "./CooperativaTopoCrud";

const NewCooperativa = Yup.object().shape({
  nome: Yup.string()
    .required("Obrigatório"),

});
class CooperativaEdit extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: {
        status: { name: "ATIVO" },
        usuarios: [{ idKey: new Date().getTime() }]
      }
    };
  }

  goToViewUser = values => {
    this.props.history.push({
      pathname: "/cooperativa/view/" + values.id,
      state: { entity: values }
    });
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.loading(true);
      cooperativaService.doGet(this.props.match.params.id).then(
        response => {
          let cooperativa = response.data.data;
          this.setState({ entity: cooperativa }, () => {
            this.props.loading(false);
          });
        },
        error => {
          console.error(error);
          this.props.loading(false);
        }
      );
    }
  }

  handleChange = (name, value) => {
    const entity = _.cloneDeep(this.formRef.state.values);
    if (userService.isConsultorio(entity)) {
      _.set(entity.secretaria, name, value);
    } else {
      _.set(entity, name, value);
    }

    this.setState({ entity });
  };

  handleSubmit = e => {
    this.formRef.validateForm(this.formRef.values).then(erros => {
      console.error(erros);
      if (_.isEmpty(erros)) {
        this.formRef.handleSubmit();
      }
    });
  };

  // getServiceDoSave = () => {
  //   let entity;
  //   if (this.formRef) {
  //     entity = _.cloneDeep(this.formRef.state.values);
  //   } else {
  //     entity = this.props.location && this.props.location.state.entity;
  //   }

  //   if (userService.isConsultorio(entity)) {
  //     return estabelecimentoService;
  //   }
  //   return userService;
  // };

  render() {
    let _this = this;
    const { entity } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid container-com-topo">
          <div
            className={`action-line ${this.state.activeClass} fixed-container-topo`}
          >
            <TopoTitleComponente
              mainTitle={`${entity.id ? "Editar " : "Nova "}  cooperativa ${entity.usuarioPerfis
                ? " - " + userService.getPerfilAtual(entity)
                : ""
                }`}
              canBack={true}
              backUrl={!entity.id ? null : "/cooperativa"}
            />
            <CooperativaTopoCrud
              values={entity}
              onChange={this.handleChange}
              handleSubmit={this.handleSubmit}
              handleCancel={this.handleCancel}
            ></CooperativaTopoCrud>
          </div>
          <div className={`contentComTopoFix pt-4`}>
            <Formik
              validationSchema={NewCooperativa}
              validateOnBlur={false}
              validateOnChange={false}
              enableReinitialize={true}
              initialValues={_this.state.entity}
              onSubmit={(values, actions) => {
                this.props.loading(true);
                cooperativaService.doSave(values).then(
                  response => {
                    this.props.success({
                      message: `Cooperativa ${values.nome}, foi ${!values.id ? "criado" : "alterado"
                        } com sucesso!`
                    });
                    let id = values.id
                      ? values.id
                      : defaultService.getIdFromUrl(response.headers.location);
                    for (let i = 0; i < values.usuarios; i++) {
                      _.set(values, `usuarioPerfis[${i}].extensao`, id);
                    }
                    values.id = id;
                    _this.goToViewUser(values);
                    this.props.loading(false);
                  },
                  erros => {
                    console.error(erros.response);
                    this.props.error({
                      message:
                        "Não foi possível criar cooperativa, existem erros no formulário!"
                    });
                    try {
                      let response = erros.response.data;
                      if (response && response.messages) {
                        for (var i = 0; i < response.messages.length; i++) {
                          let erroItem = response.messages[i];
                          actions.setFieldError(
                            erroItem.fieldName,
                            translate(erroItem.message.code)
                          );
                        }
                      }
                    } catch (error) {
                      console.error(error);
                    }

                    this.props.loading(false);
                    actions.setSubmitting(false);
                  }
                );

              }}
              ref={form => {

                this.formRef = form;
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                validationSchema,
                validateForm,
                setValues
              }) => (
                  <form onSubmit={handleSubmit}>
                    <React.Fragment>
                      <CooperativaForm
                        showButtonAdd={true}
                        values={values}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                        validateForm
                        setValues
                      ></CooperativaForm>
                    </React.Fragment>
                  </form>
                )}
            </Formik>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading }
}) => ({
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  clear: () => clear()
});

export default connect(null, mapDispatch, null, { withRef: true })(
  CooperativaEdit
);
