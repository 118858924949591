import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { objectsConstants } from "../../constants/objects.constants";
import Datatable from "../../sascComponents/datagrid/Datatable";
import TopoListagem from "../../sascComponents/topo/TopoListagem";
import { exameService } from "../../services/exame.service";
import { userService } from "../../services/user.service";
import TopoTitleComponente from "../home/TopoTitleComponente";


class ExameList extends PureComponent<Props, State> {
    constructor(props) {
        super(props);
        let initFilter = null
        const isCooperado = userService.isCooperado(this.props.user);
        if (isCooperado) {
            initFilter = [{
                fieldName: "cooperado",
                fieldDescription: "Id",
                columnOrder: 1,
                searchableField: true,
                value: this.props.user.id,
                type: "NUMBER",
                condition: { description: "=" },

            }];
        }
        this.state = {
            initFilter
        };
    }
    closeMenu = () => {
        this.props.showMenu();
    };
    handleRowClick = (fieldName, rowData) => {
    };

    render() {
        const { permissions } = this.props; 
        return (
            <div className="container-fluid container-com-topo">
                <TopoTitleComponente
                    mainTitle="Exame(s)"
                    subTitle=" "
                    canBack={false}
                />
                {(userService.isPerfil(
                  this.props.user,
                  objectsConstants.ADMINISTRADOR
                )) && (<TopoListagem
                    securityActionDo={objectsConstants.ALTERAR}
                    securityActionOn={objectsConstants.CRUDCONSULTORIOS_CADASTRAR}
                    buttonActionClassName={"btn-light"}
                    buttonAction={() => {
                        this.props.history.push("/pacoteexame");
                    }}
                    securityActionIcon={"icon-btn-add"}
                    securityActionName={"Pacotes de exames"}
                    showfilter={false}
                />)}
                <hr className="text-center w-75"></hr>

                <div className="background-lines">
                    <div className="bg-riscos"></div>
                </div>

                <Datatable
                    showCheckboxColumn={true}
                    idRow={"id"}
                    name={"Exame(s)"}
                    service={exameService}
                    showAllColumnsFilter={false}
                    classHeader={[
                        "headerLeft",
                        "headerLeft",
                        "headerLeft",
                        "headerLeft",
                        "headerLeft",
                        "headerLeft",
                        "headerLeft",
                        "headerLeft",
                        "headerLeft"
                    ]}
                    classColumn={[]}
                    initFilter={this.state.initFilter}
                    onRowClick={this.handleRowClick}
                />
            </div>
        );
    }
}

const mapDispatch = ({ menu: { showMenu, hideMenu } }) => ({
    showMenu: () => showMenu(),
    hideMenu: () => hideMenu()
});

function mapStateToProps(state) {
    const { loggedIn, user, permissions } = state.authentication;
    const { alert } = state;

    return {
        loggedIn,
        alert,
        user,
        permissions
    };
}

export default connect(
    mapStateToProps,
    mapDispatch
)(withRouter(ExameList));
