//@flow
import _ from 'lodash';

type State = {
  pendenciaOpen: boolean,
  idAtendimentoAtual: Number,
  campoSelecionado: Object
};

export default {
  state: { resolverPendenciaOpen: false, pendenciaOpen: false, idAtendimentoAtual: 0, cooperados: [], campoSelecionado: {} },
  reducers: {

    atendimentoAtual: (state: State, payload: Object) => {
      let cooperados = [];
      for (let identificacaoAtendimentoExecucao in payload.identificacaoAtendimentoExecucao) {
        if (payload.identificacaoAtendimentoExecucao[identificacaoAtendimentoExecucao].cooperado) {
          cooperados.push(payload.identificacaoAtendimentoExecucao[identificacaoAtendimentoExecucao].cooperado);
        }
      }
      cooperados = _.uniqBy(cooperados, 'id');

      return Object.assign({}, state, {
        idAtendimentoAtual: payload.id,
        cooperados,
        atendimentoPendencias: payload.atendimentoPendencias,
        numeroAtendimento: payload.numeroAtendimento
      });
    },

    campoAtual: (state: State, payload: Object) => {
      return Object.assign({}, state, {
        campoSelecionado: payload.campoSelecionado
      });
    }
  }
};
