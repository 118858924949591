import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TopoTitleComponente from "../home/TopoTitleComponente";
import _ from "lodash";
import RelatorioTopo from "./RelatorioTopo";
import DashStatusExames from "./statusExames/DashStatusExames";
import DashRegiaoEspecialidade from "./quantidadeRegiaoEspecialidade/DashRegiaoEspecialidade";
import { Formik } from "formik";


class RelatorioExport extends PureComponent<Props, State> {
    constructor(props) {
        super(props);
        this.state={yup:null,Filtro:null,controller:null,nome:null, entity: {}};
    }


    handleChange=(name,relatorio)=>{
        if(relatorio){
         const   {yup,Filtro,controller,nome} = relatorio;
         this.setState({yup,Filtro,controller,nome});
        }else{
            this.setState({yup:null,Filtro:null,controller:null,nome:null});
        }
    }

    handleStatusExamesChange = (name, value) => {
        const entity = _.cloneDeep(this.formRef.state.values);
        _.set(entity, name, value);
        this.setState({ entity });
    };


    render() {
        const {yup,Filtro,controller,nome} = this.state;
        let _this = this;
        const { entity } = this.state;
        return (
            <div className="container-fluid container-com-topo">
                <TopoTitleComponente
                    mainTitle="Relatório"
                    subTitle=" "
                    canBack={false}
                />
                <RelatorioTopo onChange={this.handleChange} name={nome} yup={yup} Filtro={Filtro} controller={controller}/>
                {this.state.controller === 'statusExames' &&
                    <div className=''>
                        <Formik
                            validateOnBlur={false}
                            validateOnChange={false}
                            enableReinitialize={true}
                            initialValues={_this.state.entity}
                            onSubmit={(values, actions) => {}}
                            ref={form => {
                                this.formRef = form;
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                                validationSchema,
                                validateForm,
                                setValues
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <React.Fragment>
                                        <DashStatusExames
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            errors={errors}
                                            handleChange={this.handleStatusExamesChange}
                                            touched={touched}
                                            validateForm
                                            setValues={setValues}
                                        ></DashStatusExames>
                                    </React.Fragment>
                                </form>
                            )}
                        </Formik>
                    </div>}
            </div>
        );
    }
}


export default connect(
    null,
    null
)(withRouter(RelatorioExport));
