import { Can } from "@casl/react";
import update from "immutability-helper";
import * as _ from "lodash";
import moment from "moment";
import React, { PureComponent } from "react";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Input } from "reactstrap";
import CheckBoxInput from "../../components/Inputs/CheckBoxInput";
import SelectInput from "../../components/Inputs/SelectInput";
import iconCriarLote from "../../img/icon_criarLote.svg";
import { objectsConstants } from "../../constants/objects.constants";
import { urlsConstants } from "../../constants/url.constant";
import { atendimentoService } from "../../services/atendimento.service";
import { statusAtendimentoService } from "../../services/statusAtendimento.service";
import { estabelecimentoService } from "../../services/estabelecimento.service";
import { userService } from "../../services/user.service";
import TopoTitleComponente from "../home/TopoTitleComponente";
import GuiaAtendimentoForm from "./GuiaAtendimentoForm";
import GuiaConsultaForm from "./GuiaConsultaForm";
import GuiaHonorariosForm from "./GuiaHonorariosForm";
import GuiaPesquisa from "./GuiaPesquisa";
import GuiaSADTForm from "./GuiaSADTForm";
import GuiaTopo from "./GuiaTopo";
import MenuHistorico from "./historico/MenuHistorico";
import MenuModelView from "./menuModelView/MenuModelView";
import PendenciasTopo from "./pendencias/PendenciasTopo";
import ResolverPendenciasTopo from "./pendencias/ResolverPendenciasTopo";
import GuiaInternacaoForm from "./GuiaInternacaoForm";
import { ConfirmAcationService } from "../../services/ConfirmAcationService";
import { loteAtendimentoService } from "../../services/loteAtendimento.service";
import { conselhoService } from "../../services/conselho.service";
import EdicaoEmMassaTopo from "./edicaoEmMassa/EdicaoEmMassaTopo";
import { Modal, ModalBody } from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormGroup } from "../../components/FormGroup";
import FormTextArea from "../../components/Inputs/FormTextArea";
import { translate } from "../../helpers/message.helper";
let download = require("downloadjs");

class GuiasList extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      atendimentos: [],
      atendimentosView: [],
      selectedAtendimentos: [],
      closeAccordion: this.props.location.state.closeAccordion
        ? this.props.location.state.closeAccordion
        : false,
      atendimentoComPendencia: {},
      listAtendimentoEdicao: [],
      rowsCheckedValue: [],
      rowsCheckedIdValue: [],
      dataTotalSize: 0,
      checkedAll: false,
      checkedAllPages: false,
      pendencias: false,
      openMenuNotificacao: false,
      atendimentoHistorico: {},
      toogleModalCriarLote: false,
      protocolo: 0,
      listStatusEncaminhamento: [],
      listUfsConselho: [],
      listConselho: [],
    };
  }

  componentWillUnmount() {
    this.props.closePendecia();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location.state &&
      this.props.location.state.idPendencia !==
        prevProps.location.state.idPendencia
    ) {
      this.findAtendimentoOrCreate();
    }
  }
  componentDidMount() {
    this.findAtendimentoOrCreate();
    this.props.closePendecia();
  }
  addAtendimentoView = (atendimento) => {
    let arrayAtendimentos = _.cloneDeep(this.state.atendimentosView);
    arrayAtendimentos.unshift(atendimento);
    this.setState({ atendimentosView: arrayAtendimentos }, () => {
      this.findAtendimentoOrCreate();
    });
  };

  findAtendimentoOrCreate = () => {
    if (
      this.props.location.state &&
      this.props.location.state.entitys &&
      this.props.location.state.entitys.length > 0
    ) {
      let ids = [];
      for (let i = 0; i < this.props.location.state.entitys.length; i++) {
        ids.push(this.props.location.state.entitys[i].id);
      }
      for (let i = 0; i < this.state.atendimentosView.length; i++) {
        ids.unshift(this.state.atendimentosView[i].id);
      }
      this.findAtendimentos(ids);
    } else {
      // this.props.loading(false);
      this.setState({ atendimentos: [{}], filterSearch: [] });
    }
  };

  findAtendimentos(ids) {
    this.props.loading(true);

    statusAtendimentoService.findAllByAtendimentos().then((response) => {
      if (response.data && response.data.length > 0) {
        this.setState({ listStatusEncaminhamento: response.data });
      }
    });

    conselhoService.findListUfsConselho().then((response) => {
      if (response.data && response.data.length > 0) {
        this.setState({ listUfsConselho: response.data });
      }
    });

    conselhoService.findAll().then((response) => {
      if (response.data && response.data.length > 0) {
        this.setState({ listConselho: response.data });
      }
    });

    atendimentoService.findByIds(ids).then(
      (response) => {
        const extension = userService.getExtensionUser(this.props.user);
        let atendimentos = response.data;

        for (var j = 0; j < atendimentos.length; j++) {
         if(atendimentos[j].atendimentoProcedimentos.length==0){
          let atendimentoProcedimentos = 
            {
              numero:1,
              tabela:"22"
            }
          atendimentos[j].atendimentoProcedimentos.push(atendimentoProcedimentos)
          } 
        }
        const isAuditor = userService.isAuditor(this.props.user);
        if (extension && !isAuditor) {
          estabelecimentoService.doGet(extension).then((estabelecimento) => {
            for (var i = 0; i < atendimentos.length; i++) {
              atendimentos[i].estabelecimento = estabelecimento.data.data;
            }
            this.setState({ atendimentos });
          });
        } else {
          this.setState({ atendimentos });
        }

        this.props.loading(false);
      },
      (error) => {
        this.props.loading(false);
      }
    );
  }

  changeFilter = (filterSearch) => {
    this.setState({ filterSearch });
  };

  updateSaved = (atendimento, index) => {
    let arrayAtendimentos = _.cloneDeep(this.state.atendimentos);

    if (index !== undefined) {
      arrayAtendimentos[index] = atendimento;
    }
    this.updateAtendimentos(arrayAtendimentos);
    this.props.closePendecia();
  };

  updateAtendimentos = (arrayAtendimentos) => {
    let ids = [];
    for (let i = 0; i < arrayAtendimentos.length; i++) {
      ids.push(arrayAtendimentos[i].id);
    }
    this.props.loading(true);
    atendimentoService.findByIds(ids).then(
      (response) => {
        let arrayAtendimentos = response.data;

        this.setState({
          atendimentos: arrayAtendimentos,
          checkedAll: false,
          rowsCheckedValue: [],
          rowsCheckedIdValue: [],
        });
        this.props.loading(false);
      },
      (error) => {
        console.loading(error);
        this.props.loading(false);
      }
    );
  };

  addAtendimento = () => {
    let arrayAtendimentos = _.cloneDeep(this.state.atendimentos);
    arrayAtendimentos.unshift({});
    this.setState({ atendimentos: arrayAtendimentos });
  };

  cancelEditCreate = (values, index, viewMode) => {
    let arrayAtendimentos = _.cloneDeep(this.state.atendimentos);
    let listAtendimentoEdicao = _.cloneDeep(this.state.listAtendimentoEdicao);
    if (!viewMode) {
      listAtendimentoEdicao.push(values.id);
    } else {
      let indexListAtendimentoEdicao = listAtendimentoEdicao.indexOf(values.id);
      listAtendimentoEdicao.splice(indexListAtendimentoEdicao, 1);
    }
    if (arrayAtendimentos[index].id === undefined) {
      arrayAtendimentos = arrayAtendimentos.filter((item, i) => i !== index);
      this.setState({ atendimentos: arrayAtendimentos, listAtendimentoEdicao });
    } else {
      this.setState({ listAtendimentoEdicao });
    }
  };

  filterAtendimentos = (atendimentos) => {
    let atendimentoFilter = [];
    while (
      document.getElementsByClassName("sentencaEncontrada") != null &&
      document.getElementsByClassName("sentencaEncontrada").length > 0
    ) {
      document
        .getElementsByClassName("sentencaEncontrada")[0]
        .classList.remove("sentencaEncontrada");
    }

    if (this.state.filterSearch && this.state.filterSearch.length > 0) {
      for (let i = 0; i < this.state.filterSearch.length; i++) {
        const { filter } = this.state.filterSearch[i];
        let sentenca = this.state.filterSearch[i].value;
        if (moment.isMoment(sentenca)) {
          sentenca = sentenca.format("YYYY-MM-DD");
        }
        for (
          let indexAtendimento = 0;
          indexAtendimento < atendimentos.length;
          indexAtendimento++
        ) {
          const atendimento = atendimentos[indexAtendimento];
          let showAtendimento = false;
          for (var propName in atendimento) {
            var propValue = atendimento[propName];
            showAtendimento =
              showAtendimento ||
              this.validaFilterValue(propValue, sentenca, propName, filter);
          }
          if (showAtendimento) {
            atendimentoFilter.push(atendimento);
          }
        }
      }
    } else {
      atendimentoFilter = _.cloneDeep(atendimentos);
    }

    return atendimentoFilter;
  };

  setaClasseSentencaEncontrada = (propName) => {
    let ele = document.querySelectorAll('[name="' + propName + '"]');
    for (let i = 0; i < ele.length; i++) {
      ele[i].classList.add("sentencaEncontrada");
    }
  };

  impressao = (atendimento, nomeRelatorio) => {
    let _this = this;
    let urlReport = urlsConstants.REPORT + nomeRelatorio + "/" + atendimento.id;
    if (
      this.props.user.cooperativa &&
      this.props.user.cooperativa.bloquearDataAtendimentoConsulta &&
      atendimento.type === objectsConstants.ENUM_TIPOS_GUIA.CONSULTA
    ) {
      atendimentoService.bloquearDataAtendimento(atendimento.id).then(() => {
        _this.updateAtendimentos(this.state.atendimentos);
      });
    }
    window.open(urlReport, "_blank");
  };

  validaFilterValue = (propValue, filterValue, propName, filter) => {
    if (typeof propValue === "string") {
      if (propName === filter.fieldName) {
        if (propValue.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1) {
          this.setaClasseSentencaEncontrada(propName);
          return true;
        }
      }
    }
    if (Object.prototype.toString.call(propValue) === "[object Array]") {
      let showAtendimento = false;
      for (let i = 0; i < propValue.length; i++) {
        showAtendimento =
          showAtendimento ||
          this.validaFilterValue(propValue[i], filterValue, propName, filter);
      }
      return showAtendimento;
    }
    if (Object.prototype.toString.call(propValue) === "[object Object]") {
      let showAtendimento = false;
      for (var propNameInner in propValue) {
        const propNamePass =
          propName === filter.fieldName ? propName : propNameInner;
        showAtendimento =
          showAtendimento ||
          this.validaFilterValue(
            propValue[propNameInner],
            filterValue,
            propNamePass,
            filter
          );
      }
      return showAtendimento;
    }
    return false;
  };

  setMenuModelView = (status) => {
    this.setState({ openMenuModelView: status }, () => {
      if (this.state.openMenuModelView) {
        document.body.classList.add("bodyNoScroll");
        document.getElementById("bodyMenu").scrollIntoView();
      } else {
        document.body.classList.remove("bodyNoScroll");
      }
    });
  };

  toggleMenuModelView = (toggle) => {
    this.setMenuModelView(!this.state.openMenuModelView);
  };

  onClickPendenciaRow = (atendimento) => {
    this.setState({ atendimentoComPendencia: atendimento, pendencias: true });
  };

  onCheckedRow = (checked, id, row) => {
    let rowsCheckedValue = _.cloneDeep(this.state.rowsCheckedValue);
    let rowsCheckedIdValue = _.cloneDeep(this.state.rowsCheckedIdValue);
    let checkedAll = false;
    if (checked) {
      rowsCheckedIdValue.push(row.id);
      rowsCheckedValue.push(row);
      if (rowsCheckedIdValue.length === this.state.atendimentos.length) {
        checkedAll = true;
      }
    } else {
      var index = rowsCheckedIdValue.indexOf(id);

      if (index > -1) {
        rowsCheckedValue.splice(index, 1);
        rowsCheckedIdValue.splice(index, 1);
      }
    }
    this.setState({ rowsCheckedValue, rowsCheckedIdValue, checkedAll });
  };

  checkAll = () => {
    const { checkedAll } = this.state;
    let rowsCheckedValue = [];
    let rowsCheckedIdValue = [];
    if (checkedAll) {
      this.setState({
        checkedAll: false,
        checkedAllPages: false,
        rowsCheckedValue,
        rowsCheckedIdValue,
      });
    } else {
      this.state.atendimentos.map((data) => {
        rowsCheckedValue.push(data);
        rowsCheckedIdValue.push(data.id);
      });
      this.setState({ checkedAll: true, rowsCheckedValue, rowsCheckedIdValue });
    }
  };

  handleChange = (name, value) => {};

  toogleModalExclusao = () => {
    this.setState({ toggleJustificativaCancelamentoGuia: false });
  };

  handleEncaminharAtendimento = (status, convenio) => {
    if (status.statusCancelamento) {
      this.setState({ status: status });
      this.setState({ toggleJustificativaCancelamentoGuia: true });
    } else {
      let idsAtendimentosPorOrdemDeSelecao = _.map(
        this.state.rowsCheckedIdValue,
        (item, index) => ({ idAtendimento: item, posicao: index })
      );

      if (
        idsAtendimentosPorOrdemDeSelecao.length === 0 &&
        this.state.atendimentos.length === 1
      ) {
        idsAtendimentosPorOrdemDeSelecao.push({
          idAtendimento: this.state.atendimentos[0].id,
          posicao: 0,
        });
      }

      if (convenio !== null && convenio !== undefined) {
        let atendimentosInProtocolo = _.filter(
          this.state.rowsCheckedValue,
          (atendimento) => {
            return _.get(atendimento, "convenio.id") === _.get(convenio, "id");
          }
        );
        idsAtendimentosPorOrdemDeSelecao = _.map(
          atendimentosInProtocolo,
          (item, index) => ({ idAtendimento: item.id, posicao: index })
        );
      }

      if (idsAtendimentosPorOrdemDeSelecao.length > 0) {
        this.props.loading(true);
        atendimentoService
          .encaminharAtendimentos(status.id, idsAtendimentosPorOrdemDeSelecao)
          .then(
            (response) => {
              this.props.success({
                message: `Atendimentos encaminhados para ${status.nome}`,
              });

              var base64 = _.get(response, "data.data");
              if (base64 != null) {
                this.setState({ protocolo: response.data.nome.slice(0, -4) });
              }

              this.updateAtendimentos(this.state.atendimentos);
              this.props.loading(false);

              //this.props.history.push("/guias");
            },
            (erros) => {
              console.error(erros.response);
              if (erros.response.status === 400) {
                this.props.error({
                  message:
                    "Os atendimentos não pode ser encaminhado para Protocolo, pois existem pendências nos atendimentos.",
                });
              }

              this.props.loading(false);
            }
          );
      } else {
        this.props.error({
          message: "Selecione os atendimentos que deseja encaminhar.",
        });
      }
    }
  };

  encaminharAtendimentos = (status, justificativa) => {
    let idsAtendimentosPorOrdemDeSelecao = _.map(
      this.state.rowsCheckedIdValue,
      (item, index) => ({ idAtendimento: item, posicao: index })
    );
    if (
      idsAtendimentosPorOrdemDeSelecao.length === 0 &&
      this.state.atendimentos.length === 1
    ) {
      idsAtendimentosPorOrdemDeSelecao.push({
        idAtendimento: this.state.atendimentos[0].id,
        posicao: 0,
      });
    }
    if (idsAtendimentosPorOrdemDeSelecao.length > 0) {
      this.props.loading(true);
      atendimentoService
        .encaminharAtendimentos(status.id, idsAtendimentosPorOrdemDeSelecao, justificativa)
        .then(
          (response) => {
            this.props.success({
              message: `Atendimento encaminhado para ${status.nome}`,
            });
            this.updateAtendimentos(this.state.atendimentos);
            this.props.loading(false);
          },
          (erros) => {
            console.error(erros.response);
            if (erros.response.status === 400) {
              this.props.error({
                message:
                  "O atendimento não pode ser encaminhado para Protocolo, pois existem pendências no atendimento.",
              });
            }

            this.props.loading(false);
          }
        );
    } else {
      this.props.error({
        message: "Selecione os atendimentos que deseja encaminhar.",
      });
    }
  };

  loading = (load) => {
    this.props.loading(load);
  };

  handleAtedimentoType = (index, value) => {
    let atendimentos = _.cloneDeep(this.state.atendimentos);
    atendimentos[index].type = value.label;
    this.setState({ atendimentos });
  };

  getBtnNovoAtendimento = () => {
    let canCreate = false;
    const extension = userService.getExtensionUser(this.props.user);
    const isAuditor = userService.isAuditor(this.props.user);
    if (extension && !isAuditor) {
      canCreate = true;
    } else if (
      this.props.filtroAtendimento &&
      this.props.filtroAtendimento.estabelecimento
    ) {
      canCreate = true;
    }

    if (canCreate) {
      return (
        <div className="px-sm-3 mt-2 mt-sm-0  d-flex align-items-center">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              this.props.history.push({
                pathname: "/guias/criar",
                state: { entitys: this.state.atendimentos },
              });
            }}
          >
            <span className="icon-btn-add"></span>Novo atendimento
          </button>
        </div>
      );
    }
  };

  getValidationScheme = (type) => {
    let validationScheme = {};
    switch (type) {
      case objectsConstants.ENUM_TIPOS_GUIA.CONSULTA:
        validationScheme = objectsConstants.validateGuiaConsulta;
        break;
      case objectsConstants.ENUM_TIPOS_GUIA.HONORARIO:
        validationScheme = objectsConstants.validateHonorarios;
        break;
      case objectsConstants.ENUM_TIPOS_GUIA.INTERNACAO:
        validationScheme = objectsConstants.validateGuiaInternacao;
        break;
      default:
        validationScheme = objectsConstants.validateGuiaSADT;
        break;
    }
    return validationScheme;
  };

  renderAtendimentos = (atendimento, index) => {
    const validationSchema = this.getValidationScheme(atendimento.type);
    if (atendimento.type) {
      let GuiaAtual = null;
      switch (atendimento.type) {
        case objectsConstants.ENUM_TIPOS_GUIA.CONSULTA:
          GuiaAtual = GuiaConsultaForm;
          break;
        case objectsConstants.ENUM_TIPOS_GUIA.HONORARIO:
          GuiaAtual = GuiaHonorariosForm;
          break;
        case objectsConstants.ENUM_TIPOS_GUIA.INTERNACAO:
          GuiaAtual = GuiaInternacaoForm;
          break;
        default:
          GuiaAtual = GuiaSADTForm;
          break;
      }
      return (
        <GuiaAtendimentoForm
          //<Drag
          addAtendimentoView={this.addAtendimentoView}
          index={index}
          collapse={
            (this.state.atendimentos.length < 2 || !atendimento.id) &&
            !this.state.closeAccordion
          }
          moveGuia={this.moveAtendimento}
          atendimento={atendimento}
          onSaved={this.updateSaved}
          onCancelEditCreate={this.cancelEditCreate}
          onCheckedAtendimento={this.onCheckedRow}
          onClickPendencia={this.onClickPendenciaRow}
          imprimir={this.impressao}
          rowsCheckedIdValue={this.state.rowsCheckedIdValue}
          checkedAll={this.state.checkedAll}
          key={atendimento.id}
          id={atendimento.id}
          toggleHistorico={this.toggleHistorico}
          criarGuiaPassoPasso={this.state.criarGuiaPassoPasso}
          GuiaForm={GuiaAtual}
          initData={atendimento}
          validateObject={validationSchema}
          wrappedComponentRef={(c) => (this.guiaFormItem = c)}
          listStatusEncam={this.state.listStatusEncaminhamento}
          listUfsConselho={this.state.listUfsConselho}
          listConselho={this.state.listConselho}
        />
      );
    } else {
      return (
        <div className="card content-form cardGuia cardCrud">
          <div
            className="card-header d-flex  flex-column flex-sm-row b-bottom"
            id="headingOne"
            onClick={this.toggle}
          >
            <div className="flex-grow-1 d-flex align-items-center">
              <span className="icon-order"></span>
              <div className="p-1 fz-sm-10">1</div>
              <div className="p-1 ">
                <CheckBoxInput
                  className="mb-0 pb-1"
                  label=""
                  name="checkItem"
                  checked={false}
                  onChange={() => {}}
                />
              </div>
              <div className="p-1 status-guia">
                <span>- Em Criação</span>
              </div>

              <div className="p-2 w-250p">
                <SelectInput
                  className=""
                  name={"tipoGuia"}
                  value={""}
                  options={objectsConstants.TIPOS_GUIA}
                  onChange={(name, value) => {
                    this.handleAtedimentoType(index, value);
                  }}
                  returnFullObject={true}
                  labelKey={"name"}
                  valueKey={"id"}
                  placeholder="Tipo de guia de atendimento"
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  moveAtendimento = (dragIndex: number, hoverIndex: number) => {
    const dragAtendimento = this.state.atendimentos[dragIndex];
    let lista = update(this.state.atendimentos, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragAtendimento],
      ],
    });
    this.setState({ atendimentos: lista });
  };

  setMenuHistorico = (status, atendimento) => {
    this.setState({ openMenuHistorico: status, listaHistoricos: [] }, () => {
      if (this.state.openMenuHistorico) {
        this.loadHistoryAtendimento(atendimento);

        document.body.classList.add("bodyNoScroll");
        document.getElementById("bodyMenu").scrollIntoView();
      } else {
        document.body.classList.remove("bodyNoScroll");
      }
    });
  };
  loadHistoryAtendimento = (atendimento) => {
    this.props.loading(true);
    atendimentoService.historico(atendimento.id).then(
      (result) => {
        this.setState(
          { listaHistoricos: result.data, atendimentoHistorico: atendimento },
          () => {
            this.props.loading(false);
          }
        );
      },
      (error) => {
        this.setState({ listaHistoricos: [] }, () => {
          this.props.loading(false);
        });
        console.error(error);
      }
    );
  };

  toggleHistorico = (atendimento) => {
    this.setMenuHistorico(!this.state.openMenuHistorico, atendimento);
  };

  callBackCreateLote = (id) => {
    loteAtendimentoService.doGet(id).then((response) => {
      this.props.loteAtual(id);
      this.props.history.push({
        pathname: "/lotes/" + id,
        state: {
          lote: response.data.data,
          listAtendimentos: this.state.rowsCheckedValue.map((atendimento) => {
            if (
              _.get(atendimento, "convenio.id") ===
              _.get(response.data.data.convenio, "id")
            ) {
              return atendimento.id;
            }
          }),
        },
      });
    });
  };

  bloqueiaEdicaoStatusGuiaRecebidaPerfilCooperadoConsultorio = () => {
    const user = userService.getCurrentUser();
    const tipoPerfil = user.perfilAtual.perfil.tipoPerfil;
    if (
      tipoPerfil === objectsConstants.CONSULTORIO ||
      tipoPerfil === objectsConstants.COOPERADO
    ) {
      return true;
    }
    return false;
  };

  async isAtendimentoRecebidosMais15Dias() {
    this.props.loading(true);
    let statusRecebidosMasi15dias = [];
    if (!this.bloqueiaEdicaoStatusGuiaRecebidaPerfilCooperadoConsultorio()) {
      let tipoGuia = this.state.atendimentos
        .map((ele) => ele.type)
        .filter((elem, pos, arr) => arr.indexOf(elem) == pos);
      let type = [];

      for (const i of tipoGuia) {
        for (const j of objectsConstants.TIPOS_GUIA_REGRA_CONVENIO) {
          if (i === j.label) if (!type.includes(j.id)) type.push(j.id);
        }
      }

      let convenios = this.state.atendimentos
        .map((ele) => ele.convenio.id)
        .filter((elem, pos, arr) => arr.indexOf(elem) == pos);

      await statusAtendimentoService
        .findAllByAtendimentosStatusRecebido15Dias(type, convenios)
        .then((response) => {
          statusRecebidosMasi15dias.push(...response.data);
        });
    }
    this.props.loading(false);
    if (statusRecebidosMasi15dias.length > 0)
      return !statusRecebidosMasi15dias.some((ele) => ele === false);
    else return true;
  }

  isAuditoria = () => {
    for (let i = 0; i < this.state.atendimentos.length; i++) {
      let atendimento = this.state.atendimentos[i];
      if (
        !atendimento.status ||
        atendimento.status.situacao !==
          objectsConstants.SITUACAO_ATENDIMENTO.AUDITORIA
      ) {
        return false;
      }
    }
    return true;
  };

  toogleModalCriarLote = () => {
    this.setState({ toogleModalCriarLote: !this.state.toogleModalCriarLote });
  };

  verificacaoAtendimentosLote =() => {
    let listAtendimento = [];

    this.state.rowsCheckedValue.forEach(atendimento => {
        let situacao = _.get(atendimento, "status.situacao");
        let nome = _.get(atendimento, "status.nome");

        let canEdit = situacao === objectsConstants.SITUACAO_ATENDIMENTO.AUDITORIA
            || situacao === objectsConstants.SITUACAO_ATENDIMENTO.RECEBIDA
            || situacao === objectsConstants.SITUACAO_ATENDIMENTO.REMOVIDAS_DO_LOTE
            || situacao === objectsConstants.SITUACAO_ATENDIMENTO.PENDENCIA_RESOLVIDA
            || nome === objectsConstants.INCONSISTENCIA_AUXILIARES;
        if (!canEdit) {
            listAtendimento.push(atendimento.numeroAtendimento);
        }
    });
  
    return listAtendimento;
}

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid container-com-topo">
          <TopoTitleComponente
            mainTitle="Em criação"
            subTitle=" "
            canBack={true}
          />

          <div
            className={`action-line ${this.state.activeClass} fixed-container-topo`}
            id="pendenciaTopo"
          >
            {!this.props.pendenciaOpen &&
              !this.props.resolverPendenciaOpen &&
              !this.props.edicaoEmMassaOpen && (
                <GuiaTopo
                  rowsCheckedIdValue={this.state.rowsCheckedIdValue}
                  listaAtendimentos={this.state.atendimentos}
                  addAtendimento={this.addAtendimento}
                  handleEncaminharAtendimento={this.handleEncaminharAtendimento}
                  atendimentoEdicao={this.state.listAtendimentoEdicao.length}
                  addAtendimentoView={this.addAtendimentoView}
                  numeroProtocolo={this.state.protocolo}
                />
              )}

            <Modal
              isOpen={this.state.toggleJustificativaCancelamentoGuia}
              toggle={() => {
                this.setState({
                  toggleJustificativaCancelamentoGuia:
                    !this.state.toggleJustificativaCancelamentoGuia,
                });
              }}
              backdrop="static"
              modalClassName=""
              className=""
              centered={true}
            >
              <ModalBody>
                <Formik
                  validationSchema={Yup.object().shape({
                    justificativa: Yup.string().required("Obrigatorio"),
                  })}
                  validateOnBlur={false}
                  validateOnChange={false}
                  enableReinitialize={true}
                  initialValues={{}}
                  onSubmit={(values, actions) => {
                    this.encaminharAtendimentos(
                      this.state.status,
                      values.justificativa
                    );
                    this.setState({
                      filter: {},
                      toggleJustificativaCancelamentoGuia: false,
                    });
                  }}
                  ref={(form) => {
                    this.formRef = form;
                  }}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    validateForm,
                    setValues,
                    touched,
                  }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="modalCooperado">
                          <div className="row">
                            <div className="col-11 text-center my-2">
                              {this.state &&
                                this.state.status &&
                                this.state.status.nome ===
                                  objectsConstants.STATUS_RECUSADO.NOME && (
                                  <h2 className="font-weight-bold">
                                    {" "}
                                    <span className="font-bold cor-destaque-alerta">
                                      {
                                        "Caso confirme a recusa desta guia, o atendimento não será faturado pela cooperativa."
                                      }
                                    </span>
                                  </h2>
                                )}
                              <h2 className="font-weight-bold">
                                Informe o motivo de encaminhar a guia para o
                                status{" "}
                                <span className="font-bold">
                                  {this.state && this.state.status
                                    ? this.state.status.nome
                                    : ""}
                                </span>
                                :
                              </h2>
                            </div>
                          </div>

                          <div className="row">
                            <div className="flex-fill px-5 m-auto justify-content-center">
                              <FormGroup className="col-12">
                                <FormTextArea
                                  label={"Justificativa"}
                                  id={"justificativa"}
                                  className={"textArea"}
                                  type={"textarea"}
                                  disabled={false}
                                  onChange={handleChange}
                                  name={"justificativa"}
                                  erroMensagem={errors.justificativa}
                                  placeholder=""
                                  value={values.justificativa}
                                />
                              </FormGroup>
                            </div>
                          </div>
                          <div className="row">
                            <div className="flex-grow px-1 m-auto justify-content-center">
                              <button
                                className="btn btn-secondary white mt-3 mb-4 mx-2"
                                onClick={() => {
                                  this.toogleModalExclusao();
                                }}
                                type={"button"}
                              >
                                Cancelar
                              </button>
                              <button
                                className="btn btn-primary white mt-3 mb-4 mx-2"
                                type={"submit"}
                              >
                                Encaminhar
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </ModalBody>
            </Modal>

            <Modal
              isOpen={this.state.toogleModalCriarLote}
              toggle={this.toggleCriarLote}
              backdrop="static"
              modalClassName=""
              className=""
              centered={true}
            >
              <ModalBody>
                <div className="confirm-ui css-alert-sasc w-100">
                  <img src={iconCriarLote} alt="icon" />
                  <h2>Novo lote</h2>
                  <p>
                    <b></b>
                  </p>
                  <div className="row body-confirm">
                    <span>
                      {" "}
                      Existem atendimentos com convênios diferentes, para criar
                      o lote selecione o convenio desejado?
                    </span>
                  </div>
                  <SelectInput
                    className="min-width"
                    name={"setor"}
                    value={this.state.convenio}
                    options={this.state.convenios}
                    onChange={(name, value) => {
                      this.setState({ convenio: value });
                    }}
                    returnFullObject={true}
                    labelKey={"nome"}
                    valueKey={"id"}
                    placeholder="Selecione o Convenio"
                  />

                  <button
                    className="btn btn-secondary white mt-3 mb-4 mx-2"
                    onClick={() => {
                      this.toogleModalCriarLote();
                    }}
                  >
                    Não
                  </button>
                  <button
                    disabled={!this.state.convenio}
                    dclassName="btn btn-primary white mt-3 mb-4 mx-2"
                    //onClick={()=>{this.addAtendimenosInLote(_.filter(this.props.rowsCheckedValue,(atendimento)=>{
                    //    return _.get(atendimento,'estabelecimentoConvenio.convenio.id')===_.get(this.state.convenio,'id');
                    //}));this.toogleModalCriarLote();}}
                    onClick={() => {
                      loteAtendimentoService.addAtendimenosInLote(
                        _.filter(this.state.rowsCheckedValue, (atendimento) => {
                          return (
                            _.get(atendimento, "convenio.id") ===
                            _.get(this.state.convenio, "id")
                          );
                        }),
                        this.props.loading,
                        this.props.success,
                        this.props.error,
                        this.callBackCreateLote
                      );
                      this.toogleModalCriarLote();
                    }}
                    //onClick={()=>{loteAtendimentoService.addAtendimenosInLote(this.state.rowsCheckedValue, this.props.loading, this.props.success, this.props.error, this.callBackCreateLote);}}
                  >
                    Sim
                  </button>
                </div>
              </ModalBody>
            </Modal>
            {this.props.pendenciaOpen && (
              <PendenciasTopo
                atendimento={this.state.atendimentoComPendencia}
                onSaved={this.updateSaved}
              />
            )}
            {this.props.resolverPendenciaOpen && (
              <ResolverPendenciasTopo
                atendimento={this.state.atendimentoComPendencia}
                onSaved={this.updateSaved}
              />
            )}

            {this.props.edicaoEmMassaOpen && (
              <EdicaoEmMassaTopo
                atendimento={this.state.atendimentoComPendencia}
                listaAtendimentos={this.state.atendimentos}
                onSaved={this.updateAtendimentos}
              />
            )}
          </div>
          <div id="conteudoAtendimentos" className={`contentComTopoFix`}>
            
            <div className="background-lines">
              <div className="bg-riscos"></div>
            </div>

            {this.state.atendimentos[0] && this.state.atendimentos[0].id && (
              <div className="action-line d-flex flex-column flex-sm-row flex-wrap justify-content-between align-items-center p-0">
                {this.state.rowsCheckedValue.length === 0 &&
                  this.state.atendimentos[0].id && (
                    <React.Fragment>
                      <button
                        type="button"
                        className="btn btn-light btDefaultGrid"
                        onClick={this.checkAll}
                      >
                        <span className="icon-btn-check-black"></span>
                        Selecionar todos
                      </button>

                      <button
                        type="button"
                        className="btn btn-light btDefaultGrid ml-auto"
                        onClick={this.toggleMenuModelView}
                      >
                        <span className="icon-visualizar"></span>
                        Modelo de visualização
                      </button>
                    </React.Fragment>
                  )}
                <Can
                  I={objectsConstants.ALTERAR}
                  a={objectsConstants.CRUD_ATENDIMENTOS_CRIAR}
                  ability={this.props.permissions}
                >
                  {this.getBtnNovoAtendimento()}
                </Can>

                {this.state.rowsCheckedValue.length > 0 && (
                  <div
                    className={
                      "d-flex flex-column flex-sm-row  justify-content-between align-items-center boxAction"
                    }
                  >
                    <div className="labelInfoAction  mr-sm-auto">
                      <label className="control control--checkbox">
                        <Input
                          type={"checkbox"}
                          value={this.state.checkedAll}
                          checked={this.state.checkedAll}
                          className={`classUnchecked`}
                          onClick={this.checkAll}
                        />{" "}
                        <div className="control__indicator"></div>
                      </label>
                      {!this.state.checkedAllPages && (
                        <React.Fragment>
                          {this.state.rowsCheckedValue.length}{" "}
                        </React.Fragment>
                      )}
                      {this.state.checkedAllPages && (
                        <React.Fragment>
                          {this.state.atendimentos}{" "}
                        </React.Fragment>
                      )}
                      {this.props.name} selecionados
                    </div>
                    <Can
                      I={objectsConstants.ALTERAR}
                      a={objectsConstants.CRUD_LOTES_CRIAR}
                      ability={this.props.permissions}
                    >
                      <button
                        type="button"
                        style={{ zIndex: 10000 }}
                        className="btn btn-light"
                        disabled={""}
                        onClick={async () => {
                          let convenios = _.uniqBy(
                            _.map(this.state.rowsCheckedValue, function (n) {
                              return _.get(n, "convenio");
                            }),
                            "id"
                          );

                          if (
                            loteAtendimentoService.canCreateLote(
                              this.state.rowsCheckedValue
                            ) &&
                            convenios.length === 1
                          ) {
                            if (await this.isAtendimentoRecebidosMais15Dias()) {
                              ConfirmAcationService.confirmMsg(
                                () => {
                                  loteAtendimentoService.addAtendimenosInLote(
                                    this.state.rowsCheckedValue,
                                    this.props.loading,
                                    this.props.success,
                                    this.props.error,
                                    this.callBackCreateLote
                                  );
                                },
                                "Novo lote",
                                <span>
                                  {" "}
                                  Deseja realmente criar um novo lote com estes
                                  atendimentos selecionados?
                                </span>,
                                <span className="verde-destaque">{`${this.state.rowsCheckedValue.length} atendimentos selecionados`}</span>,
                                "Não",
                                "Sim",
                                objectsConstants.TYPE_FORWARD
                              );
                            } else {
                              ConfirmAcationService.confirmMsg(
                                () => {},
                                "Criação de lote Bloqueada",
                                <span>
                                  {" "}
                                  Favor entrar em contato com o administrador da
                                  cooperativa.
                                </span>,
                                null,
                                "OK",
                                null,
                                objectsConstants.TYPE_WARNING
                              );
                            }
                          } else {
                            if (!this.isAuditoria()) {
                              ConfirmAcationService.confirmMsg(
                                () => {},
                                "Existe(m) atendimento(s) já pendente(s),faturado(s), glosado(s) ou repassado(s)",
                                <span>
                                  {this.verificacaoAtendimentosLote().map((item) => (
                                    <div key={item}>
                                      {item}
                                    </div>
                                  ))}
                                  {" "}
                                  Não é possivel criar lote com atendimento
                                  pendente, faturado, glosado ou repassado.
                                </span>,
                                null,
                                "OK",
                                null,
                                objectsConstants.TYPE_WARNING
                              );
                            } else {
                              if (
                                await this.isAtendimentoRecebidosMais15Dias()
                              ) {
                                /*ConfirmAcationService.confirmMsg(
                                () => {

                                },
                                "Existem atendimentos com convênios diferentes",
                                <span>
                                  {" "}
                                      Para criar um lote selecione atendimentos de um mesmo convênio.
                    </span>,
                                null,
                                "OK",
                                null,
                                objectsConstants.TYPE_WARNING
                              );*/
                                this.setState({
                                  convenios,
                                  toogleModalCriarLote: true,
                                });
                              } else {
                                ConfirmAcationService.confirmMsg(
                                  () => {},
                                  "Criação de lote Bloqueada",
                                  <span>
                                    {" "}
                                    Favor entrar em contato com o administrador
                                    da cooperativa.
                                  </span>,
                                  null,
                                  "OK",
                                  null,
                                  objectsConstants.TYPE_WARNING
                                );
                              }
                            }
                          }
                        }}
                      >
                        <span className="icon-btn-add"></span>
                        Criar lotes
                      </button>
                    </Can>
                  </div>
                )}
              </div>
            )}

            {this.state.atendimentos[0] && this.state.atendimentos[0].id && (
              <div className="searchGuia row">
                <GuiaPesquisa changeFilter={this.changeFilter}></GuiaPesquisa>
              </div>
            )}
            <DndProvider backend={Backend}>
              {this.state.atendimentos &&
                this.filterAtendimentos(this.state.atendimentos).map(
                  (atendimento, index) =>
                    this.renderAtendimentos(atendimento, index)
                )}
            </DndProvider>
          </div>

          {this.state.atendimentoHistorico.id && (
            <MenuHistorico
              titulo={this.state.atendimentoHistorico.numeroAtendimento}
              subTitulo={this.state.atendimentoHistorico.status.nome}
              listaHistoricos={this.state.listaHistoricos}
              toggleHistorico={this.toggleHistorico}
              openMenuHistorico={this.state.openMenuHistorico}
            />
          )}
          <MenuModelView
            titulo={""}
            subTitulo={"Modelo"}
            atendimentos={this.state.atendimentos}
            toggleMenuModelView={this.toggleMenuModelView}
            openMenuModelView={this.state.openMenuModelView}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  pendencia: { togglePendencia, atendimentoAtual, closePendecia },
  lotesCollapse: { toggleLotes, loteAtual, abreLotes },
  edicaoEmMassa: { toggleEdicaoEmMassa, atendimentoParaEditar },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
  togglePendencia: () => togglePendencia(),
  closePendecia: () => closePendecia(),
  atendimentoAtual: (atendimento) => atendimentoAtual(atendimento),
  toggleLotes: () => toggleLotes(),
  abreLotes: () => abreLotes(),
  loteAtual: (idLoteAtual) => loteAtual({ idLoteAtual }),
  atendimentoParaEditar: (atendimento) => atendimentoParaEditar(atendimento),
  toggleEdicaoEmMassa: () => toggleEdicaoEmMassa(),
});

function mapStateToProps(state) {
  const { pendenciaOpen, idAtendimentoAtual, resolverPendenciaOpen } =
    state.pendencia;
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;
  const { filtroAtendimento } = state.filtro;
  const { edicaoEmMassaOpen, idAtendimentoParaEditar } = state.edicaoEmMassa;

  return {
    pendenciaOpen,
    resolverPendenciaOpen,
    idAtendimentoAtual,
    loggedIn,
    alert,
    user,
    permissions,
    filtroAtendimento,
    edicaoEmMassaOpen,
    idAtendimentoParaEditar,
  };
}

export default connect(mapStateToProps, mapDispatch)(withRouter(GuiasList));
