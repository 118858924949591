//@flow
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { objectsConstants } from '../../constants/objects.constants';
import { estabelecimentoService } from '../../services/estabelecimento.service';
import { userService } from '../../services/user.service';
import TopoTitleComponente from '../home/TopoTitleComponente';
import UsuarioForm from '../usuarios/UsuarioForm';
import UsuarioTopoView from '../usuarios/UsuarioTopoView';
import CooperadoList from '../cooperados/CooperadoList';
import CooperadoForm from '../cooperados/CooperadoForm';
import ConsultorioForm from '../consultorios/ConsultorioForm';


class PerfilView extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: { status: true }
    };
  }

  

  componentDidMount() {
    this.loadUser();
  }

  getServiceDoLoadUser = () => {
    let entity =
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.entity;
    if (
      entity &&
      entity.perfilAtual.perfil.tipoPerfil === objectsConstants.CONSULTORIO
    ) {
      return estabelecimentoService.doGet(entity.perfilAtual.extensao);
    }
    return userService.doGet(this.props.match.params.id);
  };

  loadUser = () => {
    if (this.props.match.params.id) {
      this.props.loading(true);
      this.getServiceDoLoadUser().then(
        response => {
          let user = response.data.data;
          this.setState({ entity: user }, () => {
            this.props.loading(false);
          });
        },
        error => {
          console.error(error);
          this.props.loading(false);
        }
      );
    }
  };

  isAtivo = values => {
    return values.status && values.status.name === 'ATIVO' ? true : false;
  };

  disableEnabledUser = () => {
    let values = userService.isConsultorio(this.state.entity)
      ? this.state.entity.secretaria
      : this.state.entity;
    this.props.loading(true);
    let functionStatus = this.isAtivo(values)
      ? userService.inativarUsuario
      : userService.ativarUsuario;
    functionStatus([values.id])
      .then(response => {
        this.loadUser();
        this.props.success({ message: 'Alterado com sucesso.' });
        this.props.loading(false);
      })
      .catch(() => {
        this.props.success('Não foi possível inativar o usuário.');
        this.props.loading(false);
      });
  };

 
  render() {
    let _this = this;
    const { entity } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid container-com-topo">
          {entity.id && (
            <React.Fragment>
              <TopoTitleComponente
                mainTitle={`Dados do usuário`}
                canBack={true}
              />
              <UsuarioTopoView
                values={entity}
                viewMode={true}
                qtdeAtendimento={entity.qtdeAtendimento}
              ></UsuarioTopoView>

              <FormByProfile
                values={entity}
                viewMode={true}
                errors={[]}
              ></FormByProfile>

              <ListCooperadoByProfile
                values={entity.secretaria}
              ></ListCooperadoByProfile>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const FormByProfile = React.memo(({ values, ...others }) => {
 if (
  values &&
  values.perfilAtual &&
  values.perfilAtual.perfil &&
  values.perfilAtual.perfil.tipoPerfil === objectsConstants.COOPERADO
  ) {
    return <CooperadoForm    
    values={values}
    viewMode={true}
    createMode={false}
    canEdit={false}
    readOnly
    errors={[]}
    {...others}
  ></CooperadoForm>
  }
  return <UsuarioForm values={values} {...others} />;
});

const ListCooperadoByProfile = React.memo(({ values, ...others }) => {
  if (
    values &&
    values.usuarioPerfis &&
    values.perfilAtual.perfil.tipoPerfil === objectsConstants.CONSULTORIO
  ) {
    return (
      <React.Fragment>
        <CooperadoList noCacheFilter={true} userFilter={values} {...others} hideTopo={true} />
      </React.Fragment>
    );
  }
  return null;
});

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading }
}) => ({
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  clear: () => clear()
});

export default connect(
  null,
  mapDispatch,
  null,
  { withRef: true }
)(withRouter(PerfilView));
