//@flow
import { urlsConstants } from "../constants/url.constant";
import { defaultService } from "./defaultService";
import { objectsConstants } from "../constants/objects.constants";

export const convenioService = {
  findAll,
  findAllBloquearConsultorio,
  inativarConvenio,
  ativarConvenio,
  ativarConvenioByFilter,
  inativarConvenioByFilter,
  exportToExcel,
  findSearchableColumns,
  doGet,
  doSave,
  findTableColumns,
  findByFilter,
  saveFaturamento,
  disableEnabledConvenio,
  historicoCronograma,
  historicoCnpj,
  historicoRegras,
  findByFilterAtendimento,
  isSenhaObrigatoria,
  findComplementoConvenio,
  complementoObrigatorio,
  validaDigitosCarteirinha,
  findComplementoConvenioPorTipoGuia,
  validaQtdDigitosSenha,
  qtdDigitosSenha,
  saveProcedimentos,
  isCPFObrigatoria,
  isDataNascimentoObrigatoria,
  findRegraEspecialidadeProcedimentoPorTipoGuia,
  findListagem
};

function saveProcedimentos(procedimentos,idConvenio){
  return defaultService.doPost(`${urlsConstants.CONVENIO}saveProcedimentos/${idConvenio}`, procedimentos);
}

function qtdDigitosSenha(listaRegras,tipoGuia){
  if (listaRegras) {
    for (var i = 0; i < listaRegras.length; i++) {
      if (objectsConstants.TIPOS_GUIA_REGRA_CONVENIO[listaRegras[i].tipoGuia.id]
          && objectsConstants.TIPOS_GUIA_REGRA_CONVENIO[listaRegras[i].tipoGuia.id].label === tipoGuia) {
        if(listaRegras[i].quantDigitosSenha){
          return listaRegras[i].quantDigitosSenha;
        }

      }
    }
  }
  return 0;
}

function validaQtdDigitosSenha(senha,listaRegras,tipoGuia){
  if (listaRegras) {
    for (var i = 0; i < listaRegras.length; i++) {
      if (objectsConstants.TIPOS_GUIA_REGRA_CONVENIO[listaRegras[i].tipoGuia.id]
          && objectsConstants.TIPOS_GUIA_REGRA_CONVENIO[listaRegras[i].tipoGuia.id].label === tipoGuia) {
        if(listaRegras[i].quantDigitosSenha && listaRegras[i].quantDigitosSenha !==senha.length){
          return true
        }

      }
    }
  }
  return false;
}

function findComplementoConvenioPorTipoGuia(atendimento){
  let type;
  for(let i=0; i< objectsConstants.TIPOS_GUIA_REGRA_CONVENIO.length;i++){
    if(objectsConstants.TIPOS_GUIA_REGRA_CONVENIO[i] 
      && objectsConstants.TIPOS_GUIA_REGRA_CONVENIO[i].label===atendimento.type){
     type = objectsConstants.TIPOS_GUIA_REGRA_CONVENIO[i].id;
    }
  }
  if(atendimento&&atendimento.convenio&&atendimento.type) {
    return defaultService.doGet(`${urlsConstants.COMPLEMENTO_CONVENIO}findByConvenioAndTipoGuia/${atendimento.convenio.id}/${type}`);
  }
}
function complementoObrigatorio(tiposGuiaProduto,tipoGuia){
  if(tiposGuiaProduto){
    for(let i=0; i< tiposGuiaProduto.length;i++){
      if(objectsConstants.TIPOS_GUIA_REGRA_CONVENIO[tiposGuiaProduto[i].id]
        && objectsConstants.TIPOS_GUIA_REGRA_CONVENIO[tiposGuiaProduto[i].id].label===tipoGuia){
        return true;
      }
    }
  }
  return false;
}

function isSenhaObrigatoria(tipoGuiaObrigatorio, type, listaRegraConvenio,caraterAtendimento, atendimentoExecucaos) {
  if(listaRegraConvenio&&caraterAtendimento) {
    for(let j = 0; j< listaRegraConvenio.length ; j++){
      let regra = listaRegraConvenio[j];
      if (regra&& regra.tipoGuia
               && objectsConstants.TIPOS_GUIA_REGRA_CONVENIO
               && objectsConstants.TIPOS_GUIA_REGRA_CONVENIO[regra.tipoGuia.id]
               && objectsConstants.TIPOS_GUIA_REGRA_CONVENIO[regra.tipoGuia.id].label === type) {
          if(regra.caraterAtendimentoSenhaObrigatoria){
            let caraterAtendimentoSenhaObrigatoria = false;
            for(let w = 0; w<regra.caraterAtendimentoSenhaObrigatoria.length ; w++){
              if(regra.caraterAtendimentoSenhaObrigatoria[w].name===caraterAtendimento.name){
                caraterAtendimentoSenhaObrigatoria = true;
              }
            }
            return caraterAtendimentoSenhaObrigatoria;
          }
      }
    }
  }
  if (tipoGuiaObrigatorio) {
    for (let guia of tipoGuiaObrigatorio) {
      if (objectsConstants.TIPOS_GUIA_REGRA_CONVENIO
          && objectsConstants.TIPOS_GUIA_REGRA_CONVENIO[guia.id]
          && objectsConstants.TIPOS_GUIA_REGRA_CONVENIO[guia.id].label === type) {
        let regra = listaRegraConvenio.find(regra => regra.tipoGuia.id === guia.id);
        if (regra.procedimentosSenhaObrigatoria.length === 0) { 
          return true; 
        } else if(regra.procedimentosSenhaObrigatoria.find(pro => atendimentoExecucaos
          .find(ae => ae.codigoProcedimento === pro.codigo) != undefined)) { 
          return true; 
        }
      }
    }
  }
  return false;
}

function validaDigitosCarteirinha(convenio,qtddigitos){
    if (!qtddigitos||(convenio.quantMaxDigitosCarteirinha<qtddigitos.length||convenio.quantMinDigitosCarteirinha>qtddigitos.length)) {
      return true;
    }
    return false;
}
function historicoRegras(id) {
  return defaultService.doGet(`${urlsConstants.CONVENIO}historicoRegras/${id}`);
}
function historicoCronograma(id) {
  return defaultService.doGet(`${urlsConstants.CONVENIO}historicoCronograma/${id}`);
}
function historicoCnpj(id) {
  return defaultService.doGet(`${urlsConstants.CONVENIO}historicoCnpj/${id}`);
}
function disableEnabledConvenio(id) {
  return defaultService.doGet(`${urlsConstants.CONVENIO}disableEnabledConvenio/${id}`);
}

function findComplementoConvenio(convenio){
  if(convenio) {
    return defaultService.doGet(`${urlsConstants.COMPLEMENTO_CONVENIO}findByConvenio/${convenio.id}`);
  }
}

function findAll(object) {
  let tipoGuia;
  if (object && object.tipoGuia) {
    for (let i = 0; i < objectsConstants.TIPOS_GUIA_REGRA_CONVENIO.length; i++) {
      if (objectsConstants.TIPOS_GUIA_REGRA_CONVENIO[i]
          && objectsConstants.TIPOS_GUIA_REGRA_CONVENIO[i].label === object.tipoGuia) {
        tipoGuia = objectsConstants.TIPOS_GUIA_REGRA_CONVENIO[i].id;
      }
    }
  }
  if (object && object.id) {
    return defaultService.doGet(`${urlsConstants.CONVENIO}findByEstabelecimento/${object.id}`);
  }
  if (object && object.estabelecimento && object.estabelecimento.id && object.tipoGuia) {
    return defaultService.doGet(`${urlsConstants.CONVENIO}findByEstabelecimento/${object.estabelecimento.id}/${tipoGuia}`);
  }
  if (object && object.tipoGuia) {
    return defaultService.doGet(`${urlsConstants.CONVENIO}findAll/${tipoGuia}`);
  }
  return defaultService.doGet(`${urlsConstants.CONVENIO}findAll`);


}

function findListagem () {
  return defaultService.doGet(`${urlsConstants.CONVENIO}findListagem`);
}

function findAllBloquearConsultorio(object){

  return defaultService.doGet(`${urlsConstants.CONVENIO}cadastroConsultorio/findAll`);

}
function doGet(id) {
  return defaultService.doGet(`${urlsConstants.CONVENIO}${id}`);
}

function doSave(entity: any) {
  return defaultService.doSave(urlsConstants.CONVENIO, entity);
}

function exportToExcel(filter, fields, labels) {
  return defaultService.exportToExcel('convenio', filter, fields, labels);
}

function findTableColumns() {
  return defaultService.doGet(`${urlsConstants.CONVENIO}list/fields`);
}

function findSearchableColumns() {
  return defaultService.doGet(`${urlsConstants.CONVENIO}search/fields`);
}
function inativarConvenio(ids) {
  return defaultService.doPost(`${urlsConstants.CONVENIO}inativar`, ids);
}

function ativarConvenio(ids) {
  return defaultService.doPost(`${urlsConstants.CONVENIO}ativar`, ids);
}

function ativarConvenioByFilter(listFields) {
  return defaultService.doPost(`${urlsConstants.CONVENIO}ativarByFilter`, {
    pageSize: objectsConstants.PAGE_MAX,
    page: 0,
    listFields
  });
}

function inativarConvenioByFilter(listFields) {
  return defaultService.doPost(`${urlsConstants.CONVENIO}inativarByFilter`, {
    pageSize: objectsConstants.PAGE_MAX,
    page: 0,
    listFields
  });
}

function findByFilter(options: Options, filter: any): Promise<Array<>> {
  return defaultService.findByFiltro(
    `${urlsConstants.CONVENIO}list`,
    options,
    filter
  );
}

function saveFaturamento(convenioFaturamentos, id) {
  return defaultService.doPost(`${urlsConstants.CONVENIO}saveFaturamento/${id}`, { convenioFaturamentos })
}

function findByFilterAtendimento(filter, input) {
  filter.descricaoFiltro = input;
  return defaultService.doPost(`${urlsConstants.ATENDIMENTO}getConvenioByFiltroAtendimento`, filter);

}

function isCPFObrigatoria(type,listaRegraConvenio) {
  if(listaRegraConvenio){
    for(let j = 0; j< listaRegraConvenio.length ; j++){
      let regra = listaRegraConvenio[j];
      if (regra&&regra.tipoGuia
               && objectsConstants.TIPOS_GUIA_REGRA_CONVENIO
               &&objectsConstants.TIPOS_GUIA_REGRA_CONVENIO[regra.tipoGuia.id]
               &&objectsConstants.TIPOS_GUIA_REGRA_CONVENIO[regra.tipoGuia.id].label === type) {
            return regra.cpfObrigatorio;
      }
    }
  }
  return false;
}

function isDataNascimentoObrigatoria(type,listaRegraConvenio) {
  if(listaRegraConvenio){
    for(let j = 0; j< listaRegraConvenio.length ; j++){
      let regra = listaRegraConvenio[j];
      if (regra&&regra.tipoGuia
               && objectsConstants.TIPOS_GUIA_REGRA_CONVENIO
               && objectsConstants.TIPOS_GUIA_REGRA_CONVENIO[regra.tipoGuia.id]
               && objectsConstants.TIPOS_GUIA_REGRA_CONVENIO[regra.tipoGuia.id].label === type) {
            return regra.dataNascimentoObrigatorio;
      }
    }
  }
  return false;
}

function findRegraEspecialidadeProcedimentoPorTipoGuia(guia,convenio,idCooperado,listProcedimentos){
  let tipoGuia;
  if (guia) {
    for (let i = 0; i < objectsConstants.TIPOS_GUIA_REGRA_CONVENIO.length; i++) {
      if (objectsConstants.TIPOS_GUIA_REGRA_CONVENIO
          && objectsConstants.TIPOS_GUIA_REGRA_CONVENIO[i] 
          && objectsConstants.TIPOS_GUIA_REGRA_CONVENIO[i].label === guia) {
        tipoGuia = objectsConstants.TIPOS_GUIA_REGRA_CONVENIO[i].id;
      }
    }
  }
  return defaultService.doPost(`${urlsConstants.CONVENIO}findRegraEspecialidadeProcedimentoPorTipoGuia/${convenio.id}/${tipoGuia}`,{idCooperado: idCooperado, procedimentos: listProcedimentos});
}