import "jquery/dist/jquery.js";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import "./App.scss";
import * as serviceWorker from "./serviceWorker";
import { store } from "./store/store";
import RememberGate from "./views/RememberGate";
import { ClearBrowserCacheBoundary } from 'react-clear-browser-cache';
const root = document.getElementById("root");
String.prototype.toProperCase = function () {
  return this.toLowerCase().replace(/(^[a-z]| [a-z]|-[a-z])/g, function ($1) {
    return $1.toUpperCase();
  });
};

if (root !== null) {
  ReactDOM.render(
      <ClearBrowserCacheBoundary auto fallback='Loading' duration={60000}>
    <Provider store={store}>
      <RememberGate>
        <App />
      </RememberGate>
    </Provider>
      </ClearBrowserCacheBoundary>,
    root
  );
}
serviceWorker.unregister();
