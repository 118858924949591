//@flow
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { pacoteExameService } from '../../../services/pacoteExame.service';
import TopoTitleComponente from '../../home/TopoTitleComponente';
import PacoteExameForm from './PacoteExameForm';
import PacoteExameTopoView from './PacoteExameTopoView';

class PacoteExameView extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: {}
    };
  }

  

  componentDidMount() {
    this.loadUser();
  }

  

  loadUser = () => {
    if (this.props.match.params.id) {
      this.props.loading(true);
      pacoteExameService.doGet(this.props.match.params.id).then(
        response => {
          let user = response.data.data;
          this.setState({ entity: user }, () => {
            this.props.loading(false);
          });
        },
        error => {
          console.error(error);
          this.props.loading(false);
        }
      );
    }
  };

  render() {
    let _this = this;
    const { entity } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid container-com-topo">
          {entity.id && (
            <React.Fragment>
              <TopoTitleComponente
                mainTitle={`Dados do pacote de exame`}
                canBack={true}
                backUrl={'/pacoteexame'}
              />
              <PacoteExameTopoView
                values={entity}
                viewMode={true}
              ></PacoteExameTopoView>

              <PacoteExameForm
                values={entity}
                viewMode={true}
                errors={[]}
                refreshEntity={this.loadUser}
              ></PacoteExameForm>

            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}





const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading }
}) => ({
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  clear: () => clear()
});

export default connect(
  null,
  mapDispatch,
  null,
  { withRef: true }
)(withRouter(PacoteExameView));
