//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import CooperativaEdit from './CooperativaEdit';
import CooperativaList from './CooperativaList';
import CooperativaView from './CooperativaView';
export default class Cooperativa extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/cooperativa" component={CooperativaList} exact />
          <Route path="/cooperativa/novo" component={CooperativaEdit} />
          <Route path="/cooperativa/edit/:id" component={CooperativaEdit} />
          <Route path="/cooperativa/view/:id" component={CooperativaView} />
        </Switch>
      </React.Fragment>
    );
  }
}
