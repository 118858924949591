//@flow
import { oauthHeader } from '../helpers/oauth-header';
import { urlsConstants } from '../constants/url.constant';
import { objectsConstants } from '../constants/objects.constants';
import { defaultService } from './defaultService';
import axios from "axios/index";

export const modeloVisualizacaoService = {
   findAll,
    save,
    toogleCriarGuiaPassoPasso,
    adicionarModeloVisualizacaoUsuario,
    alterarModeloVisualizacaoUsuario,
    removerModeloVisualizacaoUsuario 
};


function findAll(atendimentosType) {
    return defaultService.doPost(`${urlsConstants.MODELO_VISUALIZACAO}visualizacaoCooperativa`,atendimentosType)
}
function save(entity) {
    return defaultService.doSave(`${urlsConstants.MODELO_VISUALIZACAO}`,entity)
}
function toogleCriarGuiaPassoPasso(criarGuiaPassoPasso) {
    return defaultService.doPost(`${urlsConstants.MODELO_VISUALIZACAO}criarGuiaPassoPasso`,criarGuiaPassoPasso)
}

function adicionarModeloVisualizacaoUsuario(idModelo,atendimentosType) {
    return defaultService.doPost(`${urlsConstants.MODELO_VISUALIZACAO}adicionarModelovisualizacaoUsuario/${idModelo}`,atendimentosType)
}

function alterarModeloVisualizacaoUsuario(idModeloAntigo,idModeloNovo,atendimentosType) {
    return defaultService.doPost(`${urlsConstants.MODELO_VISUALIZACAO}alterarModelovisualizacaoUsuario/${idModeloAntigo}/${idModeloNovo}`,atendimentosType)
}

function removerModeloVisualizacaoUsuario(idModelo,atendimentosType) {
    return defaultService.doPost(`${urlsConstants.MODELO_VISUALIZACAO}removerModelovisualizacaoUsuario/${idModelo}`,atendimentosType)
}