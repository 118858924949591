//@flow
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';
import {objectsConstants} from "../constants/objects.constants";

export const notificacaoService = {
    doGet,
    doSave,
    findByFilter,
    doDelete, 
    find,
    findAll ,
    findTableColumns,
    findSearchableColumns,
    findByConsultorio,
    findByEspecialidade,
    exportToExcel,
    ativarNotificacaoByFilter,
    inativarNotificacaoByFilter,
    inativarNotificacao,
    ativarNotificacao
};

function inativarNotificacao(ids) {
    return defaultService.doPost(`${urlsConstants.NOTIFICACAO_CONSULTORIO}inativar`, ids);
}

function ativarNotificacao(ids) {
    return defaultService.doPost(`${urlsConstants.NOTIFICACAO_CONSULTORIO}ativar`, ids);
}

function ativarNotificacaoByFilter(listFields) {
    return defaultService.doPost(`${urlsConstants.NOTIFICACAO_CONSULTORIO}ativarByFilter`, {
        pageSize: objectsConstants.PAGE_MAX,
        page: 0,
        listFields
    });
}

function inativarNotificacaoByFilter(listFields) {
    return defaultService.doPost(`${urlsConstants.NOTIFICACAO_CONSULTORIO}inativarByFilter`, {
        pageSize: objectsConstants.PAGE_MAX,
        page: 0,
        listFields
    });
}
function exportToExcel(filter, fields, labels) {
    return defaultService.exportToExcel('notificacaoConsultorio', filter, fields, labels);
}
function findByConsultorio(){
    return defaultService.doGet(`${urlsConstants.NOTIFICACAO_CONSULTORIO}findByConsultorio`);
}

function findByEspecialidade(){
  return defaultService.doGet(`${urlsConstants.NOTIFICACAO_CONSULTORIO}findByCooperado`);
}

function doGet(id) {
  return defaultService.doGet(`${urlsConstants.NOTIFICACAO_CONSULTORIO}${id}`);
}

function doSave(entity: any) {
  return defaultService.doSave(urlsConstants.NOTIFICACAO_CONSULTORIO, entity);
}

function findByFilter(
    options: Options,
    filter: any,
    dafaultFilter
): Promise<Array<>> {
    return defaultService.findByFiltro(
        `${urlsConstants.NOTIFICACAO_CONSULTORIO}list`,
        options,
        filter,
        dafaultFilter
    );
}


function doDelete(id) {
  return defaultService.doDelete(`${urlsConstants.NOTIFICACAO_CONSULTORIO}${id}`);
}

function find() {
  return defaultService.doGet(`${urlsConstants.NOTIFICACAO_CONSULTORIO}findAll}`);
}

function findAll() {
  return defaultService.doGet(`${urlsConstants.NOTIFICACAO_CONSULTORIO}findAll`);
}

function findTableColumns() {
  return defaultService.doGet(`${urlsConstants.NOTIFICACAO_CONSULTORIO}list/fields`);
}

function findSearchableColumns() {
  return defaultService.doGet(`${urlsConstants.NOTIFICACAO_CONSULTORIO}search/fields`);
}