import * as React from 'react';
import { urlsConstants } from './../../constants/url.constant';
import './ImageInput.css';
import avatarDefault from '../../img/icon_perfil.svg';
import logoDefault from '../../img/logoDefault.png';

let fileInput;
export default class ImageInput extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    let dataUrl;
    if (props.id) {
      dataUrl = `${urlsConstants.URL_MIDIAS}${props.id}`;
    } else {
      dataUrl = this.props.dataUrl ? this.props.dataUrl : avatarDefault;
    }
    this.state = {
      dataUrl,
      showRemove: false
    };
  }

  onChange = (e: SyntheticEvent<>) => {
    var files = e.target.files;
    if (files.length) {
      var self = this;
      var maxWidth = this.props.maxWidth;
      var maxHeight = this.props.maxHeight;
      let props = this.props;
      var imageVO = {
        nome: files[0].name,
        tamanho: files[0].size,
        tipo: files[0].type
      };
      resize(files[0], maxWidth, maxHeight, function (resizedDataUrl) {

        imageVO.data = resizedDataUrl;
        imageVO.midiaConteudoVO = { foto64: resizedDataUrl };
        //self.setState({ dataUrl: resizedDataUrl, imageVO });
        props.onChange(imageVO);
      });
    }
  };
  removeImage = e => {
    e.stopPropagation();
    this.props.onRemove();
  };
  openInputFile = (event: any) => {
    if (event) event.preventDefault();
    if (this.props.readOnly) {
      return false;
    }
    this.fileInput.click();
  };
  render() {
    var image;
    var dataUrl = this.state.dataUrl;
    var removeImage = this.state.showRemove;
    if (dataUrl) {
      image = (
        <img
          src={dataUrl}
          alt={this.props.alt}
          className={`img-fluid ${this.props.className}`}
          onClick={this.openInputFile}
          height={this.props.height ? this.props.height + 'px' : '100%'}
          id={this.props.idDom}
        />
      );
    }

    return (
      <div className="buttonImage imageInner mt-2 action">
        <input
          type="file"
          accept="image/*"
          onChange={this.onChange}
          className="d-none img-profile-input"
          ref={input => {
            this.fileInput = input;
          }}
        />
        {image}
        {!this.props.readOnly && (
          <div className="changeImage action">
            <div
              className="icon-foto"
              alt="Icone para alterar imagem"
              onClick={this.openInputFile}
            ></div>
          </div>
        )}
        {this.props.canRemove && (
          <div className="removeImage action">
            <div
              className="icon-x"
              alt="Icone para alterar imagem"
              onClick={this.removeImage}
            ></div>
          </div>
        )}
      </div>
    );
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.dataUrl) {
      this.setState({
        dataUrl: nextProps.dataUrl,
        showRemove: true
      });
    } else if (nextProps.id) {
      this.setState({
        dataUrl: `${urlsConstants.MIDIA_IMAGE}${nextProps.id}`,
        showRemove: true
      });
    }
  }
}

function resize(file, maxWidth, maxHeight, fn) {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function (event) {
    var dataUrl = event.target.result;
    fn(dataUrl);
  };
}