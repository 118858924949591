import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Datatable from "../../../sascComponents/datagrid/Datatable";
import TopoTitleComponente from "../../home/TopoTitleComponente";
import LinhaAcaoAtivarInativarCooperadoSudo from "./LinhaAcaoAtivarInativarCooperadoSudo";
import { cooperadoSudoService } from "../../../services/cooperadoSudo.service";
import { objectsConstants } from "../../../constants/objects.constants";
import CooperadoSudoTopo from "./CooperadoSudoTopo";

const listFields = [
  {
    path: "Cooperado",
    fieldName: "nome",
    onlyNumber: false,
    searchEmpty: false,
    emptyTextSearch: "",
    url: "",
    fieldDescription: "Nome",
    type: "STRING",
  },
  {
    path: "Cooperado",
    fieldName: "numeroConselho",
    onlyNumber: false,
    searchEmpty: false,
    emptyTextSearch: "",
    url: "",
    fieldDescription: "Número de conselho",
    type: "STRING",
  },
  {
    path: "Cooperado",
    fieldName: "cpf",
    onlyNumber: false,
    searchEmpty: false,
    emptyTextSearch: "",
    url: "",
    fieldDescription: "CPF",
    type: "STRING",
  },
  {
    path: "Cooperado.pessoaEspecialidades.especialidade",
    fieldName: "nome ",
    onlyNumber: false,
    searchEmpty: false,
    emptyTextSearch: "",
    url: "",
    fieldDescription: "Especialidade(s)",
    type: "STRING",
  },
  {
    path: "Cooperado",
    fieldName: "status",
    onlyNumber: false,
    searchEmpty: false,
    emptyTextSearch: "",
    url: "",
    fieldDescription: "Status",
    type: "ENUM",
    enumListValues: [
      {
        name: "INATIVO",
        description: "Inativo",
      },
      {
        name: "ATIVO",
        description: "Ativo",
      },
    ],
  }
];

const columns = [
  {
    path: "Cooperado",
    fieldName: "nome",
    onlyNumber: false,
    searchEmpty: false,
    emptyTextSearch: "",
    url: "",
    fieldDescription: "Nome",
    type: "STRING",
  },
  {
    path: "Cooperado",
    fieldName: "numeroConselho",
    onlyNumber: false,
    searchEmpty: false,
    emptyTextSearch: "",
    url: "",
    fieldDescription: "Número de conselho",
    type: "STRING",
  },
  {
    path: "Cooperado",
    fieldName: "cpf",
    onlyNumber: false,
    searchEmpty: false,
    emptyTextSearch: "",
    url: "",
    fieldDescription: "CPF",
    type: "STRING",
  },
  {
    path: "Cooperado.pessoaEspecialidades.especialidade",
    fieldName: "nome",
    onlyNumber: false,
    searchEmpty: false,
    emptyTextSearch: "",
    searchable: true,
    url: "",
    fieldDescription: "Especialidade(s)",
    type: "STRING",
  },
  {
    path: "Cooperado",
    fieldName: "status",
    onlyNumber: false,
    searchEmpty: false,
    emptyTextSearch: "",
    url: "",
    fieldDescription: "Status",
    type: "ENUM"
  }
];

class CooperadoSudoList extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      initFilter: []
    };
  }

  handleRowClick = (fieldName, rowData) => {
    this.props.history.push({
      pathname: "/cooperadosSudo/view/" + rowData.id,
      state: { entity: rowData }
    });
  };

  closeMenu = () => {
    this.props.showMenu();
  };


  render() {
    const { permissions, user, hideTopo } = this.props;
    return (

      <div className="container-fluid container-com-topo">
        {!hideTopo && (
          <React.Fragment>
            <TopoTitleComponente
              mainTitle="Cooperados"
              subTitle=" "
              canBack={false}
            />
            <CooperadoSudoTopo
              securityActionDo={objectsConstants.ALTERAR}
              securityActionOn={objectsConstants.CRUDCOOPERADOS_DADOS}
              buttonActionClassName={"btn-light"}
              buttonAction={() => {
                this.props.history.push("/cooperadosSudo/novo");
              }}
              securityActionIcon={"icon-btn-add"}
              securityActionName={"Novo Cooperado"}
            />

            <hr className="text-center w-75"></hr>

            <div className="background-lines">
              <div className="bg-riscos"></div>
            </div>
          </React.Fragment>
        )}

        <Datatable
          showCheckboxColumn={true}
          idRow={"id"}
          name={"Cooperado(s)"}
          service={cooperadoSudoService}
          showAllColumnsFilter={false}
          initFilter={this.state.initFilter}
          ActionLine={LinhaAcaoAtivarInativarCooperadoSudo}
          onRowClick={this.handleRowClick}
          noCacheFilter={this.props.noCacheFilter}
          columns={columns}
          listFields={listFields}
        />
      </div>
    );
  }
}

const mapDispatch = ({ menu: { showMenu, hideMenu } }) => ({
  showMenu: () => showMenu(),
  hideMenu: () => hideMenu()
});

function mapStateToProps(state) {
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    alert,
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(CooperadoSudoList));
