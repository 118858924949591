import { urlsConstants } from "../constants/url.constant";
import { defaultService } from "./defaultService";

export const regimeService = {
    findAll,
    findByCodigo
}

function findAll() {
    return defaultService.doGet(`${urlsConstants.REGIME}findAll`);
}

function findByCodigo(codigo) {
    return defaultService.doGet(`${urlsConstants.REGIME}findByCodigo/${codigo}`);
}