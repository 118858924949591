import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { objectsConstants } from "../../constants/objects.constants";
import Datatable from "../../sascComponents/datagrid/Datatable";
import TopoListagem from "../../sascComponents/topo/TopoListagem";
import { cooperadoService } from "../../services/cooperado.service";
import { userService } from "../../services/user.service";
import TopoTitleComponente from "../home/TopoTitleComponente";
import LinhaAcaoAtivarInativarCooperado from "./LinhaAcaoAtivarInativarCooperado";

const fields = [
  {
    "path": "Cooperado",
    "fieldName": "nome",
    "onlyNumber": false,
    "searchEmpty": false,
    "emptyTextSearch": "",
    "url": "",
    "fieldDescription": "Nome ",
    "type": "STRING"
  },
  {
    "path": "Cooperado",
    "fieldName": "numeroConselho",
    "onlyNumber": false,
    "searchEmpty": false,
    "emptyTextSearch": "",
    "url": "",
    "fieldDescription": "Numero conselho",
    "type": "STRING"
  },
  {
    "path": "Cooperado.pessoaEspecialidades",
    "fieldName": "especialidade",
    "onlyNumber": false,
    "searchEmpty": false,
    "emptyTextSearch": "",
    "url": "",
    "fieldDescription": "Especialidade(s)",
    "type": "STRING"
  },
  {
    "path": "Cooperado.estabelecimentos",
    "fieldName": "status",
    "onlyNumber": false,
    "searchEmpty": false,
    "emptyTextSearch": "",
    "url": "/enum/findStatus",
    "fieldDescription": "Status",
    "type": "SELECT"
  }
];

class CooperadoList extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    let user = props.userFilter ? props.userFilter : props.user;
    let extension;
    if (userService.isConsultorio(user)) {
      extension = user.perfilAtual.extensao;
    }
    this.state = {
      initFilter: [
        { path: "Cooperado.estabelecimentos", fieldName: "status", fieldDescription: "Status", type: "ENUM", orderBy: "DESC" },
        {
          path: "Cooperado.estabelecimentos.estabelecimento",
          fieldName: "id",
          value: props.extensao ? props.extensao : extension
        }]
    };
  }

  handleRowClick = (fieldName, rowData) => {
    const { user } = this.props;
    let user_perfil = user.perfilAtual.perfil.tipoPerfil;
    if (user_perfil === (objectsConstants.ADMINISTRADOR || objectsConstants.PROTOCOLO)) {
      this.props.history.push({
        pathname: "/usuario/view/" + rowData.id,
        state: { entity: rowData }
      });
    } else {
      this.props.history.push({
        pathname: "/cooperados/view/" + rowData.id,
        state: { entity: rowData }
      });
    }
  };

  closeMenu = () => {
    this.props.showMenu();
  };


  render() {
    const { user, hideTopo } = this.props;
    return (

      <div className="container-fluid container-com-topo">
        {!hideTopo && (
          <React.Fragment>
            <TopoTitleComponente
              mainTitle="Cooperados"
              subTitle=" "
              canBack={false}
            />
            <TopoListagem
              securityActionDo={objectsConstants.ALTERAR}
              securityActionOn={objectsConstants.CRUDCOOPERADOS_DADOS}
              buttonActionClassName={"btn-light"}
              buttonAction={() => {
                this.props.history.push("/cooperados/novo");
              }}
              securityActionIcon={"icon-btn-add"}
              securityActionName={"Novo Cooperado"}
            />

            <hr className="text-center w-75"></hr>

            <div className="background-lines">
              <div className="bg-riscos"></div>
            </div>
          </React.Fragment>
        )}

        <Datatable
          listFields={user.perfilAtual.perfil.tipoPerfil === objectsConstants.ADMINISTRADOR && fields}
          showCheckboxColumn={true}
          idRow={"id"}
          keyTagItem={'nome'}
          name={"Cooperado(s)"}
          service={cooperadoService}
          showAllColumnsFilter={false}
          initFilter={this.state.initFilter}
          ActionLine={LinhaAcaoAtivarInativarCooperado}
          onRowClick={this.handleRowClick}
          noCacheFilter={this.props.noCacheFilter}
        />
      </div>
    );
  }
}

const mapDispatch = ({ menu: { showMenu, hideMenu } }) => ({
  showMenu: () => showMenu(),
  hideMenu: () => hideMenu()
});

function mapStateToProps(state) {
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    alert,
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(CooperadoList));
