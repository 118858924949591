//@flow
import React, { PureComponent } from "react";
import { connect } from "react-redux";

type Props = {
  title: string,
  className: string,
  type: string,
  icone: string,
  onSubmit: any,
  disabled: boolean
};

class BtnLink extends PureComponent<Props> {
  render() {
    const { title, className, onSubmit, type, icone, disabled } = this.props;

    let defaultClass = "btn btn-link";
    if (this.props.className) {
      defaultClass += " " + className;
    }

    return (
      <button
        onClick={onSubmit}
        className={defaultClass}
        type={type}
        disabled={disabled}>
        <span className={icone}></span>
        {title}
      </button>
    );
  }
}

export default connect()(BtnLink);
