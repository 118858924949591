//@flow
import { Label, InputType } from 'reactstrap';
import { ToolTipFeedBack } from '../Utils/ToolTipFeedBack';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { translate } from '../../helpers/message.helper';
import { ToolTipHelp } from '../Utils/ToolTipHelp';
import { objectsConstants } from '../../constants/objects.constants';
//TODO ALTErar this.handleBorderColor(messageValidate)
type Props = {
  label: string,
  type: InputType,
  name: string,
  id: string,
  value?: string | string[] | number,
  size?: number,
  onChange?: any,
  onBlur?: any,
  children?: any,
  required: boolean,
  placeholder: string,
  disabled: boolean,
  maxLength?: number,
  style?: any,
  messageValidate?: string
};


export default  class FormTextArea extends PureComponent<Props> {
  _input: any;

  static defaultProps = {
    required: false,
    size: 12,
    disabled: false

  };

  getInput = () => {
    return this._input;
  };

  render() {
    //FIXME NAO SE DEVE ALTERAR O VALOR DE UMA VARIAVEL DO TIPO PROPS. O PROPS É IMUTAVEL.
    let { messageValidate, alertMensagem } = this.props;    

    if (this.props.erroMensagem) {
      messageValidate = this.props.erroMensagem;
    }
    const value = this.props.value;

    let mensagemAlert;

    if (alertMensagem) {
      let tipoGuia = objectsConstants.TIPOS_GUIA.find(element => { if (element.label === localStorage.getItem("tipoGuia")) return element; });

      mensagemAlert= alertMensagem.find(element =>
        this.props.name.toLowerCase().includes(element.campoAtendimentoTipoGuia.campoAtendimento.nomeCampo.toLowerCase())
        && this.props.label.toLowerCase().includes(element.campoAtendimentoTipoGuia.campoAtendimento.label.toLowerCase())
        && tipoGuia.id == element.tipoGuia.id);
    }

    return (
      <div className={`${this.props.className && this.props.className} ${this.props.required ? "input-required" : ""}`}>

        {this.props.label && (
          <React.Fragment>
            <Label for={this.props.name} className='label-nowrap' title={this.props.label}>
              {this.props.label}
              {this.props.required && '*'}
            </Label>{' '}
            {mensagemAlert && (
              <React.Fragment>
                <i className="icon-exclamation" id={'help_' + mensagemAlert.id} />
                <ToolTipHelp
                  target={'help_' + mensagemAlert.id}
                  message={mensagemAlert.mensagemAlerta}
                />
              </React.Fragment>
            )}
            {this.props.helpText && (
              <React.Fragment>
                <i className="icon-duvida" id={'help_' + this.props.id} />
                <ToolTipHelp
                  target={'help_' + this.props.id}
                  message={this.props.helpText}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        <textarea
          id={this.props.id?this.props.id:this.props.name}
          name={this.props.name}
          value={value}
          onBlur={this.props.onBlur}
          onChange={this.props.onChange}
          required={this.props.required}
          placeholder={this.props.placeholder === "" || this.props.placeholder === undefined ? this.props.label : this.props.placeholder}
          disabled={this.props.disabled ? this.props.disabled : false}
          maxLength={this.props.maxLength}
          innerRef={input => {
            this._input = input;
          }}
          rows={this.props.rows}
          className={`form-control ${this.props.className} ${
            messageValidate ? 'is-invalid' : ''
            }`}
        >
          {this.props.children}
        </textarea>
        {messageValidate && (
          <ToolTipFeedBack
            target={this.props.id?this.props.id:this.props.name}
            messageValidate={messageValidate}
          />
        )}
      </div>
    );
  }
}

// function mapStateToProps(state) {
//   const { messages } = state.fieldMessage;
//   const { alert } = state;

//   return {
//     messages,
//     alert
//   };
// }

// export default connect(mapStateToProps)(FormTextArea);
