import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FormInput from '../../components/Inputs/FormInput';

/*CSS*/
import check from '../../img/check.svg';

class CpfEncontrado extends PureComponent<Props, State> {
  constructor(props){
    super(props)
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyPress);
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress);
  }
 
  handleKeyPress(event) {
    if (event.keyCode === 13) {
      this.props.login();
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 text-center move-up">
            <img src={check} alt="pessoa" height="30px"/>
          </div>
          <div className="col-12 text-center mt-3">
            <h2 className="text-primary">
              <b>{this.props.username}</b>
            </h2>
          </div>
          <div className="col-12 text-center">
            <p>Digite sua senha de acesso:</p>
          </div>
        </div>

        <div className="row">
          <div className="col-12 inputSenha">
            <label>Senha</label>
            <FormInput
              name={'password'}
              id={'password'}
              value={this.props.password}
              type={'password'}
              onChange={this.props.onChange}
              placeholder="Digite a sua senha"
              maxLength={30}
              viewPassword={true}
              onKeyPress={(e) => this.handleKeyPress(e)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 esqueciSenha">
            <a
              className="link-cinza"
              href="#"
              onClick={this.props.esqueciSenha}
            >
              Esqueci minha senha
            </a>
            <br/>
            <a className="link-cinza"
              href="https://sasc.coop.br/wp-content/uploads/2021/06/passoa-a-passo-limpeza-de-cache.pdf" target="_blank">
              Dica: Como limpar o cache do navegador?
            </a>
          </div>
        </div>

        <div className="row">
          <div className="col mb-15-negative text-center">
            <button
              className="btn btn-primary"
              type="button"
              onClick={this.props.login}
            >
              Conectar
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { loggedIn } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    alert
  };
}

const mapDispatch = ({
  alert: { error, clear, success, info },
  load: { loading }
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  info: msg => info(msg)
});
export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(CpfEncontrado));
