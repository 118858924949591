import _ from "lodash";
import React, { PureComponent } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { objectsConstants } from "../../constants/objects.constants";
import { formatterHelper } from "../../helpers/formatter.helper";
import anexo from "../../img/icon_anexo_BIG.svg";
import { ConfirmAcationService } from "../../services/ConfirmAcationService";

import { urlsConstants } from "../../constants/url.constant";
import { defaultService } from "../../services/defaultService";

let download = require("downloadjs");

type Props = {
  target?: any,
  itens: [],
};

const modifiers = {
  preventOverflow: {
    enabled: false,
  },
  flip: {
    enabled: false,
  },
};

function resize(file, maxWidth, maxHeight, fn) {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function (event) {
    var dataUrl = event.target.result;

    var midiaVO = {
      nome: file.name,
      tamanho: file.size,
      contentType: file.type,
      idTemp: file.id,
    };
    const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
    if (validImageTypes.includes(file.type)) {
      var image = new Image();
      image.src = dataUrl;
      image.onload = function () {
        var resizedDataUrl = resizeImage(image, maxWidth, maxHeight, 0.7);
        midiaVO.data = resizedDataUrl;
        fn(midiaVO);
      };
    } else {
      midiaVO.data = dataUrl;
      fn(midiaVO);
    }
  };
}

function resizeImage(image, maxWidth, maxHeight, quality) {
  var canvas = document.createElement("canvas");

  var width = image.width;
  var height = image.height;

  if (width > height) {
    if (width > maxWidth) {
      height = Math.round((height * maxWidth) / width);
      width = maxWidth;
    }
  } else {
    if (height > maxHeight) {
      width = Math.round((width * maxHeight) / height);
      height = maxHeight;
    }
  }

  canvas.width = width;
  canvas.height = height;

  var ctx = canvas.getContext("2d");
  ctx.drawImage(image, 0, 0, width, height);
  return canvas.toDataURL("image/jpeg", quality);
}

export class ModalAnexos extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      openAnexo: false,
    };
  }
  onChange = (e: SyntheticEvent<>) => {
    var files = e.target.files;
    if (files.length) {
      var self = this;
      var maxWidth = this.props.maxWidth;
      var maxHeight = this.props.maxHeight;
      for (let i = 0; i < files.length; i++) {
        let item = files[i];
        resize(item, maxWidth, maxHeight, function (midiaFile) {
          let { itens } = self.props;
          let newArrayFiles = _.cloneDeep(itens);
          newArrayFiles.push(midiaFile);
          self.props.onChange(self.props.name, newArrayFiles);
        });
      }
    }
  };

  cancelar = () => {
    let { itens } = this.props;
    let newArrayFiles = _.cloneDeep(itens);
    _.remove(newArrayFiles, function (item) {
      return item.midia.idTemp == null && !item.id;
    });
    this.props.onChange(this.props.name, newArrayFiles);
    this.props.toogle();
  };

  openInputFile = () => {
    this.inputFile.click();
  };

  downLoadFile = (anexo: any) => {
    if (anexo.id) {
      let url = urlsConstants.MIDIA + 'vo/' + anexo.id;
      defaultService.doGet(url).then(result => {
        let dataFile = result.data;
        if (dataFile) {
          let uri = `data:${dataFile.contentType};base64,${dataFile.data}`;
          if (dataFile.data && dataFile.data.includes("base64,")) {
            uri = dataFile.data;
          }

          download(uri, dataFile.nome, dataFile.contentType);
        }
      });
    }
    return false;
  };

  downLoadDataFile = (dataFile: any) => {
    if (dataFile) {
      let uri = `data:${dataFile.contentType};base64,${dataFile.data}`;
      if (dataFile.data.includes("base64,")) {
        uri = dataFile.data;
      }

      download(uri, dataFile.nome, dataFile.contentType);
    }
    return false;
  };
  removeItem = (e, image) => {
    let { itens } = this.props;
    let newArrayFiles = _.cloneDeep(itens);
    _.remove(newArrayFiles, function (item) {
      return (image.id && image.id === item.midia.id || image.nome == item.midia.nome)
    });
    this.props.onChange(this.props.name, newArrayFiles);
  };

  toggle = () => {
    let { itens } = this.props;
    let newArrayFiles = _.cloneDeep(itens);
    for (let i = 0; i < newArrayFiles.length; i++) {
      let item = newArrayFiles[i];
      _.set(item.midia, "idTemp", i);
    }
    this.props.onChange(this.props.name, newArrayFiles);

    this.props.toogle();
  };

  show = () => {
    this.toggle();
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.toggle}
          backdrop="static"
          modalClassName=""
          className="cinza"
          centered={true}
          modifiers={modifiers}
          target={this.props.target}
        >
          <ModalBody>
            <div className="modalCooperado">
              <div className="row">
                <div className="col-12 text-center ">
                  <img src={anexo} alt="anexo" />
                </div>
                <div className="col-12 text-center my-2">
                  <h2 className="font-weight-bold">Anexar</h2>
                </div>
              </div>

              <div className="row">
                <input
                  type="file"
                  accept={this.props.accept ? this.props.accept : "*"}
                  onChange={this.onChange}
                  className="d-none img-profile-input"
                  maxLength="10024"
                  multiple={true}
                  ref={(input) => {
                    this.inputFile = input;
                  }}
                />

                {this.props.itens.length === 0 && (
                  <div className="pb-3 w-100 text-center">
                    <div className="font-12px cinza4">
                      {"Nenhum anexo selecionado "}
                    </div>
                  </div>
                )}
                {this.props.itens.map((item, i) => {
                  let anexo = item;
                  if (item.midia) {
                    anexo = item.midia;
                  }
                  let criadorId = 0;
                  if (item.criador) {
                    criadorId = item.criador.id;
                  }
                  return (
                    <div
                      className="px-3 w-100 d-flex flex-row lineFile"
                      id={anexo.nome}
                      key={anexo.id ? anexo.id : i}
                    >
                      <div
                        className="font-italic font-12px cut-text flex-fill"
                        onClick={() => {
                          this.downLoadFile(anexo);
                        }}
                      >
                        {anexo.nome}{" "}
                      </div>
                      <div className="font-10px">
                        {anexo.dataCadastro
                          ? formatterHelper.converterFormatoData(
                              anexo.dataCadastro
                            )
                          : ""}
                      </div>
                      <div className="font-10px">
                        {anexo.tamanho
                          ? formatterHelper.getFileSize(anexo.tamanho)
                          : ""}
                      </div>

                      {criadorId === this.props.user.id &&
                        !this.props.modeView && (
                          <div className="">
                            <button
                              className="btn btn-icon-only"
                              onClick={(e) => {
                                ConfirmAcationService.confirmMsg(
                                  () => {
                                    this.removeItem(i, anexo);
                                  },
                                  "Excluir anexo",
                                  <span>
                                    {" "}
                                    Deseja realmente excluir o anexo{" "}
                                    <span className="font-bold">
                                      {anexo.nome}
                                    </span>
                                    ?
                                  </span>,
                                  <span className="text-danger">{}</span>,
                                  "Não",
                                  "Sim",
                                  objectsConstants.TYPE_EXCLUIR
                                );
                              }}
                            >
                              <span className="icon-btn-excluir"></span>
                            </button>
                          </div>
                        )}
                    </div>
                  );
                })}
              </div>
              {!this.props.viewMode && (
                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={this.openInputFile}
                  >
                    <span className="icon-btn-anexar"></span>Anexar arquivo
                  </button>
                </div>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            {this.props.viewMode && (
              <div className="w-100 text-center">
                <button
                  className="btn-secondary"
                  onClick={() => {
                    this.toggle();
                  }}
                >
                  {" "}
                  Fechar{" "}
                </button>
              </div>
            )}

            {!this.props.viewMode && (
              <React.Fragment>
                <div className="w-50 text-center">
                  <button
                    className="btn-secondary"
                    onClick={() => {
                      this.cancelar();
                    }}
                  >
                    {" "}
                    Cancelar{" "}
                  </button>
                </div>
                <div className="w-50 text-center">
                  <button
                    className="btn-primary"
                    onClick={() => {
                      this.toggle();
                    }}
                  >
                    {" "}
                    Anexar{" "}
                  </button>
                </div>
              </React.Fragment>
            )}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}
