//@flow
import React from 'react';
import moment from 'moment'
type State = {
  load: boolean
};

type Payload = {
  load: boolean
};
function pause(milliseconds) {
    var dt = new Date();
    while ((new Date()) - dt <= milliseconds) { /* Do nothing */ }
}
export default {
  state: { load: false,lastTimeLoad:null },
  reducers: {
    loading: (state: State, payload: Payload) => {
      if(payload.load) {

          return Object.assign({}, state, {
              load: payload.load,
              lastTimeLoad: moment()
          });
      }else{
          var diff = moment().diff(state.lastTimeLoad);

        if(diff>1000) {
            return Object.assign({}, state, {
                load: payload.load,
                lastTimeLoad: moment()
            });
        }else {
            pause(1000 - diff)
            return Object.assign({}, state, {
                load: payload.load,
                lastTimeLoad: moment()
            });
        }

      }
    }
  }
};
