import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { userService } from "../../services/user.service";
import { withRouter } from "react-router-dom";
import CpfInput from "../../components/Inputs/CpfInput";
import { FormGroup } from "../../components/FormGroup";
import ReCAPTCHA from "react-google-recaptcha";
import { avisosService } from "../../services/avisos.service";

class Login extends PureComponent<Props, State> {
    constructor(props) {
        super(props)
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyPress);
    }
        
    
    
    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress);
    }

    handleKeyPress(event) {
        if (event.keyCode === 13) {
            this.props.existByCpf();
        }
    }
    onChange = (value) => {
        this.props.onChange('captcha', value);
    }
    render() {
        const { children } = this.props;
        
        return (
            <React.Fragment>
                <div className="row">{children}</div>

                <div className="row">
                    <div className="col txtInicial">
                        <p>Insira seu CPF abaixo para acessar ou se cadastrar no sistema</p>
                    </div>
                </div>

                <div className="row">
                    <FormGroup className="col-12">
                        <CpfInput
                            name={"username"}
                            id={"username"}
                            style={"inputCPF"}
                            label={"CPF:"}
                            value={this.props.username}
                            onChange={this.props.onChange}
                            placeholder="Digite o seu CPF"
                            maxLength={14}
                        />

                    </FormGroup>
                    {/*<div className={'col-12 captcha'}><ReCAPTCHA*/}
                    {/*    sitekey="6LddsrsfAAAAAMpcVjQJtZm_AaEw5hzhhLEaQcJg"*/}
                    {/*    onChange={this.onChange}*/}
                    {/*    badge={'bottomright'}*/}
                    {/*/>*/}
                    {/*</div>*/}

                    <div className="row">

                    </div>
                    <div className="col mb-15-negative text-center">
                        <button
                            className="btn-primary"
                            type="button"
                            onClick={this.props.existByCpf}

                        >
                            Avançar
                        </button>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { loggedIn } = state.authentication;
    const { alert } = state;

    return {
        loggedIn,
        alert
    };
}

const mapDispatch = ({
    alert: { error, clear, success, warning },
    authentication: { login },
    load: { loading }
}) => ({
    error: (message, code) => error({ message, code }),
    clear: () => clear(),
    success: msg => success(msg),
    login: (user, password) => login({ user, password }),
    loading: (load: boolean) => loading({ load }),
    warning: messages => {    
    messages.forEach(msg => {
            warning(msg)
        });
    }
   

    
});
export default connect(
    mapStateToProps,
    mapDispatch
)(withRouter(Login));
