//@flow
type State = {
  load: boolean
};

export default {
  state: { showError: false, errorsImportacaoNotifications: [] },
  reducers: {
    toogleErrorImportacaoNotification: (state: State) => {
      let showError = !state.showError;
      if (showError) {
        document.body.classList.add("bodyNoScroll", "notificationOpened");
        document.getElementById("bodyNotification").scrollIntoView();
      } else {
        document.body.classList.remove("bodyNoScroll", "notificationOpened");
      }
      return Object.assign({}, state, {
        showError: !state.showError
      });
    },
    addAllErrors: (state, payload) => {
      return Object.assign({}, state, {
        errorsImportacaoNotifications: payload.errorsImportacaoNotifications
      });
    }

  }
};
