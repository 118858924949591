import React, { PureComponent } from "react";
import { pendenciaService } from "../../services/pendencia.service";
import Avatar from "../../components/Avatar/Avatar";
import { dateHelper } from "../../helpers/date.helper";
import { Row } from "reactstrap";

export default class NotificationResponsavel extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      pendencia: {
        id:this.props.notification.idPendencia,
        usuario: this.props.notification.userPendencia 
      }
    };
  }
  //componentWillMount() {
    //pendenciaService.doGet(this.props.notification.entity).then(response => {
      //this.setState({ pendencia: response.data.data });
    //});
  //}
  render() {
    const { pendencia } = this.state;
    const { notification } = this.props;

    if (pendencia.id && pendencia.usuario) {
      if(notification.taskType == "INCONSISTENCIA_AUXILIARES") {
        return (
          <React.Fragment>
              <div className="col-2 col-md-1 py-2 pl-3 pr-1 mt-4 mt-md-0">
                <Avatar
                  className="avatarNotification mr-5"
                  user={pendencia.usuario}
                ></Avatar>
              </div>
              <div className="col-6 mt-4 notificationCenter">
                <p id="ownerNotif" className="m-0">
                  {pendencia.usuario.nome}
                </p>
                <p id="dataNotif" className="m-0 txtGray">
                  {dateHelper.format(notification.dataCriacao, { mode: "DATE" })}
                </p>
              </div>
          </React.Fragment>
        );
      }  else {
        return (
          <React.Fragment>
            <div className="col-2 col-md-1 p-0 mt-4 mt-md-0">
              <Avatar
                className="avatarNotification mr-5"
                user={pendencia.usuario}
              ></Avatar>
            </div>
  
            <div className="col px-0 mt-4 mt-md-0">
              <p id="ownerNotif" className="m-0">
                {pendencia.usuario.nome}
              </p>
              <p id="dataNotif" className="m-0 txtGray">
                {dateHelper.format(notification.dataCriacao, { mode: "DATE" })}
              </p>
            </div>
          </React.Fragment>
  
        );
      }
    }
    return null;

  }
}
