//@flow
export default {
  state: { modelo: [] },
  reducers: {
    handleModelo: (state, payload) => {
      return Object.assign({}, state, {
        modelo: payload
      });
    }

  },

};
