//@flow
import * as React from "react";
import SelectInput from "./SelectInput";
import { objectsConstants } from "../../constants/objects.constants";
import { connect } from "react-redux";
import { userService } from "../../services/user.service";

type Props = {
  label: string,
  name: string,
  value: any,
  onChange: any,
  returnFullObject: boolean,
  messageValidate?: any,
  disabled?: boolean,
  size?: number,
  required: boolean,
  service: any
};

type State = {
  options: Array<any>
};
class RoleSelectInput extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      options: []
    };
  }

  componentDidMount() {
    userService.getProfiles().then(response => {
      let options = response.data;
      this.setState({ options });
    });

  }

  render() {
    return (
      <SelectInput
        label={this.props.label}
        valueKey={"id"}
        labelKey={"nome"}
        name={this.props.name}
        value={this.props.value}
        options={this.state.options}
        onChange={this.props.onChange}
        multi={false}
        returnFullObject={this.props.returnFullObject}
        messageValidate={this.props.messageValidate}
        disabled={this.props.disabled}
        size={this.props.size}
        style={this.props.style}
        required={this.props.required}
        noSize={this.props.noSize}
        placeholder={this.props.placeholder === "" || this.props.placeholder === undefined ? this.props.label : this.props.placeholder}
        clearable={false}
        className={this.props.className}
      />
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(RoleSelectInput);
