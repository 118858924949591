import { Formik } from "formik";
import _ from "lodash";
import moment from "moment";
import React, { PureComponent } from "react";
import { confirmAlert } from "react-confirm-alert";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import FormTextArea from "../../../components/Inputs/FormTextArea";
import InputViewEdit from "../../../components/Inputs/InputViewEdit";
import SelectInput from "../../../components/Inputs/SelectInput";
import { ModalAnexos } from "../../../components/Utils/ModalAnexos";
import { ToolTipHelp } from "../../../components/Utils/ToolTipHelp";
import { objectsConstants } from "../../../constants/objects.constants";
import { formatterHelper } from "../../../helpers/formatter.helper";
import { translate } from "../../../helpers/message.helper";
import iconResolverPendencia from "../../../img/iconAlert_ResolverPendencia.svg";
import iconPendencia from "../../../img/icon_pendente_WHITE.svg";
import { atendimentoService } from "../../../services/atendimento.service";
import { justificativaService } from "../../../services/justificativa.service";
import { pendenciaService } from "../../../services/pendencia.service";

const validatePendenciaTopo = Yup.object().shape({
  descricaoResolucaoPendencia: Yup.string().required("Obrigatório")
});

class ResolverPendenciasTopo extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    let atendimento = { id: props.idAtendimentoAtual, type: objectsConstants.ENUM_TIPOS_GUIA.SADT };
    this.state = {
      filter: {},
      openAnexo: false,
      viewMode: false,
      openMenuNotificacao: false,
      atendimento
    };
  }


  componentDidMount() {
    atendimentoService.findByIds([this.props.idAtendimentoAtual]).then(response => {
      let atendimentos = response.data;
      this.setState({ atendimento: atendimentos[0] });
    })
  }

  fecharPendencia = () => {};

  toggleAnexos = () => {
    this.setState(({ openAnexo }) => ({ openAnexo: !openAnexo }));
  };

  atualizaAnexos = values => {
    this.props.loading(true);
    _.get(values, "component");
    _.unset(values, "component");
    _.set(values, "atendimento", {
      id: this.props.idAtendimentoAtual,
      type: objectsConstants.ENUM_TIPOS_GUIA.SADT
    });
    pendenciaService.doSave(values).then(response => {
      this.props.loading(false);
      this.toggleAnexos();
    },
      erros => {
        console.error(erros);
        this.props.loading(false);
        this.toggleAnexos();
      });
  }

  confirmPendencia = (onConfirm, campoSelecionado, setFieldValue) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-ui css-alert-sasc">
            <img src={iconResolverPendencia} alt="icon" />
            <h2>{"RESOLVER PENDÊNCIA"}</h2>
            <p>
              <b>{campoSelecionado.label}</b>
            </p>
            <p>
              Ao resolver a pendência, a guia é encaminhada automaticamente para
              o status anterior, sinalizando o criador da pendência que ela foi
              resolvida.
            </p>
            <p className="font-bold pt-2">
              Você concorda que a pendência foi realmente resolvida?
            </p>
            <button
              className="btn btn-secondary white mt-3 mb-4 mx-2"
              onClick={() => {
                onClose();
              }}
            >
              Cancelar
            </button>
            {
              <button
                className="btn btn-primary white mt-3 mb-4 mx-2"
                onClick={() => {
                  onConfirm();
                  onClose();
                }}
              >
                Sim
              </button>
            }
          </div>
        );
      }
    });
  };

  openInputFile = () => {
    if (!this.state.viewMode && !this.props.campoSelecionado.id) {
      this._anexosInput.openInputFile();
    }
  };

  toggleNotificacaoPendencia = toggle => {
    this.setState({ openMenuNotificacao: toggle });
  };
  handleChange = (nameParam: string, valueParam: any) => {
    const { atendimento } = this.state;
    let newAtendimento = _.cloneDeep(atendimento);
    if (!nameParam.currentTarget) {
      //verificar se é procedimento tem que fazer os sets diferentes
      if (nameParam.includes('.codigoProcedimento')) {
        _.set(newAtendimento, nameParam, valueParam.codigo);
        _.set(newAtendimento, nameParam.replace('.codigoProcedimento', '.descricao'), valueParam.descricao);

      } else if(nameParam.includes('.cooperado.numeroConselho')){
        //const partes = nameParam.split('.');
        //const indiceCooperado = partes.findIndex(part => part.includes('cooperado'));
        //const param = partes.slice(0, indiceCooperado + 1).join('.');
        //_.set(newAtendimento, param, valueParam);
        _.set(newAtendimento, nameParam, valueParam);
        
      }
       else if(nameParam.includes('cooperado.conselho.numero')){
        _.set(newAtendimento, 'cooperado', valueParam);
        _.set(newAtendimento, nameParam, valueParam);
      
      } 
      else {
        _.set(newAtendimento, nameParam, valueParam);
      }

    } else {
      const {  value,name } = nameParam.currentTarget;
      _.set(newAtendimento, name, value);
    }
    this.setState({ atendimento: newAtendimento });
  };

  render() {
    const { campoSelecionado } = this.props;
    const { atendimento } = this.state;
    let _this = this;
    let value = _.get(
      atendimento,
      campoSelecionado.nomeCampo,
      campoSelecionado.value
    );
 
if (campoSelecionado.nomeCampo.includes('dataIniFaturamento') 
|| campoSelecionado.nomeCampo.includes('dataFimFaturamento')
|| campoSelecionado.nomeCampo.includes('.dataAtendimento')
|| campoSelecionado.nomeCampo.includes('.dataFinalAtendimento')
) {
    let formattedDate;
    if(typeof value !== "object" && value.match(/^\d{4}-\d{2}-\d{2}$/)) {
        formattedDate = value;
    }else {
        const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
        if (regex.test(value)) {
          formattedDate = value;
        } else {
            if(campoSelecionado.nomeCampo.includes('.dataAtendimento') 
              || campoSelecionado.nomeCampo.includes('.dataFinalAtendimento')
            ){
              formattedDate = moment(value, "DD/MM/YYYY").format('YYYY-MM-DD');
            }else{
              formattedDate = moment(value, "DD/MM/YYYY").format('YYYY-MM-DD HH:mm:ss');
            }
        }
    }
    value = formattedDate;
}


if (campoSelecionado.nomeCampo.includes('.horaInicial') || campoSelecionado.nomeCampo.includes('.horaFinal')){
    let formattedDate;
    if(typeof value !== "object" && value.match(/^\d{2}:\d{2}:\d{2}$/)) {
        formattedDate = value;
    }else{
      formattedDate = moment(value).format('HH:mm:ss');
    }  
    value = formattedDate;
}

  
    if (campoSelecionado.nomeCampo.includes('.codigoProcedimento')) {
      value = { codigo: value }
    }
    let InputPendencia = null;
    if(campoSelecionado.component){
      InputPendencia = React.cloneElement(campoSelecionado.component, {
        viewMode: false,
        modeView:false,
        label: null,
        onChange: this.handleChange,
        value,
        values:this.state.atendimento
      });
    }   
    return (
      <React.Fragment>
        <div className="barra-topo pb-3 p-sm-0 bg-branco rounded d-flex flex-column flex-sm-row align-content-stretch flex-wrap">
          <React.Fragment>
            <Formik
              validationSchema={validatePendenciaTopo}
              validateOnBlur={false}
              validateOnChange={false}
              enableReinitialize={true}
              initialValues={atendimento}
              onSubmit={(values, actions) => {
                this.props.loading(true);

                let requestVO = campoSelecionado;
                let dataIni =  moment(values.dataIniFaturamento).format('YYYY-MM-DD');
                let horaIni =  moment(values.dataIniFaturamento).format('HH:mm:ss');
                let dataHoraIni = dataIni+"T"+horaIni;

                let dataFim =  moment(values.dataFimFaturamento).format('YYYY-MM-DD');
                let horaFim =  moment(values.dataFimFaturamento).format('HH:mm:ss');
                let dataHoraFim = dataFim+"T"+horaFim;

                
                if(campoSelecionado.nomeCampo.includes('.horaInicial') 
                  || campoSelecionado.nomeCampo.includes('.dataFinalAtendimento')
                ){
                  let dadoHoraIncialFim = _.get(values,campoSelecionado.nomeCampo);
                  let dataInicialFim =  moment(dadoHoraIncialFim).format('YYYY-MM-DD');
                  let horaInicialFim =  moment(dadoHoraIncialFim).format('HH:mm:ss');
                  let dataHoraInicailFim = dataInicialFim+"T"+horaInicialFim;
                  _.set(values, campoSelecionado.nomeCampo, dataHoraInicailFim);
                }else if (campoSelecionado.nomeCampo.includes('.cooperado.numeroConselho')){
                  let cooperado = _.get(values,campoSelecionado.nomeCampo)
                  const partes = campoSelecionado.nomeCampo.split('.');
                  const indiceCooperado = partes.findIndex(part => part.includes('cooperado'));
                  const param = partes.slice(0, indiceCooperado + 1).join('.');
                  _.set(values, param, cooperado)
                  
                }else if(campoSelecionado.nomeCampo.includes('cooperado.conselho.numero')){
                  _.set(values, campoSelecionado.nomeCampo, "")
                }


                _.set(values, "dataIniFaturamento", dataHoraIni);
                _.set(values, "dataFimFaturamento", dataHoraFim);
                _.set(requestVO, "atendimento", values);
                _.set(requestVO, "atendimentoPendenciaAnexos", values.atendimentoPendenciaAnexosResolucao);
                _.set(
                  requestVO,
                  "descricaoResolucaoPendencia",
                  values.descricaoResolucaoPendencia
                );
                _.unset(requestVO, "component");

                //set atendimento
                pendenciaService.resolverPendencia(requestVO).then(
                  response => {
                    atendimentoService.findByIds([this.props.idAtendimentoAtual]).then(res => {
                      let checkAtendimento = res.data[0];
                      let title = "Pendência resolvida com sucesso!";
                      let pendencias = checkAtendimento.atendimentoPendencias;
                      if (pendencias && pendencias.length > 0) {
                        title = "Registro salvo com sucesso, favor verificar campos pendentes do atendimento"
                      }

                      let result = response.data;
                      if (result.novoStatus && result.novoStatus.nome && (pendencias && pendencias.length === 0)) {
                        this.props.success({
                          title: title,
                          message: ` A guia ${values.numeroAtendimento} foi encaminhada para o status ${result.novoStatus.nome}`
                        });
                      } else {
                        this.props.success({
                          message: title
                        });
                      }
                      this.props.closePendecia();
                      this.props.onSaved();
                      this.props.campoAtual({ campoSelecionado: {} });
                      this.props.loading(false);
                      actions.setSubmitting(false);
                    });
                  },
                  erros => {
                    console.error(erros);
                    this.props.error({
                      message: "Não foi possível resolver pendência."
                    });
                    try {
                      let response = erros.response.data;
                      if (response && response.messages) {
                        for (var i = 0; i < response.messages.length; i++) {
                          let erroItem = response.messages[i];
                          actions.setFieldError(
                            erroItem.fieldName,
                            translate(erroItem.message.code)
                          );
                        }
                      }
                    } catch (error) {
                      console.error(error);
                    }

                    this.props.loading(false);
                    actions.setSubmitting(false);
                    campoSelecionado.nomeCampo = {};
                  }
                );
              }}
              ref={form => {
                this.formRef = form;
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                validateForm,
                setValues
              }) => (
                  <React.Fragment>
                    <div className="icone-pendencia-left py-4 py-sm-4 px-sm-5 d-flex align-items-center justify-content-center">
                      <div className="pr-sm-5 d-flex align-items-center">
                        <img
                          src={iconPendencia}
                          alt="Pendencia"
                          className="iconMedio"
                        />
                        <div>
                          <h2 className="status-txt branco pl-2 mb-0">
                            <span> Resolver pendência </span>
                          </h2>
                          <p className="mb-0 branco pl-2">
                            {campoSelecionado.label}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="px-2 py-4 py-sm-4 px-sm-5 d-flex flex-column justify-content-center align-items-center borda-separacao input-pendencia">
                    <label>{campoSelecionado.label}{campoSelecionado.nomeCampo === "observacao" &&
                      <React.Fragment>
                        <i className="icon-duvida" id={"obs"} />
                        <ToolTipHelp
                          target={"obs"}
                          message={"Insira a justificativa do atendimento, o texto deve ter no mínimo 2 palavras"}
                        />
                      </React.Fragment>}
                      {campoSelecionado.nomeCampo === "observacao" &&
                        <SelectInput
                          className="min-width"
                          name={"observacao"}
                          id={"observacao"}
                          value={this.state.justificativa}
                          onChange={(name, value) => {
                            let justificativa = value.texto;
                            if (InputPendencia.props.value !== null) {
                              this.handleChange(name, justificativa.concat(" ", InputPendencia.props.value));
                            } else {
                              this.handleChange(name, justificativa);
                            }
                          }}
                          returnFullObject={true}
                          labelKey={"texto"}
                          valueKey={"id"}
                          placeholder="Selecione a justificativa"
                          onFetchData={justificativaService.findByTexto}
                        />}
                    </label>
                      {InputPendencia}
                    </div>
                    {campoSelecionado.label === "Anexos - Guia física" &&
                    <div className="borda-separacao">                    
                        <InputViewEdit
                        label="Anexo encaminhado fisicamente para a cooperativa?"
                        component={SelectInput}
                        options={objectsConstants.SIM_NAO}
                        name={"anexoEncaminhado"}
                        searchable={true}
                        returnFullObject={true}
                        defaultValue={this.state.atendimento.anexoEncaminhado &&
                          this.state.atendimento.anexoEncaminhado === false ? 'Não'
                          : formatterHelper.getLabelEnum(
                            this.state.atendimento.anexoEncaminhado &&
                            this.state.atendimento.anexoEncaminhado.value
                              ? this.state.atendimento.anexoEncaminhado.value
                              : this.state.atendimento.anexoEncaminhado,
                            objectsConstants.SIM_NAO
                          )}
                        value={_.isBoolean(this.state.atendimento.anexoEncaminhado) ? formatterHelper.getEnum(this.state.atendimento.anexoEncaminhado, objectsConstants.SIM_NAO) : this.state.atendimento.anexoEncaminhado}
                        labelKey={"label"}
                        valueKey={"value"}
                        onChange={(name, value) => {
                          this.handleChange(name, value.value);
                        }}
                      />
                      
                      <div><label>{atendimento && atendimento.cooperativa && atendimento.cooperativa.id === 7 && atendimento.atendimentoAnexos && atendimento.atendimentoAnexos.length > 0 ? "Data de inclusão do anexo: " + formatterHelper.converterFormatoData(atendimento.atendimentoAnexos[0].midia.dataCadastro):""}</label></div>
                    </div>}
                    <div className="py-2 py-sm-4 px-3 d-flex flex-fill flex-column justify-content-center borda-separacao">

                      <InputViewEdit
                        label={"Descrição da resolução"}
                        component={FormTextArea}
                        helpText={campoSelecionado.nomeCampo === "observacao" && "Informe as ações realizadas para resolver a pendência"}
                        required={true}
                        noSize={true}
                        rows={3}
                        name={"descricaoResolucaoPendencia"}
                        value={values.descricaoResolucaoPendencia}
                        onChange={this.handleChange}
                        erroMensagem={errors.descricaoResolucaoPendencia}
                        id={"descricaoResolucaoPendencia"}
                        placeholder="Insira uma breve descrição da resolução"
                      />
                    </div>

                    {!this.state.viewMode && values.id && (
                      <React.Fragment>
                        <div className="px-sm-3 d-flex flex-column align-items-center justify-content-center borda-separacao">
                          <button
                            type="button"
                            className="btn btn-link pb-0 pb-sm-3"
                            onClick={this.toggleAnexos}
                          >
                            <span className="icon-btn-anexar"></span>Anexar
                          </button>

                          <div
                            id="anexos"
                            className="pointer text-center"
                            onClick={this.toggleAnexos}
                          >
                            <React.Fragment>
                              <div className="font-10px verde-destaque font-bold">
                                {_.size(values.atendimentoPendenciaAnexosResolucao)} arquivo
                                </div>
                              <div className="font-10px verde-destaque">anexado</div>
                            </React.Fragment>
                          </div>
                          <ModalAnexos
                            ref={anexos => (this._anexosInput = anexos)}
                            name="atendimentoPendenciaAnexosResolucao"
                            toogle={this.toggleAnexos}
                            isOpen={this.state.openAnexo}
                            updateAnexos={() =>
                              this.atualizaAnexos(values)
                            }
                            target={"anexos"}
                            itens={
                              values.atendimentoPendenciaAnexosResolucao
                                ? values.atendimentoPendenciaAnexosResolucao
                                : []
                            }
                            onChange={(name, anexos) => {
                              let atendimentoPendenciaAnexosResolucao = [];
                              for (let index = 0; index < anexos.length; index++) {
                                if (!anexos[index].midia) {
                                  atendimentoPendenciaAnexosResolucao.push({
                                    midia: anexos[index],
                                    criador: this.props.user
                                  });
                                } else {
                                  atendimentoPendenciaAnexosResolucao.push(anexos[index]);
                                }

                              }
                              setFieldValue(name, atendimentoPendenciaAnexosResolucao);
                            }}
                            viewMode={null}
                            user={this.props.user}
                          />
                        </div>
                        <div className="py-2 py-sm-4  px-3 d-flex w-25 flex-row align-items-center justify-content-center">
                          <button
                            className="btn btn-secondary white mt-3 mb-4 mx-2"
                            onClick={() => {
                              this.props.closePendecia();
                                this.props.closePendecia();
                                this.props.campoAtual({campoSelecionado:{}});
                            }}
                            disabled={isSubmitting}
                          >
                            Cancelar
                        </button>
                          <button
                            className="btn btn-primary white mt-3 mb-4 mx-2"
                            onClick={() => {
                              validateForm(values).then(erros => {
                                if (_.isEmpty(erros)) {
                                  _this.confirmPendencia(
                                    handleSubmit,
                                    values,
                                    setFieldValue
                                  );
                                }
                              });
                            }}
                            disabled={isSubmitting}
                          >
                            Resolver
                      </button>
                        </div>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
            </Formik>
          </React.Fragment>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error },
  load: { loading },
  pendencia: { closePendecia },
  selectableInput: {campoAtual},
}) => ({
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  closePendecia: () => closePendecia(),
  campoAtual: campoSelecionado => campoAtual(campoSelecionado)
});

function mapStateToProps(state) {
  const {
    resolverPendenciaOpen,
  } = state.pendencia;

  const { user } = state.authentication;
  const {campoSelecionado,  idAtendimentoAtual} = state.selectableInput;

  return {
    resolverPendenciaOpen,
    idAtendimentoAtual,
    campoSelecionado,
    user
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(ResolverPendenciasTopo));
