import _ from "lodash";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, CardHeader } from "reactstrap";
import BtnLink from "../../components/Buttons/BtnLink";
import { FormGroup } from "../../components/FormGroup";
import CpfCnpjInput from "../../components/Inputs/CpfCnpjInput";
import CpfInput from "../../components/Inputs/CpfInput";
import FormInput from "../../components/Inputs/FormInput";
import InputViewEdit from "../../components/Inputs/InputViewEdit";
import SelectInput from "../../components/Inputs/SelectInput";
import { objectsConstants } from "../../constants/objects.constants";
import { formatterHelper } from "../../helpers/formatter.helper";
import { ConfirmAcationService } from "../../services/ConfirmAcationService";
import { estabelecimentoService } from "../../services/estabelecimento.service";
import { userService } from "../../services/user.service";
import CooperativaUsuarioForm from "./CooperativaUsuarioForm";
// import ConsultorioUsuarioForm from "./ConsultorioUsuarioForm";

class CooperativaForm extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      viewMode: true
    };
  }
  goToEdit = () => {
    this.props.history.push({
      pathname: "/cooperativa/edit/" + this.props.values.id,
      state: { entity: this.props.values }
    });
  };


  addUsuario = (setFieldValues, values) => {
    let usuarios = _.cloneDeep(values.usuarios);
    usuarios.unshift({ idKey: new Date().getTime() });
    setFieldValues("usuarios", usuarios);
  };

  addUsuarioAndEdit = (setFieldValues, values) => {
    this.setState({ viewMode: false });
  };

  removerUsuario = (setFieldValues, values, index) => {
    let usuarios = _.cloneDeep(values.usuarios);
    usuarios.splice(index, 1);
    setFieldValues("usuarios", usuarios);
    this.props.info({
      message: `Usuário removido com sucesso!`
    });
  };

  excluirUsuario = (consultorio, usuario) => {
    this.props.loading(true);
    estabelecimentoService.removerUsuario(consultorio, usuario).then(
      response => {
        this.props.success({
          message: `Usuário foi excluido com sucesso!`
        });
        if (this.props.refreshEntity) {
          this.props.refreshEntity();
        }
      },
      erros => {
        console.error(erros.response);
        this.props.error({
          message: "Não foi possível excluir usuário!"
        });
        this.props.loading(false);
      }
    );
    this.props.loading(false);
  };

  cancelEditMode = (e, setValues) => {
    e.stopPropagation();
    ConfirmAcationService.confirmMsg(
      () => {
        this.confirmCancel(setValues);
      },
      `Cancelar Edição `,
      "Você confirma que deseja cancelar a edição dos cronogramas",
      null,
      "Não",
      "Sim",
      objectsConstants.TYPE_CANCEL
    );
  };

  confirmCancel = setValues => {
    this.setState({ viewMode: true });
  };
  render() {
    const {
      values,
      setFieldValue,
      errors,
      isSubmitting,
      handleSubmit,
      viewMode,
    } = this.props;
    return (
      <React.Fragment>
        <div className="item">
          <Card className="cardCrud content-form">
            <CardHeader>
              <div className="d-flex justify-content-between align-items-center">
                <div className="mr-sm-auto card-title">Dados</div>
                <div className="px-sm-3">
                  {viewMode && (<><div className="d-flex flex-row justify-content-center justify-sm-content-end">
                    <button
                      type="button"
                      className="btn btn-light btDefaultGrid d-none d-lg-block"
                      onClick={() => this.props.history.push("/justificativas")}
                    >
                      <span className="icon-btn-editar"></span>
                      Justificativas de Reconsulta
                    </button>
                    <button
                      type="button"
                      className="btn btn-light btDefaultGrid d-none d-lg-block"
                      onClick={() => this.props.history.push("/descricoesPendencia")}
                    >
                      <span className="icon-btn-editar"></span>
                      Descrições de Pendência
                    </button>
                    <button
                      type="button"
                      className="btn btn-light"
                      onClick={this.goToEdit}
                    >
                      <span className="icon-btn-editar"></span>Editar
                    </button>
                  </div></>)}
                </div>
              </div>
            </CardHeader>

            <CardBody className={"form-inside viewMode"}>
              <div className="row section-form">
                <h6 className="w-100">Cooperativa</h6>
              </div>
              <div className="row section-form">
                <FormGroup className="">
                  <InputViewEdit
                    component={FormInput}
                    label={"CNES"}
                    type="text"
                    id="cnes"
                    name="cnes"
                    placeholder="Digite o CNES"
                    value={values.cnes}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    erroMensagem={errors.cnes}
                    viewMode={viewMode}
                    required={true}
                  />
                </FormGroup>

                <FormGroup >
                  <InputViewEdit
                    component={CpfCnpjInput}
                    label={"CPF ou CNPJ"}
                    id="cnpj"
                    name="cnpj"
                    placeholder="Digite o CPF ou CNPJ"
                    value={values.cnpj}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    erroMensagem={errors.cnpj}
                    viewMode={viewMode}
                    required={true}
                    onBlur={() => {
                      let match = values.cnpj.match(/\d+/g);

                      if (match != null && match.join("").length === 11) {
                        this.props.loading(true);
                        userService.existByCpf(values.cnpj).then(response => {
                          if (response.data) {
                            if (values.usuarios.length == 1) {
                              setFieldValue(`usuarios[${0}]`, response.data);
                            }
                          }
                          this.props.loading(false);
                        }, () => {
                          this.props.loading(false);

                        });
                      }
                    }}
                  />
                </FormGroup>
                <FormGroup className="">
                  <InputViewEdit
                    component={FormInput}
                    label={"Nome"}
                    type="text"
                    id="nome"
                    name="nome"
                    placeholder="Digite o nome da cooperativa"
                    value={values.nome}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    erroMensagem={errors.nome}
                    viewMode={viewMode}
                    required={true}
                  />
                </FormGroup>
                <FormGroup className="">
                  <InputViewEdit
                    component={FormInput}
                    label={"Sigla"}
                    type="text"
                    id="sigla"
                    name="sigla"
                    placeholder="Digite a silga da cooperativa"
                    value={values.sigla}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    erroMensagem={errors.silga}
                    viewMode={viewMode}
                    required={true}
                  />
                </FormGroup>
                <FormGroup className="">
                  <InputViewEdit
                    component={FormInput}
                    label={"Código da operadora"}
                    type="text"
                    id="codigoOperadora"
                    name="codigoOperadora"
                    placeholder="Digite o código da operadora"
                    value={values.codigoOperadora}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    erroMensagem={errors.codigoOperadora}
                    viewMode={viewMode}
                    required={true}
                  />
                </FormGroup>
                <FormGroup className="">
                  <InputViewEdit
                    label="Guia Física Obrigatória"
                    component={SelectInput}
                    options={objectsConstants.SIM_NAO}
                    name={"guiaFisicaObrigatoria"}
                    searchable={true}
                    returnFullObject={true}
                    defaultValue={values.guiaFisicaObrigatoria &&
                      values.guiaFisicaObrigatoria.value == false ? 'Não'
                      : formatterHelper.getLabelEnum(
                        values.guiaFisicaObrigatoria &&
                          values.guiaFisicaObrigatoria.value
                          ? values.guiaFisicaObrigatoria.value
                          : values.guiaFisicaObrigatoria,
                        objectsConstants.SIM_NAO
                      )}
                    value={_.isBoolean(values.guiaFisicaObrigatoria) ? formatterHelper.getEnum(values.guiaFisicaObrigatoria, objectsConstants.SIM_NAO) : values.guiaFisicaObrigatoria}
                    labelKey={"label"}
                    valueKey={"value"}
                    viewMode={viewMode}
                    onChange={(name, value) => {
                      setFieldValue(name, value.value);
                    }}
                  />
                </FormGroup>
                  <FormGroup className="">
                      <InputViewEdit
                          label="Reconsulta Automática"
                          component={SelectInput}
                          options={objectsConstants.SIM_NAO}
                          name={"reconsultaAutomatica"}
                          searchable={true}
                          returnFullObject={true}
                          defaultValue={values.reconsultaAutomatica &&
                          values.reconsultaAutomatica.value === false ? 'Não'
                              : formatterHelper.getLabelEnum(
                                  values.reconsultaAutomatica &&
                                  values.reconsultaAutomatica.value
                                      ? values.reconsultaAutomatica.value
                                      : values.reconsultaAutomatica,
                                  objectsConstants.SIM_NAO
                              )}
                          value={_.isBoolean(values.reconsultaAutomatica) ? formatterHelper.getEnum(values.reconsultaAutomatica, objectsConstants.SIM_NAO) : values.reconsultaAutomatica}
                          labelKey={"label"}
                          valueKey={"value"}
                          viewMode={viewMode}
                          onChange={(name, value) => {
                              setFieldValue(name, value.value);
                          }}
                      />
                  </FormGroup>

              </div>
            </CardBody>
          </Card>
        </div>

        <div className="item pt-3">
          <Card className="cardCrud">
            <CardHeader className=" d-flex align-items-center flex-column flex-sm-row b-bottom">
              <div className="flex-grow-1 d-flex align-items-center">
                <div className="mr-sm-auto card-title">Usuários</div>
              </div>
              <div className="buttons-right ml-auto">
                {this.props.showButtonAdd && (
                  <BtnLink
                    type={"button"}
                    className={""}
                    icone={"icon-btn-add"}
                    title={"Adicionar usuário"}
                    onSubmit={e => {
                      // if (this.state.viewMode && values.id) {
                      //   this.addUsuarioAndEdit(setValues, values);
                      // } else {
                      this.addUsuario(setFieldValue, values);
                      // }
                    }}
                    disabled={false}
                  />
                )}
                {/* {!this.state.viewMode && (
                  <Can
                    I={objectsConstants.ALTERAR}
                    a={objectsConstants.CRUDCONSULTORIOS_DADOS}
                    ability={this.props.permissions}
                  >
                    <button
                      type={"button"}
                      className={"btn btn-secondary btn-pequeno mr-3"}
                      onClick={e => {
                        this.cancelEditMode(e, setValues);
                      }}
                    >
                      Cancelar
                    </button>
                    <button
                      type={"button"}
                      onClick={e => {
                        e.stopPropagation();
                        this.usuarioForm.handleSubmit();
                        // validateForm(values).then(errors => {
                        //   if (_.isEmpty(errors)) {
                        //     handleSubmit(values, { setValues });
                        //   } else {
                        //     this.props.error({
                        //       message: messages.ERRO_CRIAR_CRONOGRAMA_CONVENIO
                        //     });
                        //   }
                        // });
                      }}
                      className={"btn btn-primary btn-pequeno mr-2"}
                      icone={"icon-btn-check-black"}
                    >
                      Salvar
                    </button>
                  </Can>
                )} */}
              </div>
            </CardHeader>
            <CardBody className={"form-inside viewMode"}>
              {this.state.viewMode && (
                <div className="row section-form">
                  <h6 className="w-100">Usuários da cooperativa</h6>
                </div>
              )}
              {!this.state.viewMode && (
                <React.Fragment>
                  <div className="row section-form">
                    <h6 className="w-100 verde-destaque">Adicionar usuário</h6>
                  </div>
                  <CooperativaUsuarioForm
                    consultorio={values}
                    wrappedComponentRef={c => (this.usuarioForm = c)}
                    onCreatedUser={usuario =>
                      this.userCreated(setFieldValue, usuario, values)
                    }
                  ></CooperativaUsuarioForm>
                </React.Fragment>
              )}
              {values &&
                values.usuarios &&
                values.usuarios.map((usuario, index) => {
                  return (
                    <React.Fragment
                      key={usuario.cpf ? usuario.cpf : usuario.idKey}
                    >
                      <div className="row section-form">
                        <FormGroup className="">
                          <InputViewEdit
                            component={CpfInput}
                            label="CPF"
                            id="secretariaCpf"
                            name={`usuarios[${index}].cpf`}
                            value={usuario.cpf}
                            placeholder="Digite o CPF"
                            onChange={(name, value) =>
                              setFieldValue(name, value)
                            }
                            noSize={true}
                            viewMode={viewMode}
                            required={true}
                            erroMensagem={_.get(
                              errors,
                              `usuarios[${index}].cpf`
                            )}
                            onBlur={() => {
                              if (usuario.cpf.length) {
                                this.props.loading(true);
                                userService.existByCpf(usuario.cpf).then(
                                  response => {
                                    if (response.data) {
                                      setFieldValue(
                                        `usuarios[${index}]`,
                                        response.data
                                      );
                                    }
                                    this.props.loading(false);
                                  },
                                  () => {
                                    this.props.loading(false);
                                  }
                                );
                              }
                            }}
                          />
                        </FormGroup>
                        <FormGroup className="">
                          <InputViewEdit
                            component={FormInput}
                            label={"Nome"}
                            type="text"
                            id="secretariaNome"
                            name={`usuarios[${index}].nome`}
                            value={usuario.nome}
                            onChange={(name, value) =>
                              setFieldValue(name, value)
                            }
                            noSize={true}
                            placeholder="Digite o nome"
                            viewMode={viewMode}
                            required={true}
                            erroMensagem={_.get(
                              errors,
                              `usuarios[${index}].nome`
                            )}
                            disabled={userService.isCooperado(usuario)}
                          />
                        </FormGroup>
                        <FormGroup className="">
                          <InputViewEdit
                            component={FormInput}
                            label="Senha"
                            type="password"
                            placeholder={"Digite a Senha"}
                            id={`usuarios[${index}].password`}
                            name={`usuarios[${index}].password`}
                            value={usuario.password}
                            required={values.id ? false : true}
                            onChange={(name, value) =>
                              setFieldValue(name, value)
                            }
                            viewMode={viewMode}
                            defaultValue={"**********"}
                            erroMensagem={_.get(
                              errors,
                              `usuarios[${index}].password`
                            )}
                            disabled={userService.isCooperado(usuario)}
                            viewPassword={true}
                            noSize={true}
                          />
                        </FormGroup>
                        {!viewMode && (
                          <FormGroup className="central fixo">
                            <BtnLink
                              type={"button"}
                              className={""}
                              icone={"icon-btn-excluir"}
                              title={"Excluir"}
                              onSubmit={e => {
                                this.removerUsuario(
                                  setFieldValue,
                                  values,
                                  index
                                );
                              }}
                              disabled={false}
                            />

                          </FormGroup>
                        )}
                      </div>
                    </React.Fragment>
                  );
                })}
            </CardBody>
          </Card>
        </div>



        <div className="item">
          {!viewMode && (
            <div className="d-flex flex-row flex-sm-row pt-2 pb-3 justify-content-center">
              <button
                type="submit"
                className="btn btn-primary d-blokc d-sm-none"
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                Salvar
              </button>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
const mapDispatch = ({
  alert: { success, error, clear, info },
  load: { loading }
}) => ({
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  clear: () => clear(),
  info: msg => info(msg)
});

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(CooperativaForm));
