import { urlsConstants } from "../constants/url.constant";
import { defaultService } from './defaultService';

export const convenioUsuarioService = {
    findTableColumns,
    findSearchableColumns,
    findByFilter,
    ativarUsuario,
    inativarUsuario,
    vincularUsuario,
    exportToExcel,
    findByConvenioAndUsuario
};

function findTableColumns() {
    return defaultService.doGet(`${urlsConstants.CONVENIO_USUARIO}list/fields`);
}

function findSearchableColumns() {
    return defaultService.doGet(`${urlsConstants.CONVENIO_USUARIO}search/fields`);
}

function findByFilter(options, filter): Promise<Array<>> {
    return defaultService.findByFiltro(
        `${urlsConstants.CONVENIO_USUARIO}list`,
        options,
        filter
    );
}

function ativarUsuario(params) {
    return defaultService.doPut(`${urlsConstants.CONVENIO_USUARIO}ativarConvenioCooperado`, params);
}

function inativarUsuario(params) {
    return defaultService.doPut(`${urlsConstants.CONVENIO_USUARIO}inativarConvenioCooperado`, params);
}

function vincularUsuario(params) {
    return defaultService.doPut(`${urlsConstants.CONVENIO_USUARIO}vincularConvenioCooperado`, params);

}

function findByConvenioAndUsuario(convenioId, usuarioId) {
    return defaultService.doPost(`${urlsConstants.CONVENIO_USUARIO}findByConvenioAndUsuario/${convenioId}/${usuarioId}`);
}

function exportToExcel(filter, fields, labels) {
    return defaultService.exportToExcel("convenioUsuario", filter, fields, labels);
}