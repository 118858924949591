//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import UsuarioEdit from '../usuarios/UsuarioEdit';
import PerfilView from './PerfilView';
export default class Perfil extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/perfil/edit/:id" component={UsuarioEdit}  />
          <Route path="/perfil/view/:id" component={PerfilView}  />
         
        </Switch>
      </React.Fragment>
    );
  }
}
