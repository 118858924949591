import { defaultService } from "./defaultService";
import { urlsConstants } from "../constants/url.constant";

export const mensagemService = {
    doGet,
    findAll,
    findTableColumns,
    findSearchableColumns,
    findByFilter,
    doSave,
    doDelete,
    checkPrazo,
    exportToExcel,
    findByCooperado
};

async function doGet(id) {
    return defaultService.doGet(`${urlsConstants.MENSAGEM}find/${id}`)
}

function findAll() {
    return defaultService.doGet(`${urlsConstants.MENSAGEM}list`);
}

function findTableColumns() {
    return defaultService.doGet(`${urlsConstants.MENSAGEM}list/fields`);
}

function findSearchableColumns() {
    return defaultService.doGet(`${urlsConstants.MENSAGEM}search/fields`);
}

function doSave(values) {
    return defaultService.doSave(`${urlsConstants.MENSAGEM}save/`, values);
}

function doDelete(values) {
    return defaultService.doPost(`${urlsConstants.MENSAGEM}delete`, values);
}

function checkPrazo(values) {
    return defaultService.doPost(`${urlsConstants.MENSAGEM}checkPrazo`, values);
}

function findByFilter(options: Options, filter: any): Promise<Array<>> {
    return defaultService.findByFiltro(
        `${urlsConstants.MENSAGEM}list`,
        options,
        filter
    );
}



function exportToExcel(filter, fields, labels) {
    return defaultService.exportToExcel('mensagem', filter, fields, labels);
}

function findByCooperado(parent) {
    return defaultService.doPost(`${urlsConstants.MENSAGEM}findByCooperado/${parent}`);
}