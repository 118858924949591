//import { Can } from "@casl/react";
//import { Formik } from "formik";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
//import { Collapse } from "reactstrap";
//import * as Yup from "yup";
//import BtnLink from "../../components/Buttons/BtnLink";
//import CheckBoxInput from "../../components/Inputs/CheckBoxInput";
//import { objectsConstants } from "../../constants/objects.constants";
//import { translate } from "../../helpers/message.helper";
//import { atendimentoService } from "../../services/atendimento.service";
//import { ConfirmAcationService } from "../../services/ConfirmAcationService";
//import { defaultService } from "../../services/defaultService";
//import TopoTitleComponente from "../home/TopoTitleComponente";
import DadosAutorizacaoAtendimentoGuiaForm from "./componentes/DadosAutorizacaoAtendimentoGuiaForm";
import DadosBeneficiarioGuiaForm from "./componentes/guiaHonorarios/DadosBeneficiarioGuiaForm";
import DadosContratadoGuiaForm from "./componentes/guiaHonorarios/DadosContratadoGuiaForm";
import DadosExecucaoGuiaHonorarioForm from "./componentes/guiaHonorarios/DadosExecucaoGuiaHonorarioForm";
import DadosInternacaoGuiaForm from "./componentes/guiaHonorarios/DadosInternacaoGuiaForm";
import DadosProfissionalExecutanteGuiaForm from "./componentes/guiaHonorarios/DadosProfissionalExecutanteGuiaForm";
import DadosSolicitanteGuiaForm from "./componentes/guiaHonorarios/DadosSolicitanteGuiaForm";
import ValoresProcedimentosGuiaForm from "./componentes/guiaHonorarios/ValoresProcedimentosGuiaForm";
import PassoaPassoItem from "./PassoaPassoItem";
//import DadosConvenioConsultaForm from "./componentes/guiaConsulta/DadosConvenioConsultaForm";

class GuiaHonorariosForm extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      criarGuiaPassoPasso,
      errors,
      setFieldValue,
      values,
      handleChange,
      setValues,
      viewMode,
      etapaAtual,
      changeGuia,
      ...otherProps
    } = this.props;

    return (
      <React.Fragment>
        <PassoaPassoItem
          etapa={1}
          atendimento={values}
          etapaAtual={etapaAtual}
          criarGuiaPassoPasso={criarGuiaPassoPasso}
        >
          <DadosAutorizacaoAtendimentoGuiaForm
            errors={errors}
            setFieldValue={setFieldValue}
            setValues={setValues}
            values={values}
            user={this.props.user}
            handleChange={handleChange}
            viewMode={viewMode}
            atendimento={this.props.atendimento}
          />
          <DadosBeneficiarioGuiaForm
            errors={errors}
            setValues={setValues}
            className={"section-form"}
            setFieldValue={setFieldValue}
            values={values}
            handleChange={handleChange}
            viewMode={viewMode}
            changeGuia={changeGuia}
          />
        </PassoaPassoItem>
        <PassoaPassoItem
          etapa={2}
          atendimento={values}
          etapaAtual={etapaAtual}
          criarGuiaPassoPasso={criarGuiaPassoPasso}
        >
          <DadosSolicitanteGuiaForm
            errors={errors}
            className={"section-form"}
            setFieldValue={setFieldValue}
            values={values}
            handleChange={handleChange}
            viewMode={viewMode}
            user={this.props.user}
          />
          <DadosContratadoGuiaForm
            errors={errors}
            className={"section-form"}
            setFieldValue={setFieldValue}
            values={values}
            handleChange={handleChange}
            viewMode={viewMode}
          />
          <DadosInternacaoGuiaForm
            errors={errors}
            className={"section-form"}
            setFieldValue={setFieldValue}
            values={values}
            handleChange={handleChange}
            viewMode={viewMode}
            user={this.props.user}
          />

        </PassoaPassoItem>
        <PassoaPassoItem
          etapa={3}
          atendimento={values}
          etapaAtual={etapaAtual}
          criarGuiaPassoPasso={criarGuiaPassoPasso}
        >

          <DadosProfissionalExecutanteGuiaForm
            className={"section-form"}
            setFieldValue={setFieldValue}
            values={values}
            errors={errors}
            handleChange={handleChange}
            viewMode={viewMode}
            listUfsConselho={this.props.listUfsConselho}
            listConselho={this.props.listConselho}
            setValues={setValues}
            {...otherProps}
          />

          <DadosExecucaoGuiaHonorarioForm
            errors={errors}
            className={"section-form"}
            setFieldValue={setFieldValue}
            values={values}
            handleChange={handleChange}
            viewMode={viewMode}
            setValues={setValues}
            {...otherProps}
          />


        </PassoaPassoItem>

        <PassoaPassoItem
          etapa={4}
          atendimento={values}
          etapaAtual={etapaAtual}
          criarGuiaPassoPasso={criarGuiaPassoPasso}
        >

          <div className="w-100 h-20"></div>
          <ValoresProcedimentosGuiaForm
            errors={errors}
            setFieldValue={setFieldValue}
            values={values}
            handleChange={handleChange}
            viewMode={viewMode}
          />

        </PassoaPassoItem>

        <PassoaPassoItem
          etapa={5}
          atendimento={values}
          etapaAtual={etapaAtual}
          criarGuiaPassoPasso={criarGuiaPassoPasso}
        >
          {/* Obersavacoes e anexo */}

        </PassoaPassoItem>




      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  pendencia: { togglePendencia, atendimentoAtual }
}) => ({
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  togglePendencia: () => togglePendencia(),
  atendimentoAtual: atendimento => atendimentoAtual(atendimento)
});

function mapStateToProps(state) {
  const { pendenciaOpen, idAtendimentoAtual } = state.pendencia;
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;
  const { filtroAtendimento } = state.filtro;

  return {
    pendenciaOpen,
    idAtendimentoAtual,
    loggedIn,
    alert,
    user,
    permissions,
    filtroAtendimento
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(GuiaHonorariosForm));
