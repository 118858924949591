//@flow
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import React, { PureComponent } from 'react';
import { InputType, Label } from 'reactstrap';
import { ToolTipFeedBack } from '../Utils/ToolTipFeedBack';
import './FormTextCKEditor.css';


type Props = {
  label: string,
  type: InputType,
  name: string,
  id: string,
  value?: string | string[] | number,
  size?: number,
  onChange?: any,
  onBlur?: any,
  children?: any,
  required: boolean,
  placeholder: string,
  disabled: boolean,
  maxLength?: number,
  style?: any,
  messageValidate?: string
};

class UploadAdapter {
  constructor(loader) {
    this.loader = loader;
    this.upload = this.upload.bind(this)
    this.abort = this.abort.bind(this)
  }



  abort() {
    // Reject promise returned from upload() method.
  }
}

export default class FormTextCKEditor extends PureComponent<Props> {
  _input: any;

  static defaultProps = {
    required: false,
    size: 12,
    disabled: false

  };

  getInput = () => {
    return this._input;
  };

  componentWillReceiveProps(nextProps: Props, nextContext: *): * {

    if (nextProps.value && nextProps.value !== this.props.value) {

    }

  }

  render() {
    let { messageValidate, name, } = this.props;

    // LISTAR TODOS OS PLUGINS DO CK EDITOR
    let plugins = ClassicEditor.builtinPlugins.map(plugin => plugin.pluginName);

    const MyCustomUploadAdapterPlugin = (editor) => {

      editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new UploadAdapter(loader);
      };
    }

    if (this.props.erroMensagem) {
      messageValidate = this.props.erroMensagem;
    }

    return (
      <div id={this.props.id}
        className={`${this.props.className && this.props.className}
             ${this.props.required ? "input-required" : ""} ${messageValidate ? 'is-invalid' : ''}`}>

        <div ref={this._editor} />
        {this.props.label && (
          <React.Fragment>
            <Label for={this.props.name}>
              {this.props.label}
              {this.props.required && '*'}
            </Label>{' '}

          </React.Fragment>
        )}
        <CKEditor
          editor={ClassicEditor}
          id={"editor_" + this.props.id}
          data={this.props.value}
          config={{
            el: this._editor,
            placeholder: this.props.placeholder,
            removePlugins: ['Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed']
            
          }}
          onBlur={this.props.onBlur}
          onChange={(event, editor) => {
            this.props.onChange(name, editor.getData());
          }}
          required={this.props.required}
          disabled={this.props.disabled ? this.props.disabled : false}
          onReady={editor => {
            if(editor&&editor.setData) {
              editor.setData(this.props.value || "")
            }
          }}

        />
        {messageValidate && (
          <ToolTipFeedBack
            target={this.props.id}
            messageValidate={messageValidate}
          />
        )}
      </div>
    );
  }
}