import _ from 'lodash';
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FormGroup } from 'reactstrap';
import FormInput from "../../../components/Inputs/FormInput";
import FormSelectInput from "../../../components/Inputs/FormSelectInput";
import InputViewEdit from "../../../components/Inputs/InputViewEdit";
import SelectInput from "../../../components/Inputs/SelectInput";
import { objectsConstants } from "../../../constants/objects.constants";
import SelectableInput from "../../../sascComponents/inputs/SelectableInput";
import { atendimentoService } from "../../../services/atendimento.service";
import { enumService } from "../../../services/enum.service";
import { regimeService } from '../../../services/regime.service';
class DadosAtendimentoGuiaForm extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
  }

  verificaRetorno = () => {   
    this.props.verificaRetorno(`tipoConsulta`)
  };

  sendToStorange = (values) => {
    try {
      values.rascunho && localStorage.setItem('rascunho', JSON.stringify(values))
    } catch (error) {
      if (error instanceof DOMException && (
        error.code === 22 ||
        error.code === 1014 ||
        error.name === 'QuotaExceededError' ||
        error.name === 'NS_ERROR_DOM_QUOTA_REACHED'
      )) {
        console.error('Limite de armazenamento local atingido');
      } else {
        console.error('Erro ao salvar no armazenamento local:', error.message);
      }
    }
  }

  render() {
    const {
      permissions,
      setFieldValue,
      values,
      handleChange,
      viewMode,
      errors
    } = this.props;

    return (
      <div className="row section-form">
        <h6 className="w-100">Dados do Atendimento</h6>
        <SelectableInput viewMode={viewMode} atendimento={values} className="fixo mobile-100"
          name={"tipoAtendimento"}
          label={"Tipo de atendimento"}
          value={values.tipoAtendimento && values.tipoAtendimento.description}
        >
          <InputViewEdit
            label="Tipo de atendimento"
            component={FormSelectInput}
            service={enumService.findTipoAtendimento}
            className={"destaque"}
            name={"tipoAtendimento"}
            defaultValue={
              values.tipoAtendimento ? values.tipoAtendimento.description : "--"
            }
            returnFullObject={true}
            searchable={true}
            labelKey={"description"}
            valueKey={"name"}
            value={values.tipoAtendimento}
            viewMode={viewMode}
            disabled={values&&values.hospitalLocal&&values.hospitalLocal.disableHoraInicioFimExecucao}
            onChange={(name, value) => {
              setFieldValue(name, value);
            }}
            alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
            onBlur={() => this.sendToStorange(values)}
          />
        </SelectableInput>
        <FormGroup className="fixo mobile-100">
          <InputViewEdit
            label="Regime de atendimento"
            component={FormSelectInput}
            service={regimeService.findAll}
            className={"destaque"}
            name={"regimeAtendimento"}
            defaultValue={
              values.regimeAtendimento ? values.regimeAtendimento.nome : "--"
            }
            returnFullObject={true}
            searchable={true}
            labelKey={"nome"}
            valueKey={"id"}
            value={values.regimeAtendimento}
            viewMode={viewMode}
            onChange={(name, value) => {
              setFieldValue(name, value);
            }}
            alertMensagem={values.convenio ? values.convenio.listRegraMensagemTipoGuia : null}
            onBlur={() => this.sendToStorange(values)}
          />
        </FormGroup>
        <SelectableInput viewMode={viewMode} atendimento={values} className=""
          name={"indicacaoAcidente"}
          label={"Indicação de Acidente (acidente ou doença relacionada)"}
          value={values.indicacaoAcidente}
        >

          <InputViewEdit
            label="Indicação de Acidente (acidente ou doença relacionada)"
            placeholder=""
            erroMensagem={errors.indicacaoAcidente}
            component={FormSelectInput}
            service={atendimentoService.findIndicacaoAcidente}
            labelKey={"description"}
            valueKey={"name"}
            onChange={(name, date) => {
              setFieldValue(name, date);
            }}
            onBlur={() => this.sendToStorange(values)}
            returnFullObject={true}
            defaultValue={
              values.indicacaoAcidente
                ? values.indicacaoAcidente.description
                : "--"
            }
            className={""}
            required={false}
            name={"indicacaoAcidente"}
            id={"indicacaoAcidente"}
            type={"text"}
            value={values.indicacaoAcidente}
            viewMode={viewMode}
            alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
            defaultSelected={true}
          />
        </SelectableInput>
        <SelectableInput viewMode={viewMode} atendimento={values} className="fixo mobile-100"
          name={"tipoConsulta"}
          label={"Tipo de consulta"}
          value={values.tipoConsulta && values.tipoConsulta.description}
        >
          <InputViewEdit
            label="Tipo de consulta"
            component={SelectInput}
            required={false}
            disabled={values.cooperativa.reconsultaAutomatica}
            options={_.filter(objectsConstants.TIPOS_CONSULTA,(o) => {if(this.props.user.cooperativa.tipoConsultaNaoDisponiveis){for(var i=0;i<this.props.user.cooperativa.tipoConsultaNaoDisponiveis.length;i++){if(this.props.user.cooperativa.tipoConsultaNaoDisponiveis[i].name==o.name){return false;}  }} return true})}
            className={"destaque"}
            name={"tipoConsulta"}
            id={"tipoConsulta1"}
            erroMensagem={
              errors && errors.tipoConsulta
            }
            returnFullObject={true}
            searchable={true}
            defaultValue={
              values.tipoConsulta && values.tipoConsulta.description
            }
            labelKey={"description"}
            valueKey={"name"}
            value={values.tipoConsulta}
            viewMode={viewMode}
            onChange={(name, value) => {
              setFieldValue(name, value);
            }}
            onBlur={this.verificaRetorno}
            alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
          />
        </SelectableInput>
        <SelectableInput viewMode={viewMode} atendimento={values} className=""
          name={"motivoEncerramentoAtendimento"}
          label={"Motivo de encerramento do Atendimento"}
          value={values.motivoEncerramentoAtendimento}
        >
          <InputViewEdit
            label="Motivo de encerramento do Atendimento"
            component={FormInput}
            onChange={(name, date) => {
              setFieldValue(name, date);
            }}
            onBlur={() => this.sendToStorange(values)}
            name={"motivoEncerramentoAtendimento"}
            placeholder=""
            type={"text"}
            viewMode={viewMode}
            value={values.motivoEncerramentoAtendimento}
            alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
          />
        </SelectableInput>
      </div>
    );
  }
}

const mapDispatch = ({ menu: { showMenu, hideMenu } }) => ({
  showMenu: () => showMenu(),
  hideMenu: () => hideMenu()
});

function mapStateToProps(state) {
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    alert,
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(DadosAtendimentoGuiaForm));
