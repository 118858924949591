import React, { PureComponent } from 'react';
import { Label } from 'reactstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './FormTextCKEditor.css';
import { ToolTipFeedBack } from '../Utils/ToolTipFeedBack';

type Props = {
  label: string,
  name: string,
  id: string,
  value?: string,
  required: boolean,
  placeholder: string,
  disabled: boolean,
  erroMensagem?: string,
  onChange: (name: string, value: string) => void,
  onBlur?: any,
};

type State = {
    charCount: number
  };

class FormTextCKEditor extends PureComponent<Props> {
  constructor(props) {
    super(props);
    this.state = {
      previousValue: props.value || '',
    };
  }



  handleEditorChange = (event, editor) => {
    let data = editor.getData();

    const textOnly = data.replace(/<[^>]*>/g, '').replace(/&[^;]+;/g, '');

    const maxLength = this.props.maxLength;
    const charCount = textOnly.length;

    if (charCount > maxLength) {
      //data = textOnly.substring(0, maxLength);
      //editor.setData(data);
    }

    this.setState({ previousValue: data }, () => {
      this.props.onChange(this.props.name, data);
    });
    this.setState({ charCount });
};

  
  

  render() {
    const {
      label,
      name,
      id,
      value,
      required,
      placeholder,
      disabled,
      erroMensagem,
      onBlur,
      maxLength
    } = this.props;
    const { charCount } = this.state;

    return (
      <div id={id} className={`${required ? 'input-required' : ''} ${erroMensagem ? 'is-invalid' : ''}`}>
        {label && (
          <Label for={name}>
            {label}
            {required && '*'}
          </Label>
        )}
        <CKEditor
          editor={ClassicEditor}
          data={value}
          config={{
            placeholder,
          }}
          onBlur={onBlur}
          onChange={this.handleEditorChange}
          required={required}
          disabled={disabled}
        />
       {maxLength && (
      <div style={{ fontSize: '12px', color: charCount > maxLength ? 'red' : '#999' }}>
        Limite de caracteres: {charCount}/{maxLength}
      </div>
      )}
       
        {erroMensagem && <ToolTipFeedBack target={id} messageValidate={erroMensagem} />}
      </div>
    );
  }
}

export default FormTextCKEditor;
