//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import DescricoesPendenciaList from './DescricoesPendenciaList';
export default class DescricoesPendencia extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/descricoesPendencia" component={DescricoesPendenciaList} exact />
        </Switch>
      </React.Fragment>
    );
  }
}
