//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import JustificativaList from './JustificativaList';
export default class Justificativas extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/justificativas" component={JustificativaList} exact />
        </Switch>
      </React.Fragment>
    );
  }
}
