export const mascaraEmail = {
  insereMascaraEmail
};

function insereMascaraEmail(email) {
  if (email && typeof email !== 'undefined' && email !== '' && email !== undefined) {
    return email.replace(/(.{3})(.*)(?=@)/,
      function (gp1, gp2, gp3) {
        for (let i = 0; i < gp3.length; i++) {
          gp2 += "*";
        } return gp2;
      });
  } else {
    return '';
  }

}

