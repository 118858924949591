import _ from "lodash";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import InputViewEdit from "../../../components/Inputs/InputViewEdit";
import MoneyInput from "../../../components/Inputs/MoneyInput";
import { formatterHelper } from "../../../helpers/formatter.helper";
import SelectableInput from "../../../sascComponents/inputs/SelectableInput";
import { FormGroup } from "../../../components/FormGroup";
import FormTextArea from "../../../components/Inputs/FormTextArea";
import AtendimentoAnexos from "../../../components/Inputs/AtendimentoAnexos";
import SelectInput from "../../../components/Inputs/SelectInput";
import { objectsConstants } from "../../../constants/objects.constants";
import CheckBoxInput from "../../../components/Inputs/CheckBoxInput";

class ValoresProcedimentosGuiaForm extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      openAnexo: false,
    };
  }
    toggleAnexos = () => {
        if(!this.props.viewMode) {
            this.setState(({openAnexo}) => ({openAnexo: !openAnexo}));
        }
    };

  atualizaAnexos = values => {}

  getValorProcedimentos = () => {
    const { values } = this.props;
    let totalProcedimentos = this.getValorProcedimento(values);

    return formatterHelper.formatMoney(totalProcedimentos, 2, ",", ".");
  };
  getValorProcedimento(values) {
    let totalProcedimentos = 0;
    _.map(values.atendimentoExecucaos, function (procedimento) {
      if (
        procedimento &&
        procedimento.valorUnitario &&
        procedimento.fator &&
        procedimento.quantAutorizada
      ) {
        let valorProcedimento =
          procedimento.valorUnitario *
          procedimento.fator *
          procedimento.quantAutorizada;
        totalProcedimentos += isNaN(valorProcedimento) ? 0 : valorProcedimento;
      }
    });
    return totalProcedimentos;
  }

  getTotalGeral = () => {
    const { values } = this.props;
    let totalProcedimentos = this.getValorProcedimento(values);
    totalProcedimentos += isNaN(values.totalTaxasAlugueis)
      ? 0
      : values.totalTaxasAlugueis;
    totalProcedimentos += isNaN(values.totalMateriais)
      ? 0
      : values.totalMateriais;
    totalProcedimentos += isNaN(values.totalCPME) ? 0 : values.totalCPME;
    totalProcedimentos += isNaN(values.totalMedicamentos)
      ? 0
      : values.totalMedicamentos;
    totalProcedimentos += isNaN(values.totalGasesMedicinais)
      ? 0
      : values.totalGasesMedicinais;
    return formatterHelper.formatMoney(totalProcedimentos, 2, ",", ".");
  };

  sendToStorange = (values) => {
    try {
      values.rascunho && localStorage.setItem('rascunho', JSON.stringify(values))
    } catch (error) {
      if (error instanceof DOMException && (
        error.code === 22 ||
        error.code === 1014 ||
        error.name === 'QuotaExceededError' ||
        error.name === 'NS_ERROR_DOM_QUOTA_REACHED'
      )) {
        console.error('Limite de armazenamento local atingido');
      } else {
        console.error('Erro ao salvar no armazenamento local:', error.message);
      }
    }
  }

  render() {
    const { setFieldValue, values, viewMode, handleChange ,errors} = this.props;

    return (
        <React.Fragment>
      <div className="row section-form">
        <FormGroup className="">
          <label>Total do procedimento</label>
          <p>{this.getValorProcedimentos()}</p>
        </FormGroup>
        <SelectableInput viewMode={viewMode} atendimento={values} className="fixo5"
          name={`totalTaxasAlugueis`}
          label={"Total de taxas e aluguéis"}
          value={values.totalTaxasAlugueis}
        >
          <InputViewEdit
            label={"Total de taxas e aluguéis"}

            component={MoneyInput}
            onChange={(name, date) => {
              setFieldValue(name, date);
            }}
            onBlur={() => this.sendToStorange(values)}
            name={`totalTaxasAlugueis`}
            placeholder=""
            viewMode={viewMode}
            value={values.totalTaxasAlugueis}
            defaultValue={formatterHelper.formatMoney(values.totalTaxasAlugueis, 2, ",", ".")}
            alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
          />
        </SelectableInput>
        <SelectableInput viewMode={viewMode} atendimento={values} className="fixo6"
          name={"totalMateriais"}
          label={"Total de materiais"}
          value={values.totalMateriais}
        >
          <InputViewEdit
            label={"Total de materiais"}

            component={MoneyInput}
            onChange={(name, date) => {
              setFieldValue(name, date);
            }}
            onBlur={() => this.sendToStorange(values)}
            name={`totalMateriais`}
            placeholder=""
            viewMode={viewMode}
            value={values.totalMateriais}
            defaultValue={formatterHelper.formatMoney(values.totalMateriais, 2, ",", ".")}
            alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}

          />
        </SelectableInput>
        <SelectableInput viewMode={viewMode} atendimento={values} className=""
          name={"totalCPME"}
          label={"Total de OPME"}
          value={values.totalCPME}
        >
          <InputViewEdit
            label={"Total de OPME"}
            component={MoneyInput}
            onChange={(name, date) => {
              setFieldValue(name, date);
            }}
            onBlur={() => this.sendToStorange(values)}
            name={`totalCPME`}
            placeholder=""
            viewMode={viewMode}
            value={values.totalCPME}
            defaultValue={formatterHelper.formatMoney(values.totalCPME, 2, ",", ".")}
            alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}

          />
        </SelectableInput>
        <SelectableInput viewMode={viewMode} atendimento={values} className="fixo5"
          name={"totalMedicamentos"}
          label={"Total de medicamentos"}
          value={values.totalMedicamentos}
        >
          <InputViewEdit
            label={"Total de medicamentos"}
            component={MoneyInput}
            onChange={(name, date) => {
              setFieldValue(name, date);
            }}
            onBlur={() => this.sendToStorange(values)}
            name={`totalMedicamentos`}
            placeholder=""
            viewMode={viewMode}
            value={values.totalMedicamentos}
            defaultValue={formatterHelper.formatMoney(values.totalMedicamentos, 2, ",", ".")}
            alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}

          />
        </SelectableInput>
        <SelectableInput viewMode={viewMode} atendimento={values} className="fixo5"
          name={"totalGasesMedicinais"}
          label={"Total de gases medicinais"}
          value={values.dataValidadeCarteira}
        >
          <InputViewEdit
            label={"Total de gases medicinais"}
            component={MoneyInput}
            onChange={(name, date) => {
              setFieldValue(name, date);
            }}
            onBlur={() => this.sendToStorange(values)}
            name={`totalGasesMedicinais`}
            placeholder=""
            viewMode={viewMode}
            value={values.totalGasesMedicinais}
            defaultValue={formatterHelper.formatMoney(values.totalGasesMedicinais, 2, ",", ".")}
            alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}

          />
        </SelectableInput>
        <FormGroup className="fixo mobile-100">
          <label>Total geral</label>
          <p>{this.getTotalGeral()}</p>
        </FormGroup>
        <div className="quebra"></div>
        <SelectableInput viewMode={viewMode} atendimento={values} className="anexoObservacaoConsulta"
          name={"observacao"}
          label={"Observação / Justificativa"}
          value={values.observacao}
        >
          <InputViewEdit
            noSize={true}
            id={"observacao"}
            label={"Observação / Justificativa"}
            component={FormTextArea}
            className={'textArea'}
            type={'textarea'}
            onChange={handleChange}
            name={`observacao`}
            placeholder=""
            viewMode={viewMode}
            value={values.observacao}
            required={values.obersavaoObrigatoria}
            onBlur={() => this.sendToStorange(values)}
            erroMensagem={errors.observacao}
            alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}

          />
        </SelectableInput>
          <FormGroup className="anexoFixo">
          <div className="anexoSimNao">
             <InputViewEdit
              label="Anexo encaminhado fisicamente para a cooperativa?"
              component={SelectInput}
              options={objectsConstants.SIM_NAO}
              name={"anexoEncaminhado"}
              erroMensagem={errors.anexoEncaminhado}
              searchable={true}
              returnFullObject={true}
              defaultValue={values.anexoEncaminhado &&
                values.anexoEncaminhado.value === false ? 'Não'
                : formatterHelper.getLabelEnum(
                  values.anexoEncaminhado &&
                    values.anexoEncaminhado.value
                    ? values.anexoEncaminhado.value
                    : values.anexoEncaminhado,
                  objectsConstants.SIM_NAO
                )}
              value={_.isBoolean(values.anexoEncaminhado) ? formatterHelper.getEnum(values.anexoEncaminhado, objectsConstants.SIM_NAO) : values.anexoEncaminhado}
              labelKey={"label"}
              valueKey={"value"}
              viewMode={viewMode}
              onChange={(name, value) => {
                setFieldValue(name, value.value);
              }}
              onBlur={() => this.sendToStorange(values)}
              alertMensagem={values.convenio ? values.convenio.listRegraMensagemTipoGuia : null}
            />
              <label>Anexos - Guia física</label>
              <SelectableInput viewMode={viewMode} atendimento={values} className=""
                               name={"atendimentoAnexos"}
                               label={"Anexos - Guia física"}
                               value={
                                   values.atendimentoAnexos
                               }
              >

                  <InputViewEdit
                      component={AtendimentoAnexos}
                      toggleAnexos={this.toggleAnexos}
                      errors={errors}
                      values={values}
                      name={"atendimentoAnexos"}
                      atualizaAnexos={this.atualizaAnexos}
                      onChange={(name, anexos) => {

                          setFieldValue(name, anexos);
                      }}
                      openAnexo={this.state.openAnexo}
                      user={this.props.user}
                     modeView={viewMode}
                      anexosInputRefCallback={anexos => (this._anexosInput = anexos)}
                  />

              </SelectableInput>
              <div><label>{values.atendimentoAnexos 
                           && values.atendimentoAnexos.length > 0
                           && values.atendimentoAnexos[0].midia.dataCadastro
                           ? "Data de inclusão do anexo: " + formatterHelper.converterFormatoData(values.atendimentoAnexos[0].midia.dataCadastro):""}
                   </label></div>
              </div>
          </FormGroup>
      </div>
      
            <div className="row section-form">
                {values.observacaoSeparacaoGuia && viewMode&& (<FormGroup className="mobile-100">
                        <label>Observação guia com procedimento separado</label>


                        <p>{values.observacaoSeparacaoGuia}</p>
                    </FormGroup>
                )}
            </div>

            <FormGroup>
               {values.cooperativa && values.cooperativa.permiteEnvioEletronico && <div className="row section-form"><CheckBoxInput
                labelClass={"font-13px verde-destaque font-bold"}
                label={"Anexada guia com assinaturas do médico, paciente ou responsável e comprovantes"}
                name={"envioEletronico"}
                id={"envioEletronico"}
                disabled={viewMode}
                checked={values.envioEletronico}
                errors={errors}
                erroMensagem={errors.envioEletronico}
                onChange={(name, value) => {
                  setFieldValue(name, value);
                }}
              /></div>}
              </FormGroup>

        </React.Fragment>
    );
  }
}

const mapDispatch = ({ menu: { showMenu, hideMenu } }) => ({
  showMenu: () => showMenu(),
  hideMenu: () => hideMenu()
});

function mapStateToProps(state) {
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    alert,
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(ValoresProcedimentosGuiaForm));
