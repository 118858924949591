//@flow
import * as React from 'react';

export class FormGroup extends React.Component<{ children?: React.Node }, {}> {
  render() {
      const {className,...nextProps}=this.props;
    return (      
      <div className={`form-group ${className}`} {...nextProps}>
        {this.props.children}
      </div>
    );
  }
}
