//@flow
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { Bar } from "@nivo/bar";
import { Pie } from '@nivo/pie';
import Datatable from '../../../sascComponents/datagrid/Datatable';
import { examePacoteExameService } from '../../../services/examePacoteExame.service';
import { Card, CardBody, CardHeader, FormGroup } from 'reactstrap';
import InputViewEdit from '../../../components/Inputs/InputViewEdit';
import EspecialidadesSelectInput from '../../../sascComponents/especialidades/EspecialidadesSelectInput';
import _ from 'lodash';
import DatePickerInput from '../../../components/Inputs/DatePickerInput';
import { dateHelper } from '../../../helpers/date.helper';
import ConvenioSelectInput from '../../../sascComponents/convenios/ConvenioSelectInput';
import BtnLink from '../../../components/Buttons/BtnLink';
import { relatoriosService } from '../../../services/relatorios.service';
import download from 'downloadjs';
import ZipCodeInput from '../../../components/Inputs/ZipCodeInput';
import FormInput from '../../../components/Inputs/FormInput';
import SelectInput from '../../../components/Inputs/SelectInput';
import { estabelecimentoService } from '../../../services/estabelecimento.service';

class DashRegiaoEspecialidade extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
        this.state = {
            especialidadeProcedimentoQuantidade: []
        }

    }

    retriveEspecialidadeProcedimentoQuantidade = (filter) => {
        examePacoteExameService.listEspecialidadeProcedimentoQuantidade(filter).then(
            response => {
                if (response.data) {
                    let data = [];
                    response.data.sort((a, b) => a.quantidade - b.quantidade);
                    response.data.map((especialidade) => {
                        let item = { data: '', Especialidade: '' }
                        item.data = especialidade.nome
                        item.Especialidade = especialidade.quantidade
                        data.push(item)
                    })
                    this.setState({ especialidadeProcedimentoQuantidade: data })
                }
            },
            (e) => {
                console.error(e);
            }
        );
    }

    render() {
        const commonProps = {
            width: 900,
            height: 900,
            margin: { top: 60, right: 110, bottom: 350, left: 80 },
            indexBy: "data",
            keys: ["Especialidade"],
            padding: 0.2,
            labelTextColor: "white",
            labelSkipWidth: 16,
            labelSkipHeight: 16,
        };
        const colors = { Especialidade: "green" };
        const {
            values,
            setFieldValue,
            errors,
            handleChange,
            isSubmitting,
            handleSubmit,
            viewMode,
            validateForm,
            setValues
        } = this.props;
        return (

            <React.Fragment>
                <div className="item d-flex width-100">
                    <Card className="cardCrud content-form width-100">
                        <CardBody className={"form-inside viewMode"}>
                            <div className="row section-form">
                                <FormGroup className="col">
                                    <InputViewEdit
                                        label={"Data início"}
                                        component={DatePickerInput}
                                        onChange={(name, date) => {
                                            setFieldValue(name, date);
                                        }}
                                        erroMensagem={
                                            _.get(errors, `dataInicio`)
                                        }
                                        defaultValue={
                                            values.dataInicio
                                                ? dateHelper.format(values.dataInicio)
                                                : "--"
                                        }
                                        mask={"99/99/9999"}
                                        name={"dataInicio"}
                                        id={"dataInicio"}
                                        placeholder=""
                                        required={true}
                                        type={"text"}
                                        viewMode={false}
                                        value={values.dataInicio}
                                    />
                                </FormGroup>
                                <FormGroup className="col">
                                    <InputViewEdit
                                        label={"Data fim"}
                                        component={DatePickerInput}
                                        onChange={(name, date) => {
                                            setFieldValue(name, date);
                                        }}
                                        erroMensagem={
                                            _.get(errors, `dataFim`)
                                        }
                                        defaultValue={
                                            values.dataFim
                                                ? dateHelper.format(values.dataFim)
                                                : "--"
                                        }
                                        mask={"99/99/9999"}
                                        name={"dataFim"}
                                        id={"dataFim"}
                                        placeholder=""
                                        required={true}
                                        type={"text"}
                                        viewMode={false}
                                        value={values.dataFim}
                                    />
                                </FormGroup>
                                
                            </div>
                            <div className="row section-form">
                                <FormGroup>
                                    <InputViewEdit
                                        component={EspecialidadesSelectInput}
                                        label={"Especialidades"}
                                        name="especialidades"
                                        id="especialidades"
                                        multi={true}
                                        placeholder="Selecione as especialidades"
                                        onChange={(name, value) => {
                                            setFieldValue(name, value);
                                        }}
                                        returnFullObject={true}
                                        value={values.especialidades}
                                        className="sasc-select"
                                        minLength={5}
                                        erroMensagem={errors.especialidades}
                                        defaultValue={
                                            (values &&
                                                values.especialidades &&
                                                values.especialidades.length
                                                ? values.especialidades
                                                    .map(item => item.nome)
                                                    .join(', ')
                                                : '--')
                                        }
                                    ></InputViewEdit>
                                </FormGroup>
                            </div>
                            <div className="row section-form">
                                <FormGroup>
                                    <InputViewEdit
                                        component={SelectInput}
                                        label={"CEP"}
                                        multi={true}
                                        isMulti={true}
                                        onChange={(name, date) => {
                                            setFieldValue(name, date);
                                        }}
                                        viewMode={this.state.viewMode}
                                        required={false}
                                        name={'cep'}
                                        placeholder="Digite o CEP"
                                        type={"text"}
                                        value={
                                            values.cep
                                        }
                                        clearable={true}
                                        defaultValue={
                                            values.cep != null ? values.cep.map(
                                                (value, index) => {
                                                    return value;
                                                }
                                            ) : ""
                                        }
                                        id={'cep'}
                                        labelKey={"cep"}
                                        valueKey={"cep"}
                                        returnFullObject={true}
                                        onFetchData={
                                            estabelecimentoService.findCep
                                        }
                                        erroMensagem={_.get(
                                            errors,
                                            `cep`
                                          )}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <InputViewEdit
                                        component={SelectInput}
                                        label={"Logradouro"}
                                        multi={true}
                                        isMulti={true}
                                        onChange={(name, date) => {
                                            setFieldValue(name, date);
                                        }}
                                        viewMode={this.state.viewMode}
                                        required={false}
                                        name={'logradouro'}
                                        placeholder="Digite o Logradouro"
                                        type={"text"}
                                        value={
                                            values.logradouro
                                        }
                                        clearable={true}
                                        defaultValue={
                                            values.logradouro != null ? values.logradouro.map(
                                                (value, index) => {
                                                    return value;
                                                }
                                            ) : ""
                                        }
                                        id={'logradouro'}
                                        labelKey={"logradouro"}
                                        valueKey={"logradouro"}
                                        returnFullObject={true}
                                        onFetchData={
                                            estabelecimentoService.findLogradouro
                                        }
                                        erroMensagem={_.get(
                                            errors,
                                            `logradouro`
                                        )}
                                    />
                                </FormGroup>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </React.Fragment>
        );
    }
}

const mapDispatch = ({
    alert: { success, error, clear },
    load: { loading }
}) => ({
    success: msg => success(msg),
    loading: (load: boolean) => loading({ load }),
    error: msg => error(msg),
    clear: () => clear()
});

export default connect(
    null,
    mapDispatch,
    null,
    { withRef: true }
)(withRouter(DashRegiaoEspecialidade));
