//@flow
import * as React from "react";
import { objectsConstants } from "../../constants/objects.constants";
import FilterDatatable from "../../sascComponents/datagrid/FilterDatatable";
import { Button, Collapse } from "reactstrap";
import { datatableConstants } from "../../sascComponents/datagrid/constants/datatable.constants";
import * as _ from "lodash";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
class GuiaPesquisa extends React.PureComponent<{}> {
  constructor(props) {
    super(props);
    this.state = {
      searchOpen: false,
      filterSearch: [{}],
      listFields: objectsConstants.LIST_FIELDS_GUIA_SADT,
      listFieldsSelect: new Map()
    };
  }

  toggleSearch = () => {
    if (this.state.searchOpen) {
      this.setState(
        {
          searchOpen: !this.state.searchOpen,
          filterSearch: [{}],
          listFieldsSelect: new Map()
        },
        () => {
          this.props.changeFilter([]);
        }
      );
    } else {
      this.setState({ searchOpen: !this.state.searchOpen });
    }
  };

  addFilter = () => {
    let filterSearch = _.cloneDeep(this.state.filterSearch);
    filterSearch.push({});
    this.setState({ filterSearch });
  };

  changeFilter = (filter, index) => {
    if (filter &&
      filter.filter &&
      filter.filter.fieldName &&
      (filter.value || filter.filter.searchEmpty)) //|| filter.value.length >= 3
    {

      let filterSearch = _.cloneDeep(this.state.filterSearch);
      let listFieldsSelect = _.cloneDeep(this.state.listFieldsSelect);
      if (filter.filter.fieldName === datatableConstants.ALL_COLUMNS_DATATABLE_FILTER.fieldName) {
        listFieldsSelect = new Map();
        filterSearch = [];
      }
      if (this.state.filterSearch.length <= index) {
        filterSearch.push(filter);
      } else {
        filterSearch.splice(index, 1, filter);
      }

      listFieldsSelect.set(filter.filter.fieldName, index);
      this.setState({ filterSearch, listFieldsSelect }, () => {
        this.props.changeFilter(filterSearch);
      });
    } else {
      if (typeof filter.value === 'string') {
        this.props.error({ message: "É necessario preencher pelo menos 3 caracteres para realizar a pesquisa." });
      } else {
        this.props.error({ message: "É necessario preencher o valor do filtro." });
      }
    }
  };


  render() {
    return (
      <React.Fragment>
        <div className={"bloco-pesquisa text-center py-4"}>
          {!this.state.searchOpen && (
            <Button className="btn btn-light" onClick={this.toggleSearch}>
              <span className="icon-btn-pesquisar"></span>
              Pesquisar
            </Button>
          )}
          {this.state.searchOpen && (
            <Button className="btn btn-secondary" onClick={this.toggleSearch}>
              <span className="icon-btn-fechar"></span>
              Limpar pesquisa
            </Button>
          )}
        </div>
        <div className="corpo-pesquisa">
          <Collapse isOpen={this.state.searchOpen}>
            <div className="barra-pesquisa d-flex flex-column flex-sm-row">
              <div className="d-none d-sm-block icone-pesquisa blocoPontas"></div>
              <div className="d-flex flex-fill flex-column flex-sm-row justify-content-center">
                {this.state.filterSearch &&
                  this.state.filterSearch.length === 0 && (
                    <FilterDatatable
                      filter={{}}
                      listFields={this.state.listFields}
                      index={0}
                      changeFilter={this.changeFilter}
                      showAllColumnsFilter={this.props.showAllColumnsFilter}
                    />
                  )}

                {this.state.filterSearch &&
                  this.state.filterSearch.length > 0 &&
                  this.state.filterSearch.map((filter, index) => {
                    return (
                      <FilterDatatable
                        key={`${index}-${
                          filter.filter ? filter.filter.fieldName : ""
                          }`}
                        filter={filter}
                        listFields={this.state.listFields}
                        index={index}
                        listFieldsSelect={this.state.listFieldsSelect}
                        changeFilter={this.changeFilter}
                        showAllColumnsFilter={this.props.showAllColumnsFilter}
                      />
                    );
                  })}
              </div>
              <div className={`adicionarPesquisa ${this.state.filterSearch.length === 2 ? "invisible" : ""}`}>
                <button
                  className="btn btn-light"
                  data-toggle="collapse"
                  onClick={this.addFilter}
                  role="button"
                  aria-expanded="false"
                  aria-controls="abrePesquisa"
                >
                  <span className="icon-btn-add"></span>
                      Adicionar campo de pesquisa
                </button>
              </div>
            </div>
          </Collapse>
          <div className="barra-dotted">
            <div className="barra"></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading }
}) => ({
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  clear: () => clear()
});


export default connect(
  null,
  mapDispatch
)(withRouter(GuiaPesquisa));