import * as _ from "lodash";
import React, { PureComponent } from "react";
import { Button } from "reactstrap";
import SearchFormInput from "../../components/Inputs/SearchFormInput";
import SelectInput from "../../components/Inputs/SelectInput";
import IconSearch from "../../img/icon_search.svg";
import FilterSearchSelectInput from "../inputs/FilterSearchSelectInput";
import { datatableConstants } from "./constants/datatable.constants";
export default class FilterDatatable extends PureComponent<> {
  constructor(props) {
    super(props);

    const listField = this.reloadListField(props);
    this.state = { filter: this.props.filter, listField };
  }

  componentWillReceiveProps(nextProps) {
    const listField = this.reloadListField(nextProps);
    this.setState({ listField });
  }
  reloadListField = props => {
    let listField = [];
    if (props.showAllColumnsFilter) {
      const indexFieldSelect = props.listFieldsSelect.get(
        datatableConstants.ALL_COLUMNS_DATATABLE_FILTER.fieldName
      );
      if (typeof indexFieldSelect !== "undefined") {
        if (indexFieldSelect === props.index) {
          listField.push(datatableConstants.ALL_COLUMNS_DATATABLE_FILTER);
        }
      } else {
        listField.push(datatableConstants.ALL_COLUMNS_DATATABLE_FILTER);
      }
    }
    if (props.listFields) {
      props.listFields.map(field => {
        const indexFieldSelect = props.listFieldsSelect.get(field.fieldName);
        if (typeof indexFieldSelect !== "undefined") {
          if (indexFieldSelect === props.index) {
            listField.push(field);
          }
        } else {
          listField.push(field);
        }
      });
    }
    return listField;
  };

  handleChange = (name, value) => {
    const filter = _.clone(this.state.filter);
    _.set(filter, name, value);
    if (name === 'filter') {
      filter.value = '';
    }
    this.setState({ filter });
  };

  getPlaceHolder = () => {
    if (this.state.filter.filter && this.state.filter.filter.searchEmpty) {
      return this.state.filter.filter.emptyTextSearch;
    } else {
      return "Digite aqui o que procura";
    }
  };

  getPlaceHolderClass = () => {
    if (this.state.filter.filter && this.state.filter.filter.searchEmpty) {
      return "placeholder-active";
    }
    return "";
  };


  keypressHandler = event => {
    if (event.key === "Enter") {
      this.props.changeFilter(this.state.filter, this.props.index);
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="searchFilter">
          {
            <SelectInput
              className="com-icone custom-padding-select"
              name={"filter"}
              value={this.state.filter.filter}
              options={this.state.listField}
              onChange={this.handleChange}
              returnFullObject={true}
              labelKey={"fieldDescription"}
              valueKey={"fieldName"}
              placeholder="Selecione"
            />
          }
          <img src={IconSearch} className="icon-input" alt="Ícone busca" />
        </div>
        <div className="searchFilterEnd">
          <div className="input-group" onKeyPress={event => this.keypressHandler(event)}>
            {this.state.filter.filter &&
              this.state.filter.filter.type === datatableConstants.ENUM && (
                <SelectInput
                  className="com-icone custom-padding-select min-width"
                  name={"value"}
                  value={this.state.filter.value}
                  options={this.state.filter.filter.enumListValues}
                  onChange={this.handleChange}
                  returnFullObject={false}
                  labelKey={"description"}
                  valueKey={"name"}
                />
              )}
            {this.state.filter.filter &&
              this.state.filter.filter.type === datatableConstants.SELECT && (
                <FilterSearchSelectInput
                  className="com-icone custom-padding-select min-width"
                  name={"value"}
                  value={this.state.filter.value}
                  url={this.state.filter.filter.url}
                  onChange={this.handleChange}
                  returnFullObject={false}
                  labelKey={"nome"}
                  valueKey={this.state.filter.filter.fieldName}
                  placeholder={'Escolha uma opção'}
                  parent={this.props.parent}
                />
              )}
            {(!this.state.filter.filter ||
              (this.state.filter.filter.type !== datatableConstants.ENUM && this.state.filter.filter.type !== datatableConstants.SELECT)) && (
                <SearchFormInput
                  name={"value"}
                  placeholder={this.getPlaceHolder()}
                  value={this.state.filter.value}
                  onChange={this.handleChange}
                  type={this.state.filter.filter && this.state.filter.filter.type}
                  typeDocument={this.state.filter.filter && this.state.filter.filter.fieldDescription}
                  className={this.getPlaceHolderClass()}
                  // onKeyPress={event => this.keypressHandler(event)}
                />
              )}
            <div className="input-group-append justify-content-end">
              <Button
                className="btn btn-light verde-destaque"
                onClick={() => {
                  this.props.changeFilter(this.state.filter, this.props.index);
                }}
              >
                OK
              </Button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
