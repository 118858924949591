import { PureComponent } from "react";
import { atendimentoService } from "../../../services/atendimento.service";
import { connect } from "react-redux";
import {  withRouter } from "react-router-dom";
import {loteAtendimentoService} from "../../../services/loteAtendimento.service";

class VisualizarLote extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.props.loading(true);
    loteAtendimentoService
      .doGet(this.props.match.params.id)
      .then(responseLote => {
        let lote = responseLote.data.data;
        this.props.history.push({
          pathname: "/lotes/" + lote.id,
          state: { lote }
        });
        this.props.loading(true);
      },(erros)=>{
        this.props.loading(false);
        console.error(erros)
      });
  }

  render() {
    return null;
  }
}

const mapDispatch = ({ load: { loading } }) => ({
  loading: (load: boolean) => loading({ load })
});
export default connect(null, mapDispatch)(withRouter(VisualizarLote));
