import React, { PureComponent } from "react";
import { connect } from "react-redux";
import iconAtendimento from "../../../img/icon_atendimentos.svg";
import iconPendencia from "../../../img/icon_pendente_WHITE.svg";
import { withRouter } from "react-router-dom";
import FormTextArea from "../../../components/Inputs/FormTextArea";
import { Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import InputViewEdit from "../../../components/Inputs/InputViewEdit";
import { pendenciaService } from "../../../services/pendencia.service";
import { defaultService } from "../../../services/defaultService";
import { translate, messages } from "../../../helpers/message.helper";
import iconCreatePendencia from "../../../img/iconAlert_CriarPendencia.svg";
import CheckBoxInput from "../../../components/Inputs/CheckBoxInput";
import { confirmAlert } from "react-confirm-alert";
import MenuNotificar from "./MenuNotificar";
import { objectsConstants } from "../../../constants/objects.constants";
import { Can } from "@casl/react";
import { ModalAnexos } from "../../../components/Utils/ModalAnexos";
import { descricaoPendenciaService } from "../../../services/descricaoPendencia.service";
import SelectInput from "../../../components/Inputs/SelectInput";


const validatePendenciaTopo = Yup.object().shape({
  descricaoPendencia: Yup.string().required("Obrigatório")
});

class PendenciasTopo extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      filter: {},
      openAnexo: false,
      openMenuNotificacao: false,
      estabelecimento: this.props.estabelecimento,
      atendimentoPendencia: []
    };
  }
  fecharPendencia = () => {};

  toggleAnexos = () => {
    this.setState(({ openAnexo }) => ({ openAnexo: !openAnexo }));
  };

  confirmPendencia = (onConfirm, campoSelecionado, setFieldValue) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
            <div className="confirm-ui css-alert-sasc">
              <img src={iconCreatePendencia} alt="icon" />
              <h2>{"CRIAR PENDÊNCIA"}</h2>
              <p>
                <b>{campoSelecionado.label}</b>
              </p>
              <p>
                Após criar uma pendência a guia de atendimento pode ser
                encaminhada normalmente para outros status.
              </p>
              <p className="pt-2">
                Somente pendências{" "}
                <span className="font-weight-bold">impeditivas</span> que são
                encaminhadas para o status pendente.
              </p>
              {
                <div className="checkbox-com-borda p-3">
                  <CheckBoxInput
                      className=" b-bottom "
                      label={`Está pendência é impeditiva e o andamento deste atendimento só poderá continuar após a resolução da mesma.`}
                      name={"impeditiva"}
                      id={"impeditiva"}
                      checked={campoSelecionado.impeditiva}
                      onChange={setFieldValue}
                  />
                </div>
              }
              <p className="font-bold pt-2">
                Deseja realmente criar esta pendência?
              </p>
              <button
                  className="btn btn-secondary white mt-3 mb-4 mx-2"
                  onClick={() => {
                    onClose();
                  }}
              >
                Cancelar
              </button>
              {
                <button
                    className="btn btn-primary white mt-3 mb-4 mx-2"
                    onClick={() => {
                      onConfirm();
                      onClose();
                    }}
                >
                  Sim
                </button>
              }
            </div>
        );
      }
    });
  };

  atualizaAnexos = values => {
    this.props.loading(true);

    _.get(values, "component");
    _.unset(values, "component");
    _.set(values, "atendimento", {
      id: this.props.idAtendimentoAtual,
      type: objectsConstants.ENUM_TIPOS_GUIA.SADT
    });


    pendenciaService.doSave(values).then(response => {
          this.props.loading(false);
          this.toggleAnexos();
        },
        erros => {
          console.error(erros);
          this.props.loading(false);
          this.toggleAnexos();
        });
  }

  openInputFile = () => {
    this._anexosInput.openInputFile();
  };

  setMenuNotificadarStatus = status => {
    this.setState({ openMenuNotificacao: status }, () => {
      if (this.state.openMenuNotificacao) {
        document.body.classList.add("bodyNoScroll");
        document.getElementById("bodyMenu").scrollIntoView();
      } else {
        document.body.classList.remove("bodyNoScroll");
      }
    });
  };

  toggleNotificacaoPendencia = toggle => {
    this.setMenuNotificadarStatus(!this.state.openMenuNotificacao);
  }
  ;
  render() {

    const { campoSelecionado } = this.props;
    let currentComponent;
    let _this = this;
    return (
        <div className="barra-topo pb-0 bg-branco rounded d-flex flex-column flex-sm-row align-content-stretch flex-wrap">
          <div className="px-sm-5 py-2 py-sm-4  d-flex align-items-center justify-content-center">
            <img src={iconAtendimento} alt="Atendimento" className="iconMedio" />

            <div>
              <p className="mb-0"> Atendimento </p>
              <h2 className={`status-txt`}>
                <span>{this.props.numeroAtendimento}</span>
              </h2>
            </div>
          </div>
          <div className="barra-pendencia d-sm-flex flex-fill align-items-stretch">
            <div
                className={`pl-sm-4 animation-pendencia-right ${campoSelecionado.label &&
                "recolher"} d-flex flex-sm-row flex-column align-items-stretch align-items-center justify-content-center`}
            >
              <div className="px-sm-5 py-5 py-sm-4  d-flex align-items-center justify-content-center">
                <img src={iconPendencia} alt="Pendencia" className="iconMedio" />
                <div>
                  <p className="mb-0"> Atendimento </p>
                  <h2 className={`status-txt`}>
                    <span>{this.props.numeroAtendimento}</span>
                  </h2>
                </div>
              </div>
              <div className="px-sm-5 py-4 d-flex align-items-center justify-content-center flex-sm-fill">
                <div className="info-texto px-5 px-sm-3 mt-2 mt-sm-0">
                <span className="font-weight-bold font-italic verde-destaque">
                  Quer ajuda?
                </span>
                  <p className="font-italic">
                    Clique em um dos campos do atendimento abaixo para adicionar
                    uma pendência
                  </p>
                </div>
              </div>
              <div className="d-none d-sm-flex align-items-start justify-content-end">
                <button
                    className="btn btn-icon-only"
                    onClick={()=>{this.props.campoAtual({campoSelecionado:{}});this.props.closePendecia();}}
                >
                  <span className="icon-btn-fechar"></span>
                </button>
              </div>
              <div className="d-flex d-sm-none  align-items-center justify-content-center">
                <button
                    className="btn btn-secondary white mt-3 mb-4 mx-2"
                    onClick={()=>{this.props.campoAtual({campoSelecionado:{}});this.props.closePendecia();}}
                >
                  <span className="icon-btn-fechar"></span>
                  Fechar
                </button>
              </div>
            </div>
            <Formik
                validationSchema={validatePendenciaTopo}
                validateOnBlur={false}
                validateOnChange={false}
                enableReinitialize={true}
                initialValues={campoSelecionado}
                onSubmit={(values, actions) => {
                  this.props.loading(true);
                  currentComponent = _.get(values, "component");
                  _.unset(values, "component");
                  _.set(values, "atendimento", {
                    id: this.props.idAtendimentoAtual,
                    type: objectsConstants.ENUM_TIPOS_GUIA.SADT
                  });
                  //set atendimento
                  pendenciaService.doSave(values).then(
                      response => {
                        let id = values.id
                            ? values.id
                            : defaultService.getIdFromUrl(response.headers.location);

                        if (values.impeditiva) {
                          this.props.pendencia({
                            title: "Pendência criada!",
                            message: ` A guia ${this.props.idAtendimentoAtual} foi encaminhada para o status PENDENTE`
                          });
                        } else {
                          this.props.pendencia({
                            message: `Pendência criada!`
                          });
                        }

                        values.id = id;
                        _.set(values, "situacao", { name: "EM_ABERTO" });

                        _.set(values, "component", currentComponent);

                        this.props.campoAtual({ campoSelecionado: values });

                        this.props.onSaved();
                        if(this.menuNotificar){
                          this.menuNotificar.resetForm();
                        }
                        this.props.loading(false);
                        actions.setSubmitting(false);
                        this.props.closePendecia();
                      },
                      erros => {
                        console.error(erros);
                        this.props.error({
                          message: messages.ERRO_CRIAR_PENDENCIA
                        });
                        try {
                          let response = erros.response.data;
                          if (response && response.messages) {
                            for (var i = 0; i < response.messages.length; i++) {
                              let erroItem = response.messages[i];
                              if (erroItem.target === "FIELD") {
                                if (
                                    erroItem.fieldName ===
                                    "atendimentoPendenciaNotificacao"
                                ) {
                                  this.props.clear();
                                  this.props.error({
                                    message: messages.NENHUM_PERFIL_SELECIONADO
                                  });
                                  this.setMenuNotificadarStatus(true);
                                } else {
                                  actions.setFieldError(
                                      erroItem.fieldName,
                                      translate(erroItem.message.code)
                                  );
                                }
                              } else {
                                this.props.error({
                                  message: translate(erroItem.message.code)
                                });
                              }
                            }
                          }
                        } catch (error) {
                          console.error(error);
                        }

                        this.props.loading(false);
                        actions.setSubmitting(false);
                        campoSelecionado.nomeCampo = {};
                      }
                  );
                }}
                ref={form => {
                  this.formRef = form;
                }}
            >
              {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  validateForm,
                  setValues
                }) => (
                  <React.Fragment>
                    <div className="icone-pendencia d-none d-sm-flex px-sm-5 align-items-center justify-content-center">
                      <img
                          src={iconPendencia}
                          alt="Pendencia"
                          className="iconMedio"
                      />{" "}
                    </div>
                    <div className="px-sm-3 d-flex flex-fill flex-column align-items-center justify-content-center borda-separacao">
                      <label className="font-10px">{campoSelecionado.label}</label>
                      <p className="font-10px verde-destaque">{campoSelecionado.label === "Anexos - Guia física" ? campoSelecionado.value.length + " arquivo anexado" : ""}</p>
                      <p>{campoSelecionado.label !== "Anexos - Guia física" ? campoSelecionado.value : "" }</p>
                    </div>
                <div className="px-3 px-sm-3 d-flex flex-fill flex-column justify-content-center borda-separacao">
                  <SelectInput
                    className="min-width"
                    name={"descricaoPendencia"}
                    id={"descricaoPendencia"}
                    label={"Buscar descrição de pendência"}
                    value={this.state.descricao}
                    onChange={(name, value) => {
                      let descricao = value.texto;
                      if (values.descricaoPendencia) {
                        setFieldValue(name, descricao.concat(" ", values.descricaoPendencia));
                      } else {
                        setFieldValue(name, descricao);
                      }
                    }}
                    returnFullObject={true}
                    labelKey={"texto"}
                    valueKey={"id"}
                    placeholder="Selecione a descrição"
                    onFetchData={descricaoPendenciaService.findByTexto}
                  />
                  <InputViewEdit
                    label={"Descrição da pendência"}
                    component={FormTextArea}
                    required={true}
                    noSize={true}
                    rows={3}
                    name={"descricaoPendencia"}
                    value={values.descricaoPendencia}
                    onChange={handleChange}
                    viewMode={values.id}
                    erroMensagem={errors.descricaoPendencia}
                    id={"descricaoPendencia"}
                    placeholder="Insira uma breve descrição da pendência"
                  />
                </div>

                    <div className="px-sm-3 d-flex flex-column align-items-center justify-content-center borda-separacao">
                      <button
                          type="button"
                          className="btn btn-link pb-0 pb-sm-3"
                          onClick={this.toggleAnexos}
                      >
                        <span className="icon-btn-anexar"></span> {!values.id ? "Anexar" : "Anexos"}
                      </button>

                      <div
                          id="anexos"
                          className="pointer text-center"
                          onClick={this.toggleAnexos}
                      >
                        <React.Fragment>
                          <div className="font-10px verde-destaque font-bold">
                            {_.size(values.atendimentoPendenciaAnexos)} arquivo
                          </div>
                          <div className="font-10px verde-destaque">anexado</div>
                        </React.Fragment>
                      </div>
                      <ModalAnexos
                          ref={anexos => (this._anexosInput = anexos)}
                          name="atendimentoPendenciaAnexos"
                          toogle={this.toggleAnexos}
                          isOpen={this.state.openAnexo}
                          updateAnexos={() =>
                              this.atualizaAnexos(values)
                          }
                          target={"anexos"}
                          itens={
                            values.atendimentoPendenciaAnexos
                                ? values.atendimentoPendenciaAnexos
                                : []
                          }
                          onChange={(name, anexos) => {
                            let atendimentoPendenciaAnexos = [];
                            for (let index = 0; index < anexos.length; index++) {
                              if (!anexos[index].midia) {
                                atendimentoPendenciaAnexos.push({
                                  midia: anexos[index],
                                  criador: this.props.user
                                });
                              } else {
                                atendimentoPendenciaAnexos.push(anexos[index]);
                              }

                            }
                            setFieldValue(name, atendimentoPendenciaAnexos);
                          }}
                          viewMode={values.id}
                          user={this.props.user}
                      />

                    </div>
                    <div className="px-sm-3 d-flex flex-column align-items-center justify-content-center borda-separacao">
                      <button
                          type="button"
                          className="btn btn-link pb-0 pb-sm-3"
                          onClick={() => {
                            this.toggleNotificacaoPendencia(true);
                          }}
                      >
                        <span className="icon-btn-notificacao"></span>Notificar
                      </button>
                      <div className="text-center">
                        <div className="font-10px verde-destaque font-bold">
                          {
                            _.sortedUniqBy(
                                values.atendimentoPendenciaNotificacao,
                                "tipoPerfil"
                            ).length
                          }{" "}
                          perfis
                        </div>
                        <div className="font-10px verde-destaque">
                          serão notificados
                        </div>
                      </div>
                    </div>

                    {!values.id && (
                        <div className="px-sm-3 d-flex flex-row align-items-center justify-content-center">
                          <button
                              className="btn btn-secondary white mt-3 mb-4 mx-2"
                              onClick={() => {
                                  this.props.closePendecia();
                                this.props.campoAtual({campoSelecionado:{}});
                              }}
                              disabled={isSubmitting}
                          >
                            Cancelar
                          </button>
                          <button
                              className="btn btn-primary white mt-3 mb-4 mx-2"
                              onClick={() => {
                                validateForm(values).then((errors) => {

                                  if (_.isEmpty(errors)) {
                                    _this.confirmPendencia(
                                        handleSubmit,
                                        values,
                                        setFieldValue
                                    );
                                  } else {
                                    console.error('erro pendencia');

                                    this.props.error({
                                      message: messages.ERRO_CRIAR_PENDENCIA
                                    });
                                  }
                                });
                              }}
                              disabled={isSubmitting}
                          >
                            Criar pendência
                          </button>
                        </div>
                    )}

                    {values.id && (
                        <Can
                            I={objectsConstants.ALTERAR}
                            a={objectsConstants.CRUD_PENDENCIA_RESOLVER}
                            ability={this.props.permissions}
                        >


                          <div className="px-sm-3 d-flex flex-row align-items-center justify-content-center">
                            <button
                                className="btn btn-primary white mt-3 mb-4 mx-2"
                                onClick={this.props.toggleResolverPendencia}
                                disabled={isSubmitting}
                            >
                              Resolver pendência
                            </button>
                          </div>
                          <div className="d-none d-sm-flex align-items-start justify-content-end">
                            <button
                                className="btn btn-icon-only"
                                onClick={() => {
                                    this.props.closePendecia();
                                    this.props.campoAtual({campoSelecionado:{}});
                                }}
                            >
                              <span className="icon-btn-cancelar"></span>
                            </button>
                          </div>
                        </Can>
                    )}
                    <MenuNotificar
                        toggleNotificacaoPendencia={this.toggleNotificacaoPendencia}
                        openMenuNotificacao={this.state.openMenuNotificacao}
                        setFieldValue={setFieldValue}
                        viewMode={values.id}
                        value={values.atendimentoPendenciaNotificacao}
                        campoAtual={values}
                        pendenciaId={values.id}
                        wrappedComponentRef={c => (this.menuNotificar = c)}
                    />
                  </React.Fragment>
              )}
            </Formik>

            {/* </div> */}
          </div>

          {/* fim Conteudo para CriarPendencias */}
        </div>
    );
  }
}

const mapDispatch = ({
                       alert: { pendencia, error, clear },
                       load: { loading },
                       pendencia: {
                         togglePendencia,
                         toggleResolverPendencia,
                           closePendecia
                       },
                       selectableInput:{ campoAtual, atendimentoAtual,}

                     }) => ({
    closePendecia: () => closePendecia(),
  pendencia: msg => pendencia(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  clear: () => clear(),
  togglePendencia: () => togglePendencia(),
  toggleResolverPendencia: () => toggleResolverPendencia(),
  atendimentoAtual: atendimento => atendimentoAtual(atendimento),
  campoAtual: campoSelecionado => campoAtual(campoSelecionado)
});

function mapStateToProps(state) {
  const {
    pendenciaOpen,
    numeroAtendimento,


  } = state.pendencia;

  const { user, permissions } = state.authentication;
  const { idAtendimentoAtual, campoSelecionado, atendimento} = state.selectableInput;

  return {
    pendenciaOpen,
    idAtendimentoAtual,
    numeroAtendimento,
    campoSelecionado,
    user,
    permissions,
    atendimento
  };
}

export default connect(
    mapStateToProps,
    mapDispatch
)(withRouter(PendenciasTopo));
