import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Avatar from "../../../components/Avatar/Avatar";
import iconPerfil from "../../../img/icon_perfil.svg";
import { Can } from "@casl/react";
import { objectsConstants } from "../../../constants/objects.constants";
import { userService } from "../../../services/user.service";

class CooperadoSudoTopo extends PureComponent<Props, State> {
  render() {
    return (
      //add classe "bg-branco" abaixo quando esição/criação
      <div className="px-sm-5 py-sm-2 my-sm-3  rounded d-flex flex-column flex-sm-row justify-content-between align-items-center">
        <div className="bloco-dados-user mr-sm-auto d-flex flex-column flex-sm-row align-items-center"
             onClick={() => {
              this.props.history.push("/perfil/view/" + this.props.user.id);
            }}
        >
          <div className="bloco-image-user m-sm-2 mr-sm-4">
            <div className="icon-foto-user d-flex align-items-center ">
              <img src={iconPerfil} alt="iconPerfil" />
            </div>
            {/* <img src={iconFoto} className="foto-user" alt="iconFoto" /> */}
            <Avatar user={this.props.user}></Avatar>
          </div>
          <div className="info-user">
            <p>
            {this.props.user.cooperativa && this.props.user.cooperativa.sigla}
            </p>
            <h2 className="font-weight-bold">{this.props.user.nome}</h2>
            <p className="font-italic">
            {userService.getPerfilAtual(this.props.user)}
            </p>
          </div>
        </div>

        <div className="px-sm-3 mt-2 mt-sm-0">
          <Can
            I={objectsConstants.ALTERAR}
            a={objectsConstants.CRUDCOOPERADOS_DADOS}
            ability={this.props.permissions}>
            {/*<button
              type="button"
              className="btn btn-light"
              onClick={() => {
                this.props.history.push("/cooperadosSudo/novo");
              }}>
              <span className="icon-btn-add"></span>Novo Cooperado
            </button>{" "}*/}
          </Can>

          {/* */}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  null
)(withRouter(CooperadoSudoTopo));
