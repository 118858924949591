import * as _ from "lodash";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ButtonToTop from "../../components/ButtonToTop/ButtonToTop";
import SelectInput from "../../components/Inputs/SelectInput";
import { objectsConstants } from "../../constants/objects.constants";
import { createYupSchema } from "../../helpers/createYupSchema";
import { conselhoService } from "../../services/conselho.service";
import { cooperadoService } from "../../services/cooperado.service";
import { estabelecimentoService } from "../../services/estabelecimento.service";
import { modeloVisualizacaoService } from "../../services/modeloVisualizacao.service";
import { userService } from "../../services/user.service";
import TopoTitleComponente from "../home/TopoTitleComponente";
import GuiaAtendimentoForm from "./GuiaAtendimentoForm";
import GuiaConsultaForm from "./GuiaConsultaForm";
import GuiaHonorariosForm from "./GuiaHonorariosForm";
import GuiaInternacaoForm from "./GuiaInternacaoForm";
import GuiaSADTForm from "./GuiaSADTForm";
import GuiaTopo from "./GuiaTopo";
import PassoAPasso from "./componentes/PassoAPasso";
import MenuModelView from "./menuModelView/MenuModelView";


class NovoAtendimento extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    let execucaoDefault = objectsConstants.ATENDIMENTO_EXECUCAO;
    execucaoDefault.dataAtendimento = "";

    this.state = {
      criarGuiaPassoPasso: props.user.criarGuiaPassoPasso,
      atendimento: {},
      openCollapse: true,
      selectedAtendimentos: [],
      atendimentoComPendencia: {},
      listAtendimentoEdicao: [],
      rowsCheckedValue: [],
      rowsCheckedIdValue: [],
      dataTotalSize: 0,
      checkedAll: false,
      checkedAllPages: false,
      pendencias: false,
      openMenuNotificacao: false,
      atendimentoHistorico: {},
      etapasPassoPasso: [],
      etapaAtual: {},
      listUfsConselho: [],
      listConselho: [],

      initGuiaInternacao: {
        estabelecimento: {
          id: userService.getExtensionUser(this.props.user)
        },
        atendimentoExecucaos: [{
          type: objectsConstants.ENUM_TIPOS_GUIA.INTERNACAO,
          dataAtendimento: "",
          numero: 1,
          quantidade: 1,
          fator: 1,

          valorUnitario: null,
          tabela: "22",
          valorTotal: null,
          atendimentoProcedimento: {
            numero: 1,
            tabela: "22",

          }
        }],
        atendimentoProcedimentos: [
          {
            numero: 1,
            tabela: "22",
            codigoProcedimento: "",
            descricao: "",

            dataAtendimento: ""
          }
        ],
        guiaInternacaoCids: [
          {


          }
        ],
        guiaInternacaoOpms: [
          {
            numero: 1,

            valorTotal: 0,
          }
        ],
        cooperado: {},
        identificacaoAtendimentoExecucao: [
          {
            numero: 1,
            codigoCbo: null,

            codigoProcedimento: "10101012",
            descricao: "Em consultório (no horário normal ou preestabelecido)",
            dataAtendimento: ""
          }
        ],
        dataSolicitacao: "",
        cnes: this.props.user.cooperativa.cnes,
        cooperativa: this.props.user.cooperativa,
        nome: this.props.user.cooperativa.nome,
        codigoOperadora: this.props.user.cooperativa.codigoOperadora,
        nomeContratado: this.props.user.cooperativa.nome,
        codigoOperadoraContratada: this.props.user.cooperativa.codigoOperadora,
        cnesContratado: this.props.user.cooperativa.cnes,
        type: objectsConstants.ENUM_TIPOS_GUIA.INTERNACAO
      },
      initDataSADT: {
        estabelecimento: {
          id: userService.getExtensionUser(this.props.user)
        },
        atendimentoExecucaos: [{
          type: objectsConstants.ENUM_TIPOS_GUIA.SADT,
          dataAtendimento: "",
          numero: 1,
          quantidade: 1,
          fator: 1,

          valorUnitario: null,
          tabela: "22",
          valorTotal: null,
          atendimentoProcedimento: {
            numero: 1,
            tabela: "22",

          }
        }],
        atendimentoProcedimentos: [
          {
            numero: 1,
            dataAtendimento: "",
            tabela: "22",
          }
        ],
        cooperado: {},
        cooperativa: this.props.user.cooperativa,
        exames: [],
        identificacaoAtendimentoExecucao: [
          {
            numero: 1,
            codigoCbo: null,

            codigoProcedimento: "10101012",
            descricao: "Em consultório (no horário normal ou preestabelecido)",
            dataAtendimento: ""
          }
        ],
        dataSolicitacao: "",
        cnes: this.props.user.cooperativa.cnes,
        nome: this.props.user.cooperativa.nome,
        codigoOperadora: this.props.user.cooperativa.codigoOperadora,
        nomeContratado: this.props.user.cooperativa.nome,
        codigoOperadoraContratada: this.props.user.cooperativa.codigoOperadora,
        cnesContratado: this.props.user.cooperativa.cnes,
        indicacaoAcidente: objectsConstants.INDICACAO_ACIDENTE[0],
        type: objectsConstants.ENUM_TIPOS_GUIA.SADT,
        rascunho: true
      },
      initDataConsulta: {
        estabelecimento: {
          id: userService.getExtensionUser(this.props.user)
        },
        cooperativa: this.props.user.cooperativa,
        atendimentoExecucaos: [
          {
            type: objectsConstants.ENUM_TIPOS_GUIA.CONSULTA,
            numero: "",
            tabela: "22",
            codigoProcedimento: "10101012",
            tipoConsulta: objectsConstants.TIPOS_CONSULTA[0],
            quantSolicitada: "",
            quantAutorizada: "",
            descricao: "",
            dataAtendimento: "",
            valorTotal: null
          }
        ],
        identificacaoAtendimentoExecucao: [{ numero: 1 }],
        cooperado: {},
        indicacaoAcidente: objectsConstants.INDICACAO_ACIDENTE[0],
        nomeContratado: this.props.user.cooperativa.nome,
        codigoOperadoraContratada: this.props.user.cooperativa.codigoOperadora,
        cnesContratado: this.props.user.cooperativa.cnes,
        type: objectsConstants.ENUM_TIPOS_GUIA.CONSULTA
      },
      initDataHonorarios: {
        estabelecimento: {
          id: userService.getExtensionUser(this.props.user)
        },
        atendimentoExecucaos: [
          {
            type: objectsConstants.ENUM_TIPOS_GUIA.HONORARIO,
            numero: 1,
            quantidade: null,
            fator: 1,
            dataAtendimento: "",
            horaInicial: "",
            horaFinal: "",
            via: "",
            tec: "",
            valorUnitario: null,
            tabela: "22",
            valorTotal: null,
            atendimentoProcedimento: {
              numero: "1",
              tabela: "22",
              codigoProcedimento: "",
              quantSolicitada: "",
              quantAutorizada: "",
              descricao: ""
            }
          }
        ],
        atendimentoProcedimentos: [
          {
            numero: 1,
            tabela: "22",
            codigoProcedimento: "",
            quantSolicitada: "",
            quantAutorizada: "",
            descricao: ""
          }
        ],
        cooperado: {},
        cooperativa: this.props.user.cooperativa,
        identificacaoAtendimentoExecucao: [
          {
            numero: 1,
            codigoCbo: null,
            grau: ""
          }
        ],
        cnes: this.props.user.cooperativa.cnes,
        nome: this.props.user.cooperativa.nome,
        codigoOperadora: this.props.user.cooperativa.codigoOperadora,
        nomeContratado: this.props.user.cooperativa.nome,
        codigoOperadoraContratada: this.props.user.cooperativa.codigoOperadora,
        cnesContratado: this.props.user.cooperativa.cnes,
        type: objectsConstants.ENUM_TIPOS_GUIA.HONORARIO,
        rascunho: true
      }
    };
  }

  componentDidMount() {
    // this.props.loading(true);
    let extension = userService.getExtensionUser(this.props.user);
    const isAuditor = userService.isAuditor(this.props.user);
    const isConsultorio = userService.isConsultorio(this.props.user);

    const isCooperado = userService.isCooperado(this.props.user);
    if (isCooperado) {

      let initData = _.cloneDeep(this.state.initData);
      if (!initData) {
        initData = {};
      }
      initData.cooperado = this.props.user;
      
      this.setState({ initData });
      
    }
    if (isConsultorio) {
      cooperadoService.findByEstabelecimento(userService.getExtensionUser(this.props.user)).then(response => {
        let listCooperado = response.data;
        if (listCooperado.length == 1) {
          let initData = _.cloneDeep(this.state.initData);
          if (!initData) {
            initData = {};
          }
          initData.cooperado = listCooperado[0];
          this.setState({ initData });
        }


      })

    }
    let filtroEstabelecimentoPresente = false;
    if (
      this.props.filtroAtendimento &&
      this.props.filtroAtendimento.estabelecimento
    ) {
      extension = this.props.filtroAtendimento.estabelecimento.id;
      filtroEstabelecimentoPresente = true;
    }

    if (extension && !isAuditor) {

     estabelecimentoService.dadosContratado(extension).then(estabelecimento => {
        let initData = _.cloneDeep(this.state.initData);
        if (!initData) {
          initData = {};
        }
        initData.estabelecimento = estabelecimento.data;
        if (filtroEstabelecimentoPresente) {
          initData.cnes = initData.estabelecimento.cnes;
          initData.nome = initData.estabelecimento.nome;
          initData.codigoOperadora = initData.estabelecimento.codigoOperadora;
        }
        this.setState({ initData });
      });
    }
    // this.props.loading(false);

    let rascunho = JSON.parse(localStorage.getItem('rascunho'))
    if (rascunho) {
      
      /*if (!isCooperado) {
        let aten = this.state.atendimento
        aten.type = rascunho.type
        this.setState({atendimento : aten})
      }*/
      
      switch (rascunho.type) {
        /*case objectsConstants.ENUM_TIPOS_GUIA.CONSULTA:
          this.setState({initDataConsulta : rascunho})
          break;*/
        case objectsConstants.ENUM_TIPOS_GUIA.HONORARIO:
          this.setState({initDataHonorarios : rascunho})
          break;
        /*case objectsConstants.ENUM_TIPOS_GUIA.INTERNACAO:
          this.setState({initGuiaInternacao : rascunho})
          break;*/
        default:
          this.setState({initDataSADT : rascunho})
          break;
      }
    }
  }

  addAtendimento = () => {
    let arrayAtendimentos = _.cloneDeep(this.state.atendimentos);
    arrayAtendimentos.unshift({});
    this.setState({ atendimentos: arrayAtendimentos });
  };

  loading = load => {
    this.props.loading(load);
  };

  getEtapas = type => {
    let etapasPassoPasso = null;
    switch (type) {
      case objectsConstants.ENUM_TIPOS_GUIA.CONSULTA:
        etapasPassoPasso = objectsConstants.etapasConsulta;
        break;
      case objectsConstants.ENUM_TIPOS_GUIA.HONORARIO:
        etapasPassoPasso = objectsConstants.etapasHonorarios;
        break;
      case objectsConstants.ENUM_TIPOS_GUIA.INTERNACAO:
        etapasPassoPasso = objectsConstants.etapasInternacao;
        break;
      default:
        etapasPassoPasso = objectsConstants.etapasSADT;
        break;
    }
    return etapasPassoPasso;
  };

  getValidationScheme = (type, etapaAtual) => {


    let validationScheme = {};

    let validations =
      etapaAtual && etapaAtual.etapa ? etapaAtual.valdations : [];
    switch (type) {
      case objectsConstants.ENUM_TIPOS_GUIA.CONSULTA:
        validationScheme = createYupSchema(
          objectsConstants.validateGuiaConsulta,
          validations
        );
        break;
      case objectsConstants.ENUM_TIPOS_GUIA.HONORARIO:
        validationScheme = createYupSchema(
          objectsConstants.validateHonorarios,
          validations
        );
        break;
      case objectsConstants.ENUM_TIPOS_GUIA.INTERNACAO:
        validationScheme = createYupSchema(
          objectsConstants.validateGuiaInternacao,
          validations
        );
        break;
      default:
        validationScheme = createYupSchema(
          objectsConstants.validateGuiaSADT,
          validations
        );
        break;
    }
    return validationScheme;
  };

  handleAtedimentoType = type => {
    const { criarGuiaPassoPasso } = this.state;
    let atendimento = _.cloneDeep(this.state.atendimento);
    atendimento.type = type;
    atendimento.convenio = null;

    let etapasPassoPasso = this.getEtapas(type);
    let etapaAtual = criarGuiaPassoPasso ? etapasPassoPasso[0] : null;
    let validationSchema = this.getValidationScheme(type, etapaAtual);
    this.setState({
      atendimento,
      etapasPassoPasso,
      etapaAtual,
      validationSchema
    });
  };

  proximoPasso = () => {
    const { etapasPassoPasso, etapaAtual, atendimento } = this.state;
    let _this = this;
    let indexAtual = _.findIndex(etapasPassoPasso, ["etapa", etapaAtual.etapa]);
    let promise = new Promise(function (resolve) {
      _this.guiaFormItem.validateForm().then(stepIsValid => {
        if (stepIsValid) {
          if (etapasPassoPasso.length > indexAtual + 1) {
            let novaEtapa = etapasPassoPasso[indexAtual + 1];
            let validationSchema = _this.getValidationScheme(
              atendimento.type,
              novaEtapa
            );
            _this.setState({ etapaAtual: novaEtapa, validationSchema }, () => {
              resolve(true);
            });
          } else {
            //chegou na ultima etapa chama submit
            _this.guiaFormItem.submitForm();
          }
        } else {
          resolve(false);
        }
      });
    });
    return promise;
  };

  redirectView = atendimento => {
    let entitys =
      this.props.location.state && this.props.location.state.entitys
        ? this.props.location.state.entitys
        : [];
    entitys.push(atendimento);
    this.props.history.push({
      pathname: "/guias",
      state: { entitys, closeAccordion: true }
    });
  };

  renderAtendimentos = atendimento => {
    const { etapaAtual, validationSchema } = this.state;

    if (atendimento.type) {
      let GuiaAtual = null;
      let initData = null;
      let etapasPassoPasso = null;
      switch (atendimento.type) {
        case objectsConstants.ENUM_TIPOS_GUIA.CONSULTA:
          GuiaAtual = GuiaConsultaForm;
          initData = this.state.initDataConsulta;

          etapasPassoPasso = objectsConstants.etapasConsulta;
          break;
        case objectsConstants.ENUM_TIPOS_GUIA.HONORARIO:
          GuiaAtual = GuiaHonorariosForm;
          initData = this.state.initDataHonorarios;
          etapasPassoPasso = objectsConstants.etapasHonorarios;
          break;
        case objectsConstants.ENUM_TIPOS_GUIA.INTERNACAO:
          GuiaAtual = GuiaInternacaoForm;
          initData = this.state.initGuiaInternacao;
          etapasPassoPasso = objectsConstants.etapasInternacao;
          break;
        default:
          GuiaAtual = GuiaSADTForm;
          initData = this.state.initDataSADT;
          etapasPassoPasso = objectsConstants.etapasSADT;
          break;
      }
      if (this.state.initData) {
        initData.estabelecimento = this.state.initData.estabelecimento;
        initData.cnes = this.state.initData.cnes;
        initData.nome = this.state.initData.nome;
        initData.codigoOperadora = this.state.initData.codigoOperadora;
        initData.identificacaoAtendimentoExecucao[0].enviarPj = this.state.initData.estabelecimento.enviarClinica;
        initData.enviarPj = this.state.initData.estabelecimento.enviarClinica;
        initData.envioEletronico = false;
        if (this.state.initData.cooperado) {
          initData.cooperado = this.state.initData.cooperado;
          initData.identificacaoAtendimentoExecucao[0].cooperado = initData.cooperado;
        }
      }
      initData.type = atendimento.type;
      return (
        <GuiaAtendimentoForm
          //<Drag
          index={0}
          collapse={this.state.openCollapse}
          moveGuia={this.moveAtendimento}
          atendimento={atendimento}
          onSaved={this.redirectView}
          onCancelEditCreate={this.cancelEditCreate}
          onCheckedAtendimento={this.onCheckedRow}
          onClickPendencia={this.onClickPendenciaRow}
          changeTypeGuia={this.handleAtedimentoType}
          imprimir={this.impressao}
          rowsCheckedIdValue={this.state.rowsCheckedIdValue}
          checkedAll={this.state.checkedAll}
          key={atendimento.id}
          id={atendimento.id}
          toggleHistorico={this.toggleHistorico}
          criarGuiaPassoPasso={this.state.criarGuiaPassoPasso}
          GuiaForm={GuiaAtual}
          initData={initData}
          validateObject={validationSchema}
          etapasPassoPasso={etapasPassoPasso}
          etapaAtual={etapaAtual}
          wrappedComponentRef={c => (this.guiaFormItem = c)}
          listUfsConselho={this.state.listUfsConselho}
          listConselho={this.state.listConselho}
        />
      );
    } else {
      return (
        <div className="card content-form cardGuia cardCrud">
          <div
            className="card-header d-flex  flex-column flex-sm-row b-bottom"
            id="headingOne"
            onClick={this.toggle}
          >
            <div className="flex-grow-1 d-flex align-items-center form-inside">

              <div className="section-form w-250p">
                <SelectInput
                  required={true}
                  label={"Tipo de guia"}
                  className=""
                  name={"tipoGuia"}
                  value={""}
                  options={

                    userService.isConsultorio(this.props.user)
                      ? _.orderBy(objectsConstants.TIPOS_GUIA_CONSULTORIO, ['name'],
                    ['asc'])
                      : _.orderBy(objectsConstants.TIPOS_GUIA, ['name'],
                    ['asc'])
                  }
                  onChange={(name, value) => {
                    localStorage.setItem("tipoGuia",value);

                    conselhoService.findListUfsConselho().then(response=>{
                      if(response.data&&response.data.length>0) {
                          this.setState({listUfsConselho: response.data})
                      }
                    })
                
                    conselhoService.findAll().then(response=>{
                      if(response.data&&response.data.length>0) {
                          this.setState({listConselho: response.data})
                      }

                      this.handleAtedimentoType(value);

                    })

                  }}
                  returnFullObject={false}
                  labelKey={"name"}
                  valueKey={"label"}
                  placeholder="Tipo de guia de atendimento"
                />
              </div>
              <div className="p-1 status-guia">
                <span>Em Criação</span>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  handleChangeCriarGuiaPassoPasso = ativar => {
    modeloVisualizacaoService.toogleCriarGuiaPassoPasso(ativar).then(
      result => {
        let user = this.props.user;
        user.criarGuiaPassoPasso = ativar;
        this.props.refreshUser({ user });

        this.setState({ criarGuiaPassoPasso: ativar });
        if (this.state.atendimento.type) {
          this.handleAtedimentoType(this.state.atendimento.type);
        }
      },
      error => {
        console.error(error);
        this.props.error({ message: "Erro ao salvar modelo de visualização." });
      }
    );
  };

  render() {
    const { criarGuiaPassoPasso, etapasPassoPasso, etapaAtual } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid container-com-topo">
          <TopoTitleComponente
            mainTitle="Em criação"
            subTitle=" "
            canBack={true}
          />

          <div
            className={`action-line ${this.state.activeClass} fixed-container-topo`}
            id="pendenciaTopo"
          >
            {criarGuiaPassoPasso && (
              <PassoAPasso
                handelProximoPasso={this.proximoPasso}
                etapaAtual={etapaAtual}
                etapas={etapasPassoPasso}
                handleChangeCriarGuiaPassoPasso={
                  this.handleChangeCriarGuiaPassoPasso
                }
              ></PassoAPasso>
            )}
            {!criarGuiaPassoPasso && (
              <GuiaTopo
                handleChangeCriarGuiaPassoPasso={
                  this.handleChangeCriarGuiaPassoPasso
                }
                rowsCheckedIdValue={this.state.rowsCheckedIdValue}
                listaAtendimentos={[this.state.atendimento]}
                atendimentoEdicao={1}
              />
            )}
          </div>
          <MenuModelView
            titulo={""}
            subTitulo={"Modelo"}
            atendimentos={this.state.atendimentos}
          />
          <div id="conteudoAtendimentos" className={`contentComTopoFix`}>
            <hr className="text-center w-75 mb-20 mt-5"></hr>
            <div className="background-lines">
              <div className="bg-riscos"></div>
            </div>
            {this.renderAtendimentos(this.state.atendimento)}
          </div>
        </div>
        <ButtonToTop></ButtonToTop>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  pendencia: { togglePendencia, atendimentoAtual, closePendecia },
  authentication: { refreshUser }
}) => ({
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  clear: () => clear(),
  togglePendencia: () => togglePendencia(),
  closePendecia: () => closePendecia(),
  atendimentoAtual: atendimento => atendimentoAtual(atendimento),
  refreshUser: payload => refreshUser(payload)
});

function mapStateToProps(state) {
  const {
    pendenciaOpen,
    idAtendimentoAtual,
    resolverPendenciaOpen
  } = state.pendencia;
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;
  const { filtroAtendimento } = state.filtro;

  return {
    pendenciaOpen,
    resolverPendenciaOpen,
    idAtendimentoAtual,
    loggedIn,
    alert,
    user,
    permissions,
    filtroAtendimento
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(NovoAtendimento));
