//@flow
import * as React from "react";
import { connect } from "react-redux";
import SelectInput from "../../components/Inputs/SelectInput";
import { atendimentoService } from "../../services/atendimento.service";

type Props = {
    label: string,
    name: string,
    value: any,
    onChange: any,
    returnFullObject: boolean,
    messageValidate?: any,
    disabled?: boolean,
    size?: number,
    required: boolean,
    service: any
};

type State = {
    options: Array<any>
};

//const animatedComponents = makeAnimated();

class NumeroAtendimentoFilterSelectInput extends React.PureComponent<Props, State> {
    _isMounted = false;

    constructor(props: Props) {
        super(props);

        this.state = {
            options: [],
            value:null
        };
    }



    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <SelectInput
                isMulti={this.props.isMulti}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                onChange={(name,value)=>{ this.setState({value},()=>{if(value){this.props.onChange(name,value.numeroGuia)}})}}
                name={this.props.name}
                id={this.props.id}
                value={this.state.value}
                valueKey={"numeroGuia"}
                labelKey={"numeroGuia"}
                parent={this.props.filter}
                onFetchData={atendimentoService.findNumeroGuiaByFilterAtendimento}
                returnFullObject={true}
                required={this.props.required}
                clearable={true}
                className={this.props.className}
                placeholder={"Número da guia"}
                label={`Número da guia`}
                erroMensagem={this.props.erroMensagem}
            />
        );
    }
}

function mapStateToProps(state) {
    const { user } = state.authentication;
    return {
        user
    };
}

export default connect(mapStateToProps)(NumeroAtendimentoFilterSelectInput);
