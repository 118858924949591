import React, { PureComponent } from "react";
import Avatar from "react-avatar";
import { connect } from "react-redux";
import { formatterHelper } from "../../helpers/formatter.helper";
import { midiaService } from "../../services/midia.service";
import CropperView from "../../components/Imagen/CropperView";
import { userService } from "../../services/user.service";




let fileInput;

class UserAvatar extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.changeImage = this.changeImage.bind(this);
    this.state = {
      image: null,
      dataUrl: null
    };
  }



  getUserAvatar = () => {
    if (this.state.dataUrl) {
      return this.state.dataUrl;
    }
    if (this.props.user.midia) {
      return midiaService.url(this.props.user.midia.id);
    } else {
      return null;
    }
  };

  handleChangeAvatar = () => {
    if (this.props.editable) {
      this.openInputFile();
    }
  };

  changeImage(dataUrl) {
    let { image } = this.state;
    image.data = dataUrl;
    this.setState({ image: null, dataUrl }, () => {
      this.props.onChange(this.props.name, image);
    });
  }

  onChange = (e: SyntheticEvent<>) => {

    var files = e.target.files;
    if (files.length) {
      var self = this;
      var maxWidth = this.props.maxWidth;
      var maxHeight = this.props.maxHeight;
      let props = this.props;
      var imageVO = {
        nome: files[0].name,
        tamanho: files[0].size,
        contentType: files[0].type
      };
      self.resize(files[0], maxWidth, maxHeight, function (resizedDataUrl) {
        imageVO.data = resizedDataUrl;
        self.setState({ image: imageVO });
        //props.onChange(imageVO);
      });
    }
  };
  resize(file, maxWidth, maxHeight, fn) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (event) {
      var dataUrl = event.target.result;
      fn(dataUrl);
    };
  }

  openInputFile = (event: any) => {
    if (event) event.preventDefault();
    if (this.props.readOnly) {
      return false;
    }
    this.fileInput.click();
  };

  render() {
    const { image } = this.state;
    let size;


    if (!this.props.size) {
      size = 100;
    } else {
      size = this.props.size;
    }

    return (
      <div
        className={`${this.editable ? "action" : ""} ${this.props.className}`}
        onClick={this.handleChangeAvatar}
      >
        <input
          type="file"
          accept="image/*"
          onChange={this.onChange}
          className="d-none img-profile-input"
          ref={input => {
            this.fileInput = input;
          }}
        />
        {this.props.children}
        <CropperView
          modal={image ? true : false}
          onCropper={this.changeImage}
          titleBtn={"Cortar imagem"}
          title="Cortar imagem"
          subititle={""}
          maxHeight={500}
          maxWidth={500}
          dataUrl={image ? image.data : null}
        />
        <Avatar
          maxInitials={2}
          name={formatterHelper.getNameAndLastName(this.props.user.nome)}
          unstyled={true}
          className={`avatarCustom ${
            this.props.className
            } ${userService.getColorDefault(this.props.user, this.props.tipoPerfil)} ${this.getUserAvatar() && 'no-background'}`}
          size={size}
          src={this.getUserAvatar()}
        />
      </div>
    );
  }
}
export default connect()(UserAvatar);
