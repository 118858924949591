//@flow
import { urlsConstants } from "../constants/url.constant";
import { defaultService } from "./defaultService";

export const convenioPlanoService = {
    findByConvenio,
};


function findByConvenio(parent) {
    return defaultService.doGet(`${urlsConstants.CONVENIO_PLANO}findByConvenio/${parent}`);
}


