import * as _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { Modal, ModalBody } from 'reactstrap';
import Toast from '../../components/Toasts/Toast';
import Logo from '../../img/Logo.svg';
import voltar from '../../img/btn_voltar.svg';
import { userService } from '../../services/user.service';
import ComoSerUmCooperado from './ComoSerUmCooperado';
import CpfEncontrado from './CpfEncontrado';
import CpfNaoEncontrado from './CpfNaoEncontrado';
import EsqueciSenha from './EsqueciSenha';
import Login from './Login';
import SenhaEnviada from './SenhaEnviada';

class IndexLogin extends PureComponent<Props, State> {
    constructor(props) {
        super(props);
        let username = '';
        let password = '';

        if (process.env.NODE_ENV === 'development') {
            username = '91523419091'; //admin
            password = 'Coopmedrs123';
            // username = '02641058006'; //auditor   123inte
            // password = 'Code4@2020'; //admin
            // 484.990.300-25
            //username = '04361381666'; //admin
            //password = 'Code4@2020'; //multiperfil
            //password = 'Code4@2020'; //04361381666
            // password = 'Code4@2020'; //admin
            // username = '26302457050'; //SUDO
            //password = 'Code4@2020'; //SUDO
        }

        this.state = {
            entity: { username, password },
            cpfNaoEncontrado: false,
            cpfEncontrado: false,
            esqueciSenha: false,
            senhaEnviada: false,
            emailUsuario: '',
            togleComoSerUmCooperado: false,
            tooglePassoAPasso: true
        };
    }

    abreEmail = () => { }
    existByCpf = () => {
        //f (this.state.entity.captcha) {
        userService.existByCpf(this.state.entity.username).then(response => {
            if (response.data) {
                this.setState({ cpfEncontrado: true, emailUsuario: response.data.email });
            } else {
                this.setState({ cpfNaoEncontrado: true });
            }
        });
        //  } else {
        //     this.props.error('É necessario valida o captcha');
        // }
    };

    cancelarLogin = () => {
        this.setState({ esqueciSenha: false, cpfEncontrado: false, cpfNaoEncontrado: false, senhaEnviada: false });
    };


    togleComoSerUmCooperado = value => {
        this.setState({ togleComoSerUmCooperado: value });
    };

    login = () => {
        const { username, password, captcha } = this.state.entity;
        this.props.clear();
        if (username && password) {
            this.props.location.state.from.pathname = '/';
            this.props.login(username, password, captcha);
        } else {
            this.props.clear();
            this.props.error('Informe a Senha');
        }
    };

    esqueciSenha = () => {
        this.setState({ esqueciSenha: true });
    };

    recuperarSenha = () => {
        userService.esqueciSenha(this.state.entity.email).then(response => {
            if (response.data) {
                this.setState({ senhaEnviada: true });
            }
        });

    };

    handleChange = (name, value) => {
        const entity = _.clone(this.state.entity);
        _.set(entity, name, value);
        this.setState({ entity });
    };

    componentDidUpdate() {
        try {
            if (this.state.cpfEncontrado || this.state.cpfNaoEncontrado) {
                document.getElementById('img_logo').classList.add('menor');
                document.getElementById('btn_voltar').classList.remove('invisible');
            } else {
                document.getElementById('btn_voltar').classList.add('invisible');
                document.getElementById('img_logo').classList.remove('menor');
            }
        } catch {
            //se ja carregou a pagina nao repcisa nem remover nem add classes
        }
    }

    componentDidMount() {

        // this.props.warning({message:"ATENÇÃO: O sistema ficara indisponivel das 17:00 as 18:00 horas do dia 28/05/2021"})

    }

    tooglePassoAPasso = () => {
        this.setState({ tooglePassoAPasso: !this.state.tooglePassoAPasso })
    }

    render() {
        const { loggedIn } = this.props;

        let { from } = (this.props.location.state
            ? this.props.location.state.from.pathname === '/'
            : true)
            ? { from: { pathname: '/atendimento' } }
            : this.props.location.state;
        if (loggedIn) {
            return <Redirect to={from} />;
        }

        return (
            <React.Fragment>
                <Toast />

                <div className="container-fluid h-100 background-login d-flex">
                    <div className="box-branco">
                        <div className="row">
                            <div className="col-3 invisible" id="btn_voltar">
                                <a
                                    className="btn-voltar"
                                    onClick={() => {
                                        this.cancelarLogin();
                                    }}
                                >
                                    <img src={voltar} alt="voltar" />
                                </a>
                            </div>
                            <div className="col-6 text-center">
                                <img
                                    className={`logoInicial`}
                                    id="img_logo"
                                    src={Logo}
                                    alt="Logo Sasc conect"

                                />
                            </div>
                            <div className="col-3"></div>
                        </div>
                        {!this.state.cpfEncontrado &&
                            !this.state.cpfNaoEncontrado &&
                            !this.state.esqueciSenha &&
                            !this.state.senhaEnviada && (
                                <Login
                                    onChange={this.handleChange}
                                    username={this.state.entity.username}
                                    captcha={this.state.entity.captcha}
                                    existByCpf={this.existByCpf}
                                ></Login>
                            )}
                        {this.state.cpfNaoEncontrado &&
                            !this.state.esqueciSenha &&
                            !this.state.senhaEnviada && (
                                <CpfNaoEncontrado
                                    cancelarLogin={this.cancelarLogin}
                                    togleComoSerUmCooperado={this.togleComoSerUmCooperado}
                                    username={this.state.entity.username}
                                ></CpfNaoEncontrado>
                            )}{' '}
                        {this.state.cpfEncontrado &&
                            !this.state.esqueciSenha &&
                            !this.state.senhaEnviada && (
                                <CpfEncontrado
                                    cancelarLogin={this.cancelarLogin}
                                    login={this.login}
                                    esqueciSenha={this.esqueciSenha}
                                    onChange={this.handleChange}
                                    username={this.state.entity.username}
                                    password={this.state.entity.password}
                                ></CpfEncontrado>
                            )}

                        {this.state.esqueciSenha && !this.state.senhaEnviada && (
                            <EsqueciSenha
                                cancelarLogin={this.cancelarLogin}
                                recuperarSenha={this.recuperarSenha}
                                onChange={this.handleChange}
                                emailUsuario={this.state.emailUsuario}
                            ></EsqueciSenha>
                        )}


                        {this.state.senhaEnviada && (
                            <SenhaEnviada
                                cancelarLogin={this.cancelarLogin}
                                recuperarSenha={this.recuperarSenha}
                                onChange={this.handleChange}
                                emailUsuario={this.state.emailUsuario}
                            ></SenhaEnviada>
                        )}


                    </div>
                    {/* <div className="box-branco-pequeno d-flex">
            <div className="col-12 d-flex justify-content-center">
              <img src={logo_felicoop} alt="Logo felicoop" />
            </div>
          </div> */}
                </div>

                <Modal
                    isOpen={this.state.togleComoSerUmCooperado}
                    toggle={this.toggle}
                    backdrop="static"
                    modalClassName=""
                    className=""
                    centered={true}
                >
                    <ModalBody>
                        <ComoSerUmCooperado
                            togleComoSerUmCooperado={this.togleComoSerUmCooperado}
                        />
                    </ModalBody>
                </Modal>
                {/* <Modal
                    isOpen={this.state.tooglePassoAPasso}
                    toggle={this.tooglePassoAPasso}
                    backdrop="static"
                    modalClassName=""
                    className=""
                    centered={true}
                >
                    <ModalBody>
                        <div className="row">
                            <div className="col-12">
                                <a onClick={() => {
                                    this.tooglePassoAPasso();
                                }}
                                   className="btn-fechar d-flex justify-content-end">
                                    <img src={fechar} alt="fechar"/>
                                </a>
                            </div>
                            <div className="col-12 iconeCooperados text-center my-2">
                                <img src={icon_usuarioCooperado} alt="pessoas"/>
                            </div>
                            <div className="col-12 text-center my-2">
                                <h2 className="font-weight-bold">ATENÇÃO</h2>
                            </div>
                        </div>
                        Caso encontre algum problema no funcionamento do sistema, siga o passo a passo a seguir e tente
                        novamente.
                        <div>
                            <Link to="passoapasso.docx" target="_blank" download>Download</Link>
                        </div>
                    </ModalBody>
                </Modal>*/}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { loggedIn } = state.authentication;
    const { alert } = state;

    return {
        loggedIn,
        alert
    };
}

const mapDispatch = ({
    alert: { error, clear, success, warning },
    authentication: { login },
    load: { loading }
}) => ({
    error: (message, code) => error({ message, code }),
    clear: () => clear(),
    success: msg => success(msg),
    warning: msg => warning(msg),
    login: (user, password, captcha) => login({ user, password, captcha }),
    loading: (load: boolean) => loading({ load })
});
export default connect(
    mapStateToProps,
    mapDispatch
)(withRouter(IndexLogin));
