import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Logo from "../../img/Logo.svg";
import btnVoltar from "../../img/btn_voltar.svg";
import CardErrorImportacaoNotification from "./CardErrorImportacaoNotification";
import { v4 as uuidv4 } from 'uuid';

class ErrorImportacaoNotification extends PureComponent<Props, State> {
  gotoPage = page => {
    this.props.toogleErrorImportacaoNotification();
    this.props.history.push(page);
  };
  constructor(props) {
    super(props);
    this.state = {
      showUpdate: false,
    }
  }

  render() {
    const { showError, errorsImportacaoNotifications } = this.props;
    return (
      <div
        id="notificationContent"
        className={`d-flex flex-column fixed-top background-error-notification
        ${showError ? "move-in" : "move-out"}`}
      >
        <div className="bg-riscos bottom"></div>
        <header className="navbar fixed-top topo iconNotification px-4">
          <div
            className="btnVoltar mr-auto bd-highlight pl-3"
            onClick={() => {
              this.props.toogleErrorImportacaoNotification();
            }}
          >
            <img src={btnVoltar} alt="Voltar" className="backNotification" />
          </div>

          <div className="mr-auto bd-highlight">
            <img src={Logo} alt="Logo" className="iconLogo" />
          </div>
        </header>

        <div
          id="bodyNotification"
          className="bodyNotification d-flex flex-column h-100 mx-sm-auto"
        >
          <div
            className={`container-error-notifications ${!showError &&
              "d-none"}`}
          >
            {errorsImportacaoNotifications.length > 0 && (
              <React.Fragment>
                {/* COLOCAR NESTE P UMA CONDIÇÃO DE 0, 1 OU N NOTIFICAÇÕES */}
                <p className="qtdNotificacoes">
                  Você possui {errorsImportacaoNotifications.length} erros.
                </p>

                <hr className="max-hr-notifications"></hr>
                <div id="contentNotifications">
                  {errorsImportacaoNotifications.map((error) => {
                    return <CardErrorImportacaoNotification errorImportacaoNotifications={error} key={uuidv4()}/>;
                  })}
                </div>
              </React.Fragment>
            )}
            {/* SE NÃO EXISTIR NENHUMA NOTIFICAÇÃO ESSE H2 APARECE */}
            {errorsImportacaoNotifications.length === 0 && (
              <h2 className="semNotificacoes">
                Você possuí nenhum erro
              </h2>
            )}
          </div>
        </div>

        <footer>
          <p>© {new Date().getFullYear()} Sasc Conect - Fencom</p>
        </footer>
      </div>
    );
  }
}

const mapDispatch = ({
  load: { loading },
  errorImportacaoNotifications: { toogleErrorImportacaoNotification }
}) => ({
  toogleErrorImportacaoNotification: () => toogleErrorImportacaoNotification(),
  loading: (load: boolean) => loading({ load })
});

function mapStateToProps(state) {
  const { showError, errorsImportacaoNotifications } = state.errorImportacaoNotifications;
  const { user } = state.authentication;

  return {
    showError,
    user,
    errorsImportacaoNotifications
  };
}

export default connect(mapStateToProps, mapDispatch)(withRouter(ErrorImportacaoNotification));
