//@flow
import * as React from "react";
import { connect } from "react-redux";
import SelectInput from "../../components/Inputs/SelectInput";
import { statusAtendimentoService } from "../../services/statusAtendimento.service";

type Props = {
    label: string,
    name: string,
    value: any,
    onChange: any,
    returnFullObject: boolean,
    messageValidate?: any,
    disabled?: boolean,
    size?: number,
    required: boolean,
    service: any
};

type State = {
    options: Array<any>
};

//const animatedComponents = makeAnimated();

class StatusEncaminhamentoFilterSelectInput extends React.PureComponent<Props, State> {
    _isMounted = false;

    constructor(props: Props) {
        super(props);

        this.state = {
            options: [],
            listaStatusCooperativa:[]
        };
    }

componentDidMount(): void {
    statusAtendimentoService.findStatusByCooperativa().then(response => {
        let listaStatusCooperativa = response.data.map(iten => {
            return iten;
        });
        this.setState({listaStatusCooperativa});

    })
}

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <SelectInput
                onChange={this.props.onChange}
                label={'Status'}
                id={`filtro_status`}
                options={this.state.listaStatusCooperativa}
                name={this.props.name}
                placeholder=""
                style={"col-12 col-md-12"}
                returnFullObject={true}
                searchable={true}
                labelKey={"nome"}
                valueKey={"id"}
                value={ this.props.value}
                newOptionCreator={false}
                onNewOptionClick={false}
            />

        );
    }
}

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { filtroAtendimento } = state.filtro;
    return {
        user,
        filtroAtendimento
    };
}

export default connect(mapStateToProps)(StatusEncaminhamentoFilterSelectInput);
