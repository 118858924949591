import { urlsConstants } from "../constants/url.constant";
import { defaultService } from "./defaultService";
import {objectsConstants} from "../constants/objects.constants";
import _ from 'lodash';
export const beneficiarioService = {
    resetBeneficiario,
    findByCarteirinha,
    findByFilterAtendimento,
    findByCpf,
    validaTipoCarteirinha,
    findByNome,
    saveEmail,
    saveCelular,
    findAll,
    findTableColumns,
    findSearchableColumns,
    findByFilter,
    exportToExcel,
    doGet,
    doSave,
    getBloqueioAll
};

function findByCarteirinha(carteirinha) {
    return defaultService.doGet(`${urlsConstants.BENEFICIARIO}find/carteirinha/${carteirinha}`)
}

function findByCpf(cpf) {
    return defaultService.doGet(`${urlsConstants.BENEFICIARIO}find/cpf/${cpf}`)
}

function findByNome(nome) {
    return defaultService.doGet(`${urlsConstants.BENEFICIARIO}find/nome/${nome}`)
}

function findByFilterAtendimento(filter, input) {
    filter.descricaoFiltro = input;
    return defaultService.doPost(`${urlsConstants.ATENDIMENTO}getBeneficiarioByFiltroAtendimento`, filter);

}

function validaTipoCarteirinha(valorParam,convenio){
    let valor = _.clone(valorParam);
    if(!valor){
        return valor;
    }
        if(!convenio||!convenio.tipoCarteirinha){
            return valor.replace(/[^A-Za-z0-9]/g,'').toUpperCase();
        }
        for(let i =0 ; i<objectsConstants.TIPOS_VALIDACAO.length ; i++){
            if(objectsConstants.TIPOS_VALIDACAO[i].name ===convenio.tipoCarteirinha.name){
                return valor.replace(/[^A-Za-z0-9]/g,'').toUpperCase();
            }
        }
       return   valor.replace(/[^A-Za-z0-9.]/g,'').toUpperCase();
}

function resetBeneficiario(props) {
    props.values.atendimentoRn = undefined;
    props.values.cartaoNacionalSaude = undefined;
    props.values.cpf = undefined;
    props.values.dataNascimento = undefined;
    props.values.dataValidadeCarteira = undefined;
    props.values.nomeBeneficiario = undefined;
    props.values.numeroCarteira = undefined;
    props.values.plano = undefined;
}

function saveEmail(idBeneficario, email) {
    return defaultService.doPost(`${urlsConstants.BENEFICIARIO}saveEmail/${idBeneficario}`, email);
}

function saveCelular(idBeneficario, celular) {
    return defaultService.doPost(`${urlsConstants.BENEFICIARIO}saveCelular/${idBeneficario}`, celular);
}

function doSave(id, vo) {
    return defaultService.doPost(`${urlsConstants.BENEFICIARIO}save/${id}`, vo);
}

function findAll() {
    return defaultService.doGet(`${urlsConstants.BENEFICIARIO}list`);
}

function findTableColumns() {
    return defaultService.doGet(`${urlsConstants.BENEFICIARIO}list/fields`);
}

function findSearchableColumns() {
    return defaultService.doGet(`${urlsConstants.BENEFICIARIO}search/fields`);
}

function findByFilter(options: Options, filter: any): Promise<Array<>> {
    return defaultService.findByFiltro(
        `${urlsConstants.BENEFICIARIO}list`,
        options,
        filter
    );
}

function exportToExcel(filter, fields, labels) {
    return defaultService.exportToExcel('beneficiario', filter, fields, labels);
}

function doGet(id) {
    return defaultService.doGet(`${urlsConstants.BENEFICIARIO}${id}`);
}


function getBloqueioAll(atendimentos, idConvenio) {
    return defaultService.doPost(`${urlsConstants.BENEFICIARIO}bloqueio/${idConvenio}`, atendimentos);
}
