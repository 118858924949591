//@flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { InputType } from 'reactstrap';
import { datatableConstants } from '../../sascComponents/datagrid/constants/datatable.constants';
import CpfCnpjInput from './CpfCnpjInput';
import CnpjInput from "../../components/Inputs/CnpjInput";
import DatePickerInput from './DatePickerInput';
import FormInput from './FormInput';
//TODO ALTErar this.handleBorderColor(messageValidate)
type Props = {
  label: string,
  type: InputType,
  name: string,
  id: string,
  value?: string | string[] | number,
  onChange?: any,
};

class SearchFormInput extends PureComponent<Props, state> {
  constructor(props) {
    super(props);
    this.state = {
      type: this.props.type,
      typeDocument: this.props.typeDocument
    };

  }

  render() {
    const { type } = this.props;
    return (
      <SearchInput type={type} {...this.props}></SearchInput>
    );
  }
}

function SearchInput(props: any) {
  const { type,placeholder,typeDocument, ...otherProps } = props;
  switch (type) {
    case datatableConstants.NUMBER:
      return <FormInput type={"number"} placeholder={placeholder} {...otherProps}></FormInput>;
    case datatableConstants.STRING:
      return <FormInput placeholder={placeholder} {...otherProps}></FormInput>
    case datatableConstants.DATE:
      return <DatePickerInput
        {...otherProps}
        placeholder={"dd/MM/yyyy"}
        dateFormat={"yyyy-MM-dd"}
        noSize
      />

    case datatableConstants.DOCUMENT:
      if(typeDocument && typeDocument==="CNPJ"){
        return <CnpjInput placeholder={"Digite o número do documento"} {...otherProps}></CnpjInput>
      }else{
        return <CpfCnpjInput placeholder={"Digite o número do documento"} {...otherProps}></CpfCnpjInput>
      }

    default:
      return <FormInput placeholder={placeholder} {...otherProps}></FormInput>
  }
}

function mapStateToProps(state) {
  const { messages } = state.fieldMessage;
  const { alert } = state;

  return {
    messages,
    alert
  };
}

export default connect(mapStateToProps)(SearchFormInput);
