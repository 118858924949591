import React from 'react';
import { components } from 'react-select';

const ClearIndicator = (props) => {
    return (
        <components.ClearIndicator {...props}>
            <button
                type="button"
                className="btn-excluir"
            />
        </components.ClearIndicator>
    );
};

export default ClearIndicator;