import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SelectInput from "../../../components/Inputs/SelectInput";
import { objectsConstants } from "../../../constants/objects.constants";
import { cooperativaService } from "../../../services/cooperativa.service";
import { userService } from "../../../services/user.service";

class SelectCooperativaAtual extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = { cooperativaAtual: null, minhasCooperativas: [] };
  }

  componentWillMount() {
    const { userId } = this.props;
    if(this.props.tipoPerfilAtual===objectsConstants.SUDO){
      cooperativaService.findAll().then(response => {
        let options = response.data;
        this.setState({minhasCooperativas: options});
      })
    }else {
      cooperativaService.findAllByIdUser(userId).then(response => {
        let options = response.data;
        this.setState({minhasCooperativas: options});
      });
    }
  }

  gotoPage = (page, entity) => {
    this.props.history.push({
      pathname: page,
      state: {
        entity,
      }
    });
  };

  handleChange = (name, value) => {
    this.setState({ cooperativaAtual: value });
  };

  setUserInSetor = () => {
    this.props.loading(true);
    const { cooperativaAtual } = this.state;
    if(this.props.tipoPerfilAtual===objectsConstants.SUDO){
      userService.setCooperativaAtualSudo(cooperativaAtual.id).then(response => {
        const user = response.data;
        this.props.closeSelectPerfil({
          user, selectPerfilAuditor: false,
          selectPerfilAtual: false
        });
        this.props.loading(false);
        this.gotoPage("/cooperativa");
        this.props.fecharJanela();
      })


    }else {
      userService.setPerfilAtual(cooperativaAtual.id).then(response => {
        const user = response.data;
        let showAuditor = userService.isPerfil(user, objectsConstants.AUDITOR);
        this.props.closeSelectPerfil({
          user, selectPerfilAuditor: showAuditor,
          selectPerfilAtual: false
        });
        this.props.loading(false);
        this.props.fecharJanela();
      });
    }
  };

  render() {
    const { minhasCooperativas, cooperativaAtual } = this.state;
    const { show } = this.props;
    return (
      <div className={`box menu-auditor ${!show ? "fadeOut" : ""}`}>
        <div className="box-auditor">
          <h2>Como deseja acessar?</h2>
          <p>
            Selecione abaixo a Cooperativa que deseja acessar o Sasc Conect:
          </p>
          <label>Cooperativas</label>
          {this.props.tipoPerfilAtual===objectsConstants.SUDO&&(
              <SelectInput
                  className=""
                  name={"setor"}
                  value={cooperativaAtual}
                  options={minhasCooperativas}
                  onChange={this.handleChange}
                  returnFullObject={true}
                  labelKey={"nome"}
                  valueKey={"id"}
                  placeholder="Selecione a Cooperativa"
              />
          )}
          {this.props.tipoPerfilAtual!==objectsConstants.SUDO&&(
              <SelectInput
                  className=""
                  name={"setor"}
                  value={cooperativaAtual}
                  options={minhasCooperativas}
                  onChange={this.handleChange}
                  returnFullObject={true}
                  labelKey={"perfil.cooperativa.sigla"}
                  valueKey={"id"}
                  placeholder="Selecione a Cooperativa"
              />
          )}

        </div>
        <div className="botao-box">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={this.setUserInSetor}
            disabled={!cooperativaAtual}
          >
            Confirmar
          </button>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { selectPerfilAtual, user, } = state.authentication;
  return {
    selectPerfilAtual,
    user
  };
}

const mapDispatch = ({
  alert: { error, clear, success },
  authentication: { closeSelectPerfil },
  load: { loading }
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: msg => success(msg),
  closeSelectPerfil: payload => closeSelectPerfil(payload),
  loading: (load: boolean) => loading({ load })
});
export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(SelectCooperativaAtual));
