import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Card, CardBody, CardHeader } from 'reactstrap';
import BtnLink from '../../../components/Buttons/BtnLink';
import { FormGroup } from '../../../components/FormGroup';
import CpfInput from '../../../components/Inputs/CpfInput';
import FormInput from '../../../components/Inputs/FormInput';
import FormSelectInput from '../../../components/Inputs/FormSelectInput';
import InputViewEdit from '../../../components/Inputs/InputViewEdit';
import PhoneInput from '../../../components/Inputs/PhoneInput';
import ZipCodeInput from '../../../components/Inputs/ZipCodeInput';
import BancoSelectInput from '../../../sascComponents/banco/BancoSelectInput';
import ConvenioSelectInput from '../../../sascComponents/convenios/ConvenioSelectInput';
import EspecialidadesSelectInput from '../../../sascComponents/especialidades/EspecialidadesSelectInput';
import { conselhoService } from '../../../services/conselho.service';
import ApiIBGE from '../../../services/uf.service';
import { userService } from '../../../services/user.service';
import DatePickerInput from '../../../components/Inputs/DatePickerInput';

class CooperadoForm extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      consultorioParticular: false
    };
  }
  goToEdit = () => {
    this.props.history.push({
      pathname: '/cooperados/edit/' + this.props.values.id,
      state: { entity: this.props.values }
    });
  };
  goToEditAdmin = () => {
    this.props.history.push({
      pathname: '/cooperados/edit/' + this.props.values.id,
      state: { entity: this.props.values, editConvenio: true }
    });
  };
  render() {
    const {
      values,
      setFieldValue,
      errors,
      handleChange,
      getProfileName,
      isSubmitting,
      handleSubmit,
      viewMode,
      createMode,
      canEdit,
      editConvenio,
      readOnly
    } = this.props;
    return (
      <React.Fragment>
        <div className="item">
          <Card className={`cardCrud content-form ${viewMode&&'viewMode'}`}>
            <CardHeader>
              <div className="d-flex justify-content-between align-items-center">
                <div className="mr-sm-auto card-title">Dados</div>
                <div className="px-sm-3">
                  {canEdit && (
                    <button
                      type="button"
                      className="btn btn-light"
                      onClick={this.goToEdit}
                    >
                      <span className="icon-btn-editar"></span>Editar
                    </button>
                  )}
                </div>
              </div>
            </CardHeader>
            <div className="row section-form">
              <h6 className="w-100">Cooperado</h6>
            </div>
            <CardBody>
              <div className="row section-form">
                <FormGroup className="form-group-boorder col-12 col-sm-3  ">
                  <InputViewEdit
                    component={FormInput}
                    label={'Nome'}
                    type="text"
                    id="nome"
                    name="nome"
                    placeholder="Digite o nome"
                    value={values.nome}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    erroMensagem={errors.cooperado && errors.cooperado.nome}
                    viewMode={viewMode}
                    required={true}
                  />
                </FormGroup>
                <FormGroup className="form-group-boorder col-5 col-sm-2 text-center  ">
                  <InputViewEdit
                    component={FormSelectInput}
                    label={'Conselho profissional'}
                    id="conselho"
                    name="conselho"
                    placeholder="Digite o nome do usuário"
                    value={_.get(values, 'conselho')}
                    onChange={setFieldValue}
                    noSize={true}
                    erroMensagem={errors.conselho}
                    viewMode={viewMode}
                    required={true}
                    returnFullObject={true}
                    viewMode={viewMode}
                    defaultValue={viewMode && _.get(values, 'conselho.sigla')}
                    valueKey={'id'}
                    labelKey={'nome'}
                    multi={false}
                    required={this.props.required}
                    noSize={true}
                    clearable={true}
                    removeDropDown={true}
                    service={conselhoService.find}
                    defaultSelected={true}
                  />
                </FormGroup>

                <FormGroup className="form-group-boorder col-2 col-sm-1 text-center  ">
                  <InputViewEdit
                    component={FormSelectInput}
                    label={'UF'}
                    id="ufConselho"
                    name="ufConselho"
                    placeholder="Digite a UF do conselho"
                    value={_.get(values, 'ufConselho')}
                    onChange={setFieldValue}
                    noSize={true}
                    erroMensagem={errors.ufConselho}
                    viewMode={viewMode}
                    required={true}
                    returnFullObject={false}
                    viewMode={viewMode}
                    defaultValue={viewMode && _.get(values, 'ufConselho')}
                    valueKey={'sigla'}
                    labelKey={'nome'}
                    multi={false}
                    required={this.props.required}
                    noSize={true}
                    clearable={true}
                    removeDropDown={true}
                    service={ApiIBGE.findAllEstados}
                  />
                </FormGroup>

                <FormGroup className=" form-group-boorder col-5 col-sm-2 text-center ">
                  <InputViewEdit
                    component={FormInput}
                    label={'Número do conselho'}
                    type="text"
                    id="numeroConselho"
                    name="numeroConselho"
                    placeholder="Digite o número"
                    value={values.numeroConselho}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    erroMensagem={errors.numeroConselho}
                    viewMode={viewMode}
                    required={true}
                  />
                </FormGroup>

                <FormGroup className=" form-group-boorder col-6 col-sm-2 text-center ">
                  <InputViewEdit
                    component={FormInput}
                    label={'RG'}
                    type="text"
                    id="rg"
                    name="rg"
                    placeholder="Digite o número"
                    value={values.rg}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    viewMode={viewMode}
                  />
                </FormGroup>
                <FormGroup className=" form-group-boorder col-6 col-sm-2 text-center ">
                  <InputViewEdit
                    component={CpfInput}
                    label="CPF"
                    id="cpf"
                    name="cpf"
                    value={_.get(values, 'cpf')}
                    erroMensagem={errors.cooperado && errors.cooperado.cpf}
                    placeholder="Digite o CPF"
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    viewMode={viewMode}
                    required={true}
                  />
                </FormGroup>
                <FormGroup className=" form-group-boorder col-12 col-sm-8 ">
                  <InputViewEdit
                    component={EspecialidadesSelectInput}
                    label={'Especialidades'}
                    name="especialidades"
                    id="especialidades"
                    multi={true}
                    placeholder="Selecione as especialidades"
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    returnFullObject={true}
                    value={values.especialidades}
                    className="sasc-select"
                    required={true}
                    erroMensagem={errors.especialidades}
                    viewMode={viewMode}
                    defaultValue={
                      viewMode &&
                      (values.especialidades.length
                        ? values.especialidades
                          .map(item => item.nome)
                          .join(', ')
                        : '--')
                    }
                  ></InputViewEdit>
                </FormGroup>

                <FormGroup className="form-group-boorder col-6 col-sm-2 text-center  ">
                  <InputViewEdit
                    component={BancoSelectInput}
                    label={'Banco'}
                    id="pessoaDadoBancarioBancoNome"
                    name="pessoaDadoBancario.banco"
                    placeholder="Digite o nome do usuário"
                    value={_.get(values, 'pessoaDadoBancario.banco')}
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    noSize={true}
                    erroMensagem={
                      errors.pessoaDadoBancario &&
                      errors.pessoaDadoBancario.banco.id
                    }
                    viewMode={viewMode}
                    required={true}
                    returnFullObject={true}
                    viewMode={viewMode}
                    defaultValue={
                      viewMode && _.get(values, 'pessoaDadoBancario.banco.nome')
                    }
                  />
                </FormGroup>
                <FormGroup className="form-group-boorder col-6 col-sm-1 text-center ">
                  <InputViewEdit
                    component={FormInput}
                    label={'Agência'}
                    type="text"
                    id="pessoaDadoBancarioAgencia"
                    name="pessoaDadoBancario.agencia"
                    placeholder="Digite a agência"
                    value={_.get(values, 'pessoaDadoBancario.agencia')}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    erroMensagem={
                      errors.pessoaDadoBancario &&
                      errors.pessoaDadoBancario.agencia
                    }
                    viewMode={viewMode}
                    required={true}
                  />
                </FormGroup>
                <FormGroup className="form-group-boorder col-6 col-sm-1 text-center ">
                  <InputViewEdit
                    component={FormInput}
                    label={'Conta'}
                    type="text"
                    id="pessoaDadoBancarioContaCorrente"
                    name="pessoaDadoBancario.contaCorrente"
                    placeholder="Digite a conta"
                    value={_.get(values, 'pessoaDadoBancario.contaCorrente')}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    erroMensagem={
                      errors.pessoaDadoBancario &&
                      errors.pessoaDadoBancario.contaCorrente
                    }
                    viewMode={viewMode}
                    required={true}
                  />
                </FormGroup>

                <FormGroup className=" form-group-boorder col-6 col-sm-1 text-center ">
                  <InputViewEdit
                    component={DatePickerInput}
                    label={'Data nascimento'}
                    id="dataNascimento"
                    name="dataNascimento"
                    placeholder="Data nascimento"
                    value={values.dataNascimento}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    erroMensagem={errors.dataNascimento}
                    viewMode={viewMode}
                    showTimeSelect={false}
                    showTimeSelectOnly={false}
                    required={true}
                    format={'DD/MM/YYYY'}
                  />
                </FormGroup>

                <FormGroup className="form-group-boorder col-6 col-sm-2 text-center ">
                  <InputViewEdit
                    component={FormInput}
                    label={'Naturalidade'}
                    type="text"
                    id="naturalidade"
                    name="naturalidade"
                    value={_.get(values, 'naturalidade')}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    placeholder="Ex: Belo Horizonte"
                    viewMode={viewMode}
                  />
                </FormGroup>

                <FormGroup className="form-group-boorder col-6 col-sm-1 text-center ">
                  <InputViewEdit
                    component={FormInput}
                    label={'Nacionalidade'}
                    type="text"
                    id="nacionalidade"
                    name="nacionalidade"
                    value={_.get(values, 'nacionalidade')}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    placeholder="Ex: Brasileira"
                    viewMode={viewMode}
                  />
                </FormGroup>
                <FormGroup className="form-group-boorder col-6 col-sm-2 text-center ">
                  <InputViewEdit
                    component={FormInput}
                    label={'RNE - Registro nacional de estrangeiro'}
                    type="text"
                    id="rne"
                    name="rne"
                    value={_.get(values, 'rne')}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    viewMode={viewMode}
                  />
                </FormGroup>
                <FormGroup className="form-group-boorder col-6 col-sm-2 text-center ">
                  <InputViewEdit
                    component={DatePickerInput}
                    label={'Data de emissão RNE'}
                    id="rneDataEmissao"
                    name="rneDataEmissao"
                    placeholder="Data nascimento"
                    value={values.rneDataEmissao}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    viewMode={viewMode}
                    showTimeSelect={false}
                    showTimeSelectOnly={false}
                    required={false}
                    format={'DD/MM/YYYY'}
                  />
                </FormGroup>

                <FormGroup className="form-group-boorder col-6 col-sm-1 text-center r ">
                  <InputViewEdit
                    component={FormInput}
                    label={'Naturalização'}
                    type="text"
                    id="naturalizacao"
                    name="naturalizacao"
                    value={_.get(values, 'naturalizacao')}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    viewMode={viewMode}
                  />
                </FormGroup>
                <FormGroup className="form-group-boorder col-6 col-sm-1 text-center r ">
                  <InputViewEdit
                    component={FormInput}
                    label={'INSS'}
                    type="text"
                    id="inss"
                    name="inss"
                    value={_.get(values, 'inss')}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    viewMode={viewMode}
                  />
                </FormGroup>
                <FormGroup className="form-group-boorder col-6 col-sm-1 text-center r ">
                  <InputViewEdit
                    component={FormInput}
                    label={'ISS'}
                    type="text"
                    id="iss"
                    name="iss"
                    value={_.get(values, 'iss')}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    viewMode={viewMode}
                  />
                </FormGroup>
                <FormGroup className="form-group-boorder col-6 col-sm-1 text-center r ">
                  <InputViewEdit
                    component={FormInput}
                    label={'CNS'}
                    type="text"
                    id="cns"
                    name="cns"
                    value={_.get(values, 'cns')}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    viewMode={viewMode}
                  />
                </FormGroup>
                <FormGroup className="form-group-boorder col-6 col-sm-6 text-center r ">
                  <InputViewEdit
                    component={FormInput}
                    label={'Nome da mãe'}
                    type="text"
                    id="nomeMae"
                    name="nomeMae"
                    value={_.get(values, 'nomeMae')}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    viewMode={viewMode}
                  />
                </FormGroup>
                <FormGroup className="form-group-boorder col-6 col-sm-6 text-center r ">
                  <InputViewEdit
                    component={FormInput}
                    label={'Nome do pai'}
                    type="text"
                    id="nomePai"
                    name="nomePai"
                    value={_.get(values, 'nomePai')}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    viewMode={viewMode}
                  />
                </FormGroup>
                <FormGroup className="form-group-boorder col-12 col-sm-5 r">
                  <InputViewEdit
                    component={FormInput}
                    label={
                      'Classificação da condição do trabalhador estrangeiro no Brasil'
                    }
                    type="text"
                    id="classificacaoTrabalhadorEstrangeiro"
                    name="classificacaoTrabalhadorEstrangeiro"
                    value={_.get(values, 'classificacaoTrabalhadorEstrangeiro')}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    viewMode={viewMode}
                  />
                </FormGroup>
                <FormGroup className="form-group-boorder col-6 col-sm r ">
                  <InputViewEdit
                    component={FormInput}
                    label={'Raça/cor'}
                    type="text"
                    id="racaCor"
                    name="racaCor"
                    value={_.get(values, 'racaCor')}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    viewMode={viewMode}
                  />
                </FormGroup>
                <FormGroup className="form-group-boorder col-6 col-sm r ">
                  <InputViewEdit
                    component={FormInput}
                    label={'Deficiência'}
                    type="text"
                    id="deficiencia"
                    name="deficiencia"
                    value={_.get(values, 'deficiencia')}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    viewMode={viewMode}
                  />
                </FormGroup>
                <FormGroup className="form-group-boorder col-6 col-sm text-center  ">
                  <InputViewEdit
                    component={FormInput}
                    label={'Sexo'}
                    type="text"
                    id="sexo"
                    name="sexo"
                    value={_.get(values, 'sexo')}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    viewMode={viewMode}
                  />
                </FormGroup>
                <FormGroup className="form-group-boorder col-6 col-sm text-center  ">
                  <InputViewEdit
                    component={FormInput}
                    label={'Estado Civil'}
                    type="text"
                    id="estadoCivil"
                    name="estadoCivil"
                    value={_.get(values, 'estadoCivil')}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    viewMode={viewMode}
                  />
                </FormGroup>
              </div>

              <div className="row section-form mt-3">
                <h6 className="w-100">Contatos</h6>
                <FormGroup className="form-group-boorder col-12 col-md-10  ">
                  <InputViewEdit
                    component={FormInput}
                    label={'E-mail'}
                    type="email"
                    id="email"
                    name="email"
                    value={_.get(values, 'email')}
                    erroMensagem={errors.secretaria && errors.email}
                    placeholder="Digite o e-mail"
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    viewMode={viewMode}
                    required={true}
                  />
                </FormGroup>
                <FormGroup className=" form-group-boorder col-12 col-md-2  ">
                  <InputViewEdit
                    component={PhoneInput}
                    type="number"
                    id="celular"
                    name="celular"
                    value={_.get(values, 'celular')}
                    label="Celular"
                    placeholder="Digite o celular"
                    onChange={handleChange}
                    noSize={true}
                    viewMode={viewMode}
                  />
                </FormGroup>
              </div>
              {!userService.isConsultorio(this.props.user) && (
                <div className="row section-form mt-3">
                  <h6 className="w-100">
                    Consultório particular
                    {/* <CheckBoxInput
                    label={"Consultório particular"}
                    name={"requiredConsultorioParticular"}
                    id={"requiredConsultorioParticular"}
                    checked={
                      values.consultorioParticular ||
                      this.state.consultorioParticular
                    }
                    onChange={(checked, name) => {
                      this.setState({ consultorioParticular: checked });
                      setFieldValue("requiredConsultorioParticular", checked);
                    }}
                    disabled={viewMode && !createMode}
                  /> */}
                  </h6>
                  <FormGroup className="form-group-boorder col-12 col-md-1 ">
                    <InputViewEdit
                      component={ZipCodeInput}
                      type="number"
                      label="CEP"
                      id="consultorioParticularId"
                      name="consultorioParticular.id"
                      value={_.get(values, 'consultorioParticular.cep')}
                      erroMensagem={
                        errors.consultorioParticular &&
                        errors.consultorioParticular.cep
                      }
                      placeholder="CEP"
                      onChangeCEP={(name,value)=>{
                        setFieldValue(name, value);
                      }}
                      onChange={(name, value) =>
                        setFieldValue('consultorioParticular', value)
                      }
                      viewMode={viewMode && !createMode}
                      disabled={!this.state.consultorioParticular}
                      required={this.state.consultorioParticular}
                    />
                  </FormGroup>
                  <FormGroup className="form-group-boorder col-12 col-md-3 ">
                    <InputViewEdit
                      component={FormInput}
                      label={'Rua'}
                      type="text"
                      id="enderecoLogradouro"
                      name="consultorioParticular.logradouro"
                      value={_.get(values, 'consultorioParticular.logradouro')}
                      erroMensagem={
                        errors.consultorioParticular &&
                        errors.consultorioParticular.logradouro
                      }
                      placeholder="Digite a rua"
                      onChange={(name, value) => setFieldValue(name, value)}
                      noSize={true}
                      viewMode={viewMode && !createMode}
                      disabled={!this.state.consultorioParticular}
                      required={this.state.consultorioParticular}
                    />
                  </FormGroup>
                  <FormGroup className="form-group-boorder col-12 col-md-1 ">
                    <InputViewEdit
                      component={FormInput}
                      label={'Número'}
                      type="text"
                      id="enderecoNumero"
                      name="consultorioParticular.numero"
                      placeholder="Número"
                      value={_.get(values, 'consultorioParticular.numero')}
                      erroMensagem={
                        errors.consultorioParticular &&
                        errors.consultorioParticular.numero
                      }
                      onChange={(name, value) => setFieldValue(name, value)}
                      noSize={true}
                      viewMode={viewMode && !createMode}
                      disabled={!this.state.consultorioParticular}
                    />
                  </FormGroup>
                  <FormGroup className="form-group-boorder col-12 col-md-1 ">
                    <InputViewEdit
                      component={FormInput}
                      label={'Complemento'}
                      type="text"
                      id="enderecoComplemento"
                      name="consultorioParticular.complemento"
                      placeholder="Complemento"
                      value={_.get(values, 'consultorioParticular.complemento')}
                      onChange={(name, value) => setFieldValue(name, value)}
                      noSize={true}
                      viewMode={viewMode && !createMode}
                      required={false}
                      disabled={!this.state.consultorioParticular}
                    />
                  </FormGroup>
                  <FormGroup className="form-group-boorder col-12 col-md-1 ">
                    <InputViewEdit
                      component={FormInput}
                      label={'Bairro'}
                      type="text"
                      id="enderecoBairro"
                      name="consultorioParticular.bairro"
                      placeholder="Bairro"
                      value={_.get(values, 'consultorioParticular.bairro')}
                      onChange={(name, value) => setFieldValue(name, value)}
                      noSize={true}
                      erroMensagem={
                        errors.consultorioParticular &&
                        errors.consultorioParticular.bairro
                      }
                      viewMode={viewMode && !createMode}
                      disabled={!this.state.consultorioParticular}
                      required={this.state.consultorioParticular}
                    />
                  </FormGroup>
                  <FormGroup className="form-group-boorder col-12 col-md-1 ">
                    <InputViewEdit
                      component={FormInput}
                      label={'Cidade'}
                      type="text"
                      id="enderecoCidadeNome"
                      name="consultorioParticular.cidade.nome"
                      placeholder="Cidade"
                      value={_.get(values, 'consultorioParticular.cidade.nome')}
                      onChange={(name, value) => setFieldValue(name, value)}
                      noSize={true}
                      viewMode={viewMode && !createMode}
                      disabled={!this.state.consultorioParticular}
                      erroMensagem={
                        errors.consultorioParticular &&
                        errors.consultorioParticular.cidade
                      }
                      required={this.state.consultorioParticular}
                    />
                  </FormGroup>
                  <FormGroup className="form-group-boorder col-12 col-md-2 ">
                    <InputViewEdit
                      component={FormInput}
                      type="text"
                      id="consultorioParticular.responsavel"
                      name="consultorioParticular.responsavel"
                      value={_.get(values, 'consultorioParticular.responsavel')}
                      label="Responsável"
                      placeholder="Digite o nome"
                      onChange={(name, value) => setFieldValue(name, value)}
                      noSize={true}
                      viewMode={viewMode && !createMode}
                      disabled={!this.state.consultorioParticular}
                      erroMensagem={
                        errors.consultorioParticular &&
                        errors.consultorioParticular.responsavel
                      }
                      required={this.state.consultorioParticular}
                    />
                  </FormGroup>
                  <FormGroup className="form-group-boorder col-12 col-md-2 ">
                    <InputViewEdit
                      component={PhoneInput}
                      type="number"
                      id="consultorioParticular.telefone"
                      name="consultorioParticular.telefone"
                      value={_.get(values, 'consultorioParticular.telefone')}
                      label="Telefone comercial"
                      placeholder="Digite o telefone"
                      onChange={handleChange}
                      noSize={true}
                      viewMode={viewMode && !createMode}
                      disabled={!this.state.consultorioParticular}
                      erroMensagem={
                        errors.consultorioParticular &&
                        errors.consultorioParticular.telefone
                      }
                      required={this.state.consultorioParticular}
                    />
                  </FormGroup>
                </div>
              )}
              <div className="row section-form mt-3">
                <h6 className="w-100">Endereço residêncial</h6>
                <FormGroup className="form-group-boorder col-12 col-md-2 ">
                  <InputViewEdit
                    component={ZipCodeInput}
                    type="number"
                    label="CEP"
                    id="enderecoId"
                    name="endereco.id"
                    value={_.get(values, 'endereco.cep')}
                    erroMensagem={errors.endereco && errors.endereco.cep}
                    required={true}
                    placeholder="CEP"
                    required={true}
                    onChangeCEP={(name,value)=>{
                      setFieldValue(name, value);
                    }}
                    ref={input => {
                      this._cep = input;
                    }}
                    onChange={(name, value) => setFieldValue('endereco', value)}
                    viewMode={viewMode}
                  />
                </FormGroup>
                <FormGroup className="form-group-boorder col-12 col-md-4 ">
                  <InputViewEdit
                    component={FormInput}
                    label={'Rua'}
                    type="text"
                    id="enderecoLogradouro"
                    name="endereco.logradouro"
                    value={_.get(values, 'endereco.logradouro')}
                    erroMensagem={errors.endereco && errors.endereco.logradouro}
                    placeholder="Digite a rua"
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    viewMode={viewMode}
                    required={true}
                  />
                </FormGroup>
                <FormGroup className="form-group-boorder col-12 col-md-1 ">
                  <InputViewEdit
                    component={FormInput}
                    label={'Número'}
                    type="text"
                    id="enderecoNumero"
                    name="endereco.numero"
                    placeholder="Número"
                    value={_.get(values, 'endereco.numero')}
                    erroMensagem={errors.endereco && errors.endereco.numero}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    viewMode={viewMode}
                    required={true}
                  />
                </FormGroup>
                <FormGroup className="form-group-boorder col-12 col-md-1 ">
                  <InputViewEdit
                    component={FormInput}
                    label={'Complemento'}
                    type="text"
                    id="enderecoComplemento"
                    name="endereco.complemento"
                    placeholder="Complemento"
                    value={_.get(values, 'endereco.complemento')}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    viewMode={viewMode}
                    required={false}
                  />
                </FormGroup>
                <FormGroup className="form-group-boorder col-12 col-md-2 ">
                  <InputViewEdit
                    component={FormInput}
                    label={'Bairro'}
                    type="text"
                    id="enderecoBairro"
                    name="endereco.bairro"
                    placeholder="Bairro"
                    value={_.get(values, 'endereco.bairro')}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    erroMensagem={errors.endereco && errors.endereco.bairro}
                    viewMode={viewMode}
                    required={true}
                  />
                </FormGroup>
                <FormGroup className="form-group-boorder col-12 col-md-2 ">
                  <InputViewEdit
                    component={FormInput}
                    label={'Cidade'}
                    type="text"
                    id="enderecoCidadeNome"
                    name="endereco.cidade.nome"
                    placeholder="Cidade"
                    value={_.get(values, 'endereco.cidade.nome')}
                    erroMensagem={
                      errors.endereco &&
                      errors.endereco.cidade &&
                      errors.endereco.cidade.nome
                    }
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    viewMode={viewMode}
                    required={true}
                  />
                </FormGroup>
              </div>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  null
)(withRouter(CooperadoForm));
