//@flow
import * as React from "react";
import { connect } from "react-redux";
import SelectInput from "../../components/Inputs/SelectInput";

type Props = {
    label: string,
    name: string,
    value: any,
    onChange: any,
    returnFullObject: boolean,
    messageValidate?: any,
    disabled?: boolean,
    size?: number,
    required: boolean,
    service: any
};

type State = {
    options: Array<any>
};

//const animatedComponents = makeAnimated();

class EvolucaoClinicaFilterSelectInput extends React.PureComponent<Props, State> {
    _isMounted = false;

    constructor(props: Props) {
        super(props);

        this.state = {
            options: [],
            listaEvolucaoClinica: []
        };
    }

componentDidMount(): void {
   
    this.setState({listaEvolucaoClinica: [{possuiEvolucaoClinica: "true", nome:"Sim", id: 1},{possuiEvolucaoClinica: "false", nome:"Não", id: 2}]});

}

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <SelectInput
                onChange={this.props.onChange}
                label={'Evolução Clínica'}
                id={`filtro_evolucao_clinica`}
                options={this.state.listaEvolucaoClinica}
                name={this.props.name}
                placeholder=""
                style={"col-12 col-md-12"}
                returnFullObject={true}
                searchable={true}
                labelKey={"nome"}
                valueKey={"possuiEvolucaoClinica"}
                value={ this.props.value}
                newOptionCreator={false}
                onNewOptionClick={false}
            />

        );
    }
}

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { filtroAtendimento } = state.filtro;
    return {
        user,
        filtroAtendimento
    };
}

export default connect(mapStateToProps)(EvolucaoClinicaFilterSelectInput);
