import VisualizarPendencia from "./VisualizarPendencia";
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import VisualizarLote from "./VisualizarLote";

export default class Redirect extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/visualizarPendencia/:id" component={VisualizarPendencia} exact/>
          <Route path="/visualizarLote/:id" component={VisualizarLote} exact/>
        </Switch>
      </React.Fragment>
    );
  }
}
