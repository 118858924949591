//@flow
type State = {
  lotesOpen: boolean,
  idLoteAtual: Number
};

export default {
  state: { lotesOpen: false, idLoteAtual: 0 },
  reducers: {
    toggleLotes: (state: State) => {
      return Object.assign({}, state, {
        lotesOpen: !state.lotesOpen
      });
    },
    abreLotes: (state: State) => {
      return Object.assign({}, state, {
        lotesOpen: true
      });
    },
    fechaLotes: (state: State) => {
      return Object.assign({}, state, {
        lotesOpen: false
      });
    },
    loteAtual: (state: State, payload: Object) => {
      return Object.assign({}, state, {
        idLoteAtual: payload.idLoteAtual
      });
    }
  }
};
