import * as _ from "lodash";
import moment from "moment/moment";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FormGroup } from "../../../../components/FormGroup";
import DatePickerInput from "../../../../components/Inputs/DatePickerInput";
import DateTimePicker from "../../../../components/Inputs/DateTimePicker";
import FormInput from "../../../../components/Inputs/FormInput";
import InputViewEdit from "../../../../components/Inputs/InputViewEdit";
import MoneyInput from "../../../../components/Inputs/MoneyInput";
import SelectInput from "../../../../components/Inputs/SelectInput";
import { objectsConstants } from "../../../../constants/objects.constants";
import { dateHelper } from "../../../../helpers/date.helper";
import { formatterHelper } from "../../../../helpers/formatter.helper";
import SelectableInput from "../../../../sascComponents/inputs/SelectableInput";
import { procedimentoService } from "../../../../services/procedimento.service";
import FormSelectInput from "../../../../components/Inputs/FormSelectInput";
import { viaAcessoService } from "../../../../services/viaAcesso.service";
import { tecnicaService } from "../../../../services/tecnica.service";
import { atendimentoService } from "../../../../services/atendimento.service";
import { convenioService } from "../../../../services/convenio.service";
import { Modal, ModalBody } from "reactstrap";
import fechar from "../../../../img/icon_fechar_BLACK.svg";

class DadosExecucaoGuiaHonorarioForm extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      toogleModalMedicoPrincipal: false,
      exibirMedicoPrincipal: true,
      copyMedico: false,
    };
  }

  toogleModalMedicoPrincipal = () => {
    if (
      this.state.toogleModalMedicoPrincipal ||
      this.state.exibirMedicoPrincipal
    ) {
      this.setState({
        toogleModalMedicoPrincipal: !this.state.toogleModalMedicoPrincipal,
        exibirMedicoPrincipal: false,
      });
    } else {
      this.addProcedimento();
    }
  };

  getValorTotal = (indexExecucao) => {
    const { values } = this.props;
    let valorTotal = 0;
    if (
      values.atendimentoExecucaos[indexExecucao].valorUnitario &&
      values.atendimentoExecucaos[indexExecucao].fator &&
      values.atendimentoExecucaos[indexExecucao].quantidade
    ) {
      valorTotal =
        values.atendimentoExecucaos[indexExecucao].valorUnitario *
        values.atendimentoExecucaos[indexExecucao].fator *
        values.atendimentoExecucaos[indexExecucao].quantidade;
    }
    return formatterHelper.formatMoney(valorTotal, 2, ",", ".");
  };
  getOptions = (size) => {
    let arrOptions = [];
    for (let index = 1; index <= size; index++) {
      arrOptions.push({ label: index, value: index });
    }
    return arrOptions;
  };

  addProcedimento_BKP = () => {
    const { values, setValues } = this.props;
    const medicoPrincipal = this.state.copyMedico;
    let newValues = _.cloneDeep(values);
    let numero = newValues.atendimentoExecucaos.length + 1;
    this.copiaHoraProcedimento(newValues, numero);
    if (!medicoPrincipal) {
      newValues.identificacaoAtendimentoExecucao.push({
        idList: new Date().getTime(),
        numero,
        type: objectsConstants.ENUM_TIPOS_GUIA.HONORARIO,
      });
    } else {
      newValues.identificacaoAtendimentoExecucao.push({
        idList: new Date().getTime(),
        numero,
        type: objectsConstants.ENUM_TIPOS_GUIA.HONORARIO,
        cooperado: values.identificacaoAtendimentoExecucao[0].cooperado,
      });
    }
    setValues(newValues);
  };

  addProcedimento = () => {
    const { values, setValues } = this.props;
    const medicoPrincipal = this.state.copyMedico;
    let newValues = _.cloneDeep(values);
    let numero = newValues.atendimentoExecucaos.length + 1;
    let enviarPj = values.estabelecimento.enviarClinica;
    //newValues.atendimentoExecucaos.push({ idList: new Date().getTime(), numero, type: objectsConstants.ENUM_TIPOS_GUIA.HONORARIO});
    this.copiaHoraProcedimento(newValues, numero);
    if (!medicoPrincipal) {
      newValues.identificacaoAtendimentoExecucao.push({
        idList: new Date().getTime(),
        numero,
        type: objectsConstants.ENUM_TIPOS_GUIA.HONORARIO,
        enviarPj,
      });
    } else {
      newValues.identificacaoAtendimentoExecucao.push({
        idList: new Date().getTime(),
        numero,
        type: objectsConstants.ENUM_TIPOS_GUIA.HONORARIO,
        cooperado: values.identificacaoAtendimentoExecucao[0].cooperado,
        enviarPj,
      });
    }
    setValues(newValues);
  };

  removeProcedimento_BKP = (indexProcedimento) => {
    const { values, setValues } = this.props;
    let newValues = _.cloneDeep(values);
    let procedimentoExecucao =
      newValues.atendimentoExecucaos[indexProcedimento];
    newValues.atendimentoExecucaos.splice(indexProcedimento, 1);
    //remove execucao relacionada
    _.remove(newValues.identificacaoAtendimentoExecucao, {
      numero: procedimentoExecucao.numero,
    });
    //se remover todos os elementos add um padrão
    if (!newValues.identificacaoAtendimentoExecucao.length) {
      newValues.identificacaoAtendimentoExecucao = [
        {
          numero: 1,
          codigoCbo: null,
          grau: "",
        },
      ];
    }
    //reordena lista de execucao para bater index
    newValues.identificacaoAtendimentoExecucao.map(
      (identificacao, indexIdentificacao) => {
        if (indexProcedimento < identificacao.numero) {
          identificacao.numero = identificacao.numero - 1;
        }
      }
    );
    //reordena lista de dados execucao para bater index
    newValues.atendimentoExecucaos.map(
      (atendimentoExecucao, indexIdentificacao) => {
        if (indexProcedimento < atendimentoExecucao.numero) {
          atendimentoExecucao.numero = atendimentoExecucao.numero - 1;
        }
      }
    );
    newValues.atendimentoProcedimentos.map(
      (atendimentoExecucao, indexIdentificacao) => {
        atendimentoExecucao.numero = indexIdentificacao + 1;
      }
    );

    setValues(newValues);
    this.props.clear();
    this.props.info({
      message: `Procedimento removido com sucesso!`,
    });
  };

  removeProcedimento = (indexProcedimento) => {
    const { values, setValues } = this.props;
    let newValues = _.cloneDeep(values);
    let atendimentoExecucaos =
      newValues.atendimentoExecucaos[indexProcedimento];
    newValues.atendimentoExecucaos.splice(indexProcedimento, 1);
    _.remove(newValues.identificacaoAtendimentoExecucao, {
      numero: atendimentoExecucaos.numero,
    });
    //se remover todos os elementos add um padrão
    if (!newValues.identificacaoAtendimentoExecucao.length) {
      newValues.identificacaoAtendimentoExecucao = [
        {
          numero: 1,
          codigoCbo: null,
          grau: "",
        },
      ];
    }
    //reordena lista de execucao para bater index
    newValues.identificacaoAtendimentoExecucao.map((identificacao) => {
      if (indexProcedimento < identificacao.numero) {
        identificacao.numero =
          identificacao.numero > 1 ? identificacao.numero - 1 : 1;
      }
    });
    //reordena lista de dados execucao para bater index
    newValues.atendimentoExecucaos.map((atendimentoExecucao) => {
      if (indexProcedimento < atendimentoExecucao.numero) {
        atendimentoExecucao.numero =
          atendimentoExecucao.numero > 1 ? atendimentoExecucao.numero - 1 : 1;
      }
    });
    newValues.atendimentoProcedimentos.map(
      (atendimentoExecucao, indexIdentificacao) => {
        if (atendimentoExecucao && atendimentoExecucao.numero) {
          atendimentoExecucao.numero = indexIdentificacao + 1;
        }
      }
    );
    setValues(newValues);
    this.props.clear();
    this.props.info({
      message: `Procedimento removido com sucesso!`,
    });
  };

  verificaRetorno = (name, date) => {
    this.props.verificaRetorno(`tipoConsulta`, name, date);
  };

  copiaHoraProcedimento(newValues, numero) {
    return newValues.atendimentoExecucaos.push({
      idList: new Date().getTime(),
      numero,
      type: objectsConstants.ENUM_TIPOS_GUIA.HONORARIO,
      horaInicial: newValues.atendimentoExecucaos[0].horaInicial,
      horaFinal: newValues.atendimentoExecucaos[0].horaFinal,
      dataAtendimento: newValues.atendimentoExecucaos[0].dataAtendimento,
      tabela: newValues.atendimentoExecucaos[0].tabela,
    });
  }

  getGuiaPadrao = (convenio, tipoGuia, codigo, values) => {
    const { setValues } = this.props;

    atendimentoService
      .findGuiaPadrao(tipoGuia, convenio, codigo)
      .then((response) => {
        const guiaPadrao = response.data;
        const newValue = _.cloneDeep(values);
        Object.keys(newValue).map((v) => {
          if (newValue[v] === null) {
            Object.keys(guiaPadrao).map((g) => {
              if (g === v && guiaPadrao[g] != null) {
                newValue[v] = guiaPadrao[g];
              }
            });
          }
        });

        setValues(newValue);
      });
  };

  alocarQuantidade = (values, date) => {
    let quant = null;
    values.convenio.listRegrasProcedimentoTipoGuia.forEach((r) => {
      if (values.type.includes(r.tipoGuia.nome))
        if (r.procedimentos.some((p) => p.codigo === date.codigo)) {
          if (quant === null || quant < r.quantidade) quant = r.quantidade;
        }
    });
    return quant;
  };

  alocarAcomodacao = (values, date) => {
    let acomodacao = null;
    values.convenio.listRegrasProcedimentoTipoGuia.forEach((r) => {
      if (values.type.includes(r.tipoGuia.nome))
        if (r.procedimentos.some((p) => p.codigo === date.codigo)) {
          if (acomodacao === null || acomodacao !== r.acomodacao)
            acomodacao = r.acomodacao;
        }
    });
    return acomodacao;
  };

  validarProcedimento = (values, codigo) => {
    if (values.convenio && values.convenio.listRegrasProcedimentoTipoGuia) {
      let validacao = values.convenio.listRegrasProcedimentoTipoGuia.some(
        (r) => {
          if (values.type.includes(r.tipoGuia.nome))
            return r.procedimentos.some((p) => p.codigo === codigo);
          return false;
        }
      );
      return validacao;
    }
  };

  sendToStorange = (values) => {
    try {
      values.rascunho &&
        localStorage.setItem("rascunho", JSON.stringify(values));
    } catch (error) {
      if (
        error instanceof DOMException &&
        (error.code === 22 ||
          error.code === 1014 ||
          error.name === "QuotaExceededError" ||
          error.name === "NS_ERROR_DOM_QUOTA_REACHED")
      ) {
        console.error("Limite de armazenamento local atingido");
      } else {
        console.error("Erro ao salvar no armazenamento local:", error.message);
      }
    }
  };

  render() {
    const { setFieldValue, values, viewMode, setValues, errors } = this.props;
    if (
      values.atendimentoExecucaos == null ||
      values.atendimentoExecucaos.length == 0
    ) {
      let initAtendimentoExecucao = objectsConstants.INIT_ATENDIMENTO_EXECUCAO;
      initAtendimentoExecucao.type = values.type;
      for (
        let listprocedimentos = 0;
        listprocedimentos < values.atendimentoProcedimentos.length;
        listprocedimentos++
      ) {
        _.set(
          values,
          `atendimentoExecucaos[${listprocedimentos}]`,
          initAtendimentoExecucao
        );
        _.set(
          values,
          `atendimentoExecucaos[${listprocedimentos}].codigoProcedimento`,
          values.atendimentoProcedimentos[listprocedimentos].codigoProcedimento
        );
        _.set(
          values,
          `atendimentoExecucaos[${listprocedimentos}].descricao`,
          values.atendimentoProcedimentos[listprocedimentos].descricao
        );
      }
    }

    const { convenio } = values;
    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.toogleModalMedicoPrincipal}
          toggle={this.toggle}
          backdrop="static"
          modalClassName=""
          className=""
          centered={true}
        >
          <ModalBody>
            <div className="modalCooperado">
              <div className="row">
                <div className="col-10 text-center my-2">
                  <h2 className="font-weight-bold">Dados Profissional</h2>
                </div>
                <div className="col-2">
                  <a
                    onClick={() => {
                      this.toogleModalMedicoPrincipal();
                    }}
                    className="btn-fechar d-flex justify-content-end"
                  >
                    <img src={fechar} alt="fechar" className="pointer" />
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="flex-grow px-3 m-auto justify-content-center">
                  <label className="font-12px">
                    Deseja que os dados do profissional selecionado
                    anteriormente sejam os mesmos para o próximo procedimento?
                  </label>
                </div>
                <div className="flex-grow px-3 m-auto  pt-3 justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-primary mr-2"
                    disabled={false}
                    onClick={() => {
                      this.setState({ copyMedico: true }, () => {
                        this.toogleModalMedicoPrincipal();
                        this.addProcedimento();
                      });
                    }}
                  >
                    {"SIM"}
                  </button>
                  <button
                    type="submit"
                    className="btn btn-danger "
                    disabled={false}
                    onClick={() => {
                      this.setState({ copyMedico: false }, () => {
                        this.toogleModalMedicoPrincipal();
                        this.addProcedimento();
                      });
                    }}
                  >
                    {"NAO"}
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <div className="row section-form">
          <h6 className="w-100">
            Dados da Execução / Procedimento e Exames Realizados
          </h6>

          {values &&
            values.atendimentoExecucaos &&
            values.atendimentoExecucaos.map(
              (atendimentoExecucao, indexExecucao) => {
                let numeroAtendimentoExecucao = _.get(
                  atendimentoExecucao,
                  `numero`
                );
                numeroAtendimentoExecucao = numeroAtendimentoExecucao
                  ? numeroAtendimentoExecucao
                  : 1;
                let procedimento = { numero: 1 };
                if (
                  values.atendimentoProcedimentos &&
                  values.atendimentoProcedimentos.length > 0
                ) {
                  procedimento =
                    values.atendimentoProcedimentos[
                      numeroAtendimentoExecucao - 1
                    ];
                }
                return (
                  <React.Fragment
                    key={
                      atendimentoExecucao.id
                        ? atendimentoExecucao.id
                        : indexExecucao + 1
                    }
                  >
                    {indexExecucao > 0 ? <div className="quebra"></div> : ""}
                    <FormGroup className="central fixo">
                      <label>Nº</label>
                      <p>{indexExecucao + 1}</p>
                    </FormGroup>
                    <SelectableInput
                      viewMode={viewMode}
                      className="fixo"
                      atendimento={values}
                      name={`atendimentoExecucaos[${indexExecucao}].dataAtendimento`}
                      label={"Data atendimento"}
                      value={
                        _.get(
                          values,
                          `atendimentoExecucaos[${indexExecucao}].dataAtendimento`
                        ) &&
                        dateHelper.format(
                          _.get(
                            values,
                            `atendimentoExecucaos[${indexExecucao}].dataAtendimento`
                          ),
                          { mode: "DATE" }
                        )
                      }
                    >
                      <InputViewEdit
                        label="Data atendimento"
                        component={DatePickerInput}
                        onChange={(name, date) => {
                          setFieldValue(name, date);
                          this.verificaRetorno(name, date);
                        }}
                        onBlur={() => this.sendToStorange(values)}
                        defaultValue={
                          _.get(
                            values,
                            `atendimentoExecucaos[${indexExecucao}].dataAtendimento`
                          )
                            ? dateHelper.format(
                                _.get(
                                  values,
                                  `atendimentoExecucaos[${indexExecucao}].dataAtendimento`
                                ),
                                { mode: "DATE" }
                              )
                            : "--"
                        }
                        id={`atendimentoExecucaos${indexExecucao}dataAtendimento`}
                        name={`atendimentoExecucaos[${indexExecucao}].dataAtendimento`}
                        placeholder=""
                        viewMode={viewMode}
                        type={"text"}
                        value={_.get(
                          values,
                          `atendimentoExecucaos[${indexExecucao}].dataAtendimento`
                        )}
                        erroMensagem={_.get(
                          errors,
                          `atendimentoExecucaos[${indexExecucao}].dataAtendimento`
                        )}
                        required={true}
                        alertMensagem={
                          values.convenio
                            ? values.convenio.listRegraMensagemTipoGuia
                            : null
                        }
                      />
                    </SelectableInput>
                    <SelectableInput
                      viewMode={viewMode}
                      className="central fixo6"
                      atendimento={values}
                      name={`atendimentoExecucaos[${indexExecucao}].horaInicial`}
                      label={"Hora início"}
                      value={
                        values.atendimentoExecucaos[indexExecucao] &&
                        values.atendimentoExecucaos[indexExecucao]
                          .horaInicial &&
                        dateHelper.format(
                          values.atendimentoExecucaos[indexExecucao]
                            .horaInicial,
                          { mode: "TIME" }
                        )
                      }
                    >
                      <InputViewEdit
                        label="Hora início"
                        component={DateTimePicker}
                        defaultValue={
                          values.atendimentoExecucaos[indexExecucao].horaInicial
                            ? moment(
                                values.atendimentoExecucaos[indexExecucao]
                                  .horaInicial
                              ).format("HH:mm")
                            : "--"
                        }
                        name={`atendimentoExecucaos[${indexExecucao}].horaInicial`}
                        id={`atendimentoExecucaos${indexExecucao}horaInicial`}
                        erroMensagem={_.get(
                          errors,
                          `atendimentoExecucaos[${indexExecucao}].horaInicial`
                        )}
                        value={
                          values.atendimentoExecucaos[indexExecucao]
                            .horaInicial &&
                          moment(
                            values.atendimentoExecucaos[indexExecucao]
                              .horaInicial
                          )
                        }
                        onChange={(name, value) =>
                          setFieldValue(
                            name,
                            moment(value).format("YYYY-MM-DDTHH:mm:ss")
                          )
                        }
                        noSize={true}
                        onBlur={() => this.sendToStorange(values)}
                        className={"destaque"}
                        viewMode={viewMode}
                        showTimeSelect={true}
                        showTimeSelectOnly={true}
                        required={true}
                        alertMensagem={
                          values.convenio
                            ? values.convenio.listRegraMensagemTipoGuia
                            : null
                        }
                      />
                    </SelectableInput>
                    <SelectableInput
                      viewMode={viewMode}
                      className="central fixo6"
                      atendimento={values}
                      name={`atendimentoExecucaos[${indexExecucao}].horaFinal`}
                      label={"Hora final"}
                      value={
                        values.atendimentoExecucaos[indexExecucao] &&
                        values.atendimentoExecucaos[indexExecucao].horaFinal &&
                        dateHelper.format(
                          values.atendimentoExecucaos[indexExecucao].horaFinal,
                          { mode: "TIME" }
                        )
                      }
                    >
                      <InputViewEdit
                        label="Hora final"
                        component={DateTimePicker}
                        name={`atendimentoExecucaos[${indexExecucao}].horaFinal`}
                        value={
                          values.atendimentoExecucaos[indexExecucao]
                            .horaFinal &&
                          moment(
                            values.atendimentoExecucaos[indexExecucao].horaFinal
                          )
                        }
                        onChange={(name, value) =>
                          setFieldValue(
                            name,
                            moment(value).format("YYYY-MM-DDTHH:mm:ss")
                          )
                        }
                        noSize={true}
                        defaultValue={
                          values.atendimentoExecucaos[indexExecucao].horaFinal
                            ? moment(
                                values.atendimentoExecucaos[indexExecucao]
                                  .horaFinal
                              ).format("HH:mm")
                            : "--"
                        }
                        onBlur={() => this.sendToStorange(values)}
                        className={"destaque"}
                        viewMode={viewMode}
                        showTimeSelect={true}
                        showTimeSelectOnly={true}
                        id={`atendimentoExecucaos${indexExecucao}horaFinal`}
                        required={true}
                        erroMensagem={_.get(
                          errors,
                          `atendimentoExecucaos[${indexExecucao}].horaFinal`
                        )}
                        alertMensagem={
                          values.convenio
                            ? values.convenio.listRegraMensagemTipoGuia
                            : null
                        }
                      />
                    </SelectableInput>
                    <SelectableInput
                      viewMode={viewMode}
                      className="central fixo6"
                      atendimento={values}
                      name={`atendimentoExecucaos[${indexExecucao}].tabela`}
                      label={"Tabela"}
                      value={atendimentoExecucao.tabela}
                    >
                      <InputViewEdit
                        label="Tabela"
                        component={FormInput}
                        onChange={(name, date) => {
                          setFieldValue(name, date);
                        }}
                        onBlur={() => this.sendToStorange(values)}
                        name={`atendimentoExecucaos[${indexExecucao}].tabela`}
                        placeholder=""
                        viewMode={viewMode}
                        type={"text"}
                        value={atendimentoExecucao.tabela}
                        alertMensagem={
                          values.convenio
                            ? values.convenio.listRegraMensagemTipoGuia
                            : null
                        }
                      />
                    </SelectableInput>
                    <SelectableInput
                      viewMode={viewMode}
                      className="large"
                      atendimento={values}
                      name={`atendimentoExecucaos[${indexExecucao}].codigoProcedimento`}
                      label={"Código do procedimento"}
                      value={
                        values.atendimentoExecucaos[indexExecucao]
                          .codigoProcedimento
                      }
                    >
                      <InputViewEdit
                        label="Código do procedimento"
                        component={SelectInput}
                        onChange={async (name, date) => {
                          let grauRegra = "";
                          let viaRegra = "";
                          if (
                            values.type &&
                            values.convenio &&
                            values.identificacaoAtendimentoExecucao[0].cooperado
                          ) {
                            let listProcedimentos = [];
                            listProcedimentos.push(date.codigo);

                            await convenioService
                              .findRegraEspecialidadeProcedimentoPorTipoGuia(
                                values.type,
                                values.convenio,
                                values.identificacaoAtendimentoExecucao[0]
                                  .cooperado.id,
                                listProcedimentos
                              )
                              .then((response) => {
                                if (
                                  response.data.viaAcesso ||
                                  response.data.grau
                                ) {
                                  grauRegra =
                                    response.data.grau &&
                                    response.data.grau.grau;
                                  viaRegra =
                                    response.data.viaAcesso &&
                                    response.data.viaAcesso.descricao;
                                }
                              });
                          }

                          if (
                            convenio != null &&
                            values.type != null &&
                            date.codigo != null
                          ) {
                            let newValue = _.cloneDeep(values);

                            if (
                              date &&
                              date.procedimentoCooperativaList &&
                              date.procedimentoCooperativaList.length > 0 &&
                              date.procedimentoCooperativaList[0]
                                .quantidadePadrao
                            ) {
                              _.set(
                                newValue,
                                `atendimentoExecucaos[${indexExecucao}].quantidade`,
                                date.procedimentoCooperativaList[0]
                                  .quantidadePadrao
                              );
                            }
                            _.set(
                              newValue,
                              `atendimentoExecucaos[${indexExecucao}].procedimento`,
                              date
                            );
                            _.set(
                              newValue,
                              `atendimentoExecucaos[${indexExecucao}].codigoProcedimento`,
                              date.codigo
                            );
                            _.set(
                              newValue,
                              `atendimentoExecucaos[${indexExecucao}].descricao`,
                              date.descricao
                            );
                            _.map(
                              newValue.identificacaoAtendimentoExecucao,
                              (iae, index) => {
                                if (
                                  iae.numero == numeroAtendimentoExecucao ||
                                  (!iae.numero &&
                                    numeroAtendimentoExecucao == 1)
                                ) {
                                  _.set(
                                    newValue,
                                    `identificacaoAtendimentoExecucao[${index}].codigoProcedimento`,
                                    date.codigo
                                  );

                                  if (
                                    values.cooperativa &&
                                    values.cooperativa
                                      .sufixProcedimentoObrigatoriedadeGrau12
                                  ) {
                                    if (
                                      date.codigo.startsWith(
                                        values.cooperativa
                                          .sufixProcedimentoObrigatoriedadeGrau12
                                      )
                                    ) {
                                      _.set(
                                        newValue,
                                        `identificacaoAtendimentoExecucao[${index}].grau`,
                                        "12"
                                      );
                                      _.set(
                                        newValue,
                                        `identificacaoAtendimentoExecucao[${index}].disabled`,
                                        true
                                      );
                                    } else {
                                      _.set(
                                        newValue,
                                        `identificacaoAtendimentoExecucao[${index}].disabled`,
                                        false
                                      );
                                    }
                                  }
                                }
                              }
                            );

                            _.set(
                              newValue,
                              `atendimentoProcedimentos[${indexExecucao}].procedimento`,
                              date
                            );
                            _.set(
                              newValue,
                              `atendimentoProcedimentos[${indexExecucao}].codigoProcedimento`,
                              date.codigo
                            );
                            _.set(
                              newValue,
                              `atendimentoProcedimentos[${indexExecucao}].descricao`,
                              date.descricao
                            );

                            if (this.validarProcedimento(values, date.codigo)) {
                              const quant = this.alocarQuantidade(values, date);
                              const acomodacao = this.alocarAcomodacao(
                                values,
                                date
                              );
                              _.set(
                                newValue,
                                `atendimentoExecucaos[${indexExecucao}].quantidade`,
                                quant
                              );
                              if (acomodacao) {
                                _.set(newValue, `acomodacao`, acomodacao);
                              }
                            } else {
                              _.set(
                                newValue,
                                `atendimentoExecucaos[${indexExecucao}].quantidade`,
                                ""
                              );
                            }
                            atendimentoService
                              .findGuiaPadrao(
                                values.type,
                                convenio,
                                date.codigo
                              )
                              .then((response) => {
                                const guiaPadrao = response.data;
                                _.forOwn(guiaPadrao, function (value, key) {
                                  if (value) {
                                    if (_.isArray(value)) {
                                      _.forOwn(
                                        value[0],
                                        function (valueArray, keyArray) {
                                          _.set(
                                            newValue,
                                            `${key}[${indexExecucao}].${keyArray}`,
                                            valueArray
                                          );
                                        }
                                      );
                                    } else {
                                      if (!newValue[key]) {
                                        _.set(newValue, key, value);
                                      }
                                    }
                                  }
                                });
                                setValues(newValue);
                                grauRegra &&
                                  setFieldValue(
                                    `identificacaoAtendimentoExecucao[${indexExecucao}].grau`,
                                    grauRegra
                                  );
                                viaRegra &&
                                  setFieldValue(
                                    `atendimentoExecucaos[${indexExecucao}].via`,
                                    viaRegra
                                  );
                              })
                              .catch((response) => {
                                setValues(newValue);
                                grauRegra &&
                                  setFieldValue(
                                    `identificacaoAtendimentoExecucao[${indexExecucao}].grau`,
                                    grauRegra
                                  );
                                viaRegra &&
                                  setFieldValue(
                                    `atendimentoExecucaos[${indexExecucao}].via`,
                                    viaRegra
                                  );
                              });
                          } else {
                            setFieldValue(
                              `atendimentoExecucaos[${indexExecucao}].procedimento`,
                              null
                            );
                            setFieldValue(
                              `atendimentoExecucaos[${indexExecucao}].codigoProcedimento`,
                              null
                            );
                            setFieldValue(
                              `atendimentoExecucaos[${indexExecucao}].descricao`,
                              null
                            );

                            _.map(
                              values.identificacaoAtendimentoExecucao,
                              (iae, index) => {
                                if (
                                  iae.numero == numeroAtendimentoExecucao ||
                                  (!iae.numero &&
                                    numeroAtendimentoExecucao == 1)
                                ) {
                                  setFieldValue(
                                    `identificacaoAtendimentoExecucao[${index}].codigoProcedimento`,
                                    null
                                  );
                                }
                              }
                            );
                          }
                        }}
                        onBlur={() => this.sendToStorange(values)}
                        name={`atendimentoExecucaos[${indexExecucao}].codigoProcedimento`}
                        id={`atendimentoExecucaos${indexExecucao}codigoProcedimento`}
                        placeholder="Digite o código"
                        viewMode={viewMode}
                        value={{
                          codigo:
                            values.atendimentoExecucaos[indexExecucao]
                              .codigoProcedimento,
                        }}
                        defaultValue={
                          _.get(
                            values,
                            `atendimentoExecucaos[${indexExecucao}].codigoProcedimento`
                          )
                            ? _.get(
                                values,
                                `atendimentoExecucaos[${indexExecucao}].codigoProcedimento`
                              )
                            : "--"
                        }
                        labelKey={"codigo"}
                        valueKey={"codigo"}
                        required={true}
                        erroMensagem={_.get(
                          errors,
                          `atendimentoExecucaos[${indexExecucao}].codigoProcedimento`
                        )}
                        returnFullObject={true}
                        parent={{
                          convenio: values.convenio,
                          tipoGuia: values.type,
                        }}
                        onFetchData={
                          procedimentoService.findByCodigoConvenioTipoGuia
                        }
                        alertMensagem={
                          values.convenio
                            ? values.convenio.listRegraMensagemTipoGuia
                            : null
                        }
                        noResultsText={
                          "Cooperativa não possui credenciamento para cobrança. Convênio possui locais próprios."
                        }
                      />
                    </SelectableInput>
                    <SelectableInput
                      viewMode={viewMode}
                      className="large"
                      atendimento={values}
                      name={`atendimentoExecucaos[${indexExecucao}].descricao`}
                      label={"Descrição"}
                      value={
                        values.atendimentoExecucaos[indexExecucao].descricao
                      }
                    >
                      <InputViewEdit
                        label="Descrição"
                        component={FormInput}
                        onChange={(name, date) => {
                          setFieldValue(name, date);
                        }}
                        onBlur={() => this.sendToStorange(values)}
                        name={`atendimentoExecucaos[${indexExecucao}].descricao`}
                        placeholder=""
                        type={"text"}
                        viewMode={viewMode}
                        value={
                          values.atendimentoExecucaos[indexExecucao].descricao
                        }
                        disabled={true}
                        alertMensagem={
                          values.convenio
                            ? values.convenio.listRegraMensagemTipoGuia
                            : null
                        }
                      />
                    </SelectableInput>
                    <SelectableInput
                      viewMode={viewMode}
                      className="central fixo"
                      atendimento={values}
                      name={`atendimentoExecucaos[${indexExecucao}].quantidade`}
                      label={"Qtde."}
                      value={
                        values.atendimentoExecucaos[indexExecucao].quantidade
                      }
                    >
                      <InputViewEdit
                        label="Qtde."
                        component={FormInput}
                        onChange={(name, date) => {
                          setFieldValue(name, date);
                        }}
                        onBlur={() => this.sendToStorange(values)}
                        id={`atendimentoExecucaos${indexExecucao}quantidade`}
                        name={`atendimentoExecucaos[${indexExecucao}].quantidade`}
                        placeholder=""
                        type={"number"}
                        viewMode={viewMode}
                        value={
                          values.atendimentoExecucaos[indexExecucao].quantidade
                        }
                        disabled={
                          atendimentoExecucao &&
                          atendimentoExecucao.quantidade &&
                          values.convenio !== undefined &&
                          this.validarProcedimento(
                            values,
                            procedimento != undefined
                              ? procedimento.codigoProcedimento
                              : null
                          ) &&
                          true
                        }
                        required
                        erroMensagem={_.get(
                          errors,
                          `atendimentoExecucaos[${indexExecucao}].quantidade`
                        )}
                        alertMensagem={
                          values.convenio
                            ? values.convenio.listRegraMensagemTipoGuia
                            : null
                        }
                      />
                    </SelectableInput>
                    <SelectableInput
                      viewMode={viewMode}
                      className="fixo"
                      atendimento={values}
                      name={`atendimentoExecucaos[${indexExecucao}].via`}
                      label={"Via"}
                      value={values.atendimentoExecucaos[indexExecucao].via}
                    >
                      <InputViewEdit
                        label="Via"
                        component={FormSelectInput}
                        service={viaAcessoService.findAll}
                        returnFullObject={false}
                        searchable={true}
                        labelKey={"descricao"}
                        valueKey={"descricao"}
                        onChange={(name, date) => {
                          setFieldValue(name, date);
                        }}
                        onBlur={() => this.sendToStorange(values)}
                        erroMensagem={_.get(
                          errors,
                          `atendimentoExecucaos[${indexExecucao}].via`
                        )}
                        required={
                          values.cooperativa &&
                          values.cooperativa.viaTecnicaObrigatoria &&
                          !(
                            values.cooperativa
                              .sufixProcedimentoObrigatoriedadeGrau12 &&
                            values.atendimentoExecucaos[indexExecucao]
                              .codigoProcedimento &&
                            values.atendimentoExecucaos[
                              indexExecucao
                            ].codigoProcedimento.startsWith(
                              values.cooperativa
                                .sufixProcedimentoObrigatoriedadeGrau12
                            )
                          )
                        }
                        disabled={
                          !(
                            values.cooperativa &&
                            values.cooperativa.viaTecnicaObrigatoria &&
                            !(
                              values.cooperativa
                                .sufixProcedimentoObrigatoriedadeGrau12 &&
                              values.atendimentoExecucaos[indexExecucao]
                                .codigoProcedimento &&
                              values.atendimentoExecucaos[
                                indexExecucao
                              ].codigoProcedimento.startsWith(
                                values.cooperativa
                                  .sufixProcedimentoObrigatoriedadeGrau12
                              )
                            )
                          )
                        }
                        id={`atendimentoExecucaos${indexExecucao}via`}
                        name={`atendimentoExecucaos[${indexExecucao}].via`}
                        type={"text"}
                        viewMode={viewMode}
                        value={values.atendimentoExecucaos[indexExecucao].via}
                        alertMensagem={
                          values.convenio
                            ? values.convenio.listRegraMensagemTipoGuia
                            : null
                        }
                      />
                    </SelectableInput>
                    <SelectableInput
                      viewMode={viewMode}
                      className="fixo"
                      atendimento={values}
                      name={`atendimentoExecucaos[${indexExecucao}].tec`}
                      label={"Tec."}
                      value={values.atendimentoExecucaos[indexExecucao].tec}
                    >
                      <InputViewEdit
                        label="Tec."
                        component={FormSelectInput}
                        service={tecnicaService.findAll}
                        returnFullObject={false}
                        searchable={true}
                        labelKey={"descricao"}
                        valueKey={"descricao"}
                        onChange={(name, date) => {
                          setFieldValue(name, date);
                        }}
                        onBlur={() => this.sendToStorange(values)}
                        id={`atendimentoExecucaos${indexExecucao}tec`}
                        name={`atendimentoExecucaos[${indexExecucao}].tec`}
                        placeholder=""
                        type={"text"}
                        erroMensagem={_.get(
                          errors,
                          `atendimentoExecucaos[${indexExecucao}].tec`
                        )}
                        required={
                          values.cooperativa &&
                          values.cooperativa.viaTecnicaObrigatoria
                        }
                        viewMode={viewMode}
                        value={values.atendimentoExecucaos[indexExecucao].tec}
                        alertMensagem={
                          values.convenio
                            ? values.convenio.listRegraMensagemTipoGuia
                            : null
                        }
                      />
                    </SelectableInput>
                    <SelectableInput
                      viewMode={viewMode}
                      className="central fixo"
                      atendimento={values}
                      name={`atendimentoExecucaos[${indexExecucao}].fator`}
                      label={"Fator"}
                      value={values.atendimentoExecucaos[indexExecucao].fator}
                    >
                      <InputViewEdit
                        label="Fator"
                        component={FormInput}
                        onChange={(name, date) => {
                          setFieldValue(name, date);
                        }}
                        onBlur={() => this.sendToStorange(values)}
                        name={`atendimentoExecucaos[${indexExecucao}].fator`}
                        placeholder=""
                        type={"number"}
                        viewMode={viewMode}
                        value={values.atendimentoExecucaos[indexExecucao].fator}
                        alertMensagem={
                          values.convenio
                            ? values.convenio.listRegraMensagemTipoGuia
                            : null
                        }
                      />
                    </SelectableInput>
                    <SelectableInput
                      viewMode={viewMode}
                      className=""
                      atendimento={values}
                      name={`atendimentoExecucaos[${indexExecucao}].valorUnitario`}
                      label={"Valor unit."}
                      value={
                        values.atendimentoExecucaos[indexExecucao].valorUnitario
                      }
                      placeholder="R$0,00"
                    >
                      <InputViewEdit
                        label="Valor unit."
                        component={MoneyInput}
                        onChange={(name, date) => {
                          setFieldValue(name, date);
                        }}
                        onBlur={() => this.sendToStorange(values)}
                        name={`atendimentoExecucaos[${indexExecucao}].valorUnitario`}
                        placeholder="R$0,00"
                        viewMode={viewMode}
                        value={
                          values.atendimentoExecucaos[indexExecucao]
                            .valorUnitario
                        }
                        defaultValue={formatterHelper.money(
                          values.atendimentoExecucaos[indexExecucao]
                            .valorUnitario
                        )}
                        alertMensagem={
                          values.convenio
                            ? values.convenio.listRegraMensagemTipoGuia
                            : null
                        }
                      />
                    </SelectableInput>
                    <FormGroup className="">
                      <label>Valor total</label>
                      <p className="form-view">
                        {this.getValorTotal(indexExecucao)}
                      </p>
                    </FormGroup>
                    {!viewMode && (
                      <React.Fragment>
                        <FormGroup className="central fixo mobile-100">
                          {indexExecucao + 1 !==
                            values.atendimentoExecucaos.length && (
                            <React.Fragment>
                              <div className="mt-25 btn-acoes d-none d-sm-flex flex-column align-self-center pl-0 pl-sm-3">
                                <button
                                  className="btn-menos"
                                  onClick={() => {
                                    this.removeProcedimento(indexExecucao);
                                  }}
                                ></button>
                              </div>
                              <div className="bg-branco d-block d-sm-none col-12 p-2 text-center">
                                <button
                                  className="btn-menos"
                                  onClick={() => {
                                    this.removeProcedimento(indexExecucao);
                                  }}
                                ></button>
                              </div>
                            </React.Fragment>
                          )}
                          {indexExecucao + 1 ===
                            values.atendimentoExecucaos.length && (
                            <React.Fragment>
                              <div className="mt-25 btn-acoes d-none d-sm-flex flex-column align-self-center pl-0 pl-sm-3">
                                {values.atendimentoExecucaos.length > 1 && (
                                  <button
                                    className="btn-menos"
                                    onClick={() => {
                                      this.removeProcedimento(indexExecucao);
                                    }}
                                  ></button>
                                )}
                                <button
                                  className="btn-mais"
                                  onClick={this.toogleModalMedicoPrincipal}
                                ></button>
                              </div>
                              <div className="bg-branco d-block d-sm-none col-12 p-2 text-center">
                                <button
                                  className="btn-mais"
                                  onClick={this.toogleModalMedicoPrincipal}
                                ></button>
                              </div>
                            </React.Fragment>
                          )}
                        </FormGroup>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                );
              }
            )}
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  menu: { showMenu, hideMenu },
  alert: { info, clear },
}) => ({
  info: (msg) => info(msg),
  clear: () => clear(),
  showMenu: () => showMenu(),
  hideMenu: () => hideMenu(),
});

function mapStateToProps(state) {
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    alert,
    user,
    permissions,
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(DadosExecucaoGuiaHonorarioForm));
