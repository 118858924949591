import React, { PureComponent } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { formatterHelper } from "../../helpers/formatter.helper";
import anexo from "../../img/icon_anexo_BIG.svg";
import * as _ from "lodash";
import { loteAtendimentoService } from "../../services/loteAtendimento.service";
import { urlsConstants } from "../../constants/url.constant";
import { defaultService } from "../../services/defaultService";
import { ModalPreVisualizarAnexos } from "./ModalPreVisualizarAnexos";

let download = require("downloadjs");

type Props = {
  target?: any,
  itens: [],
};

const modifiers = {
  preventOverflow: {
    enabled: false,
  },
  flip: {
    enabled: false,
  },
};

export class ModalAnexosLote extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      openAnexo: false,
      openPreVisualizarAnexos: false,
      anexo: null,
    };
  }

  downLoadZipFile = (atendimento) => {
    if (atendimento) {
      this.props.loading(true);
      loteAtendimentoService
        .getDownLoadAnexoAtendimentoPorAtendimento(atendimento.id)
        .then(
          (response) => {
            var base64 = _.get(response, "data.data");
            if (base64 != null) {
              let asciiString = atob(base64);
              let array = new Uint8Array(
                [...asciiString].map((char) => char.charCodeAt(0))
              );
              const filename = `Atendimento_${atendimento.id}.zip`;
              const file = new Blob([array], { type: "application/zip" });
              download(file, filename);
              this.props.loading(false);
            }
          },
          () => {
            this.props.loading(false);
            this.props.error(
              "Ocorreu um erro ao efetuar o download do arquivo"
            );
          }
        );
    }
    return false;
  };

  downLoadFile = (anexo) => {
    if (anexo.id) {
      let url = urlsConstants.MIDIA + "vo/" + anexo.id;
      defaultService.doGet(url).then((result) => {
        let dataFile = result.data;
        if (dataFile) {
          let uri = `data:${dataFile.contentType};base64,${dataFile.data}`;
          if (dataFile.data && dataFile.data.includes("base64,")) {
            uri = dataFile.data;
          }

          download(uri, dataFile.nome, dataFile.contentType);
        }
      });
    }
    return false;
  };

  toggle = () => {
    this.props.toogle();
  };

  togglePreVisualizarAnexos = (anexo) => {
    this.setState(({ openPreVisualizarAnexos }) => ({
      openPreVisualizarAnexos: !openPreVisualizarAnexos,
    }));
    this.setState({ anexo: anexo });
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.toggle}
          backdrop="static"
          modalClassName=""
          className="modal-lg-anexos"
          centered={true}
        >
          <ModalBody>
            <div className="modal-anexos">
              <div className="row">
                <div className="col-12 text-center ">
                  <img src={anexo} alt="anexo" />
                </div>
                <div className="col-12 text-center my-2">
                  <h2 className="font-weight-bold">Visualizar anexo</h2>
                </div>
              </div>

              <div className="row">
                {this.props.itens.map((item, i) => {
                  let anexo = item;
                  if (item.midia) {
                    anexo = item.midia;
                  }
                  return (
                    <div className="itemLista px-3 w-100 d-flex flex-row lineFile">
                      <div
                        className="px-3 w-100 d-flex flex-row lineFile"
                        id={anexo.nome}
                        key={anexo.id ? anexo.id : i}
                      >
                        <div
                          className="font-italic font-12px cut-text flex-fill"
                          onClick={() => {
                            this.downLoadFile(anexo);
                          }}
                        >
                          {anexo.nome}{" "}
                        </div>
                        <div className="font-10px">
                          {anexo.dataCadastro
                            ? formatterHelper.converterFormatoData(
                                anexo.dataCadastro
                              )
                            : ""}
                        </div>
                        <div className="font-10px">
                          {anexo.tamanho
                            ? formatterHelper.getFileSize(anexo.tamanho)
                            : ""}
                        </div>
                        <div>
                          <button
                            className="btn-primary mr-2"
                            onClick={() => {
                              this.togglePreVisualizarAnexos(anexo);
                            }}
                          >
                            {" "}
                            <span className={"icon-visualizar"}></span>
                            Pré-Visualizar Anexo
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <React.Fragment>
              <div className="w-100 text-center">
                <button
                  className="btn-primary mr-2"
                  onClick={() => {
                    this.downLoadZipFile(this.props.atendimento);
                    this.toggle();
                  }}
                >
                  Realizar download do(s) anexo(s)
                </button>
                <button
                  className="btn-secondary"
                  onClick={() => {
                    this.toggle();
                  }}
                >
                  Fechar
                </button>
              </div>
            </React.Fragment>
          </ModalFooter>
        </Modal>
        <ModalPreVisualizarAnexos
          anexo={this.state.anexo}
          name="preVisualizarAnexos"
          toogle={this.togglePreVisualizarAnexos}
          isOpen={this.state.openPreVisualizarAnexos}
          target={"preVisualizarAnexos"}
          itens={
            this.props.atendimento.atendimentoAnexos
              ? this.props.atendimento.atendimentoAnexos
              : []
          }
        />
      </React.Fragment>
    );
  }
}
