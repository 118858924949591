import React from 'react';
import { connect } from 'react-redux';

type Props = {
  onClick: any
};

class ToggleButton extends React.Component<Props> {  
  constructor(props) {
    super(props);
    this.state = {
       isToggleOn: true
      };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    this.setState(prevState => ({
      isToggleOn: !prevState.isToggleOn
    }));
    this.props.onClick(this.state.isToggleOn);
  }
  render() {
    return (
      <button 
        onClick={this.handleClick}
        className={this.state.isToggleOn ? 'btn btn-danger' : 'btn btn-primary'}>
        {this.state.isToggleOn ? 'GERAR LOTE' : 'ENCAMINHAR EM MASSA'}
      </button>
    );
  }
}

export default connect()(ToggleButton);