//@flow
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';
import {objectsConstants} from "../constants/objects.constants";

export const avisosService = {
    doGet,
    doSave,
    findByFilter,
    inativarAviso,
    ativarAviso,
    ativarAvisoByFilter,
    inativarAvisoByFilter,
    findTableColumns,
    findSearchableColumns,
    exportToExcel,
    findAll


    
    //doDelete, 
    //find,
    // ,

    
}


function doGet(id) {
    return defaultService.doGet(`${urlsConstants.AVISOS}${id}`);
  }


function doSave(entity: any) {
    return defaultService.doSave(urlsConstants.AVISOS, entity);
  }

function inativarAviso(ids) {
    return defaultService.doPost(`${urlsConstants.AVISOS}inativar`, ids);
}

function ativarAviso(ids) {
    return defaultService.doPost(`${urlsConstants.AVISOS}ativar`, ids);
}


function ativarAvisoByFilter(listFields) {
    return defaultService.doPost(`${urlsConstants.AVISOS}ativarByFilter`, {
        pageSize: objectsConstants.PAGE_MAX,
        page: 0,
        listFields
    });
}

function inativarAvisoByFilter(listFields) {
    return defaultService.doPost(`${urlsConstants.AVISOS}inativarByFilter`, {
        pageSize: objectsConstants.PAGE_MAX,
        page: 0,
        listFields
    });
}


function findByFilter(
    options: Options,
    filter: any,
    dafaultFilter
): Promise<Array<>> {
    return defaultService.findByFiltro(
        `${urlsConstants.AVISOS}list`,
        options,
        filter,
        dafaultFilter
    );
}

function findTableColumns() {
    return defaultService.doGet(`${urlsConstants.AVISOS}list/fields`);
  }

  function findSearchableColumns() {
    return defaultService.doGet(`${urlsConstants.AVISOS}search/fields`);
  }

  function exportToExcel(filter, fields, labels) {
    return defaultService.exportToExcel('avisoSistemaGeral', filter, fields, labels);
}

function findAll() {
    return defaultService.doGet(`${urlsConstants.AVISOS}findAll`);
  }