import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { objectsConstants } from "../../constants/objects.constants";
import Datatable from "../../sascComponents/datagrid/Datatable";
import TopoListagem from "../../sascComponents/topo/TopoListagem";
import { mensagemService } from "../../services/mensagem.service";
import TopoTitleComponente from "../home/TopoTitleComponente";
import LinhaAcaoExcluirMensagem from "./LinhaAcaoExcluirMensagem";

class MensagemList extends PureComponent<Props, State> {
    constructor(props) {
        super(props);

        let initFilter = null
        this.state = {
            initFilter
        };
    }

    handleRowClick = (fieldName, rowData) => {
        this.props.history.push({
            pathname: "/mensagem/view/" + (rowData.id),
            state: { entity: rowData }
        });
    };

    render() {
        return (
            <div className="container-fluid container-com-topo">
                <TopoTitleComponente
                    mainTitle="Mensagem"
                    subTitle=" "
                    canBack={false}
                />

                <TopoListagem
                    securityActionDo={objectsConstants.ALTERAR}
                    securityActionOn={objectsConstants.CRUDCONSULTORIOS_CADASTRAR}
                    buttonActionClassName={"btn-light"}
                    buttonAction={() => {
                        this.props.history.push("/mensagem/novo");
                    }}
                    securityActionIcon={"icon-btn-add"}
                    securityActionName={"Cadastrar mensagem"}
                    showfilter={false}
                />

                <hr className="text-center w-75"></hr>

                <div className="background-lines">
                    <div className="bg-riscos"></div>
                </div>

                <Datatable
                    showCheckboxColumn={true}
                    idRow={"id"}
                    name={"Mensagem(s)"}
                    service={mensagemService}
                    showAllColumnsFilter={false}
                    classHeader={[
                        "headerLeft",
                        "headerLeft",
                        "headerLeft",
                    ]}
                    classColumn={[]}
                    //initFilter={this.state.initFilter}
                    ActionLine={LinhaAcaoExcluirMensagem}
                    onRowClick={this.handleRowClick}
                    keyTagItem={"nome"}
                    //noCacheFilter={this.props.noCacheFilter}
                />

            </div >
        );
    }
}

const mapDispatch = ({ menu: { showMenu, hideMenu } }) => ({
    showMenu: () => showMenu(),
    hideMenu: () => hideMenu()
});

function mapStateToProps(state) {
    const { loggedIn, user, permissions } = state.authentication;
    const { alert } = state;

    return {
        loggedIn,
        alert,
        user,
        permissions
    };
}

export default connect(
    mapStateToProps,
    mapDispatch
)(withRouter(MensagemList));