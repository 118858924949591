import * as _ from "lodash";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FormGroup } from "../../../components/FormGroup";
import CheckBoxInput from "../../../components/Inputs/CheckBoxInput";
import FormInput from "../../../components/Inputs/FormInput";
import FormSelectInput from "../../../components/Inputs/FormSelectInput";
import InputViewEdit from "../../../components/Inputs/InputViewEdit";
import SelectInput from "../../../components/Inputs/SelectInput";
import { objectsConstants } from "../../../constants/objects.constants";
import { formatterHelper } from "../../../helpers/formatter.helper";
import SelectableInput from "../../../sascComponents/inputs/SelectableInput";
import { convenioService } from "../../../services/convenio.service";
import { cooperadoService } from "../../../services/cooperado.service";
import { grauService } from "../../../services/grau.service";
import fechar from "../../../img/icon_fechar_BLACK.svg";
import { Modal, ModalBody } from "reactstrap";

class DadosProfissionalExecutanteGuiaForm extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      toogleModalMedicoPrincipal: false, exibirMedicoPrincipal: true,
      copyMedico: false
    }
  }

  addIdentificacao_BKP = () => {
    const { values, setFieldValue } = this.props;
    let identificacaoProfissionais = _.cloneDeep(
      values.identificacaoAtendimentoExecucao
    );

    let ultimoIdentificacaoProfissionais = identificacaoProfissionais[identificacaoProfissionais.length - 1];
    let cooperado = _.get(ultimoIdentificacaoProfissionais, 'cooperado');
    let numero = ultimoIdentificacaoProfissionais.numero;
    let procedimento = { numero: 1 };
    if (values.atendimentoProcedimentos && values.atendimentoProcedimentos.length > 0) {
      procedimento = values.atendimentoProcedimentos[numero - 1];
    }
    let grau = null;
    let enviarPj = values.estabelecimento.enviarClinica;
    if(procedimento && procedimento.codigoProcedimento &&
        values.cooperativa && values.cooperativa.sufixProcedimentoObrigatoriedadeGrau12 &&
        procedimento.codigoProcedimento.startsWith(values.cooperativa.sufixProcedimentoObrigatoriedadeGrau12)){
      grau="12";
    }
    identificacaoProfissionais.push({ idList: new Date().getTime(), fator: 1, numero, cooperado,grau, enviarPj });
    setFieldValue(
      "identificacaoAtendimentoExecucao",
      identificacaoProfissionais
    );

  };

  addIdentificacao = () => {
    const { values, setValues } = this.props;
    const medicoPrincipal = this.state.copyMedico;
    let newValues = _.cloneDeep(values);
    let numero = newValues.atendimentoExecucaos.length + 1;
    let procedimento = { numero: 1 };
    if (values.atendimentoProcedimentos && values.atendimentoProcedimentos.length > 0) {
      procedimento = values.atendimentoProcedimentos[numero - 1];
    }
    let grau = null;

    if (procedimento && procedimento.codigoProcedimento &&
      values.cooperativa && values.cooperativa.sufixProcedimentoObrigatoriedadeGrau12 &&
      procedimento.codigoProcedimento.startsWith(values.cooperativa.sufixProcedimentoObrigatoriedadeGrau12)) {
      grau = "12";
    }
    this.copiaHoraProcedimento(newValues, numero);
    if (!medicoPrincipal) {
      newValues.identificacaoAtendimentoExecucao.push({ idList: new Date().getTime(), numero, type: objectsConstants.ENUM_TIPOS_GUIA.HONORARIO, grau });
    } else {
      newValues.identificacaoAtendimentoExecucao.push({ idList: new Date().getTime(), numero, type: objectsConstants.ENUM_TIPOS_GUIA.HONORARIO, cooperado: values.identificacaoAtendimentoExecucao[0].cooperado, grau });
    }
    setValues(newValues);
  };

  copiaHoraProcedimento(newValues, numero) {
    newValues.atendimentoProcedimentos.push({
      idList: new Date().getTime(),
      numero,
      tabela: newValues.atendimentoProcedimentos[0].tabela
    });
    newValues.atendimentoExecucaos.push({
      idList: new Date().getTime(),
      numero,
      horaInicial: newValues.atendimentoExecucaos[0].horaInicial,
      horaFinal: newValues.atendimentoExecucaos[0].horaFinal,
      type: objectsConstants.ENUM_TIPOS_GUIA.SADT
    });
  }

  removeIdentificacao = indexIdentificacao => {
    const { values, setFieldValue } = this.props;
    let identificacaoProfissionais = _.cloneDeep(
      values.identificacaoAtendimentoExecucao
    );
    identificacaoProfissionais.splice(indexIdentificacao, 1);
    setFieldValue(
      "identificacaoAtendimentoExecucao",
      identificacaoProfissionais
    );
    this.props.clear()
    this.props.info({
      message: `Identificação removida com sucesso!`
    });
  };
  getOptions = size => {
    let arrOptions = [];
    for (let index = 1; index <= size; index++) {
      arrOptions.push({ label: index, value: index });
    }
    return arrOptions;
  };

  getFieldValue = (label, valor) => {
    return <FormGroup className="">
      <label>{label}</label>
      <p className="form-view">
        {formatterHelper.money(valor, false)}
      </p>
    </FormGroup>
  }

  getValoresGlosarepasse = (indentificacaoExecucao) => {
    if (indentificacaoExecucao.valorTotal) {
      return (
        <React.Fragment>
          <div className="quebra"></div>
          {this.getFieldValue("Valor Total", indentificacaoExecucao.valorTotal)}
          {this.getFieldValue("Valor repassado", indentificacaoExecucao.valorRepassado)}
          {this.getFieldValue("Valor Glosado", indentificacaoExecucao.valorGlosado)}
          {indentificacaoExecucao.valorGlosaDevida && this.getFieldValue("Glosa devida", indentificacaoExecucao.valorGlosaDevida)}

        </React.Fragment>
      )
    }
  }

  sendToStorange = (values) => {
    try {
      values.rascunho && localStorage.setItem('rascunho', JSON.stringify(values))
    } catch (error) {
      if (error instanceof DOMException && (
        error.code === 22 ||
        error.code === 1014 ||
        error.name === 'QuotaExceededError' ||
        error.name === 'NS_ERROR_DOM_QUOTA_REACHED'
      )) {
        console.error('Limite de armazenamento local atingido');
      } else {
        console.error('Erro ao salvar no armazenamento local:', error.message);
      }
    }
  }

  toogleModalMedicoPrincipal = () => {
    if (this.state.toogleModalMedicoPrincipal || this.state.exibirMedicoPrincipal) {
      this.setState({
        toogleModalMedicoPrincipal: !this.state.toogleModalMedicoPrincipal, exibirMedicoPrincipal: false
      })
    } else {
      this.addIdentificacao();
    }
  }

  ajustaProcedimentoSemCooperado = () => {
    const { values } = this.props;
    let numerosProcedimentosSemCooperados = values.atendimentoProcedimentos.map(item => item.numero)
      .filter(numero => !values.identificacaoAtendimentoExecucao.map(item => item.numero).includes(numero));
    numerosProcedimentosSemCooperados.forEach(item => {
      this.removeProcedimento(item);
    });
  }

  removeProcedimento = numeroProcedimento => {
    const { values, setValues } = this.props;
    let newValues = _.cloneDeep(values);
    let indice = 0;
    newValues.atendimentoExecucaos.forEach((item, index) => {
      if (item.numero === numeroProcedimento) {
        indice = index;
      }
    });
    newValues.atendimentoExecucaos.splice(indice, 1);
    newValues.atendimentoProcedimentos.splice(indice, 1);
    _.set(values, "atendimentoExecucaos", newValues.atendimentoExecucaos);
    _.set(values, "atendimentoProcedimentos", newValues.atendimentoProcedimentos);
    //reordena lista de execucao para bater index
    newValues.identificacaoAtendimentoExecucao.map((identificacao) => {
      if (indice < identificacao.numero) {
        identificacao.numero = identificacao.numero > 1 ? identificacao.numero - 1 : 1;
      }
    })
    //reordena lista de dados execucao para bater index
    newValues.atendimentoExecucaos.map((atendimentoExecucao) => {
      if (indice < atendimentoExecucao.numero) {
        atendimentoExecucao.numero = atendimentoExecucao.numero > 1 ? atendimentoExecucao.numero - 1 : 1;
      }
    })
    newValues.atendimentoProcedimentos.map((atendimentoExecucao, indexIdentificacao) => {
      atendimentoExecucao.numero = indexIdentificacao + 1;
    })
    setValues(newValues);
    this.props.clear()
    this.props.info({
      message: `Procedimento removido com sucesso!`
    });

  };

  render() {
    const {
      setFieldValue,
      values,
      errors,
      viewMode
    } = this.props;
    if (values.identificacaoAtendimentoExecucao == null || values.identificacaoAtendimentoExecucao.length == 0) {
      setFieldValue("identificacaoAtendimentoExecucao", objectsConstants.INIT_IDENTIFICACAO_ATENDIMENTO_EXECUCAO);
    }
    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.toogleModalMedicoPrincipal}
          toggle={this.toggle}
          backdrop="static"
          modalClassName=""
          className=""
          centered={true}
        >
          <ModalBody>
            <div className="modalCooperado">
              <div className="row">
                <div className="col-10 text-center my-2">
                  <h2 className="font-weight-bold">Dados Profissional</h2>
                </div>
                <div className="col-2">
                  <a onClick={() => { this.toogleModalMedicoPrincipal(); }}
                    className="btn-fechar d-flex justify-content-end">
                    <img src={fechar} alt="fechar" className="pointer" />
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="flex-grow px-3 m-auto justify-content-center">
                  <label className="font-12px">Deseja que os dados do profissional selecionado anteriormente sejam os mesmos para o próximo procedimento?</label>
                </div>
                <div className="flex-grow px-3 m-auto  pt-3 justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-primary mr-2"
                    disabled={false}
                    onClick={() => {
                      this.setState({ copyMedico: true }, () => {
                        this.toogleModalMedicoPrincipal()
                        this.addIdentificacao();
                      })
                    }}
                  >
                    {"SIM"}
                  </button>
                  <button
                    type="submit"
                    className="btn btn-danger "
                    disabled={false}
                    onClick={() => {
                      this.setState({ copyMedico: false }, () => {
                        this.toogleModalMedicoPrincipal()
                        this.addIdentificacao();
                      })
                    }}
                  >
                    {"NAO"}
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <div className="row section-form">
          <h6 className="w-100">
            Identificação do(s) Profissional(is) Executante(s)
          </h6>
          {values &&
            values.identificacaoAtendimentoExecucao &&
            values.identificacaoAtendimentoExecucao.map(
              (identificacao, indexIdentificacao) => {
                //pega o index do procedimento selecionado
                let valueSelect = {
                  label:
                    _.get(values, `identificacaoAtendimentoExecucao[${indexIdentificacao}].numero`, 1)
                  ,
                  value: _.get(values, `identificacaoAtendimentoExecucao[${indexIdentificacao}].numero`, 1)
                };

                let procedimento = { numero: 1 };
                if (values.atendimentoProcedimentos && values.atendimentoProcedimentos.length > 0) {
                  procedimento = values.atendimentoProcedimentos[valueSelect.value - 1];
                }
                return (
                  <React.Fragment key={identificacao.id ? identificacao.id : indexIdentificacao + new Date().getMilliseconds}>
                    {indexIdentificacao > 0 ? <div className="quebra"></div> : ""}

                    <FormGroup className="central fixo">
                      <InputViewEdit
                        label="Nº"
                        component={SelectInput}
                        onChange={(name, date) => {
                          let indeChanged = date.value - 1;
                          let atendimentoProcedimento =
                            values.atendimentoProcedimentos[indeChanged];
                          setFieldValue(
                            `identificacaoAtendimentoExecucao[${indeChanged}].atendimentoProcedimento`,
                            atendimentoProcedimento
                          );
                          setFieldValue(
                            name,
                            date.value
                          );
                        }}
                        onBlur={() => {
                          this.ajustaProcedimentoSemCooperado();
                          this.sendToStorange(values);
                        }}
                        options={this.getOptions(_.get(values, 'atendimentoProcedimentos.length', 0))}
                        name={`identificacaoAtendimentoExecucao[${indexIdentificacao}].numero`}
                        id={`identificacaoAtendimentoExecucao${indexIdentificacao}numero`}
                        erroMensagem={
                          _.get(errors, `identificacaoAtendimentoExecucao[${indexIdentificacao}].numero`)
                        }
                        placeholder="Selecione o procedimento"
                        viewMode={viewMode}
                        value={valueSelect}
                        defaultValue={identificacao.numero}
                        labelKey={"label"}
                        valueKey={"value"}
                        returnFullObject={true}
                        required
                      />
                    </FormGroup>
                    <SelectableInput viewMode={viewMode} atendimento={values} className="central"
                      name={`identificacaoAtendimentoExecucao[${indexIdentificacao}].grau`}

                      label={"Grau"}
                      value={
                        values.identificacaoAtendimentoExecucao[
                          indexIdentificacao
                        ].grau
                      }
                    >
                      <InputViewEdit
                        label="Grau"
                        helpText={(<div style={{ textAlign: 'left' }}>
                          00 - Cirurgião<br />
                          01 - Primeiro Auxiliar<br />
                          02 - Segundo Auxiliar<br />
                          03 - Terceiro Auxiliar<br />
                          04 - Quarto Auxiliar<br />
                          05 - Instrumentador<br />
                          06 - Anestesista<br />
                          07 - Auxiliar de Anestesista<br />
                          08 - Consultor<br />
                          09 - Perfusionista<br />
                          10 - Pediatra na sala de parto<br />
                          11 - Auxiliar SADT<br />
                          12 - Clínico<br />
                          12 - Pediatra no berçario<br />
                          13 - Intensivista<br /></div>
                        )
                        }
                        required={true}
                        component={FormSelectInput}
                        service={grauService.findAll}
                        returnFullObject={false}
                        searchable={true}
                        labelKey={"grau"}
                        valueKey={"grau"}
                        onChange={(name, date, input) => {
                          setFieldValue(name, date);
                          setFieldValue(name.replace("grau", "guiaFisicaObrigatoria"), input.guiaFisicaObrigatoria);
                        }}
                        onBlur={() => this.sendToStorange(values)}
                        name={`identificacaoAtendimentoExecucao[${indexIdentificacao}].grau`}
                        id={`identificacaoAtendimentoExecucao${indexIdentificacao}grau`}
                        erroMensagem={
                          _.get(errors, `identificacaoAtendimentoExecucao[${indexIdentificacao}].grau`)
                        }
                        placeholder=""
                        disabled={procedimento &&
                          procedimento.codigoProcedimento &&
                          values.cooperativa && values.cooperativa.sufixProcedimentoObrigatoriedadeGrau12 &&
                          procedimento.codigoProcedimento.startsWith(values.cooperativa.sufixProcedimentoObrigatoriedadeGrau12)
                        }
                        viewMode={viewMode}
                        type={"text"}
                        value={
                          values.identificacaoAtendimentoExecucao[
                            indexIdentificacao
                          ].grau != null ? values.identificacaoAtendimentoExecucao[
                            indexIdentificacao
                          ].grau : values.identificacaoAtendimentoExecucao[
                            indexIdentificacao
                          ].grau

                        }
                      />
                    </SelectableInput>
                    <SelectableInput viewMode={viewMode} atendimento={values} className="fixo mobile-100"
                      name={`identificacaoAtendimentoExecucao[${indexIdentificacao}].cooperado.cpf`}
                      label={"Código Operadora / CPF"}
                      value={
                        values.identificacaoAtendimentoExecucao[
                          indexIdentificacao
                        ].cooperado &&
                        values.identificacaoAtendimentoExecucao[
                          indexIdentificacao
                        ].cooperado.cpf
                      }
                    >
                      <InputViewEdit
                        label="Código Operadora / CPF"
                        required={true}
                        component={SelectInput}
                        onFetchData={cooperadoService.findByCpf}
                        labelKey={"cpf"}
                        valueKey={"cpf"}
                        returnFullObject={true}
                        onChange={(name, date) => {
                          setFieldValue(name, date);
                        }}
                        onBlur={() => this.sendToStorange(values)}
                        name={`identificacaoAtendimentoExecucao[${indexIdentificacao}].cooperado`}
                        id={`identificacaoAtendimentoExecucao${indexIdentificacao}cooperadocpf`}
                        erroMensagem={
                          _.get(errors, `identificacaoAtendimentoExecucao[${indexIdentificacao}].cooperado.cpf`)
                        }
                        placeholder=""
                        viewMode={viewMode}
                        type={"text"}
                        value={
                          values.identificacaoAtendimentoExecucao[
                            indexIdentificacao
                          ].cooperado
                            ? values.identificacaoAtendimentoExecucao[
                              indexIdentificacao
                            ].cooperado
                            : ""
                        }
                        defaultValue={
                          values.identificacaoAtendimentoExecucao[
                            indexIdentificacao
                          ].cooperado
                            ? values.identificacaoAtendimentoExecucao[
                              indexIdentificacao
                            ].cooperado.cpf
                            : ""
                        }
                      />
                    </SelectableInput>
                    <SelectableInput viewMode={viewMode} atendimento={values} className="central"
                      name={`identificacaoAtendimentoExecucao[${indexIdentificacao}].cooperado.conselho`}
                      label={"Conselho profissional"}
                      value={
                        values.identificacaoAtendimentoExecucao[indexIdentificacao].cooperado &&
                        values.identificacaoAtendimentoExecucao[indexIdentificacao].cooperado.conselho
                        && values.identificacaoAtendimentoExecucao[indexIdentificacao].cooperado.conselho.sigla
                      }
                    >

                      <InputViewEdit
                        label="Conselho profissional"
                        component={SelectInput}
                        onChange={(name, date) => {
                          setFieldValue(name, date);
                        }}
                        onBlur={() => this.sendToStorange(values)}
                        className={""}
                        id={`identificacaoAtendimentoExecucao${indexIdentificacao}cooperadoconselho`}
                        name={`identificacaoAtendimentoExecucao[${indexIdentificacao}].cooperado.conselho`}
                        placeholder=""
                        viewMode={viewMode}
                        required={true}
                        erroMensagem={
                          _.get(errors, `identificacaoAtendimentoExecucao[${indexIdentificacao}].cooperado.conselho.sigla`)
                        }
                        valueKey={"id"}
                        labelKey={"sigla"}
                        returnFullObject={true}
                        defaultSelected={true}
                        value={
                          values.identificacaoAtendimentoExecucao[indexIdentificacao].cooperado
                            ? values.identificacaoAtendimentoExecucao[indexIdentificacao].cooperado.conselho
                            : ""
                        }
                        defaultValue={
                          values.identificacaoAtendimentoExecucao[indexIdentificacao].cooperado
                            && values.identificacaoAtendimentoExecucao[indexIdentificacao].cooperado.conselho
                            ? values.identificacaoAtendimentoExecucao[indexIdentificacao].cooperado.conselho.sigla
                            : ""
                        }
                        options={this.props.listConselho}
                      />
                    </SelectableInput>

                    <SelectableInput viewMode={viewMode} atendimento={values} className="central"
                      name={`identificacaoAtendimentoExecucao[${indexIdentificacao}].cooperado.uf`}
                      label={"UF"}
                      value={
                        values.identificacaoAtendimentoExecucao[indexIdentificacao].cooperado &&
                        values.identificacaoAtendimentoExecucao[indexIdentificacao].cooperado.ufConselho
                      }
                    >

                      <InputViewEdit
                        label="UF"
                        component={SelectInput}
                        onChange={(name, date) => {
                          setFieldValue(name, date);
                        }}
                        onBlur={() => this.sendToStorange(values)}
                        className={""}
                        id={`identificacaoAtendimentoExecucao${indexIdentificacao}cooperadoufConselho`}
                        name={`identificacaoAtendimentoExecucao[${indexIdentificacao}].cooperado.ufConselho`}
                        placeholder=""
                        viewMode={viewMode}
                        required={false}
                        erroMensagem={
                          _.get(errors, `identificacaoAtendimentoExecucao[${indexIdentificacao}].cooperado.ufConselho`)
                        }
                        valueKey={"id"}
                        labelKey={"nome"}
                        returnFullObject={false}
                        defaultSelected={true}
                        value={
                          values.identificacaoAtendimentoExecucao[indexIdentificacao].cooperado
                            ? values.identificacaoAtendimentoExecucao[indexIdentificacao].cooperado.ufConselho
                            : ""
                        }
                        defaultValue={
                          values.identificacaoAtendimentoExecucao[indexIdentificacao].cooperado
                            ? values.identificacaoAtendimentoExecucao[indexIdentificacao].cooperado.ufConselho
                            : ""
                        }
                        options={this.props.listUfsConselho}
                      />
                    </SelectableInput>
                    <SelectableInput viewMode={viewMode} atendimento={values} className="fixo"
                      name={`identificacaoAtendimentoExecucao[${indexIdentificacao}].cooperado.numeroConselho`}
                      label={"Nº do conselho"}
                      value={
                        values.identificacaoAtendimentoExecucao[
                          indexIdentificacao
                        ].cooperado &&
                        values.identificacaoAtendimentoExecucao[
                          indexIdentificacao
                        ].cooperado.conselho &&
                        values.identificacaoAtendimentoExecucao[
                          indexIdentificacao
                        ].cooperado.numeroConselho
                      }
                    >
                      <InputViewEdit
                        label="Nº do conselho"
                        component={SelectInput}
                        onChange={(name, date) => {
                          setFieldValue(`identificacaoAtendimentoExecucao[${indexIdentificacao}].cooperado`, date);

                          if (values.type && values.convenio && values.atendimentoProcedimentos[0]) {
                            let procedimentos = values.atendimentoProcedimentos;
                            let listProcedimentos = [];
                            for (let p = 0; p < procedimentos.length; p++) {
                              listProcedimentos.push(procedimentos[p].codigoProcedimento);
                            }


                            let contador = 0;
                            let listaids = [];
                            let idCoperadoLista = (values.identificacaoAtendimentoExecucao[indexIdentificacao].id
                              ? values.identificacaoAtendimentoExecucao[indexIdentificacao].id
                              : values.identificacaoAtendimentoExecucao[indexIdentificacao].idList)

                            for (let iae = 0; iae < values.identificacaoAtendimentoExecucao.length; iae++) {
                              if (values.identificacaoAtendimentoExecucao[iae].numero == values.identificacaoAtendimentoExecucao[values.identificacaoAtendimentoExecucao[indexIdentificacao].numero - 1].numero) {
                                listaids[contador] = (values.identificacaoAtendimentoExecucao[iae].id
                                  ? values.identificacaoAtendimentoExecucao[iae].id
                                  : values.identificacaoAtendimentoExecucao[iae].idList)

                                contador = contador + 1;
                              }
                            }

                            if (idCoperadoLista == listaids[0]) {

                              convenioService.findRegraEspecialidadeProcedimentoPorTipoGuia(values.type, values.convenio, date.id, listProcedimentos).then(
                                response => {
                                  if (response.data.viaAcesso || response.data.grau) {
                                    setFieldValue(`identificacaoAtendimentoExecucao[${indexIdentificacao}].grau`, response.data.grau ? response.data.grau.grau : null);
                                    setFieldValue(`atendimentoExecucaos[${values.identificacaoAtendimentoExecucao[indexIdentificacao].numero - 1}].via`, response.data.viaAcesso ? response.data.viaAcesso.descricao : null);
                                  } else {
                                    setFieldValue(`identificacaoAtendimentoExecucao[${indexIdentificacao}].grau`, null);
                                  }
                                });
                            }
                          }
                        }}
                        onBlur={() => this.sendToStorange(values)}
                        className={""}
                        name={`identificacaoAtendimentoExecucao[${indexIdentificacao}].cooperado.numeroConselho`}
                        placeholder=""
                        viewMode={viewMode}
                        required={true}
                        id={`identificacaoAtendimentoExecucao${indexIdentificacao}cooperadonumeroConselho`}
                        erroMensagem={
                          _.get(errors, `identificacaoAtendimentoExecucao[${indexIdentificacao}].cooperado.numeroConselho`)

                        }
                        valueKey={"numeroConselho"}
                        labelKey={"numeroConselho"}
                        returnFullObject={true}
                        value={
                          values.identificacaoAtendimentoExecucao[
                            indexIdentificacao
                          ].cooperado &&
                            values.identificacaoAtendimentoExecucao[
                              indexIdentificacao
                            ].cooperado.conselho
                            ? values.identificacaoAtendimentoExecucao[
                              indexIdentificacao
                            ].cooperado
                            : ""
                        }
                        defaultValue={
                          values.identificacaoAtendimentoExecucao[
                            indexIdentificacao
                          ].cooperado &&
                            values.identificacaoAtendimentoExecucao[
                              indexIdentificacao
                            ].cooperado.conselho
                            ? values.identificacaoAtendimentoExecucao[
                              indexIdentificacao
                            ].cooperado.numeroConselho
                            : ""
                        }
                        parent={values.estabelecimento.id}
                        onFetchData={cooperadoService.findByNomeOuConselhoEstabelecimento}
                        alertMensagem={values.convenio ? values.convenio.listRegraMensagemTipoGuia : null}

                      />
                    </SelectableInput>
                    <SelectableInput viewMode={viewMode} atendimento={values} className="fixo3"
                      name={`identificacaoAtendimentoExecucao[${indexIdentificacao}].cooperado`}
                      label={"Nome do profissional"}
                      value={
                        values.identificacaoAtendimentoExecucao[
                          indexIdentificacao
                        ].cooperado
                        && values.identificacaoAtendimentoExecucao[
                          indexIdentificacao
                        ].cooperado.nome
                      }
                    >
                      <InputViewEdit
                        label="Nome do profissional"
                        required={true}
                        component={SelectInput}
                        parent={values.estabelecimento.id}
                        onFetchData={cooperadoService.findByNomeEstabelecimento}
                        className={"destaque"}
                        name={`identificacaoAtendimentoExecucao[${indexIdentificacao}].cooperado`}
                        id={`identificacaoAtendimentoExecucao${indexIdentificacao}cooperado`}
                        erroMensagem={
                          _.get(errors, `identificacaoAtendimentoExecucao[${indexIdentificacao}].cooperado.nome`)
                        }
                        defaultValue={
                          values.identificacaoAtendimentoExecucao[
                            indexIdentificacao
                          ].cooperado
                            ? values.identificacaoAtendimentoExecucao[
                              indexIdentificacao
                            ].cooperado.nome
                            : "--"
                        }
                        returnFullObject={true}
                        searchable={true}
                        labelKey={"nome"}
                        valueKey={"id"}
                        viewMode={viewMode}
                        value={
                          values.identificacaoAtendimentoExecucao[
                            indexIdentificacao
                          ].cooperado
                        }
                        onChange={(name, value) => {
                          setFieldValue(name, value);

                          if (values.type && values.convenio && values.atendimentoProcedimentos[0]) {
                            let procedimentos = values.atendimentoProcedimentos;
                            let listProcedimentos = [];
                            for (let p = 0; p < procedimentos.length; p++) {
                              listProcedimentos.push(procedimentos[p].codigoProcedimento);
                            }

                            let contador = 0;
                            let listaids = [];
                            let idCoperadoLista = (values.identificacaoAtendimentoExecucao[indexIdentificacao].id
                              ? values.identificacaoAtendimentoExecucao[indexIdentificacao].id
                              : values.identificacaoAtendimentoExecucao[indexIdentificacao].idList)

                            for (let iae = 0; iae < values.identificacaoAtendimentoExecucao.length; iae++) {
                              if (values.identificacaoAtendimentoExecucao[iae].numero == values.identificacaoAtendimentoExecucao[values.identificacaoAtendimentoExecucao[indexIdentificacao].numero - 1].numero) {
                                listaids[contador] = (values.identificacaoAtendimentoExecucao[iae].id
                                  ? values.identificacaoAtendimentoExecucao[iae].id
                                  : values.identificacaoAtendimentoExecucao[iae].idList)

                                contador = contador + 1;
                              }
                            }

                            if (idCoperadoLista == listaids[0]) {


                              convenioService.findRegraEspecialidadeProcedimentoPorTipoGuia(values.type, values.convenio, value.id, listProcedimentos).then(
                                response => {
                                  if (response.data.viaAcesso || response.data.grau) {
                                    setFieldValue(`identificacaoAtendimentoExecucao[${indexIdentificacao}].grau`, response.data.grau ? response.data.grau.grau : null);
                                    setFieldValue(`atendimentoExecucaos[${values.identificacaoAtendimentoExecucao[indexIdentificacao].numero - 1}].via`, response.data.viaAcesso ? response.data.viaAcesso.descricao : null);
                                  } else {
                                    setFieldValue(`identificacaoAtendimentoExecucao[${indexIdentificacao}].grau`, null);
                                  }
                                });
                            }
                          }
                        }}
                        onBlur={() => this.sendToStorange(values)}
                        alertMensagem={values.convenio ? values.convenio.listRegraMensagemTipoGuia : null}
                      />
                    </SelectableInput>
                    <SelectableInput viewMode={viewMode} atendimento={values} className="fixo"
                      name={`identificacaoAtendimentoExecucao[${indexIdentificacao}].codigoCbo`}
                      label={"Código CBO"}
                      value={
                        values.identificacaoAtendimentoExecucao[
                          indexIdentificacao
                        ].cooperado
                          ? values.identificacaoAtendimentoExecucao[
                            indexIdentificacao
                          ].cooperado.codigoCbo
                          : ""
                      }
                    >
                      <InputViewEdit
                        label="Código CBO"
                        required={true}
                        component={FormInput}
                        onChange={(name, date) => {
                          setFieldValue(name, date);
                        }}
                        onBlur={() => this.sendToStorange(values)}
                        name={`identificacaoAtendimentoExecucao[${indexIdentificacao}].codigoCbo`}
                        id={`identificacaoAtendimentoExecucao${indexIdentificacao}codigoCbo`}

                        disabled={true}
                        placeholder=""
                        type={"text"}
                        viewMode={viewMode}
                        defaultValue={
                          values.identificacaoAtendimentoExecucao[
                            indexIdentificacao
                          ].cooperado
                            ? values.identificacaoAtendimentoExecucao[
                              indexIdentificacao
                            ].cooperado.codigoCbo
                            : "--"
                        }
                        value={
                          values.identificacaoAtendimentoExecucao[
                            indexIdentificacao
                          ].cooperado
                            ? values.identificacaoAtendimentoExecucao[
                              indexIdentificacao
                            ].cooperado.codigoCbo
                            : ""
                        }

                      />
                    </SelectableInput>
                    {values.estabelecimento.enviarClinica && <CheckBoxInput
                        className="mb-0 pb-1"
                        label="Enviar Cooperado Recebedor PJ"
                        name="checkItem"
                        disabled={viewMode}
                        checked={_.get(values.identificacaoAtendimentoExecucao[indexIdentificacao], "enviarPj")}
                        onChange={(name, checked) => {
                          _.set(values.identificacaoAtendimentoExecucao[indexIdentificacao], 'enviarPj', checked);
                        }}
                      />}
                    {this.getValoresGlosarepasse(values.identificacaoAtendimentoExecucao[
                      indexIdentificacao
                    ])}
                    {!viewMode && (
                      <React.Fragment>
                        <FormGroup className="central fixo">
                          {indexIdentificacao + 1 !==
                            values.identificacaoAtendimentoExecucao.length && (
                              <React.Fragment>
                                <div className="mt-25 btn-acoes d-none d-sm-flex flex-column align-self-center pl-0 pl-sm-3">
                                  <button
                                    className="btn-menos"
                                    onClick={() => {
                                      this.removeIdentificacao(indexIdentificacao);
                                    }}
                                  ></button>
                                </div>
                                <div className="bg-branco d-block d-sm-none col-12 p-2 text-center">
                                  <button
                                    className="btn-menos"
                                    onClick={() => {
                                      this.removeIdentificacao(indexIdentificacao);
                                    }}
                                  ></button>
                                </div>
                              </React.Fragment>
                            )}
                          {indexIdentificacao + 1 ===
                            values.identificacaoAtendimentoExecucao.length && (
                              <React.Fragment>
                                <div className="mt-25 btn-acoes d-none d-sm-flex flex-column align-self-center pl-0 pl-sm-3">
                                  {values.identificacaoAtendimentoExecucao.length > 1 && <button
                                    className="btn-menos"
                                    onClick={() => {
                                      this.removeIdentificacao(indexIdentificacao);
                                    }}
                                  ></button>}
                                  <button
                                    className="btn-mais"
                                    onClick={this.toogleModalMedicoPrincipal}
                                  ></button>
                                </div>
                                <div className="bg-branco d-block d-sm-none col-12 p-2 text-center">
                                  <button
                                    className="btn-mais"
                                    onClick={this.toogleModalMedicoPrincipal}
                                  ></button>
                                </div>
                              </React.Fragment>
                            )}
                        </FormGroup>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                );
              }
            )}
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({ menu: { showMenu, hideMenu }, alert: { info, clear }, }) => ({
  info: msg => info(msg),
  clear: () => clear(),
  showMenu: () => showMenu(),
  hideMenu: () => hideMenu()
});

function mapStateToProps(state) {
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    alert,
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(DadosProfissionalExecutanteGuiaForm));
