import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import {Input} from "reactstrap";
import {objectsConstants} from "../../constants/objects.constants";
import {ConfirmAcationService} from "../../services/ConfirmAcationService";
import {withRouter} from "react-router-dom";
import { notificacaoService } from '../../services/notificacao.service';

class LinhaAcaoAtivarInativarNotificacao extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    success = () =>{
        this.props.success({message: "Alterado com sucesso."});
        this.props.loading(false);
        if (this.props.initData) {
            this.props.initData();
        }
    }
    errorAtivar = () =>{
        this.props.success("Não foi possível ativar os consultórios.");
        this.props.loading(false);
    }

    errorInativar = () =>{
        this.props.success("Não foi possível inativar os consultórios.");
        this.props.loading(false);
    }


    ativarUsuario = () =>{
        this.props.loading(true);
        if(this.props.checkedAllPages){
            const filter = this.props.getFilter();
            notificacaoService.ativarNotificacaoByFilter(filter).then(() => {
                this.success();
            }).catch(() => {
                this.errorAtivar();
            });
        }else {
            notificacaoService.ativarNotificacao(this.props.rowsCheckedValue.map((rowValue) => {
                return rowValue.id;
            })).then(() => {
                this.success();
            }).catch(() => {
               this.errorAtivar();
            });
        }
    }

    inativarUsuario = () =>{
        this.props.loading(true);
        if(this.props.checkedAllPages){
            const filter = this.props.getFilter();
            notificacaoService.inativarNotificacaoByFilter(filter).then(() => {
                this.success();
            }).catch(() => {
                this.errorInativar();
            });
        }else {
            notificacaoService.inativarNotificacao(this.props.rowsCheckedValue.map((rowValue) => {
                return rowValue.id;
            })).then(() => {
                this.success();
            }).catch(() => {
                this.errorInativar();
            });
        }
    }

    render() {
        let statusUsuarioAtivo = false;
        let statusUsuarioInativo = false;
        this.props.rowsCheckedValue.map((rowValue)=>{
            if(rowValue.ativo){
                statusUsuarioAtivo =true;
            }else{
                statusUsuarioInativo = true;
            }
        })

        return <React.Fragment>

                        {!statusUsuarioAtivo&&statusUsuarioInativo&& (
                            <button type="button" className="btn btn-primary" onClick={() => {
                                ConfirmAcationService.confirmMsg(
                                    this.ativarUsuario,
                                    "Ativar Notificações",
                                    "Você confirma que deseja ativar as notificações selecionadas",
                                    null,
                                    "Cancelar",
                                    "Confirmar",
                                    objectsConstants.TYPE_WARNING
                                );
                            }}>
                                <span className="icon-btn-check"></span>
                                Ativar Notificacao
                            </button>
                        )}
                        {statusUsuarioAtivo&&!statusUsuarioInativo&& (
                            <button type="button" className="btn btn-secondary"onClick={() => {
                                ConfirmAcationService.confirmMsg(
                                    this.inativarUsuario,
                                     "Inativar Notificações",
                                    "Você confirma que deseja inativar as notificações selecionadas",
                                    null,
                                    "Cancelar",
                                    "Confirmar",
                                    objectsConstants.TYPE_WARNING
                                );
                            }}>
                                <span className="icon-btn-fechar"></span>
                                Inativar Notificação
                            </button>
                        )}
                        {statusUsuarioAtivo&&statusUsuarioInativo && (<span className={'labelInfoAction'}>Existem dois status diferentes selecionados</span>)}
                </React.Fragment>;
    }
}

const mapDispatch = ({
                         alert: { success, error, clear },
                         load: { loading }
                     }) => ({
    success: msg => success(msg),
    loading: (load: boolean) => loading({ load }),
    error: msg => error(msg),
    clear: () => clear()
});

export default connect(
    null,
    mapDispatch,
    null,
    { withRef: true }
)(withRouter(LinhaAcaoAtivarInativarNotificacao));
