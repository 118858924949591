import * as _ from "lodash";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DatePickerInput from "../../../../components/Inputs/DatePickerInput";
import FormInput from "../../../../components/Inputs/FormInput";
import InputViewEdit from "../../../../components/Inputs/InputViewEdit";
import SelectInput from "../../../../components/Inputs/SelectInput";
import { objectsConstants } from "../../../../constants/objects.constants";
import { dateHelper } from "../../../../helpers/date.helper";
import { beneficiarioService } from "../../../../services/beneficiario.service";
import SelectableInput from "../../../../sascComponents/inputs/SelectableInput";
import { formatterHelper } from "../../../../helpers/formatter.helper";
import CpfInput from "../../../../components/Inputs/CpfInput";
import { convenioService } from "../../../../services/convenio.service";
import FormSelectInput from "../../../../components/Inputs/FormSelectInput";
import { convenioPlanoService } from "../../../../services/convenioPlano.service";
import { FormGroup } from "reactstrap";

class DadosBeneficiarioGuiaConsultaForm extends PureComponent<Props, State> {
  constructor(props) {
    if(props.changeGuia){
      beneficiarioService.resetBeneficiario(props);
    }
    super(props);
  }

  findDadosBeneficiario = () => {
    beneficiarioService
      .findByCarteirinha(this.props.values.numeroCarteira)
      .then(response => {
        let newValues = _.cloneDeep(this.props.values);

        if (newValues.cpf == undefined || newValues.cpf === "") {
          newValues.dataValidadeCarteira = response.data.dataValidadeCarteira;
          newValues.dataNascimento = response.data.dataNascimento;
          newValues.nomeBeneficiario = response.data.nomeBeneficiario;
          newValues.cartaoNacionalSaude = response.data.cartaoNacionalSaude;
          if (response.data.cpf) {
            newValues.cpf = response.data.cpf;
          } else {
            newValues.cpf = '';
          }
        }
        this.props.setValues(newValues);
      },
        error => {
          this.props.infoBlack({
            message: `Este beneficiário ainda não foi cadastrado. Solicite e preencha todas as informações do beneficiário.`
          });
        }
      );
  };

  findDadosBeneficiarioByCpf = () => {
    beneficiarioService
      .findByCpf(this.props.values.cpf)
      .then(response => {
        let newValues = _.cloneDeep(this.props.values);
        if(newValues.numeroCarteira == undefined || newValues.numeroCarteira === "") {
            newValues.dataValidadeCarteira = response.data.dataValidadeCarteira;
            newValues.dataNascimento = response.data.dataNascimento;
            newValues.nomeBeneficiario = response.data.nomeBeneficiario;
            newValues.cartaoNacionalSaude = response.data.cartaoNacionalSaude;
            newValues.numeroCarteira = response.data.numeroCarteira;
          }
        this.props.setValues(newValues);
        this.props.verificaRetorno(`atendimentoExecucaos[0].tipoConsulta`);
      },
        error => {
          this.props.infoBlack({
            message: `Este beneficiário ainda não foi cadastrado. Solicite e preencha todas as informações do beneficiário.`
          });
        }
      );
  };

	handletiposDeGuiaQueExibeCampoProduto(){
		const { values } = this.props;
		let tiposDeGuiaQueExibeCampoProduto = _.get(values, "convenio.tiposGuiaProduto");
		let exibe =false

		objectsConstants.TIPOS_GUIA_REGRA_CONVENIO.map(tipoGuia =>{
			//quando o type da guia  bate com o label do obj-contant
			if(values.type === tipoGuia.label && tiposDeGuiaQueExibeCampoProduto){
			/*
				verifica em quais tipos de guia o campo produto  deve ser exibido de acordo com o nome;
			*/
				tiposDeGuiaQueExibeCampoProduto.map(item =>{
					if(item.nome===tipoGuia.nome){
						exibe=true;
					}
				})

			}
		});

		return exibe;
	}


  render() {
    const { setFieldValue, values, errors, viewMode } = this.props;
    return (
      <React.Fragment>
        <div className={`row ${this.props.className}`}>
          <h6 className="w-100">Dados do Beneficiário</h6>
          <SelectableInput viewMode={viewMode} atendimento={values} className="fixo2"
            name={"numeroCarteira"}
            label={"Número da carteira"}
            value={values.numeroCarteira}
          >
            <InputViewEdit
              label="Número da carteira"
              component={FormInput}
              required={true}
              onChange={(name, date) => {
                  let value="";
                  value = beneficiarioService.validaTipoCarteirinha(date,values.convenio)
                  setFieldValue(name, value);
              }}
              onBlur={this.findDadosBeneficiario}
              className={"destaque"}
              name={"numeroCarteira"}
              placeholder=""
              viewMode={viewMode}
              erroMensagem={errors.numeroCarteira}
              value={values.numeroCarteira}
              maxLength={_.get(values,'convenio.quantDigitosCarteirinha',200) }
              alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
            />
          </SelectableInput>
          <SelectableInput viewMode={viewMode} atendimento={values} className="fixo2"
            name={"dataValidadeCarteira"}
            label={"Data de validade da carteira"}
            value={values.dataValidadeCarteira}
          >
            <InputViewEdit
              label="Data de validade da carteira"
              component={DatePickerInput}
              required={values.convenio && values.convenio.validadeCarteira}
              onChange={(name, date) => {
                setFieldValue(name, date);
              }}
              defaultValue={
                values.dataValidadeCarteira
                  ? dateHelper.format(values.dataValidadeCarteira, {
                    mode: "DATE"
                  })
                  : "--"
              }
              mask={"99/99/9999"}
              className={"destaque"}
              name={"dataValidadeCarteira"}
              id={"dataValidadeCarteira"}
              placeholder=""
              viewMode={viewMode}
              type={"text"}
              value={values.dataValidadeCarteira}
              erroMensagem={errors.dataValidadeCarteira}
              alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
            />
          </SelectableInput>
          {/* PLANO */}
          <FormGroup className="fixo mobile-100">
            <InputViewEdit
              label="Plano"
              component={FormSelectInput}
              service={convenioPlanoService.findByConvenio}
              returnFullObject={true}
              parent={values.convenio && values.convenio.id}
              required={values.convenio && values.convenio.planoObrigatorio}
              searchable={true}
              labelKey={"label"}
              valueKey={"plano"}
              onChange={(name, date) => {
                setFieldValue(name, date);
              }}
              erroMensagem={
                _.get(errors, `plano`)
              }
              name={`plano`}
              defaultValue={values.plano ? values.plano.label : "--"}
              placeholder=""
              viewMode={viewMode}
              value={values.plano}
              clearable={true}
            />
          </FormGroup>
          <SelectableInput viewMode={viewMode} atendimento={values} className="fixo2"
            name={"cpf"}
            label={"CPF"}
            value={values.cpf}
          >
            <InputViewEdit
              component={CpfInput}
              label="CPF"
              id="cpf"
              name="cpf"
              onChange={(name, value) => {
                setFieldValue(name, value);
              }}
              value={values.cpf}
              onBlur={this.findDadosBeneficiarioByCpf}
              placeholder="CPF"
              noSize={true}
              viewMode={viewMode}
              erroMensagem={errors.cpf}
              required={convenioService.isCPFObrigatoria(values.type,_.get(values, 'convenio.listaRegrasSenhaConvenio', false))}
              alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
            />
          </SelectableInput>
          <SelectableInput viewMode={viewMode} atendimento={values} className="fixo2"
            name={"dataNascimento"}
            label={"Data de nascimento"}
            value={values.dataNascimento}
          >
            <InputViewEdit
              label="Data de nascimento"
              component={DatePickerInput}
              onChange={(name, date) => {
                setFieldValue(name, date);
              }}
              defaultValue={
                values.dataNascimento
                  ? dateHelper.format(values.dataNascimento, {
                    mode: "DATE"
                  })
                  : "--"
              }
              mask={"99/99/9999"}
              name={"dataNascimento"}
              id={"dataNascimento"}
              placeholder="Data de nascimento"
              viewMode={viewMode}
              type={"text"}
              value={values.dataNascimento}
              erroMensagem={errors.dataNascimento}
              required={convenioService.isDataNascimentoObrigatoria(values.type,_.get(values, 'convenio.listaRegrasSenhaConvenio', false))}
              alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
            />
          </SelectableInput>

          <SelectableInput viewMode={viewMode} atendimento={values} className=""
            name={"nomeBeneficiario"}
            label={"Nome do beneficiário"}
            value={values.nomeBeneficiario}>
            <InputViewEdit
              label="Nome do beneficiário"
              component={FormInput}
              required={true}
              onChange={(name, date) => {
                  if(date && date!=null){
                      date = date.toUpperCase()
                  }
                setFieldValue(name, date);
              }}
              className={"destaque"}
              name={"nomeBeneficiario"}
              placeholder=""
              type={"text"}
              viewMode={viewMode}
              erroMensagem={errors.nomeBeneficiario}
              value={values.nomeBeneficiario}
              alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
            />
          </SelectableInput>

          <SelectableInput viewMode={viewMode} atendimento={values} className="fixo2"
            name={"cartaoNacionalSaude"}
            label={"Cartão nacional de saúde"}
            value={values.cartaoNacionalSaude}>
            <InputViewEdit
              label="Cartão nacional de saúde"
              component={FormInput}
              onChange={(name, date) => {
                setFieldValue(name, date);
              }}
              viewMode={viewMode}
              name={"cartaoNacionalSaude"}
              placeholder=""
              type={"text"}
              value={values.cartaoNacionalSaude}
              alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
            />
          </SelectableInput>

          <SelectableInput viewMode={viewMode} atendimento={values} className="fixo2"
            name={"atendimentoRn"}
            label={"Atendimento a RN"}
            value={values.atendimentoRn &&
              values.atendimentoRn.value == false ? 'Não'
              : formatterHelper.getLabelEnum(
                values.atendimentoRn &&
                  values.atendimentoRn.value
                  ? values.atendimentoRn.value
                  : values.atendimentoRn,
                objectsConstants.SIM_NAO
              )}>
            <InputViewEdit
              label="Atendimento a RN"
              component={SelectInput}
              options={objectsConstants.SIM_NAO}
              name={"atendimentoRn"}
              searchable={true}
              returnFullObject={true}
              defaultValue={values.atendimentoRn &&
                values.atendimentoRn.value == false ? 'Não'
                : formatterHelper.getLabelEnum(
                  values.atendimentoRn &&
                    values.atendimentoRn.value
                    ? values.atendimentoRn.value
                    : values.atendimentoRn,
                  objectsConstants.SIM_NAO
                )}
              value={_.isBoolean(values.atendimentoRn) ? formatterHelper.getEnum(values.atendimentoRn, objectsConstants.SIM_NAO) : values.atendimentoRn}
              labelKey={"label"}
              valueKey={"value"}
              viewMode={viewMode}
              onChange={(name, value) => {
                setFieldValue(name, value.value);
              }}
              alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
            />
          </SelectableInput>

        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  menu: { showMenu, hideMenu },
  alert: { infoBlack, error, clear }
}) => ({
  showMenu: () => showMenu(),
  hideMenu: () => hideMenu(),
  infoBlack: msg => infoBlack(msg),
  error: msg => error(msg)
});

function mapStateToProps(state) {
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    alert,
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(DadosBeneficiarioGuiaConsultaForm));
