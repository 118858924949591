//@flow
import type { OrdemServicoType } from '../types/ordemServico.type';
import * as React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { objectsConstants } from '../constants/objects.constants';
import iconWarning from '../img/iconAlert_Atencao.svg';
import iconCancel from '../img/iconAlert_CancelarCriacao.svg';
import iconExcluir from '../img/iconAlert_Excluir.svg';
import iconEncaminhar from '../img/icon_encaminhar.svg';
import iconAddLote from '../img/icon_addLote.svg';
import iconCriarLote from '../img/icon_criarLote.svg';
import iconRetorno from '../img/icon_benef.svg';

export const ConfirmAcationService = {
  confirmMsg,
  confirmMsgPrazoFaturaVencida
};

function getIcon(type) {
  if (type === objectsConstants.TYPE_EXCLUIR) {
    return iconExcluir;
  }
  if (type === objectsConstants.TYPE_WARNING) {
    return iconWarning;
  }
  if (type === objectsConstants.TYPE_CANCEL) {
    return iconCancel;
  }
  if (type === objectsConstants.TYPE_FORWARD) {
    return iconEncaminhar;
  }
  if (type === objectsConstants.TYPE_ADD_IN_LOTE) {
    return iconAddLote;
  }
  if (type === objectsConstants.TYPE_NEW_LOTE) {
    return iconCriarLote;
  }
  if (type === objectsConstants.TYPE_RETORNO) {
    return iconRetorno;
  }
}

function confirmMsg(onConfirm, title, mensagem, subMensagem, textCancelBtn, textConfirmBtn, type,onCancel) {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="confirm-ui css-alert-sasc">
          <img src={getIcon(type)} alt="icon" />
          <h2 >{title}</h2>
          <p>
            <b>{subMensagem}</b>
          </p>
          <div className='row body-confirm'>{mensagem}</div>
            {textCancelBtn&&(
          <button
            className="btn btn-secondary white mt-3 mb-4 mx-2"
            onClick={() => {
              if(onCancel){
                onCancel();
              }
              onClose();
            }}
          >{textCancelBtn}
          </button>
            )}
          {textConfirmBtn && <button
            className="btn btn-primary white mt-3 mb-4 mx-2"
            onClick={() => {
              onConfirm();
              onClose();
            }}
          >{textConfirmBtn}
          </button>
          }
        </div>
      );
    },
    closeOnEscape: false,
  closeOnClickOutside: false,

  });
}


function confirmMsgPrazoFaturaVencida(onConfirm, title, mensagem, subMensagem, textCancelBtn, textConfirmBtn, type,onCancel) {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="confirm-ui css-alert-sasc" style={{width:"800px"}}>
          <img src={getIcon(type)} alt="icon" />
          <h2 >{title}</h2>
          <p>
            <b>{subMensagem}</b>
          </p>
          <div className='row body-confirm'>{mensagem}</div>
            {textCancelBtn&&(
          <button
            className="btn btn-secondary white mt-3 mb-4 mx-2"
            onClick={() => {
              if(onCancel){
                onCancel();
              }
              onClose();
            }}
          >{textCancelBtn}
          </button>
            )}
          {textConfirmBtn && <button
            className="btn btn-primary white mt-3 mb-4 mx-2"
            onClick={() => {
              onConfirm();
              onClose();
            }}
          >{textConfirmBtn}
          </button>
          }
        </div>
      );
    },
    closeOnEscape: false,
  closeOnClickOutside: false,

  });
}