//@flow
import { AbilityBuilder } from "@casl/ability";
import * as _ from "lodash";
import { ABILITIES } from "../config/ability";
import { objectsConstants } from "../constants/objects.constants";
import { authenticationService } from "../services/authentication.service";
import { userService } from "../services/user.service";

type State = {
  loggedIn: boolean,
  user: UserType,
  refresh: boolean,
  permissions: any
};

type Payload = {
  user: UserType,
  company: any
};

function isAuditor(usuario) {
  return userService.isPerfil(usuario, objectsConstants.AUDITOR);
}
function loadUserPermission(user) {

  //difine permissões do usuario
  const ability = AbilityBuilder.define(can => {
    if (user.perfilAtual) {
      let perfil = user.perfilAtual.perfil;
      for (let j = 0; j < perfil.perfilFuncionalidades.length; j++) {
        let func = perfil.perfilFuncionalidades[j];
        can(func.permissao, func.funcionalidade);
      }
    } else {
      let perfils = user.usuarioPerfis;
      for (let i = 0; i < perfils.length; i++) {
        let perfil = perfils[i];
        for (let j = 0; j < perfil.perfil.perfilFuncionalidades.length; j++) {
          let func = perfil.perfil.perfilFuncionalidades[j];
          can(func.permissao, func.funcionalidade);
        }
      }
    }
  });
  return ability;
}

function ehMesmoPerfil(perfis) {
  const unicos = _.uniqBy(perfis, 'perfil.tipoPerfil');
  if (unicos.length > 1) {
    return false
  } else {
    return true
  }

}
export default {
  state: {
    loggedIn: false,
    user: { usuarioPerfis: [] },
    selectPerfilAuditor: false,
    selectPerfilAtual: false,
    refresh: false,
    isAuditor: false,
    isMultiPerfil: false,
    permissions: ABILITIES.DEFAULT
  },

  reducers: {
    success: (state: State, payload: Payload) => {
      const ability = loadUserPermission(payload.user);

      return Object.assign({}, state, {
        loggedIn: true,
        selectPerfilAuditor: false,
        selectPerfilAtual: false,
        isAuditor: false,
        user: payload.user,
        permissions: ability
      });
    },

    logout: (state: State) => {
      authenticationService.doLogout();
      return Object.assign({}, state, {
        loggedIn: false,
        user: { usuarioPerfis: [] },
        permissions: ABILITIES.DEFAULT
      });
    },
    loadSelectPerfil: (state: State, payload: Payload) => {
      return Object.assign({}, state, {
        loggedIn: true,
        selectPerfilAuditor: payload.selectPerfilAuditor,
        selectPerfilAtual: payload.selectPerfilAtual,
        user: payload.user,
        permissions: ABILITIES.DEFAULT,
        isAuditor: payload.isAuditor,
        isMultiPerfil: payload.isMultiPerfil
      });
    },
    closeSelectPerfil: (state: State, payload: Payload) => {
      const ability = loadUserPermission(payload.user);
      return Object.assign({}, state, {
        selectPerfilAuditor: payload.selectPerfilAuditor,
        selectPerfilAtual: payload.selectPerfilAtual,
        user: payload.user,
        permissions: ability
      });
    },
    refreshUser: (state: State, payload: Payload) => {
      return Object.assign({}, state, {
        refresh: true,
        user: payload.user
      });
    },
    loadCompany: (state: State, payload: Payload) => {
      return Object.assign({}, state, {
        refresh: true,
        company: payload.company
      });
    }
  },
  effects: (dispatch: any) => ({
    login: async (payload: { user: string, password: string ,captcha:string}) => {
      dispatch.load.loading({ load: true });
      await authenticationService.doLogin(payload.user, payload.password,payload.captcha).then(
        async () => {
          await userService.me().then(response => {
            const user = response.data;
            dispatch.filtro.removeAllFilters()
            if (user.usuarioPerfis.length > 1) {
              if (ehMesmoPerfil(user.usuarioPerfis)) {
                dispatch.authentication.success({ user, isAuditor: false });
              } else {
                dispatch.authentication.loadSelectPerfil({
                  user,
                  selectPerfilAuditor: false,
                  selectPerfilAtual: true,
                  isMultiPerfil: true,
                  isAuditor: false
                });

              }
            } else if (isAuditor(user)) {
              dispatch.authentication.loadSelectPerfil({
                user,
                selectPerfilAuditor: true,
                selectPerfilAtual: false,
                isAuditor: true
              });

            } else {
              dispatch.authentication.success({ user, isAuditor: false });
            }
            dispatch.load.loading({ load: false });
          });
        },
        () => {
          dispatch.authentication.logout();
          dispatch.alert.error({
            message: "Confira o seu login e senha e tente novamente!",
            code: "Ops,"
          });
          dispatch.load.loading({ load: false });
        }
      );
    },
    async doRefresh(payload: mixed, rootState: RootState) {
      if (authenticationService.hasToken()) {
        try {
          userService.me().then(response => {
            dispatch.authentication.success({ user: response.data });
          },
            error => {
              localStorage.clear();
              dispatch.authentication.logout();
            }
          );
        } catch (error) {
          console.error(error);
          dispatch.authentication.logout();
        }
      }
    }
  })
};
