import React, { useState, useEffect } from 'react';
import { urlsConstants } from '../../constants/url.constant';
import { defaultService } from "../../services/defaultService";

const MultiFileViewer = ({ anexos, idSelecionado }) => {
  const selectedAnexoIndex = anexos.findIndex((anexo) => anexo.midia.id === idSelecionado);
  const [currentAnexoIndex, setCurrentAnexoIndex] = useState(selectedAnexoIndex);
  const [fileURL, setFileURL] = useState(null);

  const downloadFile = async (anexo) => {
    if (anexo && anexo.midia && anexo.midia.id) {
      const url = `${urlsConstants.MIDIA}vo/${anexo.midia.id}`;
      const response = await defaultService.doGet(url);
      const base64 = response.data.data;
      const type = response.data.contentType;

      if (base64) {
        const binaryString = atob(base64);
        const byteArray = Uint8Array.from(
          binaryString,
          (char) => char.charCodeAt(0)
        );
        const fileBlob = new Blob([byteArray], { type });
        const fileURL = URL.createObjectURL(fileBlob);

        setFileURL(fileURL);
      }
    }
  };

  useEffect(() => {
    if (anexos.length > 0) {
      downloadFile(anexos[currentAnexoIndex]);
    }
  }, [currentAnexoIndex, anexos]);

  const handlePrevious = () => {
    if (currentAnexoIndex > 0) {
      setCurrentAnexoIndex(currentAnexoIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentAnexoIndex < anexos.length - 1) {
      setCurrentAnexoIndex(currentAnexoIndex + 1);
    }
  };

  if (!fileURL) {
    return <div>Carregando...</div>;
  }

  const currentAnexo = anexos[currentAnexoIndex];

  const tipoArquivo = () =>{
    if (currentAnexo.midia.contentType.startsWith("image") || currentAnexo.midia.contentType === "application/pdf") {
      if(currentAnexo.midia.contentType.endsWith("tiff")){
        return "Não foi possível realizar a pré-visualização do arquivo, favor realizar o download";
      }
      return "Visualizar";
    }
      return "Não foi possível realizar a pré-visualização do arquivo, favor realizar o download";
  }

  return (
    <div className="visualizar-anexo">
      {/* Exibe o arquivo (imagem ou PDF) */}
      {currentAnexo.midia.contentType.startsWith("image") && <img src={fileURL} alt="anexo" />}
      {currentAnexo.midia.contentType === "application/pdf" && (
        <embed src={fileURL} type="application/pdf" width="100%" height="100%" />
      )}

      {/* Botões para navegação */}
      <div>
        <button onClick={handlePrevious} disabled={currentAnexoIndex === 0}>
          Anterior
        </button>
        <button
          onClick={handleNext}
          disabled={currentAnexoIndex >= anexos.length - 1}
        >
          Próximo
        </button>
      </div>

      {/* Link para download */}
      <a href={fileURL} target="_blank" download={currentAnexo.midia.name}>
        {tipoArquivo()}: <span className="font-italic font-12px cut-text flex-fill">{currentAnexo.midia.nome}</span>
      </a>
    </div>
  );
};

export default MultiFileViewer;
