import _ from "lodash";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import BtnLink from "../../../components/Buttons/BtnLink";
import { FormGroup } from "../../../components/FormGroup";
import InputViewEdit from "../../../components/Inputs/InputViewEdit";
import SelectInput from "../../../components/Inputs/SelectInput";
import CheckBoxInput from "../../../components/Inputs/CheckBoxInput";
import { modeloVisualizacaoService } from "../../../services/modeloVisualizacao.service";
import { objectsConstants } from "../../../constants/objects.constants";
import FormInput from "../../../components/Inputs/FormInput";
import { defaultService } from "../../../services/defaultService";
import BtnSecondary from "../../../components/Buttons/BtnSecondary";
import {ModalFooter} from "reactstrap";

class MenuModelView extends PureComponent<Props, State> {
  static defaultProps = {
    listaHistoricos: []
  };
  constructor(props) {
    super(props);
    this.state = {
      modelos: [],
      modeloVisualizacao: null,
      showNew: false,
      novoModelo: {},
      texto: "",
      modeloVisualizacaoInicial:""

    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.modeloVisualizacao && this.state.modeloVisualizacao.json!=null
        && this.props.openMenuModelView == undefined
      ){
        this.setState({ modeloVisualizacaoInicial: this.state.modeloVisualizacao.id });
        this.props.handleModelo(this.state.modeloVisualizacao.json);
    }

    try {
      if (prevProps.atendimentos.length !== this.props.atendimentos.length) {
        const atendimentosType = _.uniqBy(this.props.atendimentos, "type").map(
          value => {
            return value.type;
          }
        );
        if (atendimentosType) {
          const pathAtual = this.props.location.pathname;
         
          modeloVisualizacaoService.findAll(pathAtual.includes('/lotes') ? [] : atendimentosType).then(response => {
            const modelos = response.data;
            let modeloVisualizacao = modelos[0];
            if(modeloVisualizacao && modeloVisualizacao.json) {
              modeloVisualizacao.json = modeloVisualizacao.json.sort(function (
                  a,
                  b
              ) {
                if (a.label > b.label) {
                  return 1;
                }
                if (a.label < b.label) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              });
            }
            this.setState({ modelos, modeloVisualizacao });
          });
        }
      }
    } catch (error) {
      console.error(error)
    }
    
    try {
      if ((this.props.atendimentos && this.props.atendimentos.length === 1) && (prevProps.atendimentos && prevProps.atendimentos.length === 1)) {
        const atendimentosType = _.uniqBy(this.props.atendimentos, "type").map(
          value => {
            return value.type;
          }
        );
        const prevAtendimentosType = _.uniqBy(prevProps.atendimentos, "type").map(
          value => {
            return value.type;
          }
        );
        if (prevAtendimentosType[0] !== atendimentosType[0]) {
          if (atendimentosType) {
            modeloVisualizacaoService.findAll(atendimentosType).then(response => {
              const modelos = response.data;
              let modeloVisualizacao = modelos[0];
              if (modeloVisualizacao && modeloVisualizacao.json) {
                modeloVisualizacao.json = modeloVisualizacao.json.sort(function (
                  a,
                  b
                ) {
                  if (a.label > b.label) {
                    return 1;
                  }
                  if (a.label < b.label) {
                    return -1;
                  }
                  // a must be equal to b
                  return 0;
                });
              }
              this.setState({ modelos, modeloVisualizacao });
            });
          }
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  componentDidMount() {
    try {

      if (!this.props.atendimentos) {
        modeloVisualizacaoService.findAll([]).then(response => {
          const modelos = response.data;
          let modeloVisualizacao = modelos[0];
          if(modeloVisualizacao && modeloVisualizacao.json) {
            modeloVisualizacao.json = modeloVisualizacao.json.sort(function (a, b) {
              if (a.label > b.label) {
                return 1;
              }
              if (a.label < b.label) {
                return -1;
              }
              // a must be equal to b
              return 0;
            });
          }
          this.setState({ modelos, modeloVisualizacao });
        });
      }
      
    } catch (error) {
      console.error(error)
    }
    
  }

  mudarModoVisualizarUsuario = () => {
    let modeloVisualizacao = _.cloneDeep(this.state.modeloVisualizacao);
    const atendimentosType = _.uniqBy(this.props.atendimentos, "type").map(
      value => {
        return value.type;
      }
    );

    if(this.state.modeloVisualizacaoInicial == undefined && modeloVisualizacao.id){
      this.setState({ modeloVisualizacaoInicial: modeloVisualizacao.id });
      modeloVisualizacaoService.adicionarModeloVisualizacaoUsuario(modeloVisualizacao.id,atendimentosType); 
    }else if(modeloVisualizacao.id && modeloVisualizacao.id != this.state.modeloVisualizacaoInicial){
      this.setState({ modeloVisualizacaoInicial: modeloVisualizacao.id });
      modeloVisualizacaoService.alterarModeloVisualizacaoUsuario(this.state.modeloVisualizacaoInicial,modeloVisualizacao.id,atendimentosType);
    }else if(this.state.modeloVisualizacaoInicial && modeloVisualizacao.id == undefined){
      this.setState({ modeloVisualizacaoInicial: null });
     modeloVisualizacaoService.removerModeloVisualizacaoUsuario(this.state.modeloVisualizacaoInicial,atendimentosType);
    }


  }


  handleChange = (name, value) => {
    let novoModelo = _.cloneDeep(this.state.novoModelo);
    _.set(novoModelo, name, value);
    this.setState({ novoModelo });
  };

  onChangeField = (name, checkboxValue) => {
    let modeloVisualizacao = _.cloneDeep(this.state.modeloVisualizacao);
    let showNew = true;
    modeloVisualizacao.json.map(value => {
      if (value.label === name) {
        value.visiable = checkboxValue;
      }
    });
    let findModeloMesmaConfiguracao = this.findModeloMesmaConfiguracao(
      modeloVisualizacao
    );
    if (findModeloMesmaConfiguracao != null) {
      showNew = false;
      modeloVisualizacao = findModeloMesmaConfiguracao;
    }

    this.setState({ modeloVisualizacao, showNew });
  };

  checkedAll = (name, checkboxValue) => {
    let modeloVisualizacao = _.cloneDeep(this.state.modeloVisualizacao);
    modeloVisualizacao.json.map(value => {
      value.visiable = checkboxValue;
    });
    this.setState({ modeloVisualizacao, showNew: !checkboxValue });
  };

  isArrayEqual = (x, y) => {
    return _(
      _.filter(x, field => {
        return field.show;
      })
    )
      .xorWith(
        _.filter(y, field => {
          return field.show;
        }),
        _.isEqual
      )
      .isEmpty();
  };

  findModeloMesmaConfiguracao = modeloVisualizacao => {
    const modelos = _.cloneDeep(this.state.modelos);
    return _.find(modelos, modelo => {
      if (this.isArrayEqual(modelo.json, modeloVisualizacao.json)) {
        return modelo;
      }
    });
  };
  create = () => {
    return (<React.Fragment>
      <div className="col-9 m-2 p-0 d-flex">

        <button
            type="button"
            className="btn btn-secondary ml-auto"
            onClick={() => {
              this.setState({
                texto: "",
                showNew: false
              })
            }}
        >
          <span className={`icon-btn-fechar`}></span>
          {"Cancelar"}
        </button>
        <React.Fragment>
          <button
              className="btn btn-light btDefaultGrid ml-auto "
              onClick={this.saveModelo}
          >
            <span className="icon-btn-editar"></span>
            Salvar modelo
          </button>
        </React.Fragment>

      </div>
      <div className="col-8 m-2 p-0 h-40">

        <React.Fragment>
          <FormInput
              type="text"
              id="nome"
              name="nome"
              placeholder="Nome do modelo"
              value={this.state.novoModelo.nome}
              onChange={(name, value) => {
                this.handleChange(name, value);
              }}
              noSize={true}
              required={true}
          />
        </React.Fragment>

      </div>

    </React.Fragment>);

  }

  update = () =>{
    let text =  (
        <div className="col-9 m-2 p-0 d-flex">

          <React.Fragment>


            <button
                type="button"
                className="btn btn-secondary ml-auto "
                onClick={() => {
                  this.setState({
                    texto: "",
                    showNew: false
                  })
                }}
            >
              <span className={`icon-btn-fechar`}></span>
              {"Cancelar"}
            </button>

            <button
                className="btn btn-light btDefaultGrid ml-auto "
                onClick={this.updateModelo}
            >
              <span className="icon-btn-editar"></span>
              Salvar modelo
            </button>
          </React.Fragment>

        </div>);
    return text;
  }

  saveModelo = () => {
    let modeloVisualizacao = _.cloneDeep(this.state.modeloVisualizacao);
    let novoModelo = _.cloneDeep(this.state.novoModelo);
    novoModelo.json = modeloVisualizacao.json;
    modeloVisualizacaoService.save(novoModelo).then(response => {
      let modelos = _.cloneDeep(this.state.modelos);
      let id = defaultService.getIdFromUrl(response.headers.location);
      novoModelo.id = id;
      modelos.push(novoModelo);
      this.setState({
        modelos,
        modeloVisualizacao: novoModelo,
        showNew: false,
        novoModelo: {}
      });
    });
  };
  updateModelo = () => {
    let modeloVisualizacao = _.cloneDeep(this.state.modeloVisualizacao);

    modeloVisualizacaoService.save(modeloVisualizacao).then(response =>{
      let modelos = _.cloneDeep(this.state.modelos);
      for(let i = 0; i < modelos.length; i++){
        if(modelos[i].id == modeloVisualizacao.id){
          modelos[i] = modeloVisualizacao;
        }
      }
      this.setState({
        modelos,
        modeloVisualizacao: modeloVisualizacao,
        showNew: false,
        novoModelo: {}
      });
    });

  };

  render() {
    return (
      <div
        className={`menu-model-view ${
          this.props.openMenuModelView ? "aberto" : ""
        }`}
      >
        <div
          // id="abreHistorico"
          className="text-center py-3 d-flex align-items-center justify-content-between"
        >
          <BtnLink
            type={"button"}
            className={""}
            icone={""}
            title={"Fechar"}
            onSubmit={this.props.toggleMenuModelView}
            disabled={false}
          />
          <h1 className="font-bold">
            <span className="icon-visualizacao"></span> Visualização
          </h1>
          <span className="float-left font-12px acerto-p-m invisible">
            Fechar
          </span>
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center px-2 py-1 px-sm-5 py-sm-3">
          <p className="ml-3">
            Selecione abaixo os campos da guia que deseja visualizar e editar ou
            escolha um modelo de visualização já criado.
          </p>
          <FormGroup className="form-group-boorder col">
            <InputViewEdit
              component={SelectInput}
              onChange={(name, value) => {
                this.setState({ modeloVisualizacao: value });
              }}
              label={"Modelo de visualização"}
              options={this.state.modelos}
              labelKey={"nome"}
              valueKey={"id"}
              value={this.state.modeloVisualizacao}
              returnFullObject={true}
              name={`modeloVisualizacao`}
              placeholder="Modelo Padrão"
              className={
                "form-group form-group-boorder col-12 col-sm-12 text-center"
              }
            />
            {!this.state.showNew &&
                <React.Fragment>

                  <button
                      type="button"
                      className="btn btn-light m-1 mr-2 mt-2"
                      onClick={() => {
                        this.setState({
                          texto: this.create(),
                          showNew: true
                        })

                      }}
                  >
                    <span className="icon-btn-add "></span>Novo Modelo
                  </button>
                  <button
                      type="button"
                      className="btn btn-light  m-1 mt-2"
                      onClick={() => {
                        this.setState({
                          texto: this.update(),
                          showNew: true
                        })
                      }}
                  >
                    <span className="icon-btn-editar"></span>Editar
                  </button>

                </React.Fragment>
            }

          </FormGroup>

          <hr className="w-100 b-dash" />
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center py-1 py-sm-3">
          <div className={"row col-12 "}>
            <React.Fragment id="createOrUpdate">
              {this.state.showNew&&this.state.texto}
            </React.Fragment>
            <div className="col-12 col-sm-12 p-0">
              <CheckBoxInput
                label={"Todos"}
                name={""}
                id={""}
                value={""}
                onChange={this.checkedAll}
                checked={
                  this.state.modeloVisualizacao &&
                  this.state.modeloVisualizacao.json.filter(value => {
                    return !value.visiable;
                  }).length === 0
                }
              />
            </div>



          </div>
          <div className="col-12 col-sm-12 ml-5">
            {this.state.modeloVisualizacao &&
              this.state.modeloVisualizacao.json &&
              _.filter(this.state.modeloVisualizacao.json, field => {
                return field.show;
              }).map(field => {
                return (
                  <CheckBoxInput
                    label={field.label}
                    name={field.label}
                    disabled={!this.state.showNew}
                    id={""}
                    value={field.visiable}
                    onChange={(name, value) => {
                      this.onChangeField(name, value);
                    }}
                    checked={field.visiable}
                  />
                );
              })}
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center ">
          <button
            className="btn btn-primary white mb-4 mx-2"
            onClick={() => {
              this.mudarModoVisualizarUsuario();
              this.props.handleModelo(this.state.modeloVisualizacao.json);
              this.props.toggleMenuModelView();
            }}
          >
            ok
          </button>
        </div>
      </div>
    );
  }
}

const mapDispatch = ({ modeloVisualizacao: { handleModelo } }) => ({
  handleModelo: modelo => handleModelo(modelo)
});
function mapStateToProps(state) {
  const { idAtendimentoAtual } = state.pendencia;
  const { permissions } = state.authentication;

  return {
    idAtendimentoAtual,
    permissions
  };
}

export default connect(mapStateToProps, mapDispatch)(withRouter(MenuModelView));
