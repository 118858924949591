import React, { PureComponent } from "react";
import { Input } from "reactstrap";
export default class ColumnCheckboxAction extends PureComponent<> {
  constructor(props) {
    super(props);
    let checked = false;
    if (props.checkedAllPages) {
      checked = true;
    }
    this.state = { checked: checked, id: props.objectRow[props.labelId] };
  }

  onClick = () => {
    this.setState(
      { checked: !(this.props.checkedAll || this.state.checked) },
      () => {
        if (!this.props.onChange) {
          throw "É necessario informar o onChange do componente ColumnCheckboxAction";
        }
        this.props.onChange(
          this.state.checked,
          this.state.id,
          this.props.objectRow
        );
      }
    );
  };
  componentWillReceiveProps(nextProps) {

    if (nextProps.checkedAll) {
      this.setState({ checked: true });
    } else {
      if (nextProps.rowsCheckedIdValue.length === 0 && this.state.checked) {
        this.setState({ checked: false });
      }
    }

    if (nextProps.rowsCheckedIdValue.includes(this.state.id)) {
      this.setState({ checked: true });
    } else {
      this.setState({ checked: false });
    }
  }

  render() {
    const { title } = this.props;
    return (
      <td
        data-title={title}
        className={`colunaCheck ${
          this.props.checkedAll || this.state.checked
            ? "classChecked"
            : "classUnchecked"
          }`}>
        <label className="control control--checkbox">
          <Input
            type={"checkbox"}
            className={`${
              this.props.checkedAll || this.state.checked
                ? "classChecked"
                : "classUnchecked"
              }`}
            checked={
              this.props.checkedAll ? this.props.checkedAll : this.state.checked
            }
            onClick={this.onClick}
          />{" "}
          <div className="control__indicator"></div>
        </label>
      </td>
    );
  }
}
