import React from "react";
import { alertConstants } from "../../../constants/alert.constants";
import { datatableConstants } from "../constants/datatable.constants";
import UserAvatar from "../../../components/Avatar/Avatar";
import * as _ from "lodash";
import { dateHelper } from "../../../helpers/date.helper";
export const datatableHelper = {
  getValue,
  getClassByType
};

function getClassByType(valueParam, type, row, labelArrayKey, column) {
    let value = getFiledValue(valueParam, type, row, labelArrayKey, column);
  if (typeof value !== "undefined") {
    switch (type) {
        case datatableConstants.PHOTO:
                return value;
      case datatableConstants.ENUM:
        return value.className?value.className:"";
      default:
        return "";
    }
  } else {
    return "";
  }
}

function getValue(valueParam, type, row, labelArrayKey, column) {

    let value = getFiledValue(valueParam, type, row, labelArrayKey, column);

    if (type === datatableConstants.PHOTO) {
        return value;
      }

    return getValueByType(value,type);

}

function getFiledValue(valueParam, type, row, labelArrayKey, column) {
  if (type === datatableConstants.PHOTO) {
    return <UserAvatar user={row} size={34} />;
  }
  const fieldName = column.fieldName;
  let path;
  // if(valueParam && !_.isObject(valueParam)){
  //   return valueParam;
  // }
  if (column.path && column.path.indexOf(".") !== -1) {
    path = column.path.substring(column.path.indexOf(".") + 1);
  }
  let value;
  if (path) {
    if (path.indexOf(".") !== -1) {
      value = row[path.substring(0, path.indexOf("."))];
    } else {
      value = _.get(row, path);
    }
  } else {
    value = _.get(row, fieldName);
  }
  if (Object.prototype.toString.call(value) === "[object Array]") {
    const list = _.cloneDeep(value);
    let valueReturn = "";
    if (!list || list.length == 0) {
      return "--";
    }
    let fieldNamePropsArray;
    if (path.indexOf(".") !== -1) {
      fieldNamePropsArray = path.substring(path.indexOf(".") + 1);
    }
    for (let i = 0; i < list.length; i++) {
      if (fieldNamePropsArray) {
        valueReturn =
          valueReturn +
          getValueByType(
            _.get(list[i], fieldNamePropsArray + "." + fieldName, ""),
            type,
            column
          );
      } else {
        valueReturn =
          valueReturn + getValueByType(_.get(list[i], fieldName, ""), type,column);
      }
      if (i + 1 < list.length) {
        valueReturn = valueReturn + ", ";
      }
    }

    return valueReturn;
  } else {
    if (path) {
      return _.get(row, path + "." + fieldName);
    } else {
      return _.get(row, fieldName);
    }
  }
}
function getValueInEnumList(value,column){
  if(column && column.enumListValues){
    var result = column.enumListValues.find(obj => {
      return obj.name === value
    })
    return result?result.description:"--";
  }else{
    return value;
  }
  
}
function getValueByType(value, type,column) {
  if (typeof value !== "undefined") {
    switch (type) {
      case datatableConstants.NUMBER:
        return value;
      case datatableConstants.SELECT:
        return value;
      case datatableConstants.STRING:
        return value;
      case datatableConstants.DOCUMENT:
        return value;
      case datatableConstants.DATE:
        return dateHelper.format(value, { mode: "DATE" }) ;
      case datatableConstants.BOOLEAN:
        if (value) {
          return "Sim";
        } else {
          return "Não";
        }
      case datatableConstants.OBJECT:
        return value;
      case datatableConstants.ENUM:
        return value.description?value.description:getValueInEnumList(value,column);
    }
  } else {
    return "--";
  }
}
