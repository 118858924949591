import React, { PureComponent } from "react";
import sombra from "../statusAtendimentos/img/sombra.svg";
import iconRepasse from "../../img/icon_repassado_GREEN.svg";
import * as _ from "lodash";
import { formatterHelper } from "../../helpers/formatter.helper";
import { objectsConstants } from "../../constants/objects.constants";

export default class SumarioRepasse extends PureComponent<
  Props,
  State
  > {
    static defaultProps={
      sumario:objectsConstants.SUMARIO_REPASSE
    }
  constructor(props) {
    super(props);    
  }  

  render() {
    return (
      <React.Fragment>
       {/* Início Repassado */}
      <div className="row box-branco-repassados mt-2">
            <div className="col-8 offset-2 col-sm-3 offset-sm-0 d-flex flex-column px-4 pb-3 pb-sm-0">
              <div className="pt-3 pt-sm-0 pb-3"><img src={iconRepasse} alt="icone" width="80px"></img></div>
              <div className="fz-sm-16">Total consolidado das guias repassadas</div>
            </div>
            <div className="col-12 col-sm-5 border-left fz-sm-11  px-4">
              <div className="row mb-4 text-left">
                <div className="col-8 col-sm-9">Base Bruta de Imposto de Renda</div>
                <div className="col-4 col-sm-3 font-bold">{formatterHelper.money(this.props.sumario.baseBrutaIR)}</div>
              </div>
              <div className="row font-italic text-left mb-1">
                <div className="col-8 col-sm-9">Desconto de Dependente no IR</div>
                <div className="col-4 col-sm-3">{formatterHelper.money(this.props.sumario.descontoDependenteIR)}</div>
              </div>
              <div className="row font-italic text-left mb-1">
                <div className="col-8 col-sm-9">Base Líquida de Imposto de Renda</div>
                <div className="col-4 col-sm-3">{formatterHelper.money(this.props.sumario.baseLiquidaIR)}</div>
              </div>
              <div className="row mb-4 font-italic text-left">
                <div className="col-8 col-sm-9">Valor Imposto de Renda</div>
                <div className="col-4 col-sm-3">{formatterHelper.money(this.props.sumario.valorIR)}</div>
              </div>
              <div className="row font-italic text-left">
                <div className="col-8 col-sm-9">Saldo Devedor Acumulado</div>
                <div className="col-4 col-sm-3">{formatterHelper.money(this.props.sumario.valorDevedorAcumulado)}</div>
              </div>
            </div>
            <div className="d-block d-sm-none line"></div>
            <div className="col-12 col-sm-4 border-left fz-sm-11 px-4 d-flex align-items-end flex-column">
              <div className="w-100 mb-3 d-flex flex-row text-left">
                <div className="col-8 p-0">Total de Créditos</div>
                <div className="col-4 p-0 font-bold d-flex">{formatterHelper.money(this.props.sumario.totalCreditos)}</div>
              </div>
              <div className="w-100 font-italic d-flex flex-row text-left">
                <div className="col-8  p-0">Total de Glosa devida</div>
                <div className="col-4 font-bold  p-0">{formatterHelper.money(this.props.sumario.valorTotalGlosado)}</div>
              </div>
              {/*<div className="w-100 font-italic d-flex flex-row text-left">*/}
              {/*  <div className="col-8  p-0">Total de Descontos</div>*/}
              {/*  <div className="col-4 font-bold  p-0">{formatterHelper.formatMoney(this.props.sumario.totalDesconto)}</div>*/}
              {/*</div>*/}
              <div className="w-100 roboto fz-sm-16 mb-3 font-bold d-flex flex-row mt-auto text-left">
                <div className="col-8 verde-super-destaque  p-0">Total Repassado</div>
                <div className="col-4  p-0">{formatterHelper.money(this.props.sumario.totalRepassado)}</div>
              </div>
            </div>
          </div>
          {/* Fim Repassado */}
      </React.Fragment>
    );
  }
}
