import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormGroup } from '../../../../components/FormGroup';
import FormInput from '../../../../components/Inputs/FormInput';
import InputViewEdit from '../../../../components/Inputs/InputViewEdit';
import SelectableInput from '../../../../sascComponents/inputs/SelectableInput';
import FormSelectInput from '../../../../components/Inputs/FormSelectInput';
import { enumService } from '../../../../services/enum.service';
import * as _ from "lodash";

class DadosContratadoGuiaForm extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      permissions,
      setFieldValue,
      values,
      errors,
      handleChange,
      viewMode
    } = this.props;
    return (
      <div className="row section-form">
        <h6 className="w-100">Dados do Contratado Solicitado / Dados da internação</h6>
        <SelectableInput viewMode={viewMode} atendimento={values} className="fixo"
          name={"Código na operadora"}
          label={"codigoOperadoraContratada"}
          value={values.codigoOperadoraContratada}
        >
          <InputViewEdit
            label="Código na operadora"
            component={FormInput}
            onChange={(name, date) => {
              setFieldValue(name, date);
            }}
            viewMode={viewMode}
            disabled={true}
            name={'codigoOperadoraContratada'}
            placeholder=""
            type={'text'}
            value={values.codigoOperadoraContratada}
          />
        </SelectableInput>
        <SelectableInput viewMode={viewMode} atendimento={values} className=""
          name={"Nome do prestador"}
          label={"nomeContratado"}
          value={values.nomeContratado}
        >
          <InputViewEdit
            label="Nome do prestador"
            component={FormInput}
            onChange={(name, date) => {
              setFieldValue(name, date);
            }}
            viewMode={viewMode}
            disabled={true}
            name={'nomeContratado'}
            placeholder=""
            type={'text'}
            value={values.nomeContratado}
          />
        </SelectableInput>
        <div className="quebra"></div>
        
        <SelectableInput viewMode={viewMode} atendimento={values} className="fixo"
          name={"caraterAtendimento"}
          label={"Caráter de internação"}
          value={values.caraterAtendimento}
        >
          <InputViewEdit
            label="Caráter de internação"
            component={FormSelectInput}
            service={enumService.findCaraterAtendimento}
            name={"caraterAtendimento"}
            defaultValue={
              _.get(values, `caraterAtendimento.description`)
            }
            returnFullObject={true}
            searchable={true}
            labelKey={"description"}
            required={true}
            erroMensagem={
              _.get(errors, `caraterAtendimento`)
            }
            valueKey={"name"}
            value={values.caraterAtendimento}
            alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
            viewMode={viewMode}
            onChange={(name, value) => {
              setFieldValue(name, value);
            }}
          />
        </SelectableInput>
        <SelectableInput viewMode={viewMode} atendimento={values} className="fixo"
          name={"tipoInternacao"}
          label={"Tipo de internação"}
          value={values.tipoInternacao}
        >
          <InputViewEdit
            label="Tipo de internação"
            component={FormSelectInput}
            service={enumService.findTipoInternacao}
            name={"tipoInternacao"}            
            returnFullObject={true}
            searchable={true}
            labelKey={"description"}
            required={true}
            valueKey={"name"}
            value={values.tipoInternacao}
            viewMode={viewMode}
            onChange={(name, value) => {
              setFieldValue(name, value);
            }}
            erroMensagem={
              _.get(errors, `tipoInternacao`)
            }
            defaultValue={
              _.get(values, `tipoInternacao.description`)
            }
            alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
          />
        </SelectableInput>
        <SelectableInput viewMode={viewMode} atendimento={values} className="fixo"
          name={"regimeInternacao"}
          label={"Regime de internação"}
          value={values.regimeInternacao}
        >
          <InputViewEdit
            label="Regime de internação"
            component={FormSelectInput}
            service={enumService.findRegimeInternacao}
            name={"regimeInternacao"}
            
            returnFullObject={true}
            searchable={true}
            labelKey={"description"}
            required={false}
            valueKey={"name"}
            value={values.regimeInternacao}
            viewMode={viewMode}
            onChange={(name, value) => {
              setFieldValue(name, value);
            }}
            defaultValue={
              _.get(values, `regimeInternacao.description`)
            }
            alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
          />
        </SelectableInput>
        <SelectableInput viewMode={viewMode} atendimento={values} className="fixo"
          name={"diariasSolicitadas"}
          label={"Diárias solicitadas"}
          value={values.diariasSolicitadas}>

          {/* <p>Astolfo Soares Pereira</p> */}
          <InputViewEdit
            label="Diárias solicitadas"
            component={FormInput}
            required={true}
            onChange={(name, date) => {
              setFieldValue(name, date);
            }}
            name={"diariasSolicitadas"}
            placeholder="Diárias solicitadas"
            type={"number"}
            viewMode={viewMode}
            erroMensagem={errors.diariasSolicitadas}
            value={values.diariasSolicitadas}
            //erroMensagem={
            //  _.get(errors, `diariasSolicitadas`)
            //}
            alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
          />
        </SelectableInput>
        <SelectableInput viewMode={viewMode} atendimento={values} className=""
          name={"indicacaoClinica"}
          label={"Indicação Clínica"}
          value={values.indicacaoClinica}>
          <InputViewEdit
            label="Indicação Clínica"
            component={FormInput}
            required={false}
            onChange={(name, date) => {
              setFieldValue(name, date);
            }}
            name={"indicacaoClinica"}
            placeholder="Informe se possuí uma indicação clínica"
            type={"text"}
            viewMode={viewMode}
            value={values.indicacaoClinica}
            alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
          />
        </SelectableInput>
      </div>
    );
  }
}

const mapDispatch = ({ menu: { showMenu, hideMenu } }) => ({
  showMenu: () => showMenu(),
  hideMenu: () => hideMenu()
});

function mapStateToProps(state) {
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    alert,
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(DadosContratadoGuiaForm));
