//@flow
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { avisosService } from '../../services/avisos.service';
import { userService } from '../../services/user.service';
import TopoTitleComponente from '../home/TopoTitleComponente';
import AvisosTopoView from './AvisosTopoView';
import AvisosForm from "./AvisosForm";

const NewUsuario = Yup.object().shape({
  nome: Yup.string()
    .min(10, 'Nome muito curto!')
    .max(250, 'Nome muito grande!')
    .required('Obrigatório'),
  email: Yup.string()
    .email('email inválido')
    .required('Obrigatório'),
  telefone: Yup.string().required('Obrigatório'),
  cpf: Yup.string().required('Obrigatório'),
  password: Yup.string().required('Obrigatório')
});
class AvisosView extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: { status: true }
    };
  }

  componentDidMount() {
    this.loadUser();
  }

  loadUser = () => {
    if (this.props.match.params.id) {
      this.props.loading(true);
      avisosService.doGet(this.props.match.params.id).then(
        response => {
          let user = response.data.data;
          this.setState({ entity: user }, () => {
            this.props.loading(false);
          });
        },
        error => {
          console.log(error);
          this.props.loading(false);
        }
      );
    }
  };
  goToEdit = () => {
    this.props.history.push({
      pathname: "/avisos/edit/" + this.state.entity.id,
      state: { entity: this.state.entity }
    });
  };
  isAtivo = values => {
    return values.status && values.status.name === 'ATIVO' ? true : false;
  };

  render() {
    const { entity } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid container-com-topo">
          {entity.id && (
            <React.Fragment>
              <TopoTitleComponente
                mainTitle={`Dados do aviso`}
                canBack={!userService.isCooperado(userService.getCurrentUser())}
                backUrl={'/avisos'}
              />
              <AvisosTopoView
                values={entity}
                viewMode={true}
                edit={this.goToEdit}
                qtdeAtendimento={entity.qtdeAtendimento}
                
              ></AvisosTopoView>

              <AvisosForm
                values={entity}
                viewMode={true}
                errors={[]}
                refreshEntity={this.loadUser}
                ></AvisosForm>



             
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading }
}) => ({
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  clear: () => clear()
});

export default connect(
  null,
  mapDispatch,
  null,
  { withRef: true }
)(withRouter(AvisosView));