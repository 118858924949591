import React, { PureComponent } from "react";
import Avatar from "react-avatar";
import { connect } from "react-redux";
import { formatterHelper } from "../../helpers/formatter.helper";

class AvatarSimples extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      image: null,
      dataUrl: null
    };
  }

  getUserAvatar = () => {
    if (this.state.dataUrl) {
      return this.state.dataUrl;
    }
  };


  render() {

    return (
      <div  >

        <Avatar
          maxInitials={2}
          name={formatterHelper.getNameAndLastName(this.props.nome)}
          unstyled={true}
          className={`avatarCustom simples`}
          size={this.props.size}
          src={this.getUserAvatar()}
        />
      </div>
    );
  }
}
export default connect()(AvatarSimples);
