//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import AtendimentoList from './AtendimentoList';
export default class Atendimento extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/atendimento" component={AtendimentoList} />
        </Switch>
      </React.Fragment>
    );
  }
}
