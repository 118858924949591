//import { Can } from "@casl/react";
import _ from "lodash";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, CardHeader } from "reactstrap";
import { FormGroup } from "../../components/FormGroup";
import CpfCnpjInput from "../../components/Inputs/CpfCnpjInput";
import DatePickerInput from "../../components/Inputs/DatePickerInput";
import FormInput from "../../components/Inputs/FormInput";
import FormSelectInput from "../../components/Inputs/FormSelectInput";
import InputViewEdit from "../../components/Inputs/InputViewEdit";
import SelectInput from "../../components/Inputs/SelectInput";
import { objectsConstants } from "../../constants/objects.constants";
import { dateHelper } from "../../helpers/date.helper";
import { formatterHelper } from "../../helpers/formatter.helper";
import { convenioService } from "../../services/convenio.service";

class BeneficiarioForm extends PureComponent<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            viewMode: true
        };
    }
    goToEdit = () => {
        this.props.history.push({
            pathname: "/beneficiarios/edit/" + this.props.values.id,
            state: { entity: this.props.values }
        });
    };

    confirmCancel = setValues => {
        this.setState({ viewMode: true });
    };

    render() {
        const {
            values,
            setFieldValue,
            errors,
            handleChange,
            isSubmitting,
            handleSubmit,
            viewMode,
            validateForm,
            setValues
        } = this.props;
        return (
            <React.Fragment>
                <div className="item">
                    <Card className="cardCrud content-form">
                        <CardHeader>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="mr-sm-auto card-title">Dados</div>
                                <div className="px-sm-3">
                                    {viewMode && (
                                        <button
                                            type="button"
                                            className="btn btn-light"
                                            onClick={this.goToEdit}
                                        >
                                            <span className="icon-btn-editar"></span>Editar
                                        </button>
                                    )}
                                </div>
                            </div>
                        </CardHeader>

                        <CardBody className={"form-inside viewMode"}>
                            <div className="row section-form">
                                <FormGroup className="">
                                    <InputViewEdit
                                        component={FormInput}
                                        label={'Nome do beneficiário'}
                                        type="text"
                                        id="nome"
                                        name="nome"
                                        value={values.nomeBeneficiario}
                                        onChange={(name, value) => setFieldValue(name, value)}
                                        noSize={true}
                                        viewMode={true}
                                        required={true}
                                    />
                                </FormGroup>

                                <FormGroup className="fixo">
                                    <InputViewEdit
                                        component={CpfCnpjInput}
                                        label="CPF"
                                        id="secretariaCpf"
                                        name="cpf"
                                        value={values.cpf}
                                        onChange={(name, value) => setFieldValue(name, value)}
                                        noSize={true}
                                        viewMode={true}
                                        required={true}
                                    />
                                </FormGroup>

                                <FormGroup className="col">
                                    <InputViewEdit
                                        label={"Data nascimento"}
                                        component={DatePickerInput}
                                        onChange={(name, date) => { setFieldValue(name, date) }}
                                        defaultValue={
                                            values.dataNascimento
                                                ? dateHelper.format(values.dataNascimento)
                                                : "--"
                                        }
                                        mask={"99/99/9999"}
                                        name={"dataFim"}
                                        id={"dataFim"}
                                        placeholder=""
                                        required={true}
                                        type={"text"}
                                        viewMode={true}
                                        value={values.dataNascimento}
                                    />
                                </FormGroup>
                            </div>
                        </CardBody>
                        <CardHeader className="subheader">Validar elegibilidade</CardHeader>
                        <CardBody className={"form-inside viewMode"}>
                            <div className="row section-form">
                                <FormGroup className="">
                                    <InputViewEdit
                                        component={FormInput}
                                        label={'Número da carteira'}
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={values.numeroCarteira}
                                        onChange={(name, value) => setFieldValue(name, value)}
                                        noSize={true}
                                        viewMode={true}
                                        required={false}
                                    />
                                </FormGroup>

                                <FormGroup className="col-5">
                                    <InputViewEdit
                                        label={"Convênio"}
                                        required={true}
                                        component={FormSelectInput}
                                        service={convenioService.findListagem}
                                        className={"destaque"}
                                        sortKey={"sigla"}
                                        id={"convenio"}
                                        name={"beneficiarioConvenioBloqueio.convenio"}
                                        erroMensagem={_.get(errors, "beneficiarioConvenioBloqueio.convenio.id")}
                                        returnFullObject={true}
                                        searchable={true}
                                        labelKey={"sigla"}
                                        valueKey={"id"}
                                        defaultValue={values.beneficiarioConvenioBloqueio && values.beneficiarioConvenioBloqueio.convenio ? values.beneficiarioConvenioBloqueio.convenio.nome : "--"}
                                        value={values.beneficiarioConvenioBloqueio && values.beneficiarioConvenioBloqueio.convenio}
                                        viewMode={viewMode}
                                        onChange={(name, value) => {
                                            setFieldValue(name, value)
                                        }}
                                    />
                                </FormGroup>

                                <FormGroup className="w-20">
                                    <InputViewEdit
                                        label="Bloquear digitação da carteira para este convênio"
                                        component={SelectInput}
                                        required={true}
                                        options={objectsConstants.SIM_NAO}
                                        id={"bloquear_digitacao"}
                                        name={"beneficiarioConvenioBloqueio.bloquear_digitacao"}
                                        erroMensagem={_.get(errors, "beneficiarioConvenioBloqueio.bloquear_digitacao")}
                                        searchable={true}
                                        returnFullObject={true}
                                        defaultValue={
                                            values.beneficiarioConvenioBloqueio ? (
                                                values.beneficiarioConvenioBloqueio.bloquear_digitacao &&
                                                    values.beneficiarioConvenioBloqueio.bloquear_digitacao.value == false
                                                    ? "Não"
                                                    : formatterHelper.getLabelEnum(
                                                        values.beneficiarioConvenioBloqueio.bloquear_digitacao &&
                                                            values.beneficiarioConvenioBloqueio.bloquear_digitacao.value
                                                            ? values.beneficiarioConvenioBloqueio.bloquear_digitacao.value
                                                            : values.beneficiarioConvenioBloqueio.bloquear_digitacao,
                                                        objectsConstants.SIM_NAO
                                                    )) : "--"
                                        }
                                        value={values.beneficiarioConvenioBloqueio ? (
                                            _.isBoolean(values.beneficiarioConvenioBloqueio.bloquear_digitacao)
                                                ? formatterHelper.getEnum(
                                                    values.beneficiarioConvenioBloqueio.bloquear_digitacao,
                                                    objectsConstants.SIM_NAO
                                                )
                                                : values.beneficiarioConvenioBloqueio.bloquear_digitacao) : false
                                        }
                                        labelKey={"label"}
                                        valueKey={"value"}
                                        viewMode={viewMode}
                                        onChange={(name, value) => {
                                            setFieldValue(name, value.value);
                                        }}
                                        apendRoot={true}
                                    />
                                </FormGroup>

                            </div>
                        </CardBody>
                    </Card>
                </div>

                <div className="item">
                    {!viewMode && (
                        <div className="d-flex flex-row flex-sm-row pt-2 pb-3 justify-content-center">
                            <button
                                type="submit"
                                className="btn btn-primary d-blokc d-sm-none"
                                disabled={isSubmitting}
                                onClick={handleSubmit}
                            >
                                Salvar
                            </button>
                        </div>
                    )}
                </div>
            </React.Fragment>
        );
    }
}
const mapDispatch = ({
    alert: { success, error, clear, info },
    load: { loading }
}) => ({
    success: msg => success(msg),
    loading: (load: boolean) => loading({ load }),
    error: msg => error(msg),
    clear: () => clear(),
    info: msg => info(msg)
});

function mapStateToProps(state) {
    const { user, permissions } = state.authentication;

    return {
        user,
        permissions
    };
}

export default connect(
    mapStateToProps,
    mapDispatch
)(withRouter(BeneficiarioForm));
