import React, { PureComponent } from 'react';
import InputMask from 'react-input-mask';
import { FormGroup, Label } from 'reactstrap';
import { translate } from '../../helpers/message.helper';
import { connect } from 'react-redux';
import { ToolTipFeedBack } from '../Utils/ToolTipFeedBack';

type Props = {
  label: string,
  name: string,
  id: string,
  value?: string | string[] | number,
  size?: number,
  onChange: any,
  onBlur: any,
  children?: any,
  required: boolean,
  messageValidate?: string,
  placeholder: string,
  disabled: boolean,
  maxLength: number,
  style: any
};
class CpfCnpjInput extends PureComponent<Props> {

  render() {
    const { messages, name } = this.props;

    const value = this.props.value || '';
    let messageValidate;
    if (messages != null && messages.length > 0) {
      messages.forEach(function (message, index) {
        if (message.fieldName == name) {
          messageValidate = translate(message.message.code);
        }
      });
    }
    if (this.props.erroMensagem) {
      messageValidate = this.props.erroMensagem;
    }

    let mask;
    let match = value.match(/\d+/g);

    if (match != null && match.join('').length > 11) {
      mask = '99.999.999/9999-99';
    } else {
      mask = '999.999.999-999999';
    }

    return (
      <div
        className={`${this.props.required ? "input-required" : ""}`}
      >
        {this.props.label && (
          <Label for={this.props.name} className='label-nowrap' title={this.props.label}>
            {this.props.label}
            {this.props.required && '*'}
          </Label>
        )}
        <InputMask
          mask={mask}
          maskChar=""
          id={this.props.id}
          name={this.props.name}
          value={value}
          onChange={e => {
            const { name, type, value, checked } = e.currentTarget;
            this.props.onChange(name, value, type, checked);
          }}
          required={this.props.required}
          placeholder={this.props.placeholder === "" || this.props.placeholder === undefined ? this.props.label : this.props.placeholder}
          onBlur={this.props.onBlur}
          disabled={this.props.disabled ? this.props.disabled : false}
          className=""
          className={`form-control ${this.props.className} ${
            messageValidate ? "is-invalid" : ""
            }`}
        >
          {this.props.children}
        </InputMask>
        {messageValidate && (
          <ToolTipFeedBack
            target={this.props.id}
            messageValidate={messageValidate}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { messages } = state.fieldMessage;
  const { alert } = state;

  return {
    messages,
    alert
  };
}

export default connect(mapStateToProps)(CpfCnpjInput);
