//@flow
import React, { PureComponent } from 'react';
import ScrollToTop from 'react-scroll-up';
import TopButton from '../../img/icon_ordenacao.svg';

class ButtonToTop extends PureComponent<> {
  render() {
    return (
      <ScrollToTop
        // style={{ position: 'fixed', textalign: 'center', bottom: 80, }}
        showUnder={160}
        duration={500}
        className="btn-topo"
      >
        <div className="btn-topo">
          <img src={TopButton} alt="topo" className="top-button"></img>
          <span className="title-button-top">Voltar</span>
        </div>
      </ScrollToTop>
    );
  }
}

export default ButtonToTop;
