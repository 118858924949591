import React, { PureComponent } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import anexo from "../../img/icon_anexo_BIG.svg";
import MultiFileViewer from "./MultiFileViewer";

type Props = {
  target?: any,
  itens: [],
};

export class ModalPreVisualizarAnexos extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      openAnexo: false,
      fileURL: null,
    };
  }
  toggle = () => {
    this.props.toogle();
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.toggle}
          backdrop="static"
          modalClassName=""
          className="modal-xl"
          centered={true}
        >
          <ModalBody className={''}>
            <div>
              <div className="row">
                <div className="col-12 text-center ">
                  <img src={anexo} alt="anexo" />
                </div>
                <div className="col-12 text-center my-2">
                  <h2 className="font-weight-bold">Pré-Visualizar anexo</h2>
                </div>
              </div>
               {this.props.anexo && this.props.itens &&(
                 <MultiFileViewer anexos ={this.props.itens} idSelecionado={this.props.anexo.id} />
               )}
            </div>
          </ModalBody>
          <ModalFooter>
            <React.Fragment>
              <div className="w-100 text-center">
                <button
                  className="btn-secondary"
                  onClick={() => {
                    this.toggle();
                  }}
                >
                  Fechar
                </button>
              </div>
            </React.Fragment>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}
