import { defaultService } from "./defaultService";
import { urlsConstants } from "../constants/url.constant";

export const integracaoAtendimentoService = {
    findAll,
    findTableColumns,
    findSearchableColumns,
    findByFilter,
    exportToExcel
};

function findAll() {
    return defaultService.doGet(`${urlsConstants.INTEGRACAOATENDIMENTO}list`);
}

function findTableColumns() {
    return defaultService.doGet(`${urlsConstants.INTEGRACAOATENDIMENTO}list/fields`);
}

function findSearchableColumns() {
    return defaultService.doGet(`${urlsConstants.INTEGRACAOATENDIMENTO}search/fields`);
}

function findByFilter(options: Options, filter: any): Promise<Array<>> {
    return defaultService.findByFiltro(
        `${urlsConstants.INTEGRACAOATENDIMENTO}list`,
        options,
        filter
    );
}

function exportToExcel(filter, fields, labels) {
    return defaultService.exportToExcel('integracaoAtendimento', filter, fields, labels);
}