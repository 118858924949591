import React from "react";
import PropTypes from "prop-types";
import { default as ReactSelect } from "react-select";
import SelectInput from "./SelectInput";

const MultiSelect = props => {
  if (props.allowSelectAll) {
    return (
      <ReactSelect
        {...props}
        getOptionLabel={(data) => {
          // "data" contains the current option object, so you just use your "labelKey" to return the value of that property.
          return data[props.labelKey];
        }}
        getOptionValue={(data) => {
          // "data" contains the current option object, so you just use your "valueKey" to return the value of that property.
          return data[props.valueKey];
        }}
        options={[props.allOption, ...props.options]}
        onChange={(selected, event) => {
          if (selected !== null && selected.length > 0) {
            if (selected[selected.length - 1][props.valueKey] === props.allOption[props.valueKey]) {
              return props.onChange(props.name, [props.allOption, ...props.options]);
            }
            let result = [];
            if (selected.length === props.options.length) {
              if (selected.includes(props.allOption)) {
                result = selected.filter(
                  option => option[props.valueKey] !== props.allOption[props.valueKey]
                );
              } else if (event.action === "select-option") {
                result = [props.allOption, ...props.options];
              }
              return props.onChange(props.name, result);
            }
          }

          return props.onChange(props.name, selected);
        }}
      />
    );
  }

  return <SelectInput {...props} />;
};

MultiSelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    nome: PropTypes.string,
    id: PropTypes.string
  })
};

MultiSelect.defaultProps = {
  allOption: {
    nome: "Selecionar todos",
    id: "-1"
  }
};

export default MultiSelect;
