//@flow
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';
import * as _ from "lodash";
import {objectsConstants} from "../constants/objects.constants";
import {translate} from "../helpers/message.helper";

export const loteAtendimentoService = {
  findByFilter,
  doSave,
  doGet,
  addAtendimentos,
  doDelete,
  removeAtendimento,
  findListAtendimentoByLote,
  canCreateLote,
  addAtendimenosInLote,
  atualizarStatusLote,
  getListAtendimentoPorLote,
  getDownLoadAnexoAtendimentoPorLote,
  getDownLoadAnexoAtendimentoPorAtendimento
};

function addAtendimenosInLote ( atendimentos,loading,success,error,callback ) {  
  
  loading(true);
  let convenio = atendimentos[0].convenio;
  let lote = { atendimentos:atendimentos.map(atendimento=>{
    return atendimento.id
  }), convenio };
  doSave(lote).then(
      response => {
        let id = lote.id
            ? lote.id
            : defaultService.getIdFromUrl(response.headers.location);

        success({
          message: `Lote ${id} criado com sucesso!`
        });

              let atendimentosConvenios =[];
                for(let i=0;i<atendimentos.length;i++){
                    if(_.get(atendimentos[i], "convenio.id") !== convenio.id){

                        atendimentosConvenios.push(atendimentos[i]);
                    }
                }

        callback(id)
      },
      erros => {
        console.error(erros.response);
        try {
          let response = erros.response.data;
          if (response && response.messages) {
            for (var i = 0; i < response.messages.length; i++) {
              let erroItem = response.messages[i];
             error({
                message: translate(erroItem.message.code)
              });
            }
          }
        } catch (error) {
          console.error(error);
        }
        loading(false);
      }
  );
};
function canCreateLote (rowsCheckedValue) {
  var countBy = _.countBy(
      rowsCheckedValue,
      "estabelecimentoConvenio.convenio.id"
  );

  let canCreate = true;
  rowsCheckedValue.forEach(atendimento => {
    let situacao = _.get(atendimento, "status.situacao");
    let nome = _.get(atendimento, "status.nome");
    if (situacao === objectsConstants.SITUACAO_ATENDIMENTO.FATURAMENTO) {
      this.faturado = true;
    } else {
      this.faturado = false;
    }

    let canEdit = situacao === objectsConstants.SITUACAO_ATENDIMENTO.AUDITORIA 
      || situacao === objectsConstants.SITUACAO_ATENDIMENTO.RECEBIDA
      || situacao === objectsConstants.SITUACAO_ATENDIMENTO.REMOVIDAS_DO_LOTE
      || situacao === objectsConstants.SITUACAO_ATENDIMENTO.PENDENCIA_RESOLVIDA
      || nome === objectsConstants.INCONSISTENCIA_AUXILIARES;
    if (!canEdit) {
      canCreate = false;
    }
  });
  if (canCreate && Object.keys(countBy).length == 1) {
    return true;
  }
  return false;
};

function findListAtendimentoByLote(idLote) {
  return defaultService.doGet(`${urlsConstants.LOTE}getListAtendimentoByLote/${idLote}`);
}
function addAtendimentos(idLote, atendimentos) {

  return defaultService.doPost(`${urlsConstants.LOTE}addAtendimentos/${idLote}`, atendimentos);
}

function doGet(id) {
  return defaultService.doGet(`${urlsConstants.LOTE}${id}`);
}
function atualizarStatusLote(id) {
  return defaultService.doGet(`${urlsConstants.LOTE}atualizarStatusLote/${id}`);
}

function doSave(values) {
  return defaultService.doSave(`${urlsConstants.LOTE}`, values);
}
function doDelete(lote) {
  return defaultService.doDelete(`${urlsConstants.LOTE}${lote.id}`);
}

function removeAtendimento(idAtendimento, justificativaExclusaoLote) {
  return defaultService.doPost(`${urlsConstants.LOTE}removeAtendimento/${idAtendimento}`, { "justificativaExclusaoLote": justificativaExclusaoLote });
}

function findByFilter(options: Options, filter: any): Promise<Array<>> {

  return defaultService.findByFiltro(
    `${urlsConstants.LOTE}list`,
    options,
    filter
  );
}

function getListAtendimentoPorLote(idLote) {
  return defaultService.doGet(`${urlsConstants.LOTE}getListAtendimentoPorLote/${idLote}`);
}

function getDownLoadAnexoAtendimentoPorLote(idLote) {
  return defaultService.doGet(`${urlsConstants.LOTE}getDownLoadAnexoAtendimentoPorLote/${idLote}`);
}

function getDownLoadAnexoAtendimentoPorAtendimento(idAtendimento) {
  return defaultService.doGet(`${urlsConstants.LOTE}getDownLoadAnexoAtendimentoPorAtendimento/${idAtendimento}`);
}

