//@flow
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';
export const SubStatusService = {
  findSubStatusByCooperativa
};

function findSubStatusByCooperativa() {
  return defaultService.doGet(`${urlsConstants.SUB_STATUS}porCooperativa`);
}


