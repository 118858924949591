// @flow
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

const RememberGate = React.memo(({ doRefresh, children }) => {
  const [loading, setLoading] = useState({ loading: true });

  useEffect(() => {
    try {
      doRefresh().then(() => setLoading(false));
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }, [doRefresh]);

  if (loading) {
    return null;
  }
  return children;
});

const mapDispatch = dispatch => ({
  doRefresh: dispatch.authentication.doRefresh
});

export default connect(
  null,
  mapDispatch
)(RememberGate);
