import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import {objectsConstants} from "../../constants/objects.constants";
import {ConfirmAcationService} from "../../services/ConfirmAcationService";
import {cooperadoService} from "../../services/cooperado.service"
import {withRouter} from "react-router-dom";
class LinhaAcaoAtivarInativarCooperado extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }


    success = () =>{
        this.props.success({message: "Alterado com sucesso."});
        this.props.loading(false);
        if (this.props.initData) {
            this.props.initData();
        }
    }
    errorAtivar = () =>{
        this.props.success("Não foi possível ativar os cooperado.");
        this.props.loading(false);
    }

    errorInativar = () =>{
        this.props.success("Não foi possível inativar os cooperado.");
        this.props.loading(false);
    }

    errorVincular = () =>{
        this.props.success("Não foi possível vincular o(s) cooperado(s).");
        this.props.loading(false);
    }


    ativarCooperado = () =>{
        this.props.loading(true);
        if(this.props.checkedAllPages){
            const filter = this.props.getFilter();
            cooperadoService.ativarCooperadoByFilter(filter).then(() => {
                this.success();
            }).catch(() => {
                this.errorAtivar();
            });
        }else {
            cooperadoService.ativarCooperado(this.props.rowsCheckedValue.map((rowValue) => {
                return rowValue.id;
            }),this.props.match.params.id).then(() => {
                this.success();
            }).catch(() => {
                this.errorAtivar();
            });
        }
    }

    inativarCooperado = () =>{
        this.props.loading(true);
        if(this.props.checkedAllPages){
            const filter = this.props.getFilter();
            cooperadoService.inativarCooperadoByFilter(filter).then(() => {
                this.success();
            }).catch(() => {
                this.errorInativar();
            });
        }else {
            cooperadoService.inativarCooperado(this.props.rowsCheckedValue.map((rowValue) => {
                return rowValue.id;
            }),this.props.match.params.id).then(() => {
                this.success();
            }).catch(() => {
                this.errorInativar();
            });
        }
    }

    vincularCooperado = () => {
        this.props.loading(true);
        cooperadoService.vincularCooperado(this.props.rowsCheckedValue.map((rowValue) => {
            return rowValue.id;
        }), this.props.match.params.id).then(() => {
            this.success();
        }).catch(() => {
            this.errorVincular();
        });
    }

    render() {
        let statusCooperadoAtivo = false;
        let statusCooperadoInativo = false;
        let statusCooperadoNaoVinculado = false;
        this.props.rowsCheckedValue.map((rowValue)=>{
            if(rowValue.estabelecimentos && rowValue.estabelecimentos.length>0 && rowValue.estabelecimentos[0].status && rowValue.estabelecimentos[0].status.description === objectsConstants.ATIVO){
                statusCooperadoAtivo =true;
            }else if (rowValue.estabelecimentos && rowValue.estabelecimentos.length>0 && rowValue.estabelecimentos[0].status && rowValue.estabelecimentos[0].status.description === objectsConstants.NAO_VINCULADO) {
                statusCooperadoNaoVinculado=true;
            }else{
                statusCooperadoInativo = true;
            }
        })

        return <React.Fragment>

            {!statusCooperadoAtivo&&statusCooperadoInativo&& (
                <button type="button" className="btn btn-primary" onClick={() => {
                    ConfirmAcationService.confirmMsg(
                        this.ativarCooperado,
                        "Ativar Cooperados",
                        "Você confirma que deseja ativar os cooperados selecionados",
                        null,
                        "Cancelar",
                        "Confirmar",
                        objectsConstants.TYPE_WARNING
                    );
                }}>
                    <span className="icon-btn-check"></span>
                    Ativar Cooperados
                </button>
            )}
            {statusCooperadoAtivo&&!statusCooperadoInativo&& (
                <button type="button" className="btn btn-secondary"onClick={() => {
                    ConfirmAcationService.confirmMsg(
                        this.inativarCooperado,
                        "Inativar Cooperados",
                        "Você confirma que deseja inativar os cooperados selecionados",
                        null,
                        "Cancelar",
                        "Confirmar",
                        objectsConstants.TYPE_WARNING
                    );
                }}>
                    <span className="icon-btn-fechar"></span>
                    Inativar Cooperados
                </button>
            )}
            {statusCooperadoNaoVinculado&& (
                <button type="button" className="btn btn-primary" onClick={() => {
                    ConfirmAcationService.confirmMsg(
                        this.vincularCooperado,
                        "Ativar Cooperados",
                        "Você confirma que deseja ativar os cooperados selecionados",
                        null,
                        "Cancelar",
                        "Confirmar",
                        objectsConstants.TYPE_WARNING
                    );
                }}>
                    <span className="icon-btn-check"></span>
                    Ativar Cooperados
                </button>
            )}
            {statusCooperadoAtivo&&statusCooperadoInativo&&statusCooperadoNaoVinculado && (<span className={'labelInfoAction'}>Existem dois status diferentes selecionados</span>)}
                </React.Fragment>;
    }
}

const mapDispatch = ({
                         alert: { success, error, clear },
                         load: { loading }
                     }) => ({
    success: msg => success(msg),
    loading: (load: boolean) => loading({ load }),
    error: msg => error(msg),
    clear: () => clear()
});

export default connect(
    null,
    mapDispatch,
    null,
    { withRef: true }
)(withRouter(LinhaAcaoAtivarInativarCooperado));