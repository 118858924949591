import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Datatable from "../../sascComponents/datagrid/Datatable";
import { userService } from "../../services/user.service";
import TopoTitleComponente from "../home/TopoTitleComponente";
import TopoListagem from "../../sascComponents/topo/TopoListagem";
import LinhaAcaoAtivarInativarUsuario from "./LinhaAcaoAtivarInativarUsuario";
import { objectsConstants } from "../../constants/objects.constants";
import _ from "lodash";

const columns = [
  {
    tableName: "Usuario",
    fieldName: "midia",
    fieldDescription: "Foto",
    columnOrder: 1,
    searchableField: false,
    type: "PHOTO",
    className: "profile-datatable"
  },
  {
    tableName: "Usuario",
    fieldName: "name",
    fieldDescription: "Nome",
    columnOrder: 2,
    searchableField: true,
    type: "STRING"
  },
  {
    tableName: "Usuario",
    fieldName: "usuarioPerfis",
    labelArrayKey: "perfil.nome",
    fieldDescription: "Perfil",
    columnOrder: 3,
    searchableField: true,
    type: "ARRAY"
  },
  {
    tableName: "Usuario",
    fieldName: "convenios",
    fieldDescription: "Convênios",
    columnOrder: 4,
    searchableField: true,
    type: "NUMBER"
  },
  {
    tableName: "Usuario",
    fieldName: "status",
    fieldDescription: "Ativo",
    columnOrder: 5,
    searchableField: true,
    type: "BOOLEAN"
  }
];
class UsuarioList extends PureComponent<Props, State> {
  closeMenu = () => {
    this.props.showMenu();
  };
  handleRowClick = (fieldName, rowData) => {
    this.props.history.push({
      pathname: "/usuario/view/" + ( rowData.id),
      state: { entity: rowData }
    });
  };
  render() {
    return (
      <div className="container-fluid container-com-topo">
        <TopoTitleComponente
          mainTitle="Usuários"
          subTitle=" "
          canBack={false}
        />
        <TopoListagem
          securityActionDo={objectsConstants.ALTERAR}
          securityActionOn={objectsConstants.CRUDUSUARIO_DADOS}
          buttonActionClassName={"btn-light"}
          buttonAction={() => {
            this.props.history.push("/usuario/novo");
          }}
          securityActionIcon={"icon-btn-add"}
          securityActionName={"Novo Usuário"}
          showfilter={false}
        />
        <hr className="text-center w-75"></hr>

        <div className="background-lines">
          <div className="bg-riscos"></div>
        </div>

        <Datatable
          showCheckboxColumn={true}
          onRowClick={this.handleRowClick}
          idRow={"id"}
          classHeader={[
            "headerCenter",
            "headerLeft",
            "headerCenter",
            "headerCenter",
            "headerCenter",
            "headerCenter"
          ]}
          classColumn={[
            "colunaFoto",
            "colunaGra",
            "colunaPeq",
            "colunaPeq",
            "colunaPeq",
            "colunaPeq"
          ]}
          service={userService}
          name={"Usuário(s)"}
          showAllColumnsFilter={false}
          ActionLine={LinhaAcaoAtivarInativarUsuario}
        />
      </div>
    );
  }
}

const mapDispatch = ({ menu: { showMenu, hideMenu } }) => ({
  showMenu: () => showMenu(),
  hideMenu: () => hideMenu()
});

function mapStateToProps(state) {
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    alert,
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(UsuarioList));
