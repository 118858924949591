//@flow
import React, { PureComponent } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import '../../css/datePicker.css';
import InputMask from 'react-input-mask';
import moment from 'moment';
import { connect } from 'react-redux';
type
Props = {
    label: string,
    id: string,
    name: string,
    size?: number,
    onChange: any,
    required: boolean,
    messageValidate?: string,
    value?: any,
    placeholder: string,
    style: any,
    disabled: boolean
};

class CustomPickerInput extends PureComponent<Props> {
    _datePicker;
    static defaultProps = {
        required: false,
        size: 12
    };

    constructor(props) {
        super(props)
        let format;
        if (props.format) {
            format = props.format;
        } else {
            format = 'DD/MM/YYYY';
        }
        let dateText = props.value ? moment(props.value).format(format) : '';
        this.state = { dateText, format }
    }

    handleChangeCustom = (e) => {
        const { name, type, value, checked } = e.currentTarget;
        const dateText = value;

        const date = moment(dateText, this.state.format);
        if (date.isValid() && dateText.length === this.state.format.length && dateText != this.state.dateText) {
            this.setState({ dateText }, () => {
                this.props.onChange(date);
            })

        } else {
            this.setState({ dateText })
        }
    };

    render() {


        return (
            <React.Fragment>
                {this.props.mask && (<InputMask
                    mask={this.props.mask}
                    maskChar=""
                    id={`${this.props.id}_mask`}
                    name={this.props.name}
                    onClick={this.props.onClick}
                    value={this.state.dateText}
                    placeholder={this.props.placeholder === "" || this.props.placeholder === undefined ? this.props.label : this.props.placeholder}
                    disabled={this.props.disabled ? this.props.disabled : false}
                    className="form-control"
                    onChange={this.handleChangeCustom}
                />)}
                {!this.props.mask && (
                    <input type={'text'} onClick={this.props.onClick} onChange={this.handleChangeCustom}
                        value={this.state.dateText}
                        className={`form-control ${this.props.messageValidate ? 'is-invalid' : ''}`} />)}
            </React.Fragment>
        )
            ;
    }
}

function

    mapStateToProps(state) {
    const { messages } = state.fieldMessage;
    const { alert } = state;

    return {
        messages,
        alert
    };
}

export default connect(mapStateToProps)
    (CustomPickerInput);
