//@flow
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { estabelecimentoService } from '../../services/estabelecimento.service';
import { userService } from '../../services/user.service';
import CooperadoList from '../cooperados/CooperadoList';
import TopoTitleComponente from '../home/TopoTitleComponente';
import ConsultorioForm from './ConsultorioForm';
import ConsultorioTopoView from './ConsultorioTopoView';

const NewUsuario = Yup.object().shape({
  nome: Yup.string()
    .min(10, 'Nome muito curto!')
    .max(250, 'Nome muito grande!')
    .required('Obrigatório'),
  email: Yup.string()
    .email('email inválido')
    .required('Obrigatório'),
  telefone: Yup.string().required('Obrigatório'),
  cpf: Yup.string().required('Obrigatório'),
  password: Yup.string().required('Obrigatório')
});
class ConsultorioView extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: { status: true }
    };
  }

  

  componentDidMount() {
    this.loadUser();
  }

  

  loadUser = () => {
    if (this.props.match.params.id) {
      this.props.loading(true);
      estabelecimentoService.doGet(this.props.match.params.id).then(
        response => {
          let user = response.data.data;
          this.setState({ entity: user }, () => {
            this.props.loading(false);
          });
        },
        error => {
          console.error(error);
          this.props.loading(false);
        }
      );
    }
  };

  isAtivo = values => {
    return values.status && values.status.name === 'ATIVO' ? true : false;
  };

  disableEnabledUser = () => {
    let values =this.state.entity;
    this.props.loading(true);
    let functionStatus = this.isAtivo(values)
      ? estabelecimentoService.inativarUsuario
      : estabelecimentoService.ativarUsuario;
    functionStatus([values.id])
      .then(response => {
        this.loadUser();
        this.props.success({ message: 'Alterado com sucesso.' });
        this.props.loading(false);
      })
      .catch(() => {
        this.props.success('Não foi possível inativar o usuário.');
        this.props.loading(false);
      });
  };

  

  render() {
    const { entity } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid container-com-topo">
          {entity.id && (
            <React.Fragment>
              <TopoTitleComponente
                mainTitle={`Dados do consultório`}
                canBack={!userService.isCooperado(userService.getCurrentUser())}
                backUrl={'/consultorios'}
              />
              <ConsultorioTopoView
                values={entity}
                viewMode={true}
                disableEnabledUser={this.disableEnabledUser}
                qtdeAtendimento={entity.qtdeAtendimento}
                
              ></ConsultorioTopoView>

              <ConsultorioForm
                values={entity}
                viewMode={true}
                errors={[]}
                refreshEntity={this.loadUser}
              ></ConsultorioForm>

               <CooperadoList noCacheFilter={true} extensao={entity.id} hideTopo={true} values={entity}/>

             
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}





const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading }
}) => ({
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  clear: () => clear()
});

export default connect(
  null,
  mapDispatch,
  null,
  { withRef: true }
)(withRouter(ConsultorioView));
