import React, { PureComponent } from "react";
import Avatar from "../../components/Avatar/Avatar";
import { ModalVerAnexos } from "../../components/Utils/ModalVerAnexos";

export default class ItemHistorico extends PureComponent<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      openAnexo: false,
    };
  }

  toggleAnexos = () => {
    this.setState(({ openAnexo }) => ({ openAnexo: !openAnexo }));
  };

  render() {
    const { item } = this.props;
    return (
      <React.Fragment>
        <div className="item-historico d-flex">
          <div className="d-flex flex-column align-items-center px-3">
            <div className="fz-sm-12 mw-20 cinza5 pb-4">{this.props.index}</div>
            {item.usuario && item.usuario.id && <Avatar user={item.usuario} size={20}></Avatar>}
          </div>
          <div className="dados-historico d-flex flex-column">
            <div className={`fz-sm-12 font-italic ${item.classNameTitulo ? item.classNameTitulo : 'verde-destaque'}`}>{item.titulo}</div>
            <div className="status">{item.nomeAcao}{item.statusAcao && <span className="verde-destaque">{` - ${item.statusAcao}`}</span>}</div>
            <div className="fz-sm-10">{item.usuario && item.usuario.nome}</div>
            <div className="fz-sm-10 cinza5">{item.origen}</div>
            {item.descricao && <p className="descricao-historico">{item.descricao}</p>}
            {item.diffs && item.diffs.map((diffItem, index) => {
              return <p key={diffItem.fielName + index} className="descricao-historico">{` ${diffItem.fielName} de: `}<b>{`${diffItem.from} `}</b>para: <b>{diffItem.to}</b></p>
            })}
            {item.listaAnexo && item.listaAnexo.length > 0 && (
              <button className="btn-link linkAnexo"
                onClick={() => {
                  this.toggleAnexos();
                }}>
                <span className="icon-btn-anexar"></span> Anexos
              </button>
            )}
          </div>


          <ModalVerAnexos
            name="verAnexos"
            toogle={this.toggleAnexos}
            isOpen={this.state.openAnexo}
            target={"anexos"}
            itens={item.listaAnexo}
          />
        </div>
      </React.Fragment>
    );
  }
}
